import React from 'react';
import intl from 'react-intl-universal';
import { Box, IconButton, Menu, MenuItem, Tooltip, Badge, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';

import { DirectoryType } from '../../types';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    iconStyle: {
        color: theme.palette.text.primary,
    },
    errorIcon: {
        marginLeft: theme.spacing(1)
    }
}));

interface Props {
    directory: DirectoryType;
    editDirectory: (directory: DirectoryType, action?: string) => void;
}

const EditPopover = ({ directory, editDirectory }: Props): JSX.Element => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleCancelSub = () => {
        setAnchorEl(null);
        editDirectory(directory, 'cancel');
    };

    const handlePaySub = () => {
        setAnchorEl(null);
        editDirectory(directory, 'pay');
    };

    const handleUpgrade = () => {
        editDirectory(directory, 'upgrade');
    };

    const paySubscription = intl.get('directory.update.paySubscription').d('Pay Subscription');
    const cancelSubscription = intl.get('directory.update.cancelSubscription').d('Cancel Subscription');
    const upgradePlan = intl.get('directory.update.upgradePlan').d('Upgrade Plan');
    const editPlan = intl.get('directory.update.editPlan').d('Edit');


    const paymentRequired = directory.featured && !directory.activeSubscription;
    let action = <></>;

    // Check if the directory is featured
    if (directory.featured) {
        // Featured directories either:
        // have a subscription - provide cancel action
        // do not have a subscription - provide payment action
        if (paymentRequired) {
            // Provide action to pay subscription
            action = (
                <MenuItem onClick={handlePaySub}>
                    <Typography variant="h4">{paySubscription}</Typography>
                    <ErrorIcon fontSize="small" color="error" className={classes.errorIcon} />
                </MenuItem>
            );
        } else {
            // Provide action to cancel subscription
            action = (
                <MenuItem onClick={handleCancelSub}>
                    <Typography variant="h4">{cancelSubscription}</Typography>
                </MenuItem>
            );
        }
    } else {
        // Provide action to upgrade to featured
        action = (
            <MenuItem onClick={handleUpgrade}>
                <Typography variant="h4">{upgradePlan}</Typography>
            </MenuItem>
        );
    }

    return (
        <Box className={classes.root}>
            {paymentRequired ? (
                <Badge
                    variant="dot"
                    color="error"
                    overlap="circular"
                    badgeContent=" "
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                >
                    <Tooltip enterTouchDelay={50} title="Edit">
                        <IconButton onClick={handleMenuOpen} size="small" aria-label="edit">
                            <EditIcon className={classes.iconStyle} />
                        </IconButton>
                    </Tooltip>
                </Badge>
            ) : (
                <Tooltip enterTouchDelay={50} title="Edit">
                    <IconButton onClick={handleMenuOpen} size="small" aria-label="edit">
                        <EditIcon className={classes.iconStyle} />
                    </IconButton>
                </Tooltip>
            )}
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => editDirectory(directory)}>
                    <Typography variant="h4">{editPlan}</Typography>
                </MenuItem>
                {action}
            </Menu>
        </Box>
    );
};

export default EditPopover;
