import React from 'react';
import intl from 'react-intl-universal';
import { Box, Grid } from '@material-ui/core';

import Property from '../Property';

import { AddressType, PropertyType, ExtraDetailsType } from '../../types';
import { ITEM_URL_PROPERTY_NAME } from '../../../paddock/constants';

interface Props {
    properties?: ExtraDetailsType;
    address?: AddressType;
}

type SortedPropertyType = {
    key: string;
    value: PropertyType;
}

const groupBy = (props: ExtraDetailsType, prop: PropertyType, currentKey: string, groupBy?: string) => {
    if (!groupBy) {
        return prop.value;
    }

    let groupedValue: string | undefined = undefined;

    // Get all other keys that are not the current key
    const keys = Object.keys(props).filter((key) => key !== currentKey);

    // Loop through all keys in the object
    for (let i = 0; i < keys.length; i++) {
        // a prop that is not our current key
        const name = keys[i];
        // If the childName of the main prop matches the name of any other key, join their values together and return it.
        if (groupBy === name) {
            const childProp = props[name];
            return groupedValue = `${prop.value} ${childProp.value}`;
        }
    }

    return groupedValue;
};

const AdvancedInfo = ({ properties, address }: Props): JSX.Element => {
    if (!properties) {
        return <></>;
    }

    // const sortPropertiesByOrder = () => {
    //     const entries = Object.entries(properties);
    //     const sorted = entries.sort((a, b) => 0);

    //     return sorted;
    // };

    const sortedProperties = () => {
        const arr: Array<SortedPropertyType> = [];

        for (const prop in properties) {
            if (Object.prototype.hasOwnProperty.call(properties, prop)) {
                arr.push({
                    key: prop,
                    value: properties[prop]
                });
            }
        }

        // sort the array by number ascending. If the order is undefined, it should be ignored and pushed to the end of the array
        arr.sort(({ value: a }, { value: b }) => (a.order !== undefined ? a.order : Infinity) - (b.order !== undefined ? b.order : Infinity));

        return arr; // returns array
    };

    const getAddressProps = () => {
        if (address) {
            const { country, state, city, postal, address1 } = address;
            const addressJSX: Array<JSX.Element> = [];

            const countryText = intl.get('paddock.page.listing.property.country.label').d('Country');
            const stateText = intl.get('paddock.page.listing.property.state.label').d('State');
            const cityText = intl.get('paddock.page.listing.property.city.label').d('City');
            const addressText = intl.get('paddock.page.listing.property.address.label').d('Address');
            const postalText = intl.get('paddock.page.listing.property.postal.label').d('Postal Code');

            if (country) {
                const countryJSX = (
                    <Grid key="address-prop-country" item xs={12} sm={6} md={4} lg={3}>
                        <Property name={countryText} value={country} />
                    </Grid>
                );
                addressJSX.push(countryJSX);
            }

            if (state) {
                const stateJSX = (
                    <Grid key="address-prop-state" item xs={12} sm={6} md={4} lg={3}>
                        <Property name={stateText} value={state} />
                    </Grid>
                );
                addressJSX.push(stateJSX);
            }

            if (city) {
                const cityJSX = (
                    <Grid key="address-prop-city" item xs={12} sm={6} md={4} lg={3}>
                        <Property name={cityText} value={city} />
                    </Grid>
                );
                addressJSX.push(cityJSX);
            }

            if (address1) {
                const address1JSX = (
                    <Grid key="address-prop-address1" item xs={12} sm={6} md={4} lg={3}>
                        <Property name={addressText} value={address1} />
                    </Grid>
                );
                addressJSX.push(address1JSX);
            }

            if (postal) {
                const postalJSX = (
                    <Grid key="address-prop-postal" item xs={12} sm={6} md={4} lg={3}>
                        <Property name={postalText} value={postal} />
                    </Grid>
                );
                addressJSX.push(postalJSX);
            }

            return addressJSX;
        }

        return <></>;
    };

    return (
        <Box>
            <Grid container direction="row" spacing={2} justifyContent="flex-start">
                { getAddressProps() }
                {
                    sortedProperties().map(({ key, value: prop }) => {
                        if (key === ITEM_URL_PROPERTY_NAME) {
                            return <React.Fragment key={key} />;
                        }

                        /** Removed unused icons
                         *  const { label, value, hidden, childName, icon, iconType } = prop; */
                        const { label, value, hidden, childName } = prop;
                        if (label && label.trim().length > 0) {
                            if (!hidden) {
                                const groupedValue = groupBy(properties, prop, key, childName) || value;
                                return (
                                    <Grid key={key} item xs={12} sm={6} md={4} lg={3}>
                                        {
                                        /** these icons are configuring on CMS side collection types AuctionProperties and ListingProperties pages
                                         *  for now it looks completely broken feature that uses paid version of fontawesome.
                                         *  now it's replaced with component without icons props
                                         *  <Property name={label} value={groupedValue} icon={icon} iconType={iconType} /> */
                                        }
                                        <Property name={label} value={groupedValue} />
                                    </Grid>
                                );
                            }
                        }

                        return <React.Fragment key={key} />;
                    })
                }
            </Grid>
        </Box>
    );
};

export default AdvancedInfo;
