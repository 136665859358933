import React from 'react';
import { Pagination as MUIPagination } from '@material-ui/core';
import { connectPagination } from 'react-instantsearch-dom';
import { scrollToTop } from '../../helpers';

interface Props {
    refine: (...args: any[]) => any;
    createURL: (...args: any[]) => any;
    currentRefinement: number;
    nbPages: number;
    scrollToId?: string;
}

const Pagination = ({ currentRefinement, refine, nbPages, scrollToId }: Props): JSX.Element => {
    if (nbPages > 1) {
        return (
            <MUIPagination
                color="primary"
                count={nbPages}
                shape="circular"
                page={currentRefinement}
                onChange={(e, value) => {
                    refine(value);
                    if (scrollToId) {
                        scrollToTop(scrollToId);
                    }
                }}
            />
        );
    }

    return <></>;
};

export default React.memo(connectPagination(Pagination));
