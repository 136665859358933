import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { QUERY_IS_LOGGED_IN } from '../../queries';
import { IsLoggedInDataType } from '../../types';
import useIsMountedRef from '../useIsMountedRef';
import { useAuthenticatedUserContext } from '..';

type State = {
    isLoggedIn: boolean;
    isConfirmed: boolean;
    isBlocked: boolean;
    hasCMSAccess: boolean;
};

type ReturnType = [State, () => void];

const useAuthentication = (): ReturnType => {
    const isMountedRef = useIsMountedRef();
    const [state, setState] = useState<State>({
        isLoggedIn: false,
        isBlocked: false,
        isConfirmed: false,
        hasCMSAccess: false
    });
    const [getLoggedIn, { data: dataLoggedIn }] = useLazyQuery<IsLoggedInDataType>(QUERY_IS_LOGGED_IN);
    const { user, refetch: refetchUser } = useAuthenticatedUserContext();

    useEffect(() => {
        if (isMountedRef.current) {
            getLoggedIn();
        }

        if (dataLoggedIn) {
            const { isLoggedIn } = dataLoggedIn;
            setState((prevState) => ({
                ...prevState,
                isLoggedIn
            }));
        }
        if (user) {
            const isConfirmed = user.confirmed;
            const isBlocked = user.blocked;
            const hasCMSAccess = !!user.cmsAccess;

            setState((prevState) => ({
                ...prevState,
                isConfirmed,
                isBlocked,
                hasCMSAccess
            }));
        }
    }, [dataLoggedIn, user, getLoggedIn, isMountedRef]);

    const refetch = () => {
        getLoggedIn();
        refetchUser && refetchUser();
    };

    return [state, refetch];
};

export default useAuthentication;
