import {
    StringSchema,
    NumberSchema,
    BooleanSchema,
    string,
    number,
    boolean
} from 'yup';
import intl from 'react-intl-universal';
import { CarMakeType } from '../../types';

interface Props {
    regex?: string;
    regexMessage?: string;
    required: boolean;
    max?: number;
    min?: number;
    precision?: number;
}

enum SCHEMA_TYPE {
    STRING = 'string',
    NUMBER = 'number',
    BOOLEAN = 'boolean'
}

type MultiSchema = StringSchema | NumberSchema | BooleanSchema;

class Validate {

    schema: any;

    schemaType: string;

    constructor(schema: MultiSchema, schemaType: SCHEMA_TYPE) {
        this.schema = schema;
        this.schemaType = schemaType;
        return this;
    }

    createSchema({ regex, regexMessage, required, max, min, precision }: Props) {
        this.schema = this.schema.nullable(true);

        if (required) {
            const requiredErrorText = intl.get('paddock.dynamicField.validation.required.error').d('This value is required');
            this.schema = this.schema.required(requiredErrorText);
        }

        if (min !== undefined && min !== null) {
            const minErrorText = intl.get('paddock.dynamicField.validation.min.error', { min }).d(`The value must be greater than or equal to ${min}`);
            this.schema = this.schema.min(min, minErrorText);
        }

        if (max !== undefined && min !== null) {
            const maxErrorText = intl.get('paddock.dynamicField.validation.max.error', { max }).d(`The value must be less than or equal to ${max}`);
            this.schema = this.schema.max(max, maxErrorText);
        }

        if (precision !== undefined && precision !== null) {
            const precisionErrorText = intl.get('paddock.dynamicField.validation.precision.error', { precision }).d(`Invalid number (up to ${precision} decimal places)`);
            const precisionRegex = new RegExp(`^[0-9]+(\\.[0-9]{0,${precision}})?$`);
            this.schema = (this.schema as NumberSchema).test(
                'is-decimal',
                precisionErrorText,
                (value) => (value ? precisionRegex.test(value.toString()) : false)
            );
        }

        switch (this.schemaType) {
            case SCHEMA_TYPE.STRING:
                if (regex) {
                    const regexErrorText = intl.get('paddock.dynamicField.validation.regex.error').d('Invalid format');
                    this.schema = (this.schema as StringSchema).matches(new RegExp(regex), regexMessage ? regexMessage : regexErrorText);
                }
                // if our value is undefined, default it to a string that could pass the rest of our tests
                this.schema = (this.schema as StringSchema).default('');
                break;
            case SCHEMA_TYPE.NUMBER:
                // If our value is undefined, default it to a number that could pass the rest of our tests.
                if (min) {
                    this.schema = (this.schema as NumberSchema).default(min);
                } else if ((min === undefined || min === null) && (max !== undefined && min !== null)) {
                    this.schema = (this.schema as NumberSchema).default(max);
                } else {
                    this.schema = (this.schema as NumberSchema).default(0);
                }
                break;
            case SCHEMA_TYPE.BOOLEAN:
                break;
        }

        return this;
    }

    async validate(value: any): Promise<boolean | string> {
        let error;
        try {
            // Get the response if validation passes
            const res = await this.schema.validate(value, { abortEarly: true });
            // if it doesn't throw an error, cast it to boolean
            const passed = !!res || res === 0 || res === '' || typeof value === 'boolean';
            return passed;
        } catch (e: any) {
            // If we catch an error, get the message
            error = e.message;
        }
        // return the error message
        return error;
    }

}

const requiredErrorText = intl.get('paddock.dynamicField.validation.number.typeError').d('Please enter a number');

export type ValidateTextField = (props: Props) => (value: any) => Promise<boolean | string>
export const validateTextField: ValidateTextField = (props) => (value: any) => new Validate(string(), SCHEMA_TYPE.STRING)
    .createSchema(props)
    .validate(value);

export type ValidateSelectField = (props: Props) => (value: any) => Promise<boolean | string>
export const validateSelectField: ValidateSelectField = (props) => (value: any) => new Validate(string(), SCHEMA_TYPE.STRING)
    .createSchema(props)
    .validate(value);

export type ValidateRichTextField = (props: Props) => (value: any) => Promise<boolean | string>
export const validateRichTextField: ValidateRichTextField = (props) => (value: any) => new Validate(string(), SCHEMA_TYPE.STRING)
    .createSchema(props)
    .validate(value);

export type ValidateNumberField = (props: Props) => (value: any) => Promise<boolean | string>
export const validateNumberField: ValidateNumberField = (props) => (value: any) => new Validate(number().typeError(requiredErrorText), SCHEMA_TYPE.NUMBER)
    .createSchema(props)
    .validate(value);

export type ValidateFloatField = (props: Props) => (value: any) => Promise<boolean | string>
export const validateFloatField: ValidateFloatField = (props) => (value: any) => new Validate(number().typeError(requiredErrorText), SCHEMA_TYPE.NUMBER)
    .createSchema(props)
    .validate(parseFloat(value));

export type ValidateMakeField = (props: Props) => (value: CarMakeType) => Promise<boolean | string>
export const validateMakeField: ValidateMakeField = (props) => (value: any) => new Validate(string(), SCHEMA_TYPE.STRING)
    .createSchema(props)
    .validate(value.name);

export type ValidatePriceField = (props: Props) => (value: any) => Promise<boolean | string>
export const validatePriceField: ValidatePriceField = (props) => (value: any) => new Validate(number().typeError(requiredErrorText), SCHEMA_TYPE.NUMBER)
    .createSchema(props)
    .validate(parseFloat(value));

export type ValidateBooleanField = (props: Props) => (value: any) => Promise<boolean | string>
export const validateBooleanField: ValidateBooleanField = (props) => (value: any) => new Validate(boolean(), SCHEMA_TYPE.BOOLEAN)
    .createSchema(props)
    .validate(value);
