import { PAYMENT_PROVIDER } from '../../constants';
import { usePaymentConfigurationContext } from '../usePaymentConfigurationContext';

const usePaymentProvider = (): PAYMENT_PROVIDER => {
    const { provider, active } = usePaymentConfigurationContext();

    // if Square and Stripe are both enabled, Square should always been prioritized
    if (provider === PAYMENT_PROVIDER.SQUARE && active) {
        return PAYMENT_PROVIDER.SQUARE;
    } else if (provider === PAYMENT_PROVIDER.STRIPE && active) {
        return PAYMENT_PROVIDER.STRIPE;
    } else {
        return PAYMENT_PROVIDER.SQUARE;
    }
};

export default usePaymentProvider;
