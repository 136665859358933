import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button } from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';

import WriteCommentDialog from '../WriteCommentDialog';
import CommentsList from '../../components/CommentsList';
import { CheckpointIsAuthenticated } from '../../../common/components/checkpoints';

import { useSocketContext } from '../../hooks/useSocketContext';

const useStyles = makeStyles(theme => ({
    container: {
        width: '95%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }
    },
    commentsContainer: {
        marginTop: theme.spacing(2)
    },

    title: {
        fontWeight: 'bold'
    },
    writeComment: {
        border: '1px solid #C8C8C8',
        borderRadius: '30px',
        color: theme.palette.grey[500],
        fontWeight: 'bold',
        fontSize: 18,
        '&:hover': {
            border: '1px solid #C8C8C8',
            backgroundColor: 'rgba(184, 184, 184, 0.04)'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 12
        }
    },
    icon: {
        color: 'blue'
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic gap
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 1)
        },
    }
}));

interface Props {
    disabled?: boolean;
}

const Comments = ({ disabled = false }: Props): JSX.Element => {
    const classes = useStyles();

    const { comments, auction } = useSocketContext();

    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const commentsText = intl.get('auction.page.details.comments.label').d('Comments');
    const writeCommentText = intl.get('auction.page.details.comments.write.').d('Write a comment');

    return (
        <Box className={classes.container}>
            <Box className={classes.titleContainer}>
                <Typography className={classes.title} component="h3" variant="h1">
                    {commentsText}
                </Typography>
                {
                    !disabled && (
                        <CheckpointIsAuthenticated>
                            <Button
                                disabled={disabled}
                                className={classes.writeComment}
                                variant="outlined"
                                onClick={handleOpen}
                                startIcon={<CommentIcon className={classes.icon} />}
                            >
                                {writeCommentText}
                            </Button>
                        </CheckpointIsAuthenticated>
                    )
                }
            </Box>
            <Box className={classes.commentsContainer}>
                <CommentsList comments={comments} />
            </Box>
            {
                open && auction && !disabled && (
                    <WriteCommentDialog
                        auctionLot={auction.slug}
                        referenceId={auction.id}
                        onClose={handleClose}
                    />
                )
            }
        </Box>
    );
};

export default React.memo(Comments);
