import React, { useState } from 'react';
import intl from 'react-intl-universal';


import Dialog from '../../../common/components/Dialog';
import ActionButton from '../../../common/components/ActionButton';
import FormEmailSeller from '../../forms/EmailSeller';
import { CheckpointIsAuthenticated } from '../../../common/components/checkpoints';

interface Props {
    sellerEmail: string;
    subtitle: string;
}

const EmailSellerButton = ({ subtitle, sellerEmail }: Props): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);

    const emailSellerText = intl.get('auction.emailSeller').d('Email Seller');

    const handleClick = () => {
        setIsOpen(true);
    };

    return (
        <>
            <CheckpointIsAuthenticated>
                <ActionButton
                    id="paddock-listing-button-inquiry"
                    fullWidth
                    color="secondary"
                    variant="contained"
                    onClick={handleClick}
                >
                    {emailSellerText}
                </ActionButton>
            </CheckpointIsAuthenticated>
            {
                isOpen && (
                    <Dialog onClose={() => setIsOpen(false)}>
                        <FormEmailSeller
                            subtitle={subtitle}
                            sellerEmail={sellerEmail}
                            onSend={() => setIsOpen(false)}
                        />
                    </Dialog>
                )
            }
        </>
    );
};

export default EmailSellerButton;
