import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FeaturedCard from '../../components/FeaturedCard';
import ResultList from '../ResultList';
import StripePaymentModal from '../../../common/containers/StripePaymentModal';
import AnimatedLoader from '../../../common/components/AnimatedLoader';

import { MY_DIRECTORIES_QUERY } from '../../queries';
import { DirectoryType, StatusType } from '../../types';
import { PricingPackagesDataType, PricingPackageType } from '../../../common/types';
import { useNotification } from '../../../common/hooks';
import { BRAND, DIRECTORY_STATUS, PAYMENT_ITEM_TYPE, PAYMENT_PROVIDER, PAYMENT_TYPE } from '../../../common/constants';
import { QUERY_PRICING_PACKAGES } from '../../../common/queries';
import { cancelDirectorySubscription } from '../../../common/request';

const useStyles = makeStyles(theme => ({
    button: {
        ...theme.typography.button,
        borderRadius: 0,
        padding: theme.spacing(1, 5),
    }
}));

type SetDirectoryFunctionType = (directory: DirectoryType, plan?: PricingPackageType) => void;

interface Props {
    // Component Properties
    editDirectory: SetDirectoryFunctionType;
}

const UserDirectories = (props: Props): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const notify = useNotification();

    const handleAddNav = () => {
        history.push('/directory/add');
    };

    // Featured Plan
    const [directoryId, setDirectoryId] = useState<number>(0);
    const [featuredPlan, setFeaturedPlan] = useState<PricingPackageType | undefined>(undefined);
    const [paymentType, setPaymentType] = useState<PAYMENT_TYPE>(PAYMENT_TYPE.ONE_TIME_PAYMENT);

    // PAYMENT
    const [stripeCheckoutOpen, setStripeCheckoutOpen] = useState(false);

    // Directory Arrays
    const [directories, setDirectories] = useState<Array<DirectoryType>>([]);
    const [featuredDirectories, setFeaturedDirectories] = useState<Array<DirectoryType>>([]);
    const [nonFeaturedDirectories, setNonFeaturedDirectories] = useState<Array<DirectoryType>>([]);

    // Status Counts
    const [pending, setPending] = useState(0);
    const [approved, setApproved] = useState(0);
    const [declined, setDeclined] = useState(0);
    const [status, setStatus] = useState<StatusType | undefined>();

    // Get directory packages
    const { data: dataP, loading: loadingP, error: errorP } = useQuery<PricingPackagesDataType>(QUERY_PRICING_PACKAGES, {
        variables: {
            where: {
                enabled: true,
                type: 'DIRECTORY',
                brand: BRAND
            }
        }
    });

    // Get featured directory items
    const { data, loading, error, refetch } = useQuery(MY_DIRECTORIES_QUERY, {
        variables: {
            start: 0,
            limit: 50,
        },
        fetchPolicy: 'no-cache',
    });

    // Payment Functions
    const handleStripeClose = () => {
        setStripeCheckoutOpen(false);
    };

    const handleStripeSuccess = () => {
        // Notify received
        const message = intl.get('directory.userDirectories.paymentReceived').d('Payment received.');
        notify({ message, severity: 'success' });
        // Refetch
        refetch();
        // Close stripe
        setStripeCheckoutOpen(false);
    };

    // Edit Directory
    const handleEditDirectory = (directory: DirectoryType, action?: string) => {
        if (action === 'upgrade') {
            props.editDirectory(directory, featuredPlan);
        } else if (action === 'pay') {
            setDirectoryId(directory.id);
            setStripeCheckoutOpen(true);
        } else if (action === 'cancel') {
            // Cancel subscription
            cancelDirectorySubscription(directory.id).then(() => {
                // Notify cancelled
                const message = intl.get('directory.userDirectories.subscriptionCancelled').d('Subscription cancelled.');
                notify({ message, severity: 'success' });
                // Refetch
                refetch();
            }).catch(() => {
                // Notify failed to cancel
                const message = intl.get('directory.userDirectories.cancelSubscriptionFailed').d('Failed to cancel subscription.');
                notify({ message, severity: 'error' });
            });
        } else {
            props.editDirectory(directory);
        }
    };

    useEffect(() => {
        if (data) {
            const dir: Array<DirectoryType> = data.myDirectories;
            // Set Directories
            setDirectories(dir);
            setFeaturedDirectories([...dir.filter(directory => directory.featured && directory.status === status)]);
            setNonFeaturedDirectories([...dir.filter(directory => !directory.featured && directory.status === status)]);
            // Set Status Counts
            let p = 0;
            let a = 0;
            let d = 0;
            dir.forEach(curDir => {
                if (curDir.status === DIRECTORY_STATUS.PENDING) {
                    p++;
                } else if (curDir.status === DIRECTORY_STATUS.APPROVED) {
                    a++;
                } else if (curDir.status === DIRECTORY_STATUS.DECLINED) {
                    d++;
                }
            });
            setPending(p);
            setApproved(a);
            setDeclined(d);
            // Set Default Status
            if (!status) {
                let defaultStatus: StatusType = DIRECTORY_STATUS.PENDING;
                if (p === 0 && a !== 0) {
                    defaultStatus = DIRECTORY_STATUS.APPROVED;
                } else if (p === 0 && d !== 0) {
                    defaultStatus = DIRECTORY_STATUS.DECLINED;
                }
                setStatus(defaultStatus);
            }
        } else {
            setFeaturedDirectories([]);
            setNonFeaturedDirectories([]);
        }
        if (!featuredPlan && dataP) {
            if (dataP.pricingPackages.length) {
                // There are packages
                for (let i = 0; i < dataP.pricingPackages.length; i++) {
                    // current plan
                    const plan: PricingPackageType = dataP.pricingPackages[i];
                    // if a payment is enabled
                    if ((plan.oneTimePayment && plan.oneTimePayment.enabled)
                        || (plan.monthlyPayment && plan.monthlyPayment.enabled)
                        || (plan.yearlyPayment && plan.yearlyPayment.enabled)) {
                        let pt = PAYMENT_TYPE.ONE_TIME_PAYMENT;
                        // Check if any payment types are enabled...
                        if (plan.oneTimePayment && plan.oneTimePayment.enabled) {
                            // One time payment
                            pt = PAYMENT_TYPE.ONE_TIME_PAYMENT;
                        } else if (plan.monthlyPayment && plan.monthlyPayment.enabled) {
                            // Monthly payment
                            pt = PAYMENT_TYPE.MONTHLY_PAYMENT;
                        } else if (plan.yearlyPayment && plan.yearlyPayment.enabled) {
                            // Yearly payment
                            pt = PAYMENT_TYPE.YEARLY_PAYMENT;
                        }
                        // set this as the featured plan
                        setFeaturedPlan(plan);
                        setPaymentType(pt);
                        break;
                    }
                }
            }
        }
    }, [data, directories, dataP, featuredPlan, status]);

    if (loading || loadingP) {
        return <AnimatedLoader name="UserDirectories" />;
    }

    if (error || errorP) {
        const error = intl.get('directory.userDirectories.error').d('An error occured while fetching your directories.');
        return (
            <Box sx={{ mt: 2 }}>
                <Typography variant="body1">{error}</Typography>
            </Box>
        );
    }

    if (featuredDirectories.length === 0 && nonFeaturedDirectories.length === 0) {
        const noDirectories = intl.get('directory.userDirectories.noDirectories').d('You do not have any directories.');
        const actionAdd = intl.get('directory.userDirectories.addYourBusiness').d('Add Your Business');
        return (
            <>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1">{noDirectories}</Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Button className={classes.button} color="secondary" variant="contained" onClick={handleAddNav}>{actionAdd}</Button>
                </Box>
            </>
        );
    }

    const featuredDirectoriesDesc = intl.get('directory.userDirectories.featuredDirectories').d('Your featured directories.');
    const freeDirectoriesDesc = intl.get('directory.userDirectories.freeDirectories').d('Your free directories.');

    const pendingDesc = intl.get('directory.userDirectories.pendingDescription').d('These directories are pending approval and are not yet publicly searchable.');
    const approvedDesc = intl.get('directory.userDirectories.approvedDescription').d('These directories have been approved and are publicly searchable.');
    const declinedDesc = intl.get('directory.userDirectories.declinedDescription').d('These directories have been declined, please update them in order to get them reviewed again.');

    const pendingLabel = intl.get('directory.userDirectories.label.pending', { pending }).d(`Pending (${pending})`);
    const approvedLabel = intl.get('directory.userDirectories.label.approved', { approved }).d(`Approved (${approved})`);
    const declinedLabel = intl.get('directory.userDirectories.label.declined', { declined }).d(`Declined (${declined})`);

    let statusInfo = pendingDesc;
    if (status === DIRECTORY_STATUS.APPROVED) {
        statusInfo = approvedDesc;
    } else if (status === DIRECTORY_STATUS.DECLINED) {
        statusInfo = declinedDesc;
    }

    return (
        <Box sx={{ mt: 2 }}>
            {stripeCheckoutOpen && featuredPlan && (
                <StripePaymentModal
                    itemId={`${directoryId}`}
                    itemType={PAYMENT_ITEM_TYPE.DIRECTORY}
                    paymentProvider={PAYMENT_PROVIDER.STRIPE}
                    paymentType={paymentType}
                    pricingPackage={featuredPlan}
                    onClose={handleStripeClose}
                    onSuccess={handleStripeSuccess}
                />
            )}
            <Box sx={{ mb: 2 }}>
                <Box>
                    {!!pending && (
                        <Button
                            color={status === DIRECTORY_STATUS.PENDING ? 'primary' : 'inherit'}
                            variant="contained"
                            className={classes.button}
                            onClick={() => setStatus(DIRECTORY_STATUS.PENDING)}
                        >
                            {pendingLabel}
                        </Button>
                    )}
                    {!!approved && (
                        <Button
                            color={status === DIRECTORY_STATUS.APPROVED ? 'primary' : 'inherit'}
                            variant="contained"
                            className={classes.button}
                            onClick={() => setStatus(DIRECTORY_STATUS.APPROVED)}
                        >
                            {approvedLabel}
                        </Button>
                    )}
                    {!!declined && (
                        <Button
                            color={status === DIRECTORY_STATUS.DECLINED ? 'primary' : 'inherit'}
                            variant="contained"
                            className={classes.button}
                            onClick={() => setStatus(DIRECTORY_STATUS.DECLINED)}
                        >
                            {declinedLabel}
                        </Button>
                    )}
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Typography variant="body1">{statusInfo}</Typography>
                </Box>
            </Box>
            {!!featuredDirectories.length && (
                <>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1">{featuredDirectoriesDesc}</Typography>
                    </Box>
                    <Box sx={{ mb: 4 }}>
                        <Divider />
                    </Box>
                    <ResultList
                        directories={featuredDirectories}
                        currentPage={1}
                        totalPages={1}
                        component={FeaturedCard}
                        editDirectory={handleEditDirectory}
                    />
                </>
            )}
            {!!nonFeaturedDirectories.length && (
                <>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1">{freeDirectoriesDesc}</Typography>
                    </Box>
                    <Box sx={{ mb: 4 }}>
                        <Divider />
                    </Box>
                    <ResultList
                        directories={nonFeaturedDirectories}
                        currentPage={1}
                        totalPages={1}
                        component={FeaturedCard}
                        editDirectory={handleEditDirectory}
                    />
                </>
            )}
        </Box>
    );
};

export default UserDirectories;
