import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { QUERY_CMS_PERMISSIONS } from '../../queries';
import { CMSPermissionsDataType, CMSPermissionsType } from '../../types';
import defaultValues from './defaultValues';
import { useIsMountedRef } from '..';

type ReturnType = [CMSPermissionsType, () => void];

const useCMSPermissions = (): ReturnType => {
    const isMountedRef = useIsMountedRef();
    const [state, setState] = useState<CMSPermissionsType>(defaultValues);
    const [getCMSPermissions, { data }] = useLazyQuery<CMSPermissionsDataType>(QUERY_CMS_PERMISSIONS);

    useEffect(() => {
        if (isMountedRef.current) {
            getCMSPermissions();
        }
        if (data) {
            setState(data.myCMSPermissions);
        }
    }, [data, getCMSPermissions, isMountedRef]);

    return [state, getCMSPermissions];
};

export default useCMSPermissions;
