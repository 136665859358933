import * as yup from 'yup';
import intl from 'react-intl-universal';

export const schema = yup.object().shape({
    serviceSnappr: yup.bool(),
    exterior: yup.array()
        .min(1,
            intl.get('auction.form.media.exterior.error.min').d('You must have at least one image')
        )
        .required(
            intl.get('auction.form.media.exterior.error.required').d('Please upload an image')
        ),
    interior: yup.array()
        .min(1,
            intl.get('auction.form.media.interior.error.min').d('You must have at least one image')
        )
        .required(
            intl.get('auction.form.media.interior.error.required').d('Please upload an image')
        ),
    mechanical: yup.array()
        .min(1,
            intl.get('auction.form.media.mechanical.error.min').d('You must have at least one image')
        )
        .required(
            intl.get('auction.form.media.mechanical.error.required').d('Please upload an image')
        ),
    documents: yup.array()
        .min(1,
            intl.get('auction.form.media.documents.error.min').d('You must have at least one image')
        )
        .required(
            intl.get('auction.form.media.documents.error.required').d('Please upload an image')
        ),
});

export const snapprSchema = yup.object().shape({
    serviceSnappr: yup.bool(),
    exterior: yup.array()
        .min(1,
            intl.get('auction.form.media.exterior.error.min').d('You must have at least one image')
        )
        .required(
            intl.get('auction.form.media.exterior.error.required').d('Please upload an image')
        ),
});
