import { gql } from '@apollo/client';

import { BID_ATTRIBUTES, AUCTION_ATTRIBUTES, AUCTION_PROPERTIES_ATTRIBUTES, COMMENT_ATTRIBUTES, COMMENT_HIERARCHY_ATTRIBUTES } from './fragments';

export const QUERY_AUCTION_BIDS = gql`
    query bids($sort: String, $limit: Int, $start: Int, $where: JSON) {
        bids(sort: $sort, limit: $limit, start: $start, where: $where) {
            ...BidParts
        }
    },
	${BID_ATTRIBUTES} 
`;

export const QUERY_AUCTION = gql`
    query auction($id: ID!) {
        auction(id: $id) {
            ...AuctionParts
        }
    },
	${AUCTION_ATTRIBUTES} 
`;

export const QUERY_AUCTION_PROPERTIES_BY_BRAND = gql`
    query auctionPropertiesByBrand($brand: String!) {
        auctionPropertiesByBrand(brand: $brand) {
            ...AuctionPropertiesParts
        }
    }
    ${AUCTION_PROPERTIES_ATTRIBUTES}
`;

export const QUERY_AUCTION_BY_SLUG = gql`
	query auctionBySlug ($slug: String!, $where: JSON) {
		auctionBySlug (slug: $slug, where: $where) {
            ...AuctionParts
		}
	}
	${AUCTION_ATTRIBUTES} 
`;

export const QUERY_AUCTION_COMMENTS_BY_ID = gql`
	query findAll ($id: ID!, $where: JSON) {
		findAll (related: "auctions", entity: $id, where: $where) {
            items {
                ...CommentParts
            }
            total
            page
		}
	}
	${COMMENT_ATTRIBUTES}
`;

export const QUERY_AUCTION_COMMENTS_HIERARCHY_BY_ID = gql`
	query findAllInHierarchy ($relation: Relation!, $where: JSON) {
		findAllInHierarchy (relation: $relation, where: $where) {
            ...CommentHierarchyParts
		}
	}
	${COMMENT_HIERARCHY_ATTRIBUTES}
`;

export const QUERY_AUCTION_COMMENTS_FLAT_BY_ID = gql`
	query findAllFlat ($relation: Relation!, $where: JSON) {
		findAllFlat (relation: $relation, where: $where) {
            ...CommentParts
		}
	}
	${COMMENT_ATTRIBUTES}
`;

export const QUERY_AUCTION_RESERVE_PERCENTAGE = gql`
    query findReservePercentage($id: ID!) {
        findReservePercentage(id: $id)
    }
`;
