import React, { useEffect } from 'react';
// import intl from 'react-intl-universal';
import { useLazyQuery } from '@apollo/client';

import { USER_DIRECTORY_BOOKMARKS_QUERY } from '../../queries';
import { useAuthenticatedUserContext, useIsMountedRef, useLoggedIn } from '../../../common/hooks';
import { setBookmarkIds } from '../../helpers';

const BookmarksLoader = (): JSX.Element => {
    const isMountedRef = useIsMountedRef();
    const [isLoggedIn] = useLoggedIn();
    const { user } = useAuthenticatedUserContext();

    // Get bookmarked directories
    const [getBookmarks, { data }] = useLazyQuery(USER_DIRECTORY_BOOKMARKS_QUERY, {
        variables: {
            id: Number(user?.id || 0)
        },
        // Not cached, this will only be called again if the user changes
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        if (user && isLoggedIn && isMountedRef.current) {
            getBookmarks();
        }
    }, [getBookmarks, isLoggedIn, isMountedRef, user]);

    // When the bookmarks data has been retrieved, and it has not been set yet
    if (data && user) {
        // Put the bookmark ids into an array
        const bookmarkIds = data.directoryBookmarks.filter((_data: any) => _data.directory).map((_data: any) => _data.directory.id);
        setBookmarkIds(bookmarkIds);
    }

    return <></>;
};

export default BookmarksLoader;
