import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';

import FeaturedCard from '../../components/FeaturedCard';
import LoginRequired from '../../components/LoginRequired';

import { useLoggedIn } from '../../../common/hooks';
import { DirectoryType } from '../../types';
import { claimDirectory } from '../../../common/request';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
    },
    keyword: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textDecoration: 'none',
    },
    button: {
        ...theme.typography.button,
    }
}));

interface Props {
    directoryKey: string;
}

const ClaimDirectoryPage = ({ directoryKey }: Props): JSX.Element => {
    const classes = useStyles();
    const [isLoggedIn] = useLoggedIn();
    const [directory, setDirectory] = useState<DirectoryType | null>(null);
    const [error, setError] = useState(false);
    const [callComplete, setCallComplete] = useState(false);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized && isLoggedIn) {
            // Get the users bookmarks
            claimDirectory(directoryKey).then((directory?: DirectoryType) => {
                if (directory?.id) {
                    setDirectory(directory);
                }
            }).catch(res => {
                // Not found is not an error, simply means this key is not valid
                if (res !== 'Not Found') {
                    setError(true);
                }
            }).then(() => {
                setCallComplete(true);
            });
            // Set initialized to prevent this from re-occuring
            setInitialized(true);
        }
    }, [initialized, directoryKey, isLoggedIn]);

    // User must be signed in
    if (!isLoggedIn) {
        return (
            <Box>
                <LoginRequired action="claim" />
            </Box>
        );
    }

    // If the call has not completed, display progress indicator
    if (!callComplete) {
        return (
            <Box sx={{ mt: 4, textAlign: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }

    // If there was an error, display notification
    if (error) {
        const errorMsg = intl.get('directory.claimDirectory.error.api').d('An error occurred while processing this request, please refresh the page or contact support if the problem persists.');
        return (
            <Box sx={{ mt: 4, textAlign: 'center' }} className={classes.root}>
                <Typography variant="body1">{errorMsg}</Typography>
            </Box>
        );
    }

    const myDirectories = intl.get('directory.claimDirectory.label.myDirectories').d('My Directories');
    const noDirectoryFound = intl.get('directory.claimDirectory.label.noDirectoryFound').d('The provided key is not valid. Perhaps you have already claimed ownership of this directory.');
    const directoryClaimed = intl.get('directory.claimDirectory.label.directoryClaimed').d('You have successfully claimed ownership of this directory.');

    const content = directory
        ? (
            <>
                <FeaturedCard directory={{ ...directory }} />
                <Box sx={{ mt: 2, textAlign: 'center' }} >
                    <Typography variant="body1">{directoryClaimed}</Typography>
                    <Box sx={{ mt: 1 }}>
                        <Link to="/directory/update" className={classes.keyword}>
                            <Button className={classes.button} color="primary" variant="contained">{myDirectories}</Button>
                        </Link>
                    </Box>
                </Box>
            </>
        )
        : (
            <Box sx={{ textAlign: 'center' }}>
                <Typography>{noDirectoryFound}</Typography>
                <Box sx={{ mt: 1 }}>
                    <Link to="/directory/update" className={classes.keyword}>
                        <Button className={classes.button} color="primary" variant="contained">{myDirectories}</Button>
                    </Link>
                </Box>
            </Box>
        );

    // Render the directory
    return (
        <Box sx={{ mt: 4 }} className={classes.root}>
            <Box sx={{ mt: 2, mb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                {content}
            </Box>
        </Box>
    );
};

export default ClaimDirectoryPage;
