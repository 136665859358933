import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Tooltip, TextField } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';

import { validateFloatField } from '../../validation';
import { PropertiesFieldType } from '../../../../types';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    infoIcon: {
        width: 20,
        height: 20,
        marginLeft: theme.spacing(1),
        '& svg': {
            width: 20,
            height: 20,
        }
    },
}));


type Props = PropertiesFieldType & {
    defaultValue?: any;
    disabled?: boolean;
};

const FloatField = ({ id, name, label, required, min, max, precision, tooltip, defaultValue, placeholder, disabled = false }: Props): JSX.Element => {
    const classes = useStyles();
    const { control, errors } = useFormContext();

    const validate = useMemo(() => validateFloatField({ required, min, max, precision }), [max, min, precision, required]);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : 0.00}
            render={(props) => (
                <Box className={`${tooltip ? classes.container : ''}`}>
                    <TextField
                        {...props}
                        id={id}
                        disabled={disabled}
                        fullWidth
                        type="number"
                        variant="outlined"
                        color="secondary"
                        label={label}
                        required={required}
                        helperText={errors[name]?.message}
                        error={!!errors[name]}
                        placeholder={placeholder}
                    />
                    {tooltip && (
                        <Box className={classes.infoIcon}>
                            <Tooltip enterTouchDelay={50} title={tooltip} aria-label={tooltip}>
                                <InfoIcon />
                            </Tooltip>
                        </Box>
                    )}
                </Box>
            )}
            rules={{
                validate
            }}
        />
    );
};

export default FloatField;
