import gql from 'graphql-tag';
import { DIRECTORY_PROPERTIES } from './constants';

export const CREATE_DIRECTORY_BOOKMARK_QUERY = gql`
    mutation($directoryId: ID!, $userId: ID!) {
        createDirectoryBookmark(
            input: {
                data: {
                    directory: $directoryId
                    user: $userId
                }
            }
        ) {
            directoryBookmark {
                id
            }
        }
    }
`;

export const DELETE_DIRECTORY_BOOKMARK_QUERY = gql`
    mutation($directoryId: ID!, $userId: ID!) {
        deleteDirectoryBookmarkWhere(where: {
            directory: $directoryId,
            user: $userId
        })
    }
`;

export const CREATE_DIRECTORY_MUTATION = gql`
    mutation ($userId: ID!, $image: ID, $status: ENUM_DIRECTORY_STATUS!, $featured: Boolean!,
        $name: String!, $category: ENUM_DIRECTORY_CATEGORY!, $addressCountry: String!,
        $addressState: String!, $addressCity: String!, $addressPostal: String!,
        $addressStreet1: String!, $addressStreet2: String!, $hoursOfOperation: JSON,
        $website: String!, $phone: String!, $email: String!, $keywords: JSON!
        $facebook: String!, $instagram: String!, $youtube: String!, $linkedin: String!, $twitter: String!
        $videoURL: String!, $activeSubscription: Boolean!
    ) {
        createDirectory (
            input: {
                data: {
                    user: $userId
                    image: $image
                    status: $status
                    featured: $featured
                    name: $name
                    category: $category
                    addressCountry: $addressCountry
                    addressState: $addressState
                    addressCity: $addressCity
                    addressPostal: $addressPostal
                    addressStreet1: $addressStreet1
                    addressStreet2: $addressStreet2
                    hoursOfOperation: $hoursOfOperation
                    website: $website
                    phone: $phone
                    email: $email
                    keywords: $keywords
                    socialLinks: {
                        facebook: $facebook
                        instagram: $instagram
                        youtube: $youtube
                        linkedin: $linkedin
                        twitter: $twitter
                    }
                    videoURL: $videoURL
                    activeSubscription: $activeSubscription
                }
            }
        ) {
            directory {
                ${DIRECTORY_PROPERTIES}
            }
        }
    }
`;

export const UPDATE_DIRECTORY_MUTATION = gql`
    mutation ($id: ID!, $userId: ID!, $image: ID, $status: ENUM_DIRECTORY_STATUS!, $featured: Boolean!,
        $name: String!, $category: ENUM_DIRECTORY_CATEGORY!, $addressCountry: String!,
        $addressState: String!, $addressCity: String!, $addressPostal: String!,
        $addressStreet1: String!, $addressStreet2: String!, $hoursOfOperation: JSON,
        $website: String!, $phone: String!, $email: String!, $keywords: JSON!
        $facebook: String!, $instagram: String!, $youtube: String!, $linkedin: String!, $twitter: String!
        $videoURL: String!, $activeSubscription: Boolean!
    ) {
        updateDirectory (
            input: {
                where: {
                    id: $id
                }
                data: {
                    user: $userId
                    image: $image
                    status: $status
                    featured: $featured
                    name: $name
                    category: $category
                    addressCountry: $addressCountry
                    addressState: $addressState
                    addressCity: $addressCity
                    addressPostal: $addressPostal
                    addressStreet1: $addressStreet1
                    addressStreet2: $addressStreet2
                    hoursOfOperation: $hoursOfOperation
                    website: $website
                    phone: $phone
                    email: $email
                    keywords: $keywords
                    socialLinks: {
                        facebook: $facebook
                        instagram: $instagram
                        youtube: $youtube
                        linkedin: $linkedin
                        twitter: $twitter
                    }
                    videoURL: $videoURL
                    activeSubscription: $activeSubscription
                }
            }
        ) {
            directory {
                ${DIRECTORY_PROPERTIES}
            }
        }
    }
`;
