import { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useQuery } from '@apollo/client';

import { AuctionDataType, AuctionType } from '../../../types';
import { QUERY_AUCTION } from '../../../queries';

interface ChildrenProps {
    error: string | undefined;
    loading: boolean;
    auction?: AuctionType;
}

interface Props {
    update: boolean;
    children: (props: ChildrenProps) => JSX.Element;
    auctionId?: string;
    userId?: string;
}

const CheckPermissions = ({ update, children, auctionId, userId }: Props): JSX.Element => {
    const [auction, setAuction] = useState<AuctionType | undefined>(undefined);

    const { loading: loadingAuction, error: errorAuction, data: dataAuction } = useQuery<AuctionDataType>(QUERY_AUCTION, {
        variables: {
            id: auctionId
        },
        skip: !update,
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (dataAuction) {
            setAuction(dataAuction.auction);
        }
    }, [dataAuction, update]);

    let error = undefined;

    if (loadingAuction) {
        return children({
            loading: loadingAuction,
            error,
            auction
        });
    }

    try {
        if (errorAuction || (update && !auction)) {
            const errorText = intl.get('auction.checkPermissions.error').d('Something went wrong. Please try again. If this issue presists, please contact support.');
            throw new Error(errorText);
        }
        if (auction) {
            const { user } = auction;

            if (Number(user.id) !== Number(userId)) {
                const errorText = intl.get('auction.checkPermissions.userId.error').d('Access denied. You do not have the correct permissions to view this auction.');
                throw new Error(errorText);
            }
        }
    } catch (e: any) {
        error = e.message;
    }

    return children({
        error,
        auction,
        loading: loadingAuction
    });
};

export default CheckPermissions;
