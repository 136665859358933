import React, { useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import { Typography, Box } from '@material-ui/core';
import { makeStyles, TypographyVariant } from '@material-ui/core/styles';
import Countdown from 'react-countdown';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    text: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            fontSize: 13,
        }
    },
    subtext: {
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            fontSize: 8
        }
    }
}));

interface Props {
    date: string;
    align?: 'center' | 'left' | 'right',
    variant?: TypographyVariant;
    textPrefix?: string;
    endingText?: string;
    refresh?: () => void;
}

const HOURS_DIFFERENCE_LIMIT = 48;

const CountdownTimer = ({ date, align = 'right', variant = 'h5', textPrefix, endingText, refresh }: Props): JSX.Element => {
    const classes = useStyles();
    const completedText = intl.get('common.countdown.state.completed').d('Completed');

    const timerRef = useRef<any>();
    const formattedDate = moment.utc(date).local().format('MMM Do YYYY h:mmA');

    const shouldDaysBeInHours = useRef<boolean>(false);
    const today = moment();


    const handleRefresh = () => {
        refresh && refresh();
    };

    const handleStart = () => {
        timerRef?.current?.start();
    };

    // const handlePause = () => {
    //     timerRef?.current?.pause();
    // };

    useEffect(() => {
        handleStart();

        // if the countdown date - today's date in hours > LIMIT, make sure we display the date in hours
        // i.e scheduled date is August.15th, 2020 at 1:00 PM
        // today's date is August.13th, 2020 at 5:00 PM
        // 15th at 1:00 PM - 13th at 5:00 PM = 2 days - 4 hours = 1 day 20 hours = 44 hours <= 48 hours = true
        shouldDaysBeInHours.current = moment.utc(date).local().diff(today, 'hours') <= HOURS_DIFFERENCE_LIMIT;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);



    return (
        <Countdown
            ref={timerRef}
            date={date}
            daysInHours={shouldDaysBeInHours.current}
            autoStart={true}
            onComplete={handleRefresh}
            renderer={
                ({ formatted, completed, ...rest }) => {
                    if (completed) {
                        return (
                            <Box sx={{ textAlign: align }}>
                                <Typography className={classes.text} variant={variant}>
                                    {endingText ? endingText : completedText}
                                </Typography>
                                <Typography className={classes.subtext} variant="overline">
                                    {formattedDate}
                                </Typography>
                            </Box>
                        );
                    } else {
                        const { hours, minutes, seconds, days } = formatted;
                        return (
                            <Box sx={{ textAlign: align }}>
                                {
                                    shouldDaysBeInHours.current ? (
                                        <Typography className={classes.text} variant={variant}>
                                            { textPrefix ? `${textPrefix} ${hours}` : hours }:{minutes}:{seconds}
                                        </Typography>
                                    ) : (
                                        <Typography className={classes.text} variant={variant}>
                                            {textPrefix ? `${textPrefix} ${moment.utc(date).local().toNow(true)}` : days }
                                        </Typography>
                                    )
                                }
                                <Typography className={classes.subtext} variant="overline">
                                    {formattedDate}
                                </Typography>
                            </Box>
                        );
                    }
                }
            }
        />
    );
};

export default CountdownTimer;
