import React from 'react';
import intl from 'react-intl-universal';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    primaryText: {
        fontWeight: 'bold',
        fontFamily: 'Avenir',
        color: theme.palette.common.black,
        [theme.breakpoints.down('md')]: {
            fontSize: 13
        }
    },
    secondaryText: {
        fontFamily: 'Avenir',
        color: theme.palette.grey[600],
        // // textTransform: 'uppercase',
        [theme.breakpoints.down('md')]: {
            fontSize: 9
        }
    },
}));

interface Props {
    numberOfBids: number;
}

const BidsDisplay = ({ numberOfBids }: Props): JSX.Element => {
    const classes = useStyles();

    const bidsText = intl.get('auction.page.auction.details.bids').d('Bids');

    return (
        <Box>
            <Typography className={classes.primaryText} variant="h2">{numberOfBids}</Typography>
            <Typography className={classes.secondaryText} variant="overline">{bidsText}</Typography>
        </Box>
    );
};

export default BidsDisplay;
