import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Card,
    CardActionArea,
    CardContent,
    Box,
} from '@material-ui/core';

import CardDescription from '../../../common/components/CardDescription';
import CardTitle from '../../../common/components/CardTitle';
import CardImage from '../../../common/components/CardImage';
import CountdownTimer from '../../../common/components/CountdownTimer';
import CardAddress from '../../../common/components/CardAddress';
import WatchListIconButton from '../WatchlistIconButton';
import CardPrice from '../CardPrice';
import CardChip from '../../../common/components/CardChip';

import { AuctionCardType } from '../../types';
import { AUCTION_LINK } from '../../../common/constants';
import { AUCTION_STATUS } from '../../constants';
import { localizeCurrency } from '../../../common/helpers';

export const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        borderRadius: 'none',
        boxShadow: 'none',
        marginBottom: theme.spacing(2)
    },
    cardContent: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0)
    },
    titleBar: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'baseline'
    },
    descriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic gap
        '& > * + *': {
            margin: theme.spacing(1, 0, 0, 0)
        }
    },
    chipContainer: {
        display: 'flex',
        flexDirection: 'row',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic gap
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 1)
        },
    }
}));

interface Props {
    auction: AuctionCardType;
    refresh?: () => void;
}

const AuctionCard = ({ auction, refresh }: Props): JSX.Element => {
    const classes = useStyles();
    const theme = useTheme();

    // Fetch card properties from auction
    const {
        id,
        title,
        slug,
        status,
        reserve,
        coverImage,
        startPrice,
        finalPrice,
        currency,
        description,
        excerpt,
        address,
        finalEndDate,
        scheduledStartDate,
        // These 3 props come from algolia record
        topBid,
        bidCount,
        topBidAmount
    } = auction;

    // calculate if our auction has ended
    const hasEnded = finalEndDate ? moment(Date.now()).isAfter(finalEndDate) : false;
    // calculate if our auction has started
    const hasStarted = scheduledStartDate ? moment(Date.now()).isAfter(scheduledStartDate) : false;

    // the route to our detailed auction page
    const cardLink = `/${AUCTION_LINK}/lot/${slug}`;
    // calculate the top bid
    const auctionTopBid = topBid && topBidAmount ? topBidAmount : 0;
    // calculate our total bids on the auction
    const totalBids = bidCount ? bidCount : 0;

    // make our current bid the starting price
    let currentBidPrice = startPrice;

    // if we have a top bid, make our currentBidPrice equal to the top bid
    if (auctionTopBid !== 0.00) {
        currentBidPrice = auctionTopBid;
    }

    // if our auction has ended and we have a final price, use the final auction price
    if (hasEnded && finalPrice) {
        currentBidPrice = finalPrice;
    }

    let countdownJsx = <></>;

    const formattedPrice = useMemo(() => localizeCurrency(currentBidPrice, currency), [currentBidPrice, currency]);

    const startsInText = intl.get('common.countdown.startsIn').d('Starting in');
    const endsInText = intl.get('common.countdown.endsIn').d('Ending in');

    const reserveNotMetText = intl.get('auction.page.home.card.chip.reserveNotMet', { price: formattedPrice }).d(`Bid to ${formattedPrice}`);
    const soldText = intl.get('auction.page.home.card.chip.sold').d('Sold');
    const noReserveText = intl.get('auction.page.home.card.chip.noReserve').d('No Reserve');

    if (hasStarted && finalEndDate) {
        countdownJsx = <CountdownTimer date={finalEndDate} textPrefix={endsInText} refresh={refresh} />;
    } else if (!hasStarted && scheduledStartDate) {
        countdownJsx = <CountdownTimer date={scheduledStartDate} textPrefix={startsInText} refresh={refresh} />;
    }

    return (
        <Card className={classes.root}>
            <CardActionArea
                component={Link}
                to={cardLink}
            >
                <CardImage image={coverImage} />
            </CardActionArea>
            <CardContent className={classes.cardContent} >
                <Box className={classes.titleBar}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pr: 10 }}>
                            <WatchListIconButton auctionId={id} onWatch={refresh} />
                            <CardTitle text={title} />
                        </Box>
                        { countdownJsx }
                    </Box>
                </Box>
                <Box>
                    <CardPrice price={{ amount: currentBidPrice, currency }} totalBids={totalBids} />
                </Box>
            </CardContent>
            <CardActionArea
                component={Link}
                to={cardLink}
            >
                <Box className={classes.descriptionContainer}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        {address && <CardAddress address={address} />}
                        <Box className={classes.chipContainer}>
                            {
                                (!reserve || reserve <= 0.00) && (
                                    <CardChip backgroundColor={theme.palette.info.main} text={noReserveText} />
                                )
                            }
                            {
                                status === AUCTION_STATUS.RESERVE_NOT_MET && (
                                    <CardChip backgroundColor={theme.palette.warning.main} text={reserveNotMetText} />
                                )
                            }
                            {
                                status === AUCTION_STATUS.SOLD && (
                                    <CardChip backgroundColor={theme.palette.common.black} text={soldText} />
                                )
                            }
                        </Box>
                    </Box>
                    {description && <CardDescription description={description} excerpt={excerpt} />}
                </Box>
            </CardActionArea>
        </Card>
    );
};

export default React.memo(AuctionCard);
