import React, { ReactElement } from 'react';
import intl from 'react-intl-universal';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import RoundedBox from '../RoundedBox';
import LimegreenLabel from '../../../common/components/LimegreenLabel';

import { localizeCurrency } from '../../../common/helpers';

const useStyles = makeStyles(theme => ({
    primaryText: {
        fontWeight: 'bold',
        fontFamily: 'Avenir',
        color: theme.palette.common.black,
        [theme.breakpoints.down('md')]: {
            fontSize: 13
        },
        paddingLeft: theme.spacing()
    },
    currentBidText: {
        color: 'limegreen',
        [theme.breakpoints.down('md')]: {
            fontSize: 13
        }
    },
    currency: {
        fontSize: 10,
    },
}));

interface Props {
    bid: number;
    startPrice?: number;
    finalPrice?: number;
    currency: string;
    border?: boolean;
    horizontal?: boolean;
}

const CurrentBid = ({ bid, currency, startPrice, finalPrice, border = true, horizontal = false }: Props): ReactElement => {
    const classes = useStyles();
    const theme = useTheme();

    const currentBidText = intl.get('auction.page.currentBid').d('Current Bid');
    const startPriceText = intl.get('auction.page.startPrice').d('Starting Bid');

    let bidText = currentBidText;
    let amount = localizeCurrency(bid, currency);

    if (startPrice && bid === 0.00) {
        amount = localizeCurrency(startPrice, currency);
        bidText = startPriceText;
    }

    if (finalPrice) {
        amount = localizeCurrency(finalPrice, currency);
    }

    const style: any = {
        display: 'flex',
        flexDirection: horizontal ? 'row' : 'column',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic gap
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 3)
        }
    };

    const jsxElement = (
        <Box sx={{ ...style }}>
            <Typography className={classes.primaryText} variant="h2">{amount} <span className={classes.currency}>{currency}</span></Typography>
            <LimegreenLabel text={bidText} />
        </Box>
    );

    let jsx : ReactElement;

    if (border) {
        jsx = (
            <RoundedBox>
                { jsxElement }
            </RoundedBox>
        );
    } else {
        jsx = jsxElement;
    }

    return jsx;
};

export default CurrentBid;
