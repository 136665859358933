import * as yup from 'yup';
import intl from 'react-intl-universal';

export const schema = yup.object().shape({
    name: yup.string(),
    hasAgreedToTerms: yup.bool()
        .oneOf([true],
            intl.get('common.form.register.checkbox.acceptTerms.error.required').d('You must agree to the terms to continue forward')
        )
});
