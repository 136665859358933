import React from 'react';
// import intl from 'react-intl-universal';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    badge: {
        color: ({ color, backgroundColor }: CSSProps) => {
            if (color) {
                return color;
            }
            return theme.palette.getContrastText(backgroundColor);
        },
        backgroundColor: ({ backgroundColor }: CSSProps) => backgroundColor,
        [theme.breakpoints.down('md')]: {
            fontSize: 12
        }
    }
}));

interface Props {
    text: string;
    color?: string;
    backgroundColor: string;
    variant?: 'filled' | 'outlined';
}

interface CSSProps {
    color?: string;
    backgroundColor: string;
}

const CardChip = ({ text, backgroundColor, color, variant = 'filled' }: Props): JSX.Element => {
    const classes = useStyles({ backgroundColor, color });

    return (
        <Chip className={classes.badge} label={text} variant={variant} />
    );
};

export default CardChip;
