import React from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { SnackbarNotificationType, SnackbarNotificationFunctionType } from '../../types';



// Notifies the user with a snackbar message
const useNotification = (): SnackbarNotificationFunctionType => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const action = (key) => (
        <>
            <IconButton onClick={() => closeSnackbar(key)} color="inherit" aria-label="delete">
                <CloseIcon />
            </IconButton>
        </>
    );

    const notify = ({ message, severity, vertical = 'top', horizontal = 'right', persist = false, duration = 2000 }: SnackbarNotificationType) => {
        enqueueSnackbar(message, {
            variant: severity,
            preventDuplicate: true,
            persist,
            anchorOrigin: {
                vertical,
                horizontal
            },
            autoHideDuration: duration,
            action
        });
    };

    return notify;
};

export default useNotification;
