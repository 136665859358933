import React from 'react';
import intl from 'react-intl-universal';
import { Box, Button, Divider, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import RefinmentListAccordion from '../../../common/components/RefinementListAccordion';
// import Menu from '../../../common/components/Menu';
import RefinementList from '../../../common/components/RefinementList';
import ClearRefinements from '../../../common/components/ClearRefinements';
import CurrentRefinements from '../../../common/components/CurrentRefinements';

const useStyle = makeStyles(theme => ({
    divider: {
        marginTop: theme.spacing(0.5)
    },
    text: {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        marginRight: theme.spacing(1),
    },
    bottomButtons: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2)
        }
    },
    button: {
        ...theme.typography.button,
        padding: theme.spacing(0)
    },
    currentRefinements: {
        marginTop: theme.spacing(2)
    }
}));

interface Props {
    onClose?: () => void;
}

const FilterList = ({ onClose }: Props): JSX.Element => {
    const classes = useStyle();

    const filtersText = intl.get('auction.page.home.search.filters.title').d('Filters:');
    // const statusText = intl.get('auction.page.home.search.filters.status').d('Status');
    const sellerTypeText = intl.get('auction.page.home.search.filters.sellerType').d('Seller Type');
    const countryText = intl.get('auction.page.home.search.filters.country').d('Country');
    const makeText = intl.get('auction.page.home.search.filters.make').d('Make');
    const yearText = intl.get('auction.page.home.search.filters.year').d('Year');
    const closeFiltersText = intl.get('auction.page.home.search.filters.close.mobile').d('Close Filters');

    return (
        <>
            <Box sx={{ mb: 1 }}>
                <Typography className={classes.text} variant="h3">{filtersText}</Typography>
                <Divider className={classes.divider} />
            </Box>
            {/* <RefinmentListAccordion label={statusText}>
                <Menu attribute="status" defaultRefinement="LIVE" />
            </RefinmentListAccordion> */}
            <RefinmentListAccordion label={countryText}>
                <RefinementList attribute="address.country" />
            </RefinmentListAccordion>
            <RefinmentListAccordion label={sellerTypeText}>
                <RefinementList attribute="sellerType" />
            </RefinmentListAccordion>
            <RefinmentListAccordion label={makeText}>
                <RefinementList attribute="dataFields.make.value" searchable />
            </RefinmentListAccordion>
            <RefinmentListAccordion label={yearText}>
                <RefinementList attribute="dataFields.year.value" searchable showMore />
            </RefinmentListAccordion>
            <Hidden mdUp>
                <Box className={classes.currentRefinements}>
                    <CurrentRefinements />
                </Box>
                <Divider className={classes.divider} />
                <Box className={classes.bottomButtons}>
                    <ClearRefinements />
                    <Button
                        className={classes.button}
                        color="primary"
                        onClick={() => onClose && onClose()}
                    >
                        {closeFiltersText}
                    </Button>
                </Box>
            </Hidden>
            <Hidden mdDown>
                <Box sx={{ mt: 2 }}>
                    <ClearRefinements />
                </Box>
            </Hidden>
        </>
    );
};

export default React.memo(FilterList);
