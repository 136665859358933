import io from 'socket.io-client';

let SERVER_ENDPOINT = 'http://localhost:1337';

if (process.env.REACT_APP_BACKEND_URL) {
    SERVER_ENDPOINT = process.env.REACT_APP_BACKEND_URL;
}

export const socket = io(SERVER_ENDPOINT, {
    transports: ['websocket', 'polling']
});
// export const socket = io(SERVER_ENDPOINT);
