import React from 'react';
import intl from 'react-intl-universal';
import {
    Dialog as MUIDialog,
    DialogTitle,
    Box,
    Tooltip,
    IconButton,
    DialogContent
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { VARIATIONS } from '../../constants';
import { BrandThemeType } from '../../types';
import { useBrandConfigurationContext } from '../../hooks';

const useStyles = makeStyles(theme => ({
    content: {
        marginBottom: '8vh',
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
            marginBottom: '2vh',
        },
        backgroundColor: 'inherit',
        color: 'inherit',
    },
    dialog: {
        '& .MuiDialog-paper': {
            backgroundColor: (configTheme: BrandThemeType) => {
                if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_SECONDARY) {
                    return theme.palette.secondary.main;
                } else if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_PRIMARY) {
                    return theme.palette.primary.main;
                }
                return theme.palette.background.paper;
            },
            color: (configTheme: BrandThemeType) => {
                if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_SECONDARY) {
                    return theme.palette.secondary.contrastText;
                } else if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_PRIMARY) {
                    return theme.palette.primary.contrastText;
                }
                return theme.palette.text.primary;
            },
        },
    },
    dialogTitle: {
        color: 'inherit',
        [theme.breakpoints.down('md')]: {
            paddingBottom: 0,
        }
    },
    iconStyle: {
        color: 'inherit'
    },
}));

interface Props {
    onClose: () => void;
    children?: React.ReactNode;
}

const Dialog = ({ onClose, children }: Props): JSX.Element => {
    const brandConfig = useBrandConfigurationContext();
    const { theme: configTheme } = brandConfig;
    const classes = useStyles(configTheme);

    const closeText = intl.get('common.icon.close.tooltip').d('Close');

    const handleCloseDialog = () => {
        onClose();
    };

    return (
        <MUIDialog onClose={handleCloseDialog} open={true} className={classes.dialog} maxWidth={false}>
            <DialogTitle className={classes.dialogTitle}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Tooltip enterTouchDelay={50} title={closeText}>
                        <IconButton className={classes.iconStyle} onClick={handleCloseDialog} aria-label={closeText}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </DialogTitle>
            <DialogContent className={classes.content}>
                { children }
            </DialogContent>
        </MUIDialog>
    );
};

export default Dialog;
