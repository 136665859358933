import intl from 'react-intl-universal';

import { socket } from '../../../socket';
import { SOCKET_EMITTERS } from '../../common/constants';
import { SnackbarNotificationFunctionType } from '../../common/types';

export const getAuction = (lot: string, notify: SnackbarNotificationFunctionType): void => {
    socket.emit(SOCKET_EMITTERS.JOIN_AUCTION_LOT, { lot }, (acknowledgement) => {
        if (acknowledgement === 'failure') {
            const message = intl.get('auction.socket.getAuction.failure').d('Lot not available');
            notify({ message, severity: 'error' });
        } else {
            // Success message can go here if we want
        }
    });
};

export const joinAuctionLot = (lot: string, notify: SnackbarNotificationFunctionType): void => {
    socket.emit(SOCKET_EMITTERS.JOIN_AUCTION_LOT, { lot }, (acknowledgement) => {
        if (acknowledgement === 'failure') {
            const message = intl.get('auction.socket.joinAuctionLot.failure').d('Lot not available');
            notify({ message, severity: 'error' });
        } else {
            // Success message can go here if we want
        }
    });
};

export const leaveAuctionLot = (lot: string, notify: SnackbarNotificationFunctionType): void => {
    socket.emit(SOCKET_EMITTERS.LEAVE_AUCTION_LOT, { lot }, (acknowledgement) => {
        if (acknowledgement === 'failure') {
            // const message = intl.get('auction.socket.leaveAuctionLot.failure').d('Lot not available');
            // notify({ message, severity: 'error' });
        } else {
            // Success message can go here if we want
        }
    });
};

export const userBidOnLot = (lot: string, notify: SnackbarNotificationFunctionType): void => {
    socket.emit(SOCKET_EMITTERS.BID, { lot }, (acknowledgement) => {
        if (acknowledgement === 'failure') {
            const message = intl.get('auction.socket.bid.failure').d('Lot not available');
            notify({ message, severity: 'error' });
        } else {
            const message = intl.get('auction.socket.bid.success').d('Successfully bid');
            notify({ message, severity: 'success' });
        }
    });
};

export const getComments = (auctionId: string, lot: string, notify: SnackbarNotificationFunctionType): void => {
    socket.emit(SOCKET_EMITTERS.GET_COMMENTS, { id: auctionId, lot }, (acknowledgement) => {
        if (acknowledgement === 'failure') {
            const message = intl.get('auction.socket.getComments.failure').d('Failed to fetch comments');
            notify({ message, severity: 'error' });
        } else {
            // Success message can go here if we want
        }
    });
};


export const userCommentOnLot = (auctionId: string, lot: string, notify: SnackbarNotificationFunctionType): void => {
    socket.emit(SOCKET_EMITTERS.COMMENT, { id: auctionId, lot }, (acknowledgement) => {
        if (acknowledgement === 'failure') {
            // Failure message can go here if we want
        } else {
            // Success message can go here if we want
        }
    });
};
