import React, { useState } from 'react';
import { Box, Typography, CircularProgress, Button, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AUCTION_LINK, WHITE_LABEL_BRANDS } from '../../../common/constants';
import { AuctionType } from '../../types';
import CustomDialog from '../../../common/components/CustomDialog';
import { useQuery } from '@apollo/client';
import { BrandConfigurationSimpleDataType, BrandVariablesType } from '../../../common/types';
import { QUERY_BRAND_SIMPLE_CONFIGURATION } from '../../../common/queries';

const useStyles = makeStyles(theme => ({
    overlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 1000,
        width: '100%'
    },
    relative: {
        position: 'relative'
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2, 4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 0),
        }
    },
    title: {
        fontFamily: 'Avenir',
        textAlign: 'center'
    },
    question: {
        fontWeight: 'bold',
        margin: theme.spacing(3, 0, 0, 0),
        textAlign: 'center'
    },
    textCenter: {
        textAlign: 'center'
    },
    loadingContainer: {
        minWidth: 250,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(4)
    },
    button: {
        width: '100%',
        margin: theme.spacing(2, 0)
    }
}));

interface Props {
    children: React.ReactNode;
    brand: AuctionType['brand']
    title: AuctionType['title']
    slug: AuctionType['slug']
}
const AuctionRedirectToBrand: React.FC<Props> = ({ children, brand, title, slug }): JSX.Element => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const shouldWrap = process.env.REACT_APP_BRAND === WHITE_LABEL_BRANDS.RACING_EDGE && brand !== WHITE_LABEL_BRANDS.RACING_EDGE;
    const { data, loading, error } = useQuery<BrandConfigurationSimpleDataType, BrandVariablesType>(QUERY_BRAND_SIMPLE_CONFIGURATION, {
        variables: {
            brand
        },
        skip: !isOpen
    });


    // window.location.replace(`${data.configurationByBrand.rootUrl}/${AUCTION_LINK}/lot/${slug}`);
    return shouldWrap ? (
        <>
            <Box className={classes.relative}>
                {children}
                <Box className={classes.overlay} onClick={() => setIsOpen(true)}/>
            </Box>
            {
                <CustomDialog
                    title={''}
                    onClose={() => setIsOpen(false)}
                    open={isOpen}
                    children={
                        <Box className={classes.box}>
                            {data?.configurationByBrand && (
                                <>
                                    <Typography
                                        className={classes.title}
                                        variant="h1"
                                        component="h2"
                                    >
                                        {title}
                                    </Typography>
                                    <Typography
                                        variant="h3"
                                        className={classes.textCenter}
                                    >
                                        is hosted by our partner {data.configurationByBrand.companyName}
                                    </Typography>
                                    <Typography
                                        className={`${classes.title} ${classes.question}`}
                                        variant="h3"
                                    >
                                        Click below to visit their website and place your bid.
                                    </Typography>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="secondary"
                                        component={Link}
                                        href={`${data.configurationByBrand.rootUrl}/${AUCTION_LINK}/lot/${slug}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        Continue
                                    </Button>
                                </>
                            )}
                            {loading && <Box className={classes.loadingContainer}>
                                <CircularProgress color="primary"/>
                            </Box>}
                            {error && <Box className={classes.loadingContainer}>
                                <Typography variant="h3">Sorry, there is an error</Typography>
                            </Box>}
                        </Box>
                    }
                />
            }
        </>
    ) : (
        <>{children}</>
    );
};

export default AuctionRedirectToBrand;
