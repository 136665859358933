import React, { useMemo } from 'react';
// import intl from 'react-intl-universal';
import { Grid, Box, Typography } from '@material-ui/core';
import { connectHits } from 'react-instantsearch-dom';

import AuctionCard from '../../components/AuctionCard';

import { AuctionCardType } from '../../types';

interface Props {
    hits: Array<AuctionCardType>;
    title?: string;
    refresh?: () => void;
}

const AuctionsResult = ({ hits, title, refresh }: Props): JSX.Element => {
    const memoizedContents = useMemo(() => hits.map((auction) =>
        <Grid key={auction.id} item xs={12} sm={6} md={4}>
            <AuctionCard auction={auction} refresh={refresh} />
        </Grid>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [hits]);

    return (
        <>
            {!!(title && hits.length) && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h2">{title}</Typography>
                </Box>
            )}
            <Box sx={{ mt: 2, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Grid container spacing={2}>
                    {memoizedContents}
                </Grid>
            </Box>
        </>
    );
};

export default React.memo(connectHits(AuctionsResult));
