/* eslint-disable array-callback-return */
import React, { useMemo, ReactElement } from 'react';
// import intl from 'react-intl-universal';
import { Button, Box } from '@material-ui/core';
// eslint-disable-next-line no-restricted-imports
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';

import { connectRefinementList } from 'react-instantsearch-dom';
import { RefinementListProvided } from 'react-instantsearch-core';

import { replaceAgencyToDealer, replaceUnderscoreAndLowercase, scrollToTop } from '../../helpers';
import { AUCTION_STATUS } from '../../../auction/constants';

const useStyles = makeStyles(theme => ({
    boxContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
        },
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 1)
        }
    },
    button: {
        [theme.breakpoints.up('md')]: {
            fontSize: '15px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '13px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '15px',
            padding: theme.spacing(1, 2),
            minHeight: 30
        },
    }
}));

type Props = RefinementListProvided & {
    scrollToId?: string;
}
interface Item {
    count: number,
    isRefined: boolean,
    label: string,
    value: string[]
}

const colorMap = {
    AUCTIONS: '#008000',
    [AUCTION_STATUS.LIVE.toString()]: '#008000',
    [AUCTION_STATUS.SCHEDULED.toString()]: '#342EDE',
    RESULTS: '#000000',
};

const endedFilter: string[] = [AUCTION_STATUS.RESERVE_NOT_MET.toString(), AUCTION_STATUS.SOLD.toString()];
const auctionsFilter: string[] = [AUCTION_STATUS.LIVE.toString(), AUCTION_STATUS.SCHEDULED.toString()];
const RefinementButtonList = ({ items, refine, scrollToId }: Props): ReactElement => {
    const classes = useStyles();

    const FilterItemsFunc = (filterArray: string[], items:Item[]) => useMemo(() => items.filter((x) => {
        if (filterArray.indexOf(x.label) >= 0) {
            return x;
        }
    }), [items, filterArray]);


    // Step 1: get the array of items filtered
    const endedItems = FilterItemsFunc(endedFilter, items);
    const auctionItems = FilterItemsFunc(auctionsFilter, items);

    // If the array is not empty
    const reduceItems = (items:Item[], label:string, filter:string[]) => {
        if (items && items.length > 0) {
            // Step 2: Try and get a reduced result from a filtered array of items
            return items.reduce((prevItem, currentItem) => {
                let count = 0;
                let isRefined = false;
                if (prevItem) {
                    const { count: prevCount, isRefined: prevRefined } = prevItem;
                    count += prevCount;
                    isRefined = prevRefined;
                    if (currentItem) {
                        count += currentItem.count;
                        isRefined = prevItem.isRefined || currentItem.isRefined;
                    }
                }
                // combine the results into a hit
                const combinedHit: Item = {
                    count,
                    isRefined,
                    label: label,
                    value: filter
                };

                return combinedHit;
            }, {
                count: 0,
                isRefined: false,
                label: label,
                value: filter
            });
        }
    };

    const results: Item | undefined = reduceItems(endedItems, 'RESULTS', endedFilter);
    const auctions: Item | undefined = reduceItems(auctionItems, 'AUCTIONS', auctionsFilter);

    // Step 3: filter out the rest of items that are not a part of the initial filter array
    const restItems = items.filter((x) => endedFilter.indexOf(x.label) < 0 && auctionsFilter.indexOf(x.label) < 0);

    // Step 4: create a new array of items that has the result object from the first step
    const finalItems = [...restItems, auctions, results];

    return (
        <Box className={classes.boxContainer}>
            {
                finalItems.length > 0 && finalItems.map(item => {
                    if (!item || (item && !item.label)) {
                        return;
                    }
                    const correctedLabel = replaceUnderscoreAndLowercase(replaceAgencyToDealer(item.label));
                    const labelId = `filter-list-refinement-label-${item.value}`;
                    let color = '#FFFFFF';

                    if (colorMap) {
                        color = colorMap[item.label];
                    }

                    const style: CSSProperties = {
                        textTransform: 'capitalize',
                        color: color,
                        borderColor: item.isRefined ? color : '#707070',
                        borderRadius: '10px',
                        fontWeight: 'bold',
                        fontFamily: 'Avenir',
                        backgroundColor: item.isRefined ? `${color}11` : 'transparent',
                        py: 1,
                        px: 6,
                        minHeight: '53px',
                        maxHeight: '53px',
                        textAlign: 'center',
                        '&:hover': {
                            backgroundColor: `${color}11`,
                            borderColor: color
                        }
                    };

                    return (
                        <Button
                            key={item.label}
                            id={labelId}
                            type="button"
                            variant="outlined"
                            sx={style}
                            className={classes.button}
                            onClick={(event) => {
                                event.preventDefault();
                                refine(item.value);
                                if (scrollToId) {
                                    scrollToTop(scrollToId);
                                }
                            }}
                        >
                            {`${correctedLabel} (${item.count})`}
                        </Button>
                    );
                })
            }
        </Box>
    );
};

export default React.memo(connectRefinementList(RefinementButtonList));
