import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';

import { IsLoggedInDataType, MeFullDataType, UserType } from '../../types';
import { QUERY_IS_LOGGED_IN, QUERY_ME_FULL } from '../../queries';

interface Props {
    children: React.ReactNode;
}

type ContextType = {
    user?: UserType | null;
    setUser?: Dispatch<SetStateAction<UserType | undefined | null>>;
    refetch?: () => void;
}

const AuthenticatedUserContext = createContext<ContextType>({});

export const AuthenticatedUserProvider = ({ children }: Props): JSX.Element => {
    const apolloClient = useApolloClient();
    const [user, setUser] = useState<UserType | undefined | null>(null);

    const { data } = useQuery<IsLoggedInDataType>(QUERY_IS_LOGGED_IN);

    const isLoggedIn = !!data?.isLoggedIn;

    // This query will load in user data if a JWT_TOKEN exists within localStorage
    const { data: dataUser, loading, refetch } = useQuery<MeFullDataType>(QUERY_ME_FULL, {
        fetchPolicy: 'network-only',
        skip: !isLoggedIn
    });

    useEffect(() => {
        if (dataUser) {
            setUser(dataUser.meFull);
        }
    }, [apolloClient, dataUser, loading]);

    return (
        <AuthenticatedUserContext.Provider value={{ user, setUser, refetch }}>
            {children}
        </AuthenticatedUserContext.Provider>
    );
};

export const useAuthenticatedUserContext = (): ContextType => useContext(AuthenticatedUserContext);

export default React.memo(AuthenticatedUserProvider);
