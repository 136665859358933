import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Box, Button, List, Collapse, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionGroup } from 'react-transition-group';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Comment from '../Comment';

import { CommentType } from '../../types';
import { useLoggedIn } from '../../../common/hooks';
import { useMemo } from 'react';

const useStyles = makeStyles(theme => ({
    container: {
        margin: ({ thread }: CSSPropsType) => {
            if (thread) {
                return theme.spacing(0, 0, 0, 2);
            }
        }
    },
    comments: {
        display: 'flex',
        flexDirection: 'column',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic gap
        '& > * + *': {
            margin: theme.spacing(1, 0, 0, 0)
        },
    },
    viewMore: {
        maxWidth: 150,
        width: '100%',
        height: '100%',
        margin: ({ thread }: CSSPropsType) => {
            if (thread) {
                return theme.spacing(0, 0, 0, 2);
            }
        },
        fontWeight: 'bold',
        // // textTransform: 'inherit',
        color: '#065fd4'
    }
}));


interface Props {
    disabled?: boolean;
    comments?: Array<CommentType>;
    thread?: {
        count: number;
    }
}

type CSSPropsType = {
    thread: boolean;
}

const CommentsList = ({ thread, comments }: Props): JSX.Element => {
    const hasThreads = thread ? thread.count > 0 : false;
    const classes = useStyles({ thread: hasThreads });
    const [isLoggedIn] = useLoggedIn();
    const [showMore, setShowMore] = useState<boolean>(false);

    const noCommentsText = intl.get('auction.page.details.comments.noComments.').d('No comments have been made yet! Be the first!');

    const handleViewMore = () => {
        setShowMore(!showMore);
    };

    const sortedComments = useMemo(() => {
        if (comments) {
            if (!hasThreads || (hasThreads && showMore)) {
                // The array is frozen in strict mode, so we slice it to make a copy before sorting
                return comments.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
            }
        }
        return [];
    }, [comments, hasThreads, showMore]);

    return (
        <Box className={classes.container}>
            <List>
                {
                    hasThreads && (
                        <Button
                            className={classes.viewMore}
                            startIcon={<ExpandMoreIcon />}
                            onClick={handleViewMore}
                        >
                            {
                                showMore ? 'Hide replies' : `View ${thread?.count} replies`
                            }
                        </Button>
                    )
                }
                <TransitionGroup className={classes.comments}>
                    {
                        sortedComments && sortedComments.map((comment: CommentType) => (
                            <Collapse key={comment.id}>
                                <Comment canComment={isLoggedIn} {...comment} />
                            </Collapse>
                        ))
                    }
                </TransitionGroup>
                {
                    !hasThreads && comments && comments.length === 0 && (
                        <Typography variant="body1">{noCommentsText}</Typography>
                    )
                }
            </List>
        </Box>
    );
};

export default React.memo(CommentsList);
