import * as yup from 'yup';
import intl from 'react-intl-universal';

export const schema = yup.object().shape({
    name: yup.string()
        .required(
            intl.get('auction.form.submission.name.error.required').d('Please enter in your name')
        ),
    email: yup.string()
        .email(
            intl.get('auction.form.submission.input.email.error.invalid').d('Please enter in a valid email')
        )
        .required(
            intl.get('auction.form.submission.email.error.required').d('Please enter in your email')
        ),
    hasAgreedToTerms: yup.boolean()
        .oneOf([true],
            intl.get('auction.form.submission.terms.input.invalid').d('You must agree to the terms to continue')
        )
});
