import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box } from '@material-ui/core';

import AuthenticationDialog from '../../../containers/AuthenticationDialog';

import useLoggedIn from '../../../hooks/useLoggedIn';

const useStyles = makeStyles(theme => ({
    overlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 1000,
    }
}));

interface Props {
    children: React.ReactNode;
}

const CheckpointIsLoggedIn = ({ children }: Props): JSX.Element => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoggedIn] = useLoggedIn();

    return isLoggedIn ?
        <>{ children }</> :
        (
            <>
                <Box sx={{ position: 'relative' }}>
                    { children }
                    <Box className={classes.overlay} onClick={() => setIsOpen(true)}/>
                </Box>
                {
                    isOpen && <AuthenticationDialog onClose={() => setIsOpen(false)} />
                }
            </>
        );
};

export default CheckpointIsLoggedIn;
