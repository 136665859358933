import React, { useState, useEffect } from 'react';
// import intl from 'react-intl-universal';
import { useLocation, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

import LoginRequired from '../../components/LoginRequired';
import UserDirectories from '../../containers/UserDirectories';
import DirectoryForm from '../../components/DirectoryForm';

import { PricingPackageType } from '../../../common/types';
import { DirectoryType } from '../../types';
import { useLoggedIn } from '../../../common/hooks';

const UpdatePage = (): JSX.Element => {
    const history = useHistory();
    const location = useLocation();
    const [isLoggedIn] = useLoggedIn();

    // The user-selected directory
    const [plan, setPlan] = useState<PricingPackageType | undefined>(undefined);
    const [directory, setDirectory] = useState<DirectoryType | undefined>(undefined);

    // Ensure the pathing works as expected
    useEffect(() => {
        if (location.pathname.endsWith('update')) {
            // Upon going 'back' clear the plan so the user may re-select
            setDirectory(undefined);
        } else if (!directory) {
            // The location is currently "...update/PLAN" but we do not have a plans info
            // replace the url as we will be requiring they select a plan
            history.replace('/directory/update');
        }
    }, [location, history, directory]);

    // Function for when the user selects a directories
    const selectDirectory = (selectedDir: DirectoryType, plan?: PricingPackageType) => {
        // Set the plan if provided
        setPlan(plan);
        // Set the chosen directoriess info
        setDirectory(selectedDir);
        // Update the url
        history.push(`/directory/update/${selectedDir.name.toLowerCase().replace(/\s/g, '-')}`);
    };

    // Function for when the user chooses to change the plan
    const changeDirectory = () => {
        // Set the chosen plans info
        setDirectory(undefined);
        // Replace the url
        history.replace('/directory/update');
    };


    // User must be signed in
    if (!isLoggedIn) {
        return (
            <Box>
                <LoginRequired action="update" />
            </Box>
        );
    }

    // Select a Directory
    if (!directory) {
        // First need to select a directory
        return <UserDirectories editDirectory={selectDirectory} />;
    }

    // Once a directory is selected, allow filling the form
    return (
        <Box sx={{ mt: 4 }}>
            <DirectoryForm action="update" directory={directory} goBack={changeDirectory} plan={plan} />
        </Box>
    );
};

export default UpdatePage;
