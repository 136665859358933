import React from 'react';
import intl from 'react-intl-universal';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import parser from 'html-react-parser';

import PageHeader from '../../components/PageHeader';
import Hero from '../../components/Hero';
import Quote from '../../components/Quote';
import Points from '../../components/Points';
import Offerings from '../../components/Offerings';
import FormComponent from '../Universal/Segments/Form';
import Services from '../../components/Services';
import LinksWithImage from '../../components/LinksWithImage';

import { DealershipSolutionsPageDataType } from '../../types';
import { QUERY_DEALERSHIP_SOLUTIONS_PAGE } from '../../queries';
import { getImageUrl } from '../../helpers';

const useStyles = makeStyles(theme => ({
    box: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    spacing: {
        margin: theme.spacing(4, 0)
    }
}));

const DealershipSolutionsPage = (): JSX.Element => {
    const classes = useStyles();

    const { data: dataDealershipSolutions } = useQuery<DealershipSolutionsPageDataType>(QUERY_DEALERSHIP_SOLUTIONS_PAGE);

    const defaultHeaderText = intl.get('common.page.dealershipSolutions.header').d('Dealership Solutions');

    const data = dataDealershipSolutions && dataDealershipSolutions.dealershipSolution;
    let headerText = defaultHeaderText;

    if (data && data.title) {
        headerText = data.title;
    }

    const contentImageSource = data && data.contentImage && getImageUrl(data.contentImage, 'large');

    return (
        <>
            <PageHeader label={headerText} />
            {
                data && (
                    <Box className={classes.box}>
                        {
                            data.hero1 && (<Hero {...data.hero1} />)
                        }
                        {
                            data.quote1 && (
                                <Container className={classes.spacing} maxWidth="md">
                                    <Quote {...data.quote1} />
                                </Container>
                            )
                        }
                        {
                            data.content1 && (
                                <Container className={classes.spacing} maxWidth="md">
                                    <Typography component="span" variant="body2">{parser(data.content1)}</Typography>
                                </Container>
                            )
                        }
                        {
                            data.hero2 && (
                                <Hero {...data.hero2} />
                            )
                        }
                        <Container className={classes.spacing} maxWidth="lg">
                            <Grid container direction="row" spacing={2}>
                                <Grid container item xs={12} md={6} justifyContent="center" alignItems="center">
                                    <Grid item>
                                        {
                                            data.quote2 && (
                                                <Quote {...data.quote2} />
                                            )
                                        }
                                        {
                                            data.content2 && (
                                                <Typography component="span" variant="body2">{parser(data.content2)}</Typography>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} md={6} justifyContent="center" alignItems="center">
                                    <Grid item>
                                        {
                                            contentImageSource && (
                                                <Container className={classes.spacing} maxWidth="md">
                                                    <Box>
                                                        <img width="100%" height="100%" src={contentImageSource} alt={data?.contentImage?.alternativeText} />
                                                    </Box>
                                                </Container>
                                            )
                                        }
                                        {
                                            data.points && (
                                                <Container className={classes.spacing} maxWidth="md">
                                                    <Points points={data.points} />
                                                </Container>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                        {
                            data.hero3 && (
                                <Hero {...data.hero3} />
                            )
                        }
                        {
                            data.offerings && (
                                <Container className={classes.spacing} maxWidth="lg">
                                    <Offerings offerings={data.offerings} />
                                </Container>
                            )
                        }
                        {
                            data.services && (
                                <Container className={classes.spacing} maxWidth="lg">
                                    <Services services={data.services}/>
                                </Container>
                            )
                        }
                        {
                            data.dealers && (
                                <Container className={classes.spacing} maxWidth="lg">
                                    <LinksWithImage {...data.dealers} />
                                </Container>
                            )
                        }
                        <Box sx={{ width: '100%', mb: 4 }}>
                            <FormComponent id="1" type="CONTACT_US" reachUs />
                        </Box>
                    </Box>
                )
            }
        </>
    );
};

export default React.memo(DealershipSolutionsPage);
