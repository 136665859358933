import { gql } from '@apollo/client';

export const MEDIA_ATTRIBUTES = gql`
    fragment MediaParts on UploadFile {
        id
        name
        mime
        alternativeText
        caption
        url
        formats
    }
`;

export const ADVANCED_ADVERT_ATTRIBUTES = gql`
    fragment AdvancedAdvertParts on ComponentSystemAdvancedAdvert {
        enabled
        imageDesktop {
            ...MediaParts
        }
        imageMobile {
            ...MediaParts
        }
        relativeLink
        absoluteLink
        imageSize
        openNewTab
    }
`;

export const UNIVERSAL_PROPERTIES_ATTRIBUTES = gql`
    fragment PropertiesParts on ComponentUniversalProperties {
        background
        accentEnabled
        accentSide
        accentTitle
        accentBackground
        marginBottom
        titleColor
        titleAlignDesktop
        titleAlignMobile
        actionVariant
        actionColor
    }
`;

export const ACTIVE_PERIOD_ATTRIBUTES = gql`
    fragment ActivePeriodParts on ComponentSystemTimeframe {
        hours
        years
        months
        days
        minutes
        seconds
    }
`;

export const PAYMENT_PRICING_ATTRIBUTES = gql`
    fragment PaymentPricingParts on ComponentPricingPricing  {
        enabled
        price
    }
`;

export const PAYMENT_SNAPPR_ATTRIBUTES = gql`
    fragment PaymentSnapprParts on ComponentPricingSnappr  {
        enabled
        forceEnabled
        price
    }
`;

export const SELLING_POINTS_ATTRIBUTES = gql`
    fragment SellingPointsParts on ComponentPricingSellingPoint {
        id
        text
        subtext
    }
`;

export const SOCIAL_LINKS_ATTRIBUTES = gql`
    fragment SocialLinksParts on ComponentShareSocialLinks {
        facebook
        twitter
        instagram
        linkedin
        youtube
    }
`;

export const ADDRESS_ATTRIBUTES = gql`
    fragment AddressParts on ComponentSystemAddress {
        address1
        address2
        city
        country
        postal
        state
    }
`;

export const HEADER_NAV_ATTRIBUTES = gql`
    fragment HeaderNavParts on ComponentConfigurationMenuItem {
        active
        title
        url
        universal {
            id
            slug
        }
        subMenu {
            active
            title
            url
        }
    }
`;

export const BUSINESS_HOURS_ATTRIBUTES = gql`
    fragment BusinessHoursParts on ComponentConfigurationBusinessHours {
        weekdays
        weekend
        timezone
    }
`;

export const EMAIL_NOTIFICATION_ATTRIBUTES = gql`
    fragment EmailNotificationParts on ComponentConfigurationEmailNotificationConfiguration {
        salesEmail
        adminEmail
        daysBeforeExpiry
        emailExpiryOffset
    }
`;

export const CONTACT_US_ATTRIBUTES = gql`
    fragment ContactUsParts on ComponentConfigurationContactUs {
        email
        phoneNumber
        address
    }
`;

export const THEME_COLOURS_ATTRIBUTES = gql`
    fragment ThemeColoursParts on ComponentThemeColours {
        primaryColor
        secondaryColor
        success
        warning
        info
        error
        linkTextHover
    }
`;

export const CONFIGURATION_PADDOCK_ATTRIBUTES = gql`
    fragment ConfigurationPaddockParts on ComponentConfigurationPaddock {
        numberOfHits
        homepageBanner {
            ...AdvancedAdvertParts
        }
    },
    ${ADVANCED_ADVERT_ATTRIBUTES}
`;

export const ME_ATTRIBUTES = gql`
    fragment MeParts on UsersPermissionsMe {
        id
        username
        email
        confirmed
        blocked
    }
`;

export const ADVERT_ATTRIBUTES = gql`
    fragment AdvertParts on ComponentSystemAdvert {
        url
        image {
            ...MediaParts
        }
    },
    ${MEDIA_ATTRIBUTES}
`;

export const MODULE_NAV_ATTRIBUTES = gql`
    fragment ModuleParts on ComponentSystemModule {
        active
        name
        description
        url
        cardImage {
            ...MediaParts
        }
        featuredImage {
            ...MediaParts
        }
    },
    ${MEDIA_ATTRIBUTES}
`;

export const THEME_IMAGES_ATTRIBUTES = gql`
    fragment ThemeImagesParts on ComponentThemeImages {
        dashboardLogo {
            ...AdvertParts
        }
        socialLinksLogo {
            ...AdvertParts
        }
        headerLogo {
            ...AdvertParts
        }
        defaultProfile {
            ...AdvertParts
        }
        loginLogo {
            ...AdvertParts
        }
        defaultImage {
            ...AdvertParts
        }
    }
    ${ADVERT_ATTRIBUTES}
`;

export const THEME_ATTRIBUTES = gql`
    fragment ThemeParts on ComponentConfigurationTheme {
        darkMode
        headerVariation
        footerVariation
        loginVariation
        soldSectionVariation
        colours {
            ...ThemeColoursParts
        }
        images {
            ...ThemeImagesParts
        }
        primaryFont
        secondaryFont
        headerPosition
        headerLogoHeight
        headerLogoHeightMd
        dialogLogoHeight
    }
    ${THEME_IMAGES_ATTRIBUTES}
    ${THEME_COLOURS_ATTRIBUTES}
`;

export const USER_ADDITIONAL_ATTRIBUTES = gql`
    fragment UserAdditionalParts on UsersPermissionsUser {
        firstName
        lastName
        phone
        bio
        website
        address {
            ...AddressParts
        }
        socialLinks {
            ...SocialLinksParts
        }
    },
    ${MEDIA_ATTRIBUTES}
    ${ADDRESS_ATTRIBUTES}
    ${SOCIAL_LINKS_ATTRIBUTES}
`;

export const USER_ATTRIBUTES = gql`
    fragment UserParts on UsersPermissionsUser {
        id
        email
        username
        confirmed
        blocked
        cmsAccess
        brand
        avatar {
            ...MediaParts
        }
        ...UserAdditionalParts
    },
    ${USER_ADDITIONAL_ATTRIBUTES}
    ${MEDIA_ATTRIBUTES}
`;

export const ANNOUNCEMENT_ATTRIBUTES = gql`
    fragment AnnouncementParts on ComponentSystemAnnouncement {
        title
        subtitle
        image {
            ...MediaParts 
        }
        link
        daysToLive
        secondsBeforeBannerShows
    },
    ${MEDIA_ATTRIBUTES}
`;

export const CAROUSEL_LISTINGS_ATTRIBUTES = gql`
    fragment CarouselListingsParts on Listing {
        id
        title
        slug
        category
        type
        description
        excerpt
        images {
        ...MediaParts
        }
    },
    ${MEDIA_ATTRIBUTES}
`;

export const LISTINGS_ATTRIBUTES = gql`
    fragment ListingsParts on Listing {
        id
        title
        slug
        description
        excerpt
        images {
            ...MediaParts
        }
        user {
            ...UserParts
        }
        price
        currency
        status
        category
        address {
            ...AddressParts
        }
        type
        sellerType
        publishedAt
        poa
        showBTCExchangeRate
        extraDetails
        weighting
        expiry
    },
    ${MEDIA_ATTRIBUTES}
    ${USER_ATTRIBUTES}
    ${ADDRESS_ATTRIBUTES}
`;

export const PRICING_PACKAGES_ATTRIBUTES = gql`
    fragment PricingPackageParts on PricingPackage {
        id
        title
        type
        imageLimit
        videoLimit
        listingLimit
        listingLimitMax
        weighting
        featured
        currency
        isFree
        permittedCategories
        restrictedCategories
        sellingPoints {
            ...SellingPointsParts
        }
        oneTimePayment {
            ...PaymentPricingParts
        }
        monthlyPayment {
            ...PaymentPricingParts
        }
        yearlyPayment {
            ...PaymentPricingParts
        }
        pricePerListing {
            ...PaymentPricingParts
        }
        activePeriod {
            ...ActivePeriodParts
        }
        snappr {
            ...PaymentSnapprParts
        }
    },
    ${ACTIVE_PERIOD_ATTRIBUTES}
    ${PAYMENT_PRICING_ATTRIBUTES}
    ${PAYMENT_SNAPPR_ATTRIBUTES}
    ${SELLING_POINTS_ATTRIBUTES}
`;

export const PROMOTION_ATTRIBUTES = gql`
    fragment PromotionParts on Promotion {
        id
        isFree
        redeemed
        coupon {
            id
            redeemed
        }
    }
`;

export const IMAGE_DESCRIPTION_LINK_ATTRIBUTES = gql`
    fragment ImageDescriptionLinkParts on ComponentPageImageDescriptionLink {
        id
        title
        image {
            ...MediaParts
        }
        description
        linkText
        url
    },
    ${MEDIA_ATTRIBUTES}
`;

export const HERO_ATTRIBUTES = gql`
    fragment HeroParts on ComponentPageHero {
        text
        image {
            ...MediaParts
        }
    },
    ${MEDIA_ATTRIBUTES}
`;

export const QUOTE_ATTRIBUTES = gql`
    fragment QuoteParts on ComponentPageQuote {
        text
        author
    }
`;

export const PICTURE_INFO_ATTRIBUTES = gql`
    fragment PictureInfoParts on ComponentPagePictureInfo {
        id
        title
        description
        image {
            ...MediaParts
        }
    },
    ${MEDIA_ATTRIBUTES}
`;

export const PICTURE_INFO_WITH_ACTION_ATTRIBUTES = gql`
    fragment PictureInfoWithActionParts on ComponentPagePictureInfoWithAction {
        pictureInfo {
            ...PictureInfoParts
        }
        action {
            id
            name
            url
        }
    },
    ${PICTURE_INFO_ATTRIBUTES}
`;

export const LIST_ITEM_ATTRIBUTES = gql`
    fragment ListItemParts on ComponentPageListItem {
        text
        listSubItems {
            text
        }
    }
`;

export const INFO_ATTRIBUTES = gql`
    fragment InfoParts on ComponentPageInfo {
        title
        description
    }
`;

export const SERVICE_INFO_ATTRIBUTES = gql`
    fragment ServiceInfoParts on ComponentPageService {
        id
        title
        description
        image {
            ...MediaParts
        }
        price
        priceFrequency
        isRight
    },
    ${MEDIA_ATTRIBUTES}
`;

export const PICTURE_INFO_RICH_ATTRIBUTES = gql`
    fragment PictureInfoRichParts on ComponentPagePictureInfoRich {
        id
        title
        description
        image {
            ...MediaParts
        }
    },
    ${MEDIA_ATTRIBUTES}
`;

export const CONTENT_ATTRIBUTES = gql`
    fragment ContentParts on ComponentSystemContent {
        id
        content
        title
    }
`;

export const LINKS_WITH_IMAGES_ATTRIBUTES = gql`
    fragment LinksWithImagesParts on ComponentPageLinksWithImages {
        title
        isCarousel
        links {
            id
            name
            url
            media {
                ...MediaParts
            }
        }
    }
`;

export const PROPERTIES_FIELD_OPTION_ATTRIBUTES = gql`
    fragment PropertiesFieldOptionParts on ComponentListingPropertyOption {
        id
        name
    }
`;

export const PROPERTIES_FIELD_SIZE_ATTRIBUTES = gql`
    fragment PropertiesFieldSizeParts on ComponentListingPropertySize {
        xs
        sm
        md
        lg
        xl
    }
`;

export const PROPERTIES_FIELD_ATTRIBUTES = gql`
    fragment PropertiesFieldParts on ComponentListingPropertyField {
        id
        enabled
        name
        label
        type
        regex
        regexMessage
        required
        min
        max
        precision
        tooltip
        hidden
        options {
            ...PropertiesFieldOptionParts
        }
        size {
            ...PropertiesFieldSizeParts
        }
        placeholder
        icon
        iconType
        childName
        order
    }
    ${PROPERTIES_FIELD_OPTION_ATTRIBUTES}
    ${PROPERTIES_FIELD_SIZE_ATTRIBUTES}
`;

export const UNIVERSAL_PAGE_ATTRIBUTES = gql`
    fragment UniversalPageParts on Universals {
        id
        title
        slug
        brand
        published_at
        segments {
            ... on ComponentPageLinksWithImages {
                __typename
                title
                isCarousel
                links {
                    id
                    name
                    url
                    media {
                        ...MediaParts
                    }
                }
            }
            ... on ComponentPageLinks {
                __typename
                title
                links {
                    id
                    name
                    url
                }
            }
            ... on ComponentSystemForm {
                __typename
                title
                type
                reachUs
            }
            ... on ComponentSystemContentWithImage {
                __typename
                content
                title
                media {
                    ...MediaParts
                }
            }
            ... on ComponentSystemContent {
                __typename
                content
                title
            }
            ... on ComponentSystemHeader {
                __typename
                name
            }
            ... on ComponentPageBanner {
                __typename
                media {
                    ...MediaParts
                }
                title
                content
                alignment
                action {
                    id
                    name
                    url
                }
            }
            ... on ComponentPagePictureGrid {
                __typename
                maxRowItems
                items {
                    ...PictureInfoWithActionParts
                }
            }
            ... on ComponentUniversalCarousel {
                __typename
                properties {
                    ...PropertiesParts
                }
                titleLeft
                titleRight
                items {
                    ...PictureInfoWithActionParts
                }
                overlayLogo
                overlayPosition
            }
            ... on ComponentUniversalInformation {
                __typename
                properties {
                    ...PropertiesParts
                }
                title
                description
                boxItems {
                    ...ListItemParts
                }
                additionalInfo
                listItems1 {
                    ...ListItemParts
                }
                listItems2 {
                    ...ListItemParts
                }
            }
            ... on ComponentUniversalServices {
                __typename
                properties {
                    ...PropertiesParts
                }
                title
                description
                items {
                    title
                    description
                    media {
                        ...MediaParts
                    }
                    actionLabel
                    actionUrl
                }
            }
            ... on ComponentUniversalInventory {
                __typename
                properties {
                    ...PropertiesParts
                }
                title
                displayInventory
                inventoryCategory
                action {
                    id
                    name
                    url
                }
                view
                pageItemCount
            }
            ... on ComponentUniversalFaq {
                __typename
                properties {
                    ...PropertiesParts
                }
                title
                faqs {
                    ...InfoParts
                }
            }
            ... on ComponentUniversalAbout {
                __typename
                properties {
                    ...PropertiesParts
                }
                title
                content
                image {
                    ...MediaParts
                }
                imageName
                imageRole
                imageContent
            }
            ... on ComponentUniversalAffiliates {
                __typename
                properties {
                    ...PropertiesParts
                }
                title
                subtitle
                items {
                    image {
                        ...MediaParts
                    }
                    url
                }
            }
            ... on ComponentUniversalMedia {
                __typename
                properties {
                    ...PropertiesParts
                }
                title
                displayMedia
                articlesCategory
                action {
                    id
                    name
                    url
                }
            }
            ... on ComponentUniversalGallery {
                __typename
                properties {
                    ...PropertiesParts
                }
                title
                items {
                    image {
                        ...MediaParts
                    }
                    url
                }
            }
            ... on ComponentUniversalContact {
                __typename
                properties {
                    ...PropertiesParts
                }
                title
                address
                phone
                email
                displayAddress
                displayPhone
                displayEmail
                displayNewsletter
                displayLogo
            }
            ... on ComponentUniversalImageBanner {
                __typename
                properties {
                    ...PropertiesParts
                }
                title
                fullWidth
                image {
                    ...MediaParts
                }
            }
            ... on ComponentUniversalInformationList {
                __typename
                properties {
                    ...PropertiesParts
                }
                title
                list {
                    ...InfoParts
                }
                smMaxWidth
                mdMaxWidth
                lgMaxWidth
            }
            ... on ComponentUniversalInformationBoxes {
                __typename
                properties {
                    ...PropertiesParts
                }
                title
                list {
                    ...InfoParts
                }
                smRowItems
                mdRowItems
                lgRowItems
            }
        }
    },
    ${MEDIA_ATTRIBUTES}
    ${INFO_ATTRIBUTES}
    ${LIST_ITEM_ATTRIBUTES}
    ${UNIVERSAL_PROPERTIES_ATTRIBUTES}
    ${PICTURE_INFO_WITH_ACTION_ATTRIBUTES}
`;

export const CONTACT_INFO_ATTRIBUTES = gql`
    fragment ContactInfoParts on ComponentSystemContact {
        id
        name
        phone
        email
    }
`;

export const CONFIGURATION_AUCTION_ATTRIBUTES = gql`
    fragment ConfigurationAuctionParts on ComponentConfigurationAuction {
        host {
            id
            email
            username
            confirmed
            blocked
            created_at
            updated_at
        }
        sniperProtectionTime
        displayLimit
        dateRangeLimit
        bidMinimumIncrement
        infographic {
            desktop {
                ...MediaParts
            }
            mobile {
                ...MediaParts
            }
        }
        information {
            desktop {
                ...MediaParts
            }
            mobile {
                ...MediaParts
            }
        }
        reserveMeter {
            enabled
            fillColor
            pdfGuide {
                ...MediaParts
            }
        }
    },
    ${MEDIA_ATTRIBUTES}
`;
