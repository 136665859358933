import React from 'react';
import intl from 'react-intl-universal';
import { Paper, Box, Typography } from '@material-ui/core';
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Dropzone, { FileRejection } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';

import { MAX_FILE_SIZE_UPLOAD } from '../../../constants';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        textAlign: 'center',
        cursor: 'pointer',
        color: theme.palette.text.primary,
        padding: theme.spacing(4, 0),
    },
    icon: {
        color: theme.palette.common.black,
        fontSize: 42
    },
    active: {
        border: `2px solid ${theme.palette.primary.main}`
    },
    disabled: {
        filter: 'opacity(0.5)',
        cursor: 'default'
    }
}));

interface Props {
    id: string;
    name: string;
    accept: string;
    onDrop: (acceptedFiles: Array<File>, rejectedFiles: Array<FileRejection>) => void;
    hasReachedFileUploadLimit?: boolean;
    fileLimit?: number;
}

const DropzoneField = ({ id, name, accept, onDrop, hasReachedFileUploadLimit = false, fileLimit = 0 }: Props): JSX.Element => {
    const classes = useStyles();
    const { control } = useFormContext();

    const releaseText = intl.get('common.field.dropzone.release').d('Release to add the images here');
    const dragDropText = intl.get('common.field.dropzone.dragDrop').d('Click to upload images');

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={[]}
            render={() => (
                <Dropzone onDrop={onDrop} accept={accept} disabled={hasReachedFileUploadLimit} maxSize={MAX_FILE_SIZE_UPLOAD} maxFiles={fileLimit} multiple>
                    {
                        ({ getInputProps, getRootProps, isDragActive }) => (
                            <Paper
                                {...getRootProps()}
                                className={`${classes.root} ${isDragActive ? classes.active : ''} ${hasReachedFileUploadLimit ? classes.disabled : ''}`}
                                variant="outlined"
                                square
                            >
                                <CloudUploadIcon className={classes.icon} />
                                <input id={id} name={name} {...getInputProps()} />
                                <Box>
                                    {
                                        isDragActive ? (
                                            <Typography variant="body1">{releaseText}</Typography>
                                        ) : (
                                            <Typography variant="body1">{dragDropText}</Typography>
                                        )
                                    }
                                </Box>
                            </Paper>
                        )
                    }
                </Dropzone>
            )}
        />
    );
};

export default DropzoneField;
