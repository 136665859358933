import { gql } from '@apollo/client';

import { ADDRESS_ATTRIBUTES, CONTACT_INFO_ATTRIBUTES, MEDIA_ATTRIBUTES, PROPERTIES_FIELD_ATTRIBUTES, USER_ATTRIBUTES } from '../common/fragments';

export const BID_ATTRIBUTES = gql`
    fragment BidParts on Bid {
        id
        amount
        isFirstBid
        properties
        created_at
        bidder {
            ...UserParts
        }
    },
    ${USER_ATTRIBUTES}
`;

export const AUCTION_ATTRIBUTES = gql`
    fragment AuctionParts on Auction {
        id
        title
        slug
        sellerType
        description
        userDescription
        scheduledStartDate
        scheduledEndDate
        finalEndDate
        excerpt
        exterior {
            ...MediaParts
        }
        interior {
            ...MediaParts
        }
        mechanical {
            ...MediaParts
        }
        documents {
            ...MediaParts
        }
        coverImage {
            ...MediaParts
        }
        startPrice
        finalPrice
        minIncrementAmount
        reserve
        currency
        status
        paymentStatus
        dataFields
        bids {
            ...BidParts
        }
        hosts {
            ...UserParts
        }
        user {
            ...UserParts
        }
        contactInfo {
            ...ContactInfoParts
        }
        address {
            ...AddressParts
        }
        hasAgreedToTerms
        packageId
        paymentType
        serviceSnappr
        reserveMeterEnabled
        watchlist {
            id
        }
        brand
    },
    ${USER_ATTRIBUTES}
    ${ADDRESS_ATTRIBUTES}
    ${BID_ATTRIBUTES}
    ${MEDIA_ATTRIBUTES}
    ${CONTACT_INFO_ATTRIBUTES}
`;

export const AUCTION_PROPERTIES_ATTRIBUTES = gql`
    fragment AuctionPropertiesParts on AuctionProperties {
        id
        fields {
            ...PropertiesFieldParts
        }
    }
    ${PROPERTIES_FIELD_ATTRIBUTES}
`;

export const COMMENT_ATTRIBUTES = gql`
    fragment CommentParts on Comment {
        id
        content
        blocked
        blockedThread
        blockReason
        points
        created_at
        updated_at
        authorUser {
            ...UserParts
        }
        authorType
        authorId
        authorName
        removed
        reports {
            id
            content
            reason
            resolved
            related {
                id
                content
                blocked
                blockedThread
                blockReason
                points
                authorType
                authorId
                authorName
                removed
            }
        }
        isBid
    },
    ${USER_ATTRIBUTES}
`;

export const COMMENT_HIERARCHY_ATTRIBUTES = gql`
    fragment CommentHierarchyParts on CommentsInHierarchy {
        id
        content
        blocked
        blockedThread
        blockReason
        points
        created_at
        updated_at
        authorUser {
            ...UserParts
        }
        authorType
        authorId
        authorName
        authorAvatar
        removed
        reports {
            id
            content
            reason
            resolved
            related {
                id
                content
                blocked
                blockedThread
                blockReason
                points
                authorType
                authorId
                authorName
                removed
            }
        }
        children {
            ...CommentParts
        }
        isBid
    },
    ${USER_ATTRIBUTES}
    ${COMMENT_ATTRIBUTES}
`;

export const COMMENT_REPORT_ATTRIBUTES = gql`
    fragment CommentReportParts on CommentReport {
        id
        content
        reason
        resolved
        related {
            ...CommentParts
        }
    },
    ${COMMENT_ATTRIBUTES}
`;
