import { ThemeOptions } from '@material-ui/core';
import { BrandThemeType } from '../../modules/common/types';

type MakeTheme = (brandTheme: BrandThemeType) => ThemeOptions;

const makeNxgemTheme: MakeTheme = (brandTheme) => ({
    custom: {
        headerNav: {
            logoContainer: {
                flexGrow: 'unset',
                margin: '10px auto',
            },
            logo: {
                maxHeight: '130px',
                margin: '0',
            },
            logoBreakDownMd: {
                maxWidth: '66%',
                maxHeight: '100px',
                margin: '0',
            },
            preAppBar: {
                height: '25px',
                backgroundColor: brandTheme.colours.primaryColor,
            },
            appBar: {
            },
            postAppBar: {
                display: 'flex',
                justifyContent: 'center',
            },
            navigationItem: {
                margin: '4px 32px',
            },
        },
        footerNav: {
            container: {
                backgroundColor: 'white',
            },
            text: {
                color: 'black',
            },
            pageLinks: {
                marginTop: '24px',
            },
        },
        component: {
            action: {
                borderRadius: 0,
                backgroundColor: 'black',
                color: 'white',
            }
        }
    }
});

export default makeNxgemTheme;
