import * as yup from 'yup';
import intl from 'react-intl-universal';
import { PASSWORD_REG_EXP, USERNAME_REG_EXP } from '../../../constants';

export const schema = yup.object().shape({
    email: yup.string()
        .email(
            intl.get('common.form.register.input.email.error.invalid').d('Please enter in a valid email')
        )
        .required(
            intl.get('common.form.register.input.email.error.required').d('Please enter in your email')
        ),
    password: yup.string()
        .matches(
            PASSWORD_REG_EXP,
            intl.get('common.form.register.input.password.error').d('Password must contain at least 8 characters, one uppercase, one number and one special case character')
        )
        .required(
            intl.get('common.form.register.input.password.error.required').d('Please enter in a password')
        ),
    confirmPassword: yup.string()
        .test(
            'passwords-match',
            intl.get('common.form.register.input.confirmPassword.error.noMatch').d('Passwords don\'t match'),
            function(value) {
                // Check to see if password and the current confirm password match
                return this.parent.password === value;
            }
        )
        .required(
            intl.get('common.form.register.input.confirmPassword.error.required').d('Please confirm your password')
        ),
    firstName: yup.string()
        .trim()
        .required(
            intl.get('common.form.register.input.firstName.error.required').d('Please enter in your first name')
        ),
    lastName: yup.string()
        .trim()
        .required(
            intl.get('common.form.register.input.lastName.error.required').d('Please enter in your last name')
        ),
    username: yup.string()
        .trim()
        .matches(
            USERNAME_REG_EXP,
            intl.get('common.form.register.input.username.error.regex').d('Display name must contain between 4 - 20 characters, and cannot have an special characters or spaces')
        )
        .required(
            intl.get('common.form.register.input.username.error.required').d('Please enter in a name')
        ),
    acceptTerms: yup.bool()
        .oneOf([true],
            intl.get('common.form.register.checkbox.acceptTerms.error.required').d('The Terms & Conditions is required')
        )
});
