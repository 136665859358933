import { gql } from '@apollo/client';
import { AUCTION_ATTRIBUTES } from '../auction/fragments';
import {
    USER_ATTRIBUTES,
    THEME_ATTRIBUTES,
    HEADER_NAV_ATTRIBUTES,
    CONTACT_US_ATTRIBUTES,
    MODULE_NAV_ATTRIBUTES,
    SOCIAL_LINKS_ATTRIBUTES,
    BUSINESS_HOURS_ATTRIBUTES,
    EMAIL_NOTIFICATION_ATTRIBUTES,
    ANNOUNCEMENT_ATTRIBUTES,
    CONFIGURATION_PADDOCK_ATTRIBUTES,
    UNIVERSAL_PAGE_ATTRIBUTES,
    CAROUSEL_LISTINGS_ATTRIBUTES,
    PRICING_PACKAGES_ATTRIBUTES,
    PROMOTION_ATTRIBUTES,
    IMAGE_DESCRIPTION_LINK_ATTRIBUTES,
    SELLING_POINTS_ATTRIBUTES,
    QUOTE_ATTRIBUTES,
    HERO_ATTRIBUTES,
    MEDIA_ATTRIBUTES,
    PICTURE_INFO_ATTRIBUTES,
    LINKS_WITH_IMAGES_ATTRIBUTES,
    PICTURE_INFO_RICH_ATTRIBUTES,
    SERVICE_INFO_ATTRIBUTES,
    CONFIGURATION_AUCTION_ATTRIBUTES
} from './fragments';

// Client Side Fields START

export const QUERY_IS_LOGGED_IN = gql`
    query isUserLoggedIn {
        isLoggedIn @client
    }
`;

export const QUERY_GET_BOOKMARK_IDS = gql`
    query getBookmarkIds {
        bookmarkIds @client
    }
`;

// Client Side Fields END

export const QUERY_UNIVERSAL_PAGES = gql`
    query universals($limit: Int $start: Int $sort: String $where: JSON, $publicationState: PublicationState) {
        universals(limit: $limit start: $start sort: $sort where: $where, publicationState: $publicationState) {
            ...UniversalPageParts
        }
    },
    ${UNIVERSAL_PAGE_ATTRIBUTES}
`;

export const QUERY_EXCHANGE_RATES = gql`
  query exchangeRate {
    exchangeRate {
      rates          
    }
  } 
`;

export const QUERY_ABOUT_US_PAGE = gql`
    query aboutUsByBrand($brand: String!) {
        aboutUsByBrand(brand: $brand) {
            title
            description
            points {
                title
                description
                image {
                    url
                }
            }
            faqs {
                title
                description
            }
        }
    }
`;

export const QUERY_ME_FULL = gql`
    query meFull {
        meFull {
            ...UserParts
        }
    },
    ${USER_ATTRIBUTES}
`;

export const QUERY_COUNTRY_STATE = gql`
    query countryState {
        countryState {
            data
        }
    }
`;

export const QUERY_CAR_MAKE = gql`
    query carMake {
        carMake {
            data
        }
    }
`;

export const QUERY_USER = gql`
    query user($id: ID!) {
        user(id: $id) {
            id
            email
            confirmed
            blocked
            username
            socialLinks {
                facebook
                twitter
                instagram
                linkedin
                youtube
            }
            address {
                address1
                address2
                city
                country
                postal
                state
            }
            firstName
            lastName
            phone
            website
        }
    }
`;

export const QUERY_CMS_USERS = gql`
    query cmsUsers($limit: Int!, $start: Int!, $where: JSON) {
        cmsUsers (limit: $limit, start: $start, where: $where) {
            users {
                id
                username
                email
                confirmed
                blocked
                created_at
                updated_at
            }
            count
        }
    }
`;

export const QUERY_USERS = gql`
    query users($limit: Int!, $start: Int!, $sort:String, $where: JSON) {
        users (limit: $limit, start: $start, sort: $sort, where: $where){
            ...UserParts
        }
    },
    ${USER_ATTRIBUTES}
`;

export const QUERY_USERS_COUNT = gql`
    query usersCount($where: JSON) {
        usersCount(where: $where)
    }
`;

export const QUERY_FAQS = gql`
    query FAQs($brand: String!) {
        faqsByBrand(brand: $brand) {
            brand
            home {
                title
                description
            }
            media {
                title
                description
            }
            paddock {
                title
                description
            }
            simracing {
                title
                description
            }
            directory {
                title
                description
            }
            auction {
                title
                description
            }
            sellMyCar{
                title
                description
            }
        }
    }
`;

export const QUERY_BRAND_THEME = gql`
    query configurationByBrand($brand: String!) {
        configurationByBrand(brand: $brand) {
            theme {
                ...ThemeParts
            }
        }
    }
    ${THEME_ATTRIBUTES}
`;

export const QUERY_CMS_PERMISSIONS = gql`
    query cmsPermissions {
        myCMSPermissions {
            brand
            websiteTheme
            superAdmin
            manageUsers
            auctionEnabled
            websiteEnabled
            manageHomepage
        }
    }
`;

export const QUERY_BRAND_CONFIGURATION_HEADER = gql`
    query configurationByBrand($brand: String!) {
        configurationByBrand(brand: $brand) {
            headerNav {
                ...HeaderNavParts
            }
            contactUsConfiguration {
                ...ContactUsParts
            }
            theme {
                ...ThemeParts
            }
        }
    }
    ${THEME_ATTRIBUTES}
    ${HEADER_NAV_ATTRIBUTES}
    ${CONTACT_US_ATTRIBUTES}
`;

export const QUERY_BRAND_CONFIGURATION = gql`
    query configurationByBrand($brand: String!) {
        configurationByBrand(brand: $brand) {
            id
            brand
            rootUrl
            companyName
            headerNav {
                ...HeaderNavParts
            }
            modules {
                ...ModuleParts
            }
            businessHours {
                ...BusinessHoursParts
            }
            socialConfiguration {
                ...SocialLinksParts
            }
            contactUsConfiguration {
                ...ContactUsParts
            }
            emailNotificationConfiguration {
                ...EmailNotificationParts
            }
            announcement {
                ...AnnouncementParts
            }
            theme {
                ...ThemeParts
            }
            paddockConfiguration {
                ...ConfigurationPaddockParts
            }
            heatmap {
                title
                description
                image {
                    ...MediaParts
                }
            }
            isFloatingSellButtonDisabled
            isFeaturedCarouselDisabled
            homePage
            auctionConfiguration {
                ...ConfigurationAuctionParts
            }
        }
    }
    ${MEDIA_ATTRIBUTES}
    ${THEME_ATTRIBUTES}
    ${HEADER_NAV_ATTRIBUTES}
    ${MODULE_NAV_ATTRIBUTES}
    ${SOCIAL_LINKS_ATTRIBUTES}
    ${BUSINESS_HOURS_ATTRIBUTES}
    ${EMAIL_NOTIFICATION_ATTRIBUTES}
    ${ANNOUNCEMENT_ATTRIBUTES}
    ${CONTACT_US_ATTRIBUTES}
    ${CONFIGURATION_PADDOCK_ATTRIBUTES}
    ${CONFIGURATION_AUCTION_ATTRIBUTES}
`;

export const QUERY_BRAND_HEADER_NAV = gql`
    query configurationByBrand($brand: String!) {
        configurationByBrand(brand: $brand) {
            headerNav {
                ...HeaderNavParts
            }
        }
    }
    ${HEADER_NAV_ATTRIBUTES}
`;

export const QUERY_BRAND_MODULES = gql`
    query configurationByBrand($brand: String!) {
        configurationByBrand(brand: $brand) {
            companyName
            heatmap {
                title
                description
                image {
                    ...MediaParts
                }
            }
            modules {
                ...ModuleParts
            }
        }
    }
    ${MEDIA_ATTRIBUTES}
    ${MODULE_NAV_ATTRIBUTES}
`;

export const QUERY_BRAND_HEADERS_MODULES = gql`
    query configurationByBrand($brand: String!) {
        configurationByBrand(brand: $brand) {
            modules {
                ...ModuleParts
            }
            headerNav {
                ...HeaderNavParts
            }
            homePage
        }
    }
    ${MODULE_NAV_ATTRIBUTES}
    ${HEADER_NAV_ATTRIBUTES}
`;

export const QUERY_BRAND_COMPANY_CONTACT_CONFIGURATION = gql`
    query configurationByBrand($brand: String!) {
        configurationByBrand(brand: $brand) {
            rootUrl
            companyName
            emailNotificationConfiguration {
                ...EmailNotificationParts
            }
            contactUsConfiguration {
                ...ContactUsParts
            }
            socialConfiguration {
                ...SocialLinksParts
            }
            
        }
    }
    ${EMAIL_NOTIFICATION_ATTRIBUTES}
    ${CONTACT_US_ATTRIBUTES}
    ${SOCIAL_LINKS_ATTRIBUTES}
`;

export const QUERY_BRAND_ANNOUNCEMENT = gql`
    query configurationByBrand($brand: String!) {
        configurationByBrand(brand: $brand) {
            announcement {
                ...AnnouncementParts
            }
        }
    }
    ${ANNOUNCEMENT_ATTRIBUTES}
`;

export const QUERY_BRAND_PERMISSIONS = gql`
    query brandPermissions($brand: String!) {
        configurationByBrand(brand: $brand) {
            permissions {
                active
                type
            }
        }
    }
`;

export const QUERY_BRAND_SIMPLE_CONFIGURATION = gql`
    query configurationByBrand($brand: String!) {
        configurationByBrand(brand: $brand) {
            companyName
            rootUrl
        }
    }
`;

export const QUERY_CAROUSEL_LISTINGS = gql`
  query listings ($limit: Int!, $start: Int!, $sort:String, $where: JSON) {
    listings (limit: $limit, start: $start, sort: $sort, where: $where){
        ...CarouselListingsParts           
    }
  } 
  ${CAROUSEL_LISTINGS_ATTRIBUTES}
`;

export const QUERY_CAROUSEL_LISTINGS_BY_DETAILS = gql`
  query listingsByDetails ($sort:String, $where: JSON, $whereDetails: JSON) {
    listingsByDetails (sort: $sort, where: $where, whereDetails: $whereDetails){
        ...CarouselListingsParts           
    }
  } 
  ${CAROUSEL_LISTINGS_ATTRIBUTES}
`;

export const QUERY_PRICING_PACKAGES = gql`
  query pricingPackages($limit: Int, $start: Int, $sort: String, $where: JSON) {
    pricingPackages(limit: $limit, start: $start, sort: $sort, where: $where) {
        ...PricingPackageParts
    }
  }
  ${PRICING_PACKAGES_ATTRIBUTES}
`;

export const QUERY_PRICING_PACKAGE = gql`
  query pricingPackage($id: ID!, $publicationState: PublicationState) {
    pricingPackage(id: $id, publicationState: $publicationState) {
        ...PricingPackageParts
    }
  }
  ${PRICING_PACKAGES_ATTRIBUTES}
`;

export const QUERY_PROMO_CODE = gql`
    query promotionByCode($code: String!, $pricingPackageId: String!) {
        promotionByCode(code: $code, pricingPackageId: $pricingPackageId) {
            ...PromotionParts
        }
    },
    ${PROMOTION_ATTRIBUTES}
`;

export const QUERY_DEALER_SERVICES_PAGE = gql`
    query dealerService {
        dealerService {
            title
            imageDescriptionLink {
                ...ImageDescriptionLinkParts
            }
        }
    },
    ${IMAGE_DESCRIPTION_LINK_ATTRIBUTES}
`;

export const QUERY_DEALERSHIP_SOLUTIONS_PAGE = gql`
    query dealershipSolution {
        dealershipSolution {
            title
            hero1 {
                ...HeroParts
            }
            quote1 {
                ...QuoteParts
            }
            content1
            hero2 {
                ...HeroParts
            }
            quote2 {
                ...QuoteParts
            }
            content2
            contentImage {
                ...MediaParts
            }
            points {
                ...SellingPointsParts
            }
            hero3 {
                ...HeroParts
            }
            offerings {
                ...PictureInfoParts
            }
            services {
                ...ServiceInfoParts
            }
            dealers {
                ...LinksWithImagesParts
            }
        }
    },
    ${HERO_ATTRIBUTES}
    ${PICTURE_INFO_ATTRIBUTES}
    ${SERVICE_INFO_ATTRIBUTES}
    ${QUOTE_ATTRIBUTES}
    ${MEDIA_ATTRIBUTES}
    ${SELLING_POINTS_ATTRIBUTES}
    ${LINKS_WITH_IMAGES_ATTRIBUTES}
`;

export const QUERY_AUCTION_MARKETPLACE_PAGE = gql`
    query auctionMarketplace {
        auctionMarketplace {
            title
            hero1 {
                ...HeroParts
            }
            quote {
                ...QuoteParts
            }
            content1 {
                ...PictureInfoRichParts
            }
            hero2 {
                ...HeroParts
            }
            offerings {
                ...PictureInfoParts
            }
            hero3 {
                ...HeroParts
            }
            content2 {
                ...PictureInfoRichParts
            }
            hero4 {
                ...HeroParts
            }
            content3 {
                ...PictureInfoRichParts
            }
            hero5 {
                ...HeroParts
            }
            offerings2 {
                ...PictureInfoParts
            }
            authorities {
                ...LinksWithImagesParts
            }
        }
    },
    ${QUOTE_ATTRIBUTES}
    ${HERO_ATTRIBUTES}
    ${PICTURE_INFO_ATTRIBUTES}
    ${PICTURE_INFO_RICH_ATTRIBUTES}
    ${MEDIA_ATTRIBUTES}
    ${LINKS_WITH_IMAGES_ATTRIBUTES}
`;

export const QUERY_AUCTIONS_BY_USER = gql`
    query myAuctions($start: Int, $limit: Int, $where: JSON) {
        myAuctions(start: $start, limit: $limit, where: $where, sort: "id:desc") {
            auctions {
               ...AuctionParts
            }
            count
        }
    },
    ${AUCTION_ATTRIBUTES}
`;

export const QUERY_AUCTIONS_BY_USER_TOP_BID = gql`
    query myAuctionsBidByUser($start: Int, $limit: Int) {
        myAuctionsBidByUser(start: $start, limit: $limit, sort: "id:desc") {
            auctions {
               ...AuctionParts
            }
            count
        }
    },
    ${AUCTION_ATTRIBUTES}
`;

export const QUERY_PAYMENT_CONFIGURATION = gql`
    query paymentConfigurationByBrand($brand: String!) {
        paymentConfigurationByBrand(brand: $brand) {
            id
            provider
            brand
            stripePublishableKey
            appId
            locationId
            active
        }
    }
`;
