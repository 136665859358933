import React, { useState, ReactElement } from 'react';
import intl from 'react-intl-universal';
import { useForm } from 'react-hook-form';
import {
    Grid,
    TextField,
    Link,
    Box,
    Container,
    Typography,
    Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useApolloClient } from '@apollo/client';

// import Container from '../../components/Container';
import LoginButton from '../../../components/Button';


import { schema } from './validation';
import { useAuthenticatedUserContext, useNotification } from '../../../hooks';
import { AuthenticationActionFunctionType, BrandThemeType, BrandUserLoginDataType, BrandUserLoginVariablesType } from '../../../types';
import { MUTATION_LOGIN_USER } from '../../../mutations';
import { login } from '../../../request';
import { BRAND, VARIATIONS } from '../../../constants';

const useStyle = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'inherit',
        color: 'inherit',
    },
    link: {
        cursor: 'pointer',
        color: (configTheme: BrandThemeType) => {
            if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_SECONDARY) {
                return theme.palette.primary.main;
            } else if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_PRIMARY) {
                return theme.palette.secondary.main;
            }
            return theme.palette.primary.main;
        },
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        paddingBottom: theme.spacing(1),
        backgroundColor: 'inherit',
        color: 'inherit',
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
    bar: {
        marginTop: theme.spacing(2),
        width: '70%',
        height: '4px',
        backgroundColor: theme.palette.divider,
    },
    inherit: {
        backgroundColor: 'inherit',
        color: 'inherit',
    },
}));

type InputsType = {
    email: string;
    password: string;
}

interface Props {
    configTheme: BrandThemeType;
    onLogin?: () => void;
    switchAction: AuthenticationActionFunctionType;
    title?: string;
    subtitle?: string;
}

const LoginUser = ({ configTheme, onLogin, title, subtitle, switchAction }: Props): ReactElement => {
    const classes = useStyle(configTheme);
    const apolloClient = useApolloClient();
    const notify = useNotification();
    const { setUser } = useAuthenticatedUserContext();

    const [confirmed, setConfirmed] = useState<boolean>(true);

    const { loginVariation } = configTheme;

    const inheritStyle = {
        backgroundColor: 'inherit',
        color: 'inherit',
    };

    const defaultValues = {
        email: '',
        password: ''
    };

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<InputsType>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const [loginUser, { loading }] = useMutation<BrandUserLoginDataType, BrandUserLoginVariablesType>(MUTATION_LOGIN_USER, {
        onCompleted({ loginUser }) {
            if (loginUser) {
                const { jwt, user } = loginUser;
                if (jwt && jwt.length > 0 && user) {
                    login(jwt, user.id, apolloClient);
                    setUser && setUser(user);
                }
            }
            // Call the login callback if its defined
            onLogin && onLogin();
        },
        onError(errors) {
            const { graphQLErrors } = errors;
            if (graphQLErrors && graphQLErrors.length > 0) {
                const [error] = graphQLErrors;
                const ext = error.extensions;

                if (ext) {
                    const { exception } = ext;
                    const { data } = exception;
                    const { id } = data;

                    if (id === 'Auth.form.error.invalid') {
                        const message = intl.get('common.form.login.error.invalid').d('Your email or password is incorrect.');
                        notify({ message, severity: 'error' });
                    } else if (id === 'Auth.form.error.confirmed') {
                        setConfirmed(false);
                        // Notify that the email needs to be confirmed
                        const message = intl.get('common.form.login.error.confirmed').d('Your email address has not been confirmed.');
                        notify({ message, severity: 'warning' });
                    } else if (id === 'Auth.form.error.blocked') {
                        // Blocked User
                        const message = intl.get('common.form.login.error.blocked').d('Your account has been blocked. Contact support.');
                        notify({ message, severity: 'error' });
                    } else if (id === 'Auth.form.error.rateLimit') {
                        // Blocked User
                        const message = intl.get('common.form.login.error.rateLimit').d('Your account has had too many failed login attempts. Please wait a few minutes.');
                        notify({ message, severity: 'error' });
                    } else if (id === 'Auth.form.error.email.provide') {
                        // Blocked User
                        const message = intl.get('common.form.login.error.noEmail').d('Please provide your email.');
                        notify({ message, severity: 'error' });
                    } else if (id === 'Auth.form.error.password.provide') {
                        // Blocked User
                        const message = intl.get('common.form.login.error.noPassword').d('Please provide your password.');
                        notify({ message, severity: 'error' });
                    }
                }
            }
        }
    });

    const onSubmit = (data: InputsType) => {
        loginUser({
            variables: {
                email: data.email,
                password: data.password,
                brand: BRAND
            },
            fetchPolicy: 'network-only'
        }).then(() => ({}));
    };

    const submitText = intl.get('common.form.login.button.submit').d('Sign In');
    const forgotPassText = intl.get('common.form.login.link.forgotPassword').d('Forgot your password?');
    const newAccountText = intl.get('common.form.login.link.newAccount').d('Create an account');
    const resendConfirmationText = intl.get('common.form.login.link.resendConfirmation').d('Resend your account confirmation email?');
    const emailText = intl.get('common.form.login.input.email').d('Email Address');
    const passwordText = intl.get('common.form.login.input.password').d('Password');

    return (
        <>
            <Box sx={{ mb: 3, textAlign: 'center' }}>
                {
                    title && (
                        <Box sx={{ mb: 1, display: 'inline-block' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="h1">{title}</Typography>
                                <Box className={classes.bar}></Box>
                            </Box>
                        </Box>
                    )
                }
                {
                    subtitle && subtitle.length > 0 && (
                        <Typography variant="subtitle1">{subtitle}</Typography>
                    )
                }
            </Box>
            <Container maxWidth="xs">
                <div className={classes.paper}>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="form-login-input-email"
                                    inputRef={register}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={emailText}
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    helperText={errors.email ? errors.email.message : ''}
                                    error={!!errors.email}
                                    inputProps={{ style: inheritStyle }}
                                    InputProps={{ style: inheritStyle }}
                                    InputLabelProps={{ style: inheritStyle }}
                                    FormHelperTextProps={{ style: inheritStyle }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="form-login-input-password"
                                    inputRef={register}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={passwordText}
                                    type="password"
                                    autoComplete="current-password"
                                    helperText={errors.password ? errors.password.message : ''}
                                    error={!!errors.password}
                                    inputProps={{ style: inheritStyle }}
                                    InputProps={{ style: inheritStyle }}
                                    InputLabelProps={{ style: inheritStyle }}
                                    FormHelperTextProps={{ style: inheritStyle }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LoginButton
                                    id="form-login-button-submit"
                                    type="submit"
                                    fullWidth
                                    text={submitText}
                                    disabled={loading}
                                    className={classes.submit}
                                    color={loginVariation === VARIATIONS.FILLED_SECONDARY ? 'primary' : 'secondary'}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <Link
                                    id="form-login-link-forgot"
                                    className={classes.link}
                                    onClick={() => switchAction('forgot')}
                                    variant="body2"
                                >
                                    {forgotPassText}
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    id="form-login-link-register"
                                    className={classes.link}
                                    onClick={() => switchAction('register')}
                                    variant="body2"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    {newAccountText}
                                </Link>
                            </Grid>

                            {
                                !confirmed && (
                                    <>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Link
                                                id="form-login-link-resendConfirmation"
                                                className={classes.link}
                                                onClick={() => switchAction('resendConfirmation')}
                                                variant="body2"
                                            >
                                                {resendConfirmationText}
                                            </Link>
                                        </Grid>
                                    </>
                                )
                            }
                        </Grid>
                    </form>
                </div>
            </Container>
        </>
    );
};

export default LoginUser;
