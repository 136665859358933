import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ResultList from '../ResultList';
import FeaturedCard from '../../components/FeaturedCard';
import NonFeaturedCard from '../../components/NonFeaturedCard';
import AnimatedLoader from '../../../common/components/AnimatedLoader';

import { DirectoryType } from '../../types';
import { BOOKMARKED_DIRECTORIES_QUERY } from '../../queries';
import useBookmarkIds from '../../hooks/useBookmarkIds';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
    },
}));

const Bookmarks = (): JSX.Element => {
    const classes = useStyles();
    const [markIds] = useBookmarkIds();
    // Set the stores 'bookmarkIds' into a state, so we don't re-load
    const [bookmarkIds, setBookmarkIds] = useState(markIds);

    const bookmarksError = intl.get('directory.favourites.bookmarks.error').d('An error occured while fetching your favourites.');
    const noFavourites = intl.get('directory.favourites.bookmarks.noFavourites').d('You have not favourited any directories.');

    useEffect(() => {
        // If the stores bookmarkIds are updated, and this states bookmarkIds is empty
        if (bookmarkIds.length === 0) {
            // set the states bookmarkIds to be those found in the store
            setBookmarkIds(markIds);
        }
    }, [bookmarkIds, markIds]);

    // Get featured directory items
    const { data, loading, error } = useQuery(BOOKMARKED_DIRECTORIES_QUERY, {
        variables: {
            ids: bookmarkIds,
        },
        skip: bookmarkIds.length === 0,
    });

    if (loading) {
        return <AnimatedLoader name="Bookmarks" />;
    }

    if (error) {
        return (
            <Box sx={{ mt: 2 }}>
                <Typography variant="body1" color="error">{bookmarksError}</Typography>
            </Box>
        );
    }

    // Default the directory arrays as empty
    let featuredDirectories: Array<DirectoryType> = [];
    let nonFeaturedDirectories: Array<DirectoryType> = [];

    // When the data has loaded
    if (data) {
        // data.directories = DirectoryType[], data.directoryCount = number
        const directories: Array<DirectoryType> = data && data.directories;
        featuredDirectories = [...directories.filter(directory => directory.featured)];
        nonFeaturedDirectories = [...directories.filter(directory => !directory.featured)];
    }

    if (featuredDirectories.length === 0 && nonFeaturedDirectories.length === 0) {
        return (
            <Box sx={{ py: '10%', width: '100%' }} className={classes.root}>
                <Typography variant="body1" align="center">{noFavourites}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ mt: 2 }} className={classes.root}>
            <ResultList
                directories={featuredDirectories}
                currentPage={1}
                totalPages={1}
                component={FeaturedCard}
            />
            <ResultList
                directories={nonFeaturedDirectories}
                currentPage={1}
                totalPages={1}
                component={NonFeaturedCard}
            />
        </Box>
    );
};

export default Bookmarks;
