import React from 'react';
import intl from 'react-intl-universal';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';

import PageHeader from '../../components/PageHeader';
import Hero from '../../components/Hero';
import Offerings from '../../components/Offerings';
import FormComponent from '../Universal/Segments/Form';
import Quote from '../../components/Quote';

import { AuctionMarketplacePageDataType } from '../../types';
import { QUERY_AUCTION_MARKETPLACE_PAGE } from '../../queries';
import ContentWithImage from '../../components/ContentWithImage';
import LinksWithImage from '../../components/LinksWithImage';

const useStyles = makeStyles(theme => ({
    box: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    spacing: {
        margin: theme.spacing(8, 0),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(4, 0),
        }
    }
}));

const AuctionMarketplacePage = (): JSX.Element => {
    const classes = useStyles();

    const { data: dataAuctionMarketplace } = useQuery<AuctionMarketplacePageDataType>(QUERY_AUCTION_MARKETPLACE_PAGE);

    const defaultHeaderText = intl.get('common.page.dealershipSolutions.header').d('Dealership Solutions');

    const data = dataAuctionMarketplace && dataAuctionMarketplace.auctionMarketplace;
    let headerText = defaultHeaderText;

    if (data && data.title) {
        headerText = data.title;
    }
    return (
        <>
            <PageHeader label={headerText} />
            {
                data && (
                    <Box className={classes.box}>
                        {
                            data.hero1 && (<Hero {...data.hero1} />)
                        }
                        {
                            data.quote && (
                                <Container className={classes.spacing} maxWidth="md">
                                    <Quote {...data.quote} />
                                </Container>
                            )
                        }
                        {
                            data.content1 && (
                                <Container className={classes.spacing} maxWidth="md">
                                    <ContentWithImage {...data.content1} />
                                </Container>
                            )
                        }
                        {
                            data.hero2 && (
                                <Hero {...data.hero2} />
                            )
                        }
                        {
                            data.offerings && (
                                <Container className={classes.spacing} maxWidth="lg">
                                    <Offerings offerings={data.offerings} />
                                </Container>
                            )
                        }
                        {
                            data.hero3 && (
                                <Hero {...data.hero3} />
                            )
                        }
                        {
                            data.content2 && (
                                <Container className={classes.spacing} maxWidth="md">
                                    <ContentWithImage {...data.content2} imagePosition="left" />
                                </Container>
                            )
                        }
                        {
                            data.hero4 && (
                                <Hero {...data.hero4} />
                            )
                        }
                        {
                            data.content3 && (
                                <Container className={classes.spacing} maxWidth="md">
                                    <ContentWithImage {...data.content3} />
                                </Container>
                            )
                        }
                        {
                            data.hero5 && (
                                <Hero {...data.hero5} />
                            )
                        }
                        {
                            data.offerings2 && (
                                <Container className={classes.spacing} maxWidth="lg">
                                    <Offerings offerings={data.offerings2} />
                                </Container>
                            )
                        }
                        {
                            data.authorities && (
                                <Container className={classes.spacing} maxWidth="lg">
                                    <LinksWithImage {...data.authorities} />
                                </Container>
                            )
                        }
                        <Box sx={{ width: '100%', mb: 4 }}>
                            <FormComponent id="1" type="CONTACT_US" reachUs />
                        </Box>
                    </Box>
                )
            }
        </>
    );
};

export default React.memo(AuctionMarketplacePage);
