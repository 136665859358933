import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import AnimatedLoader from '../../common/components/AnimatedLoader';
import PrivateRoute from '../../common/routes/PrivateRoute';

import HomePage from '../pages/Home';
// import CheckoutPage from '../pages/Checkout';
import CreateAuctionPage from '../pages/CreateAuction';
import EditAuctionPage from '../pages/EditAuction';
import AuctionPage from '../pages/Auction';

interface Props {
    queryParams: string | null;
}

const AuctionRoutes = ({ queryParams }: Props): JSX.Element => {
    const { url } = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        if (queryParams) {
            history.push({
                search: queryParams
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Suspense fallback={<AnimatedLoader />}>
            <Switch>
                <Route exact path={url}>
                    <HomePage />
                </Route>
                {/* Checkout Page - appropriate location state should be set */}
                {/* <PrivateRoute exact path={`${url}/checkout`} >
                    <CheckoutPage />
                </PrivateRoute> */}
                <PrivateRoute exact path={`${url}/create`}>
                    <CreateAuctionPage />
                </PrivateRoute>
                <PrivateRoute exact path={`${url}/:auctionId/edit`}>
                    <EditAuctionPage />
                </PrivateRoute>
                <Route exact path={`${url}/lot/:slug`}>
                    <AuctionPage />
                </Route>
            </Switch>
        </Suspense>
    );
};

export default AuctionRoutes;
