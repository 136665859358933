import React, { useState, ReactElement } from 'react';
import intl from 'react-intl-universal';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';

import BidButton from '../../../common/components/Button';
import PaymentMethodModal from '../../../common/components/PaymentMethodModal';

import { AUCTION_STATUS, BID_ERROR } from '../../constants';
import { MUTATION_COMMENT_CREATE, MUTATION_CREATE_BID } from '../../mutations';
import { CreateBidDataType, CreateCommentDataType, CreateCommentVariableType } from '../../types';
import { useAuthenticatedUserContext, useNotification } from '../../../common/hooks';
import { checkFirstBidByUser } from '../../helpers';
import { useSocketContext } from '../../hooks/useSocketContext';
import { userBidOnLot, userCommentOnLot } from '../../sockets/emitters';
import { localizeCurrency } from '../../../common/helpers';
import { CMS_CONTENT_TYPE_REF, CMS_FIELD_TYPE, PAYMENT_ITEM_TYPE, PAYMENT_PROVIDER, PAYMENT_TYPE, REFRESH_TIMEOUT_STRIPE_SUCCESS } from '../../../common/constants';
import { PaymentModalSuccessResultReturnType, PaymentOnSuccessfulPaymentDataType } from '../../../common/types';

const useStyles = makeStyles(theme => ({
    button: {
        padding: theme.spacing(2, 5),
        backgroundColor: 'green',
        margin: theme.spacing(1, 0, 0),
        fontSize: '0.875rem',
        lineHeight: '1.4375em',
        border: '1px solid green'
    },
}));

interface Props {
    id: string;
    amount?: number | string;
}

const getErrorMessage = (errorId: BID_ERROR): string => {
    let message = intl.get('auction.page.bidBar.bid.invalid').d('You cannot bid at this moment.');
    switch (errorId) {
        case BID_ERROR.OWN_AUCTION:
            message = intl.get('auction.page.bidBar.bid.invalid.own.auction').d('You cannot bid on your own auction');
            break;
        case BID_ERROR.NO_AUCTION:
            message = intl.get('auction.page.bidBar.bid.invalid.no.auction').d('You cannot bid on an auction that doesn\'t exist');
            break;
        case BID_ERROR.AUCTION_STATUS_SCHEDULED:
            message = intl.get('auction.page.bidBar.bid.invalid.auction.status.scheduled').d('Auction has not started yet. Bidding isn\'t available yet');
            break;
        case BID_ERROR.AUCTION_STATUS_PENDING:
            message = intl.get('auction.page.bidBar.bid.invalid.auction.status.pending').d('Auction is not available yet. Bidding isn\'t available yet');
            break;
        case BID_ERROR.AUCTION_STATUS_SOLD:
            message = intl.get('auction.page.bidBar.bid.invalid.auction.status.sold').d('Auction has sold. Not accepting any further bids.');
            break;
        case BID_ERROR.AUCTION_STATUS_RESERVE_NOT_MET:
            message = intl.get('auction.page.bidBar.bid.invalid.auction.status.reserve_not_met').d('Auction has ended. Not accepting any further bids.');
            break;
        case BID_ERROR.DATE_BEFORE:
            message = intl.get('auction.page.bidBar.bid.invalid.date.start.before').d('Cannot bid on an auction that hasn\'t started yet.');
            break;
        case BID_ERROR.DATE_AFTER:
            message = intl.get('auction.page.bidBar.bid.invalid.date.end.after').d('Cannot bid on an auction that has ended already.');
            break;
        case BID_ERROR.FIRST_BID_TOO_LOW:
            message = intl.get('auction.page.bidBar.bid.invalid.firstBid.tooLow').d('Bid does not exceed the auction starting price.');
            break;
        case BID_ERROR.INCREMENT_NOT_MET:
            message = intl.get('auction.page.bidBar.bid.invalid.increment.notMet').d('Bid does not meet or exceed the minimum increment amount.');
            break;
        case BID_ERROR.AMOUNT_TOO_LOW:
            message = intl.get('auction.page.bidBar.bid.invalid.amount.tooLow').d('Bid does not exceed the highest current bid.');
            break;
        case BID_ERROR.AMOUNT_TOO_HIGH:
            message = intl.get('auction.page.bidBar.bid.invalid.amount.tooHigh').d('The bid amount meets or exceeds the allowable bid limit of 999,999,999.00. Please lower your bid amount.');
            break;
    }

    return message;
};

const BidNowButton = ({ id, amount }: Props): ReactElement => {
    const classes = useStyles();
    const { auction } = useSocketContext();
    const notify = useNotification();
    const [isPaymentOpen, setIsPaymentOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const { user } = useAuthenticatedUserContext();

    const referenceId = auction ? auction.id : '';
    const auctionLot = auction ? auction.slug : '';
    const currency = auction ? auction.currency : 'USD';

    const [createBid, { loading: loadingCreateBid }] = useMutation<CreateBidDataType>(MUTATION_CREATE_BID);
    const [addComment] = useMutation<CreateCommentDataType, CreateCommentVariableType>(MUTATION_COMMENT_CREATE, {
        onCompleted({ commentAdd }) {
            if (commentAdd) {
                userCommentOnLot(referenceId, auctionLot, notify);
            } else {
                const message = intl.get('auction.comment.add.failure').d('Something went wrong. Please try again.');
                notify({ message, severity: 'error' });
            }
        }
    });

    let isLive = false;

    if (auction && auction.status === AUCTION_STATUS.LIVE) {
        isLive = true;
    }

    const username = user?.username || 'Anonymous';
    const formattedAmount = localizeCurrency(Number(amount || 0), currency);

    const isFirstBid = checkFirstBidByUser(user?.id || '', auction?.bids);
    const bidNowText = intl.get('auction.bidNow').d('Bid Now');
    const commentBidText = intl.get('auction.bid.comment', { amount: formattedAmount, currency, username }).d(`${formattedAmount} ${currency} bid placed by ${username}`);

    const onSuccess = (data: PaymentOnSuccessfulPaymentDataType) => {
        setIsPaymentOpen(false);
        setIsProcessing(true);
        if (data) {
            if ((data as PaymentModalSuccessResultReturnType).method && (data as PaymentModalSuccessResultReturnType).method === PAYMENT_PROVIDER.STRIPE) {
                // since stripe uses hooks to confirm payment intents, we need ample time before
                // trying to bid automatically after payment to insure the hold has been made
                setTimeout(() => {
                    setIsProcessing(false);
                    void onBid();
                }, REFRESH_TIMEOUT_STRIPE_SUCCESS);
            } else {
                void onBid();
            }
        } else {
            void onBid();
        }

        const message = intl.get('auction.bids.notify.bidNowButton.payment.success').d('Hold submitted.');
        notify({ message, severity: 'success' });
        setIsProcessing(false);
    };

    const onBid = async() => {
        if (auction && user) {
            setIsProcessing(true);
            const bidData: any = {
                amount: Number(amount),
                auction: auction.id,
                bidder: user.id,
                isFirstBid
            };

            try {
                await createBid({
                    variables: {
                        input: {
                            data: bidData
                        }
                    }
                });

                // if (res?.data?.createBid?.bid?.id) {
                userBidOnLot(auction.slug, notify);

                await addComment({
                    variables: {
                        comment: {
                            content: commentBidText,
                            authorId: user.id,
                            authorEmail: user.email,
                            authorUsername: user.username,
                            authorName: user.username,
                            related: {
                                field: CMS_FIELD_TYPE.COMMENT,
                                ref: CMS_CONTENT_TYPE_REF.AUCTION,
                                refId: `${referenceId}`
                            },
                            isBid: true
                        }
                    }
                });

                setIsProcessing(false);
                // }
            } catch (err: any) {
                if (err.message === 'bid.paymentRequired') {
                    // Prompt for payment
                    setIsPaymentOpen(true);
                } else {
                    // Unhandled error
                    const message = getErrorMessage(err.message);
                    notify({ message, severity: 'warning' });
                }

                setIsProcessing(false);
            }
        }
    };

    return (
        <>
            <BidButton
                id={id}
                className={classes.button}
                loading={loadingCreateBid}
                fullWidth
                disabled={isProcessing || (!amount && !isLive) || !user}
                color="secondary"
                text={bidNowText}
                onClick={onBid}
            />
            {
                // TODO: Implement Pay Now & Pay Later at some point, fetch itemId before payment
                isPaymentOpen && (
                    <PaymentMethodModal
                        itemId={auction?.id || '-1'}
                        itemType={PAYMENT_ITEM_TYPE.BID}
                        amount={Number(amount)}
                        paymentType={PAYMENT_TYPE.HOLD_PAYMENT}
                        onSuccess={onSuccess}
                        onFailure={() => {
                            // eslint-disable-next-line
                            console.log('failure');
                        }}
                        onClose={() => setIsPaymentOpen(false)}
                        isPromoEnabled={false}
                    />
                )
            }
        </>
    );
};

export default BidNowButton;
