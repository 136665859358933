import React, { ElementType } from 'react';
import intl from 'react-intl-universal';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { ApolloError } from '@apollo/client';

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(1)
    },
    errorContainer: {
        margin: theme.spacing(2, 0)
    },
    noDataContainer: {
        margin: theme.spacing(2, 0)
    }
}));

interface Props<T> {
    loading: boolean;
    error?: ApolloError;
    errorMessage?: React.ReactNode | React.ReactNodeArray;
    data?: T;
    parentAs?: ElementType;
    childAs?: ElementType;
    children(data: T): React.ReactNode;
    errorComponent?: React.ReactNode;
    loadingComponent?: React.ReactNode;
}

// Use this in conjuction with useQuery hook from apollo
const QueryResult = <T extends Record<string, unknown> | number | string | boolean>({ loading, error, errorMessage, data, parentAs = 'div', childAs, children, errorComponent, loadingComponent }: Props<T>): JSX.Element => {
    const classes = useStyles();

    if (error) {
        const errorText = intl.get('common.queryResult.error.default').d('Error');
        const errorContent = errorMessage ? errorMessage : <Typography variant="h5" color="error" component={childAs ? childAs : 'span'}>{`${errorText}: ${error.message}`}</Typography>;
        if (errorComponent) {
            return (
                <>
                    {errorComponent}
                </>
            );
        } else {
            return (
                <Box className={classes.errorContainer} component={parentAs}>
                    {errorContent}
                </Box>
            );
        }
    }

    if (loading) {
        if (loadingComponent) {
            return (
                <>
                    {loadingComponent}
                </>
            );
        } else {
            return (
                <Box className={classes.loadingContainer} component={parentAs}>
                    <Box component={childAs ? childAs : 'div'}>
                        <CircularProgress color="primary" />
                    </Box>
                </Box>
            );
        }
    }

    if (!data) {
        const noDataText = intl.get('common.queryResult.noData').d('No data available... Please try again later or contact support.');
        return (
            <Box className={classes.noDataContainer} component={parentAs}>
                <Typography variant="h5" component={childAs ? childAs : 'span'}>
                    {noDataText}
                </Typography>
            </Box>
        );
    }

    return (
        <>
            {children(data)}
        </>
    );
};

export default QueryResult;
