import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ComponentLinkWithImageType, UniversalLinksWithImageSegmentProps } from '../../types';
import { getImageUrl, isRelativeUrl } from '../../helpers';

const useStyle = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(9, 0),
        position: 'relative'
    },
    title: {
        fontWeight: 'bold',
        fontSize: 45,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: 25
        }
    },
    titleContainer: {
        marginBottom: theme.spacing(2)
    },
    linksContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 6)
        },
        [theme.breakpoints.down('md')]: {
            '& > * + *': {
                margin: theme.spacing(4, 0, 0, 0)
            },
        }
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
    },
    imageContainer: {
        maxWidth: 210,
        maxHeight: 115,
        [theme.breakpoints.down('md')]: {
            maxWidth: 130,
        }
    },
    image: {
        width: '100%',
        height: '100%',
        maxWidth: 210,
        maxHeight: 115,
        objectFit: 'contain',
        [theme.breakpoints.down('md')]: {
            maxWidth: 130,
        }
    },
    redAccent: {
        position: 'absolute',
        backgroundColor: theme.palette.primary.main,
        width: 35,
        height: 35,
        top: 0,
        right: 0,
        [theme.breakpoints.down('md')]: {
            width: 25,
            height: 25,
        }
    }
}));

type Props = UniversalLinksWithImageSegmentProps

const LinksWithImage = ({ title, links }: Props): JSX.Element => {
    const classes = useStyle();

    const generateLogoLink = ({ id, url, media, name }: ComponentLinkWithImageType) => {
        const mediaUrl = getImageUrl(media, 'small');
        const isRelative = isRelativeUrl(url);

        return (
            <Box key={`${id}-${url}`} className={classes.imageContainer}>
                {
                    isRelative ? (
                        <Link className={classes.link} to={url}>
                            <img className={classes.image} src={mediaUrl} alt={name} />
                        </Link>
                    ) : (
                        <a className={classes.link} href={url}>
                            <img className={classes.image} src={mediaUrl} alt={name} />
                        </a>
                    )
                }
            </Box>
        );
    };

    return (
        <Box>
            {
                title && (
                    <Box className={classes.titleContainer}>
                        <Typography variant="h1" component="h2" className={classes.title}>{title}</Typography>
                    </Box>
                )
            }
            <Paper className={classes.paper}>
                <Box className={classes.redAccent} />
                <Box className={classes.linksContainer}>
                    {
                        links.map((link) => generateLogoLink(link))
                    }
                </Box>
            </Paper>
        </Box>
    );
};

export default LinksWithImage;
