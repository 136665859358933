import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useForm } from 'react-hook-form';
import {
    Grid,
    TextField,
    Container,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers/yup';

import SubmitButton from '../../../components/Button';
import { FieldSelect } from '../../../components/fields';

import { schema } from './validation';
import { useBrandConfigurationContext, useNotification } from '../../../hooks';
import { sendEmail, subscribeToMailchimp } from '../../../request';

import CheckboxField from '../../../components/fields/Checkbox';
import { NO_LOGIN_WHITELABEL } from '../../../constants';

const useStyle = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    }
}));

type InputsType = {
    name: string;
    email: string;
    reason: string;
    phoneNumber?: string;
    message: string;
    subscribe: boolean;
}

interface Props {
    contactUsEmail?: string;
    hideNewsletter?: boolean;
}

const ContactUs = ({ contactUsEmail, hideNewsletter }: Props): JSX.Element => {
    const classes = useStyle();
    const notify = useNotification();
    const config = useBrandConfigurationContext();

    const [submitting, setSubmitting] = useState<boolean>(false);

    let reasons = [
        { key: 'general', value: 'general', label: intl.get('common.form.contactUs.reason.general').d('General') },
        { key: 'technicial-support', value: 'technicial support', label: intl.get('common.form.contactUs.reason.technicalSupport').d('Technical Support') },
        { key: 'sales', value: 'sales', label: intl.get('common.form.contactUs.reason.sales').d('Sales') },
        { key: 'listing', value: 'listing', label: intl.get('common.form.contactUs.reason.listing').d('Listing') },
        { key: 'directory', value: 'directory', label: intl.get('common.form.contactUs.reason.directory').d('Directory') },
        { key: 'media', value: 'media', label: intl.get('common.form.contactUs.reason.media').d('Media') },
        { key: 'dealerships', value: 'dealerships', label: intl.get('common.form.contactUs.reason.dealerships').d('Dealerships') },
        { key: 'partnerships', value: 'partnerships', label: intl.get('common.form.contactUs.reason.partnerships').d('Partnerships') },
        { key: 'employment-oppourtunities', value: 'employment oppourtunities', label: intl.get('common.form.contactUs.reason.employmentOpportunities').d('Employment Opportunities') }
    ];

    if (NO_LOGIN_WHITELABEL) {
        reasons = [
            { key: 'general', value: 'general', label: intl.get('common.form.contactUs.reason.general').d('General') },
            { key: 'technicial-support', value: 'technicial support', label: intl.get('common.form.contactUs.reason.technicalSupport').d('Technical Support') },
            { key: 'sales', value: 'sales', label: intl.get('common.form.contactUs.reason.sales').d('Sales') },
            { key: 'media', value: 'media', label: intl.get('common.form.contactUs.reason.media').d('Media') },
            { key: 'partnerships', value: 'partnerships', label: intl.get('common.form.contactUs.reason.partnerships').d('Partnerships') },
            { key: 'employment-oppourtunities', value: 'employment oppourtunities', label: intl.get('common.form.contactUs.reason.employmentOpportunities').d('Employment Opportunities') }
        ];
    }

    const defaultValues = {
        name: '',
        email: '',
        reason: '',
        phoneNumber: '',
        message: '',
        subscribe: false
    };

    const {
        register,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm<InputsType>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const onSubmit = (data: InputsType) => {
        setSubmitting(true);

        // Send an email to contact us email.
        const templateVariables = {
            'v:name': data.name,
            'v:phone': data.phoneNumber,
            'v:email': data.email,
            'v:message': data.message
        };

        const emailObj = {
            to: contactUsEmail ? contactUsEmail : 'info@racingedge.com',
            from: data.email,
            subject: `Contact Us Submission - ${data.reason}`,
            template: 'contact.us',
            'o:tag': ['contact', data.reason],
            ...templateVariables
        };

        sendEmail(emailObj)
            .then((res) => {
                if (data.subscribe) {
                    const fields = {
                        name: data.name
                    };

                    subscribeToMailchimp(data.email, fields)
                        .then((res) => {
                            if (res.error) {
                                const message = intl.get('common.form.contactUs.subscribe.error').d('An error occured when trying to subscribe. The provided email may be subscribed already or looks fake.');
                                notify({ message, severity: 'error' });
                            }
                        });
                }

                const message = intl.get('common.form.contactUs.sendEmail.success', { companyName: config.companyName }).d(`Thank you for reaching out to ${config.companyName}. We will try to get back to you as soon as possible.`);
                notify({ message, severity: 'success' });

                setSubmitting(false);
            })
            .catch((err) => {
                const { message } = err;
                notify({ message, severity: 'error' });
                setSubmitting(false);
            });
    };

    const submitText = intl.get('common.form.contactUs.button.submit').d('Submit');
    const emailText = intl.get('common.form.contactUs.input.email').d('Email Address');
    const nameText = intl.get('common.form.contactUs.input.name').d('Name');
    const reasonText = intl.get('common.form.contactUs.select.reason').d('Reason');
    const phoneNumberText = intl.get('common.form.contactUs.input.phoneNumber').d('Phone Number');
    const messageText = intl.get('common.form.contactUs.input.message').d('Message');
    const messagePlaceholderText = intl.get('common.form.contactUs.input.message.placeholder').d('Leave us a message...');
    const subscribeText = intl.get('common.form.contactUs.checkbox.subscribe').d('Subscribe to our monthly newsletter to get exciting news and special deals!');

    return (
        <Container maxWidth="sm">
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            id="form-contactUs-input-name"
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={nameText}
                            name="name"
                            autoComplete="name"
                            helperText={errors.name ? errors.name.message : ''}
                            error={!!errors.name}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="form-contactUs-input-email"
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={emailText}
                            name="email"
                            autoComplete="email"
                            helperText={errors.email ? errors.email.message : ''}
                            error={!!errors.email}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FieldSelect
                            id="form-contactUs-select-reason"
                            label={reasonText}
                            name="reason"
                            control={control}
                            items={reasons}
                            errorMessage={errors.reason?.message}
                            required
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="form-contactUs-input-phoneNumber"
                            inputRef={register}
                            variant="outlined"
                            fullWidth
                            label={phoneNumberText}
                            name="phoneNumber"
                            autoComplete="tel"
                            helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
                            error={!!errors.phoneNumber}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="form-contactUs-input-message"
                            inputRef={register}
                            multiline
                            rows={3}
                            variant="outlined"
                            margin="normal"
                            placeholder={messagePlaceholderText}
                            fullWidth
                            required
                            label={messageText}
                            name="message"
                            helperText={errors.message ? errors.message.message : ''}
                            error={!!errors.message}
                        />
                    </Grid>
                    {!hideNewsletter && (
                        <Grid item xs={12}>
                            <CheckboxField
                                id="form-contactUs-checkbox-subscribe"
                                name="subscribe"
                                register={register}
                                label={subscribeText}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} textAlign="right">
                        <SubmitButton
                            id="form-contactUs-button-submit"
                            type="submit"
                            text={submitText}
                            disabled={submitting}
                            className={classes.submit}
                            color="secondary"
                        />
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

export default ContactUs;
