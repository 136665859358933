import { useRef, useEffect, MutableRefObject } from 'react';

const useIsMountedRef = (): MutableRefObject<boolean | null> => {
    const isMountedRef = useRef<boolean | null>(null);

    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return isMountedRef;
};

export default useIsMountedRef;
