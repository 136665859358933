import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import intl from 'react-intl-universal';

import { HeadCell, Order } from '../../types';

type ContextType = {
    numSelected: number;
    title?: string;
    count?: number;
    rows: Array<any>;
    filteredRows: Array<any>;
    setFilteredRows: Dispatch<SetStateAction<Array<any>>>;
    hasSearch?: boolean;
    selected: Array<string>;
    setSelected: Dispatch<SetStateAction<Array<string>>>;
    hasSelect?: boolean;
    order: Order;
    orderBy: string;
    setOrder: Dispatch<SetStateAction<Order>>;
    setOrderBy: Dispatch<SetStateAction<string>>;
    headCells: Array<HeadCell>;
    onRefresh?: () => void;
    onSelectedDelete?: (selected: Array<string>) => void;
    page: number;
    rowsPerPage: number;
    countInTitle?: boolean;
    hasAutoRefresh?: boolean;
}

// We initialise them without default values, to make that happen, we
// apply the Partial helper type.
export const DataTableContext = createContext<Partial<ContextType>>({});

// Validate the context otherwise throw error
export const useDataTableContext = (): Partial<ContextType> => {
    const context = useContext(DataTableContext);

    if (!context) {
        const errorText = intl.get('common.datatable.context.error').d('The Row Component cannot be rendered outside of the Data Table Component');
        throw new Error(errorText);
    }

    return context;
};

export default useDataTableContext;
