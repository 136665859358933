/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import packageJson from '../../../../../package.json';
import { useIsMountedRef } from '../../hooks';

// If the latest build date is greater than the cached build date, we will return true
const buildDateGreaterThan = (latestDate, currentDate) => {
    const momLatestDateTime = moment(latestDate);
    const momCurrentDateTime = moment(currentDate);

    if (momLatestDateTime.isAfter(momCurrentDateTime)) {
        return true;
    } else {
        return false;
    }
};

// HOC component that uses the build date located in meta.json to see if the current build is newer or older.
const withClearCache = (Component) => {
    const ClearCache = (props) => {
        const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);
        const isMountedRef = useIsMountedRef();

        useEffect(() => {
            // fetch from meta.json
            fetch('/meta.json')
                .then((response) => response.json())
                .then((meta) => {
                    if (isMountedRef.current) {
                        // get the buildDate from the meta.json & buildDate from package.json
                        const latestVersionDate = meta.buildDate;
                        const currentVersionDate = packageJson.buildDate;

                        // Check to see if we should force a refresh or not
                        const shouldForceRefresh = buildDateGreaterThan(
                            latestVersionDate,
                            currentVersionDate
                        );
                        // if we do, call refresh function
                        if (shouldForceRefresh) {
                            setIsLatestBuildDate(false);
                            refreshCacheAndReload();
                        } else {
                            // else just tell the component that this is the latest build
                            setIsLatestBuildDate(true);
                        }
                    }
                });
        }, [isMountedRef]);

        const refreshCacheAndReload = () => {
            if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then((names) => {
                    for (const name of names) {
                        caches.delete(name);
                    }
                });
            }
            // delete browser cache and hard reload
            window.location.reload(true);
        };

        // If the latest build date and the cached build date are same we don't clear the cache and we load the component.
        return (
            <React.Fragment>
                {isLatestBuildDate ? <Component {...props} /> : null}
            </React.Fragment>
        );
    };

    return ClearCache;
};

export default withClearCache;
