import React, { useState } from 'react';
// import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
    Box,
    Typography,
} from '@material-ui/core';

import Bookmark from '../../containers/Bookmark';
import HoursDisplay from '../HoursDisplay';

import { displayUrl, displayAddress } from '../../helpers';
import { DirectoryType } from '../../types';

const Accordion = withStyles((theme) => ({
    root: {
        border: `1px solid ${theme.palette.action.selected}`,
        borderRadius: '5px',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 0,
        '&$expanded': {
            minHeight: 0,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        paddingTop: 0,
    },
}))(MuiAccordionDetails);

interface Props {
    directory: DirectoryType;
    editDirectory?: (directory: DirectoryType, action?: string) => void;
}

const ResultsItem = ({ directory, editDirectory }: Props): JSX.Element => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Accordion square expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ mr: 1 }}>
                            <Bookmark directory={directory} />
                        </Box>
                        <Typography variant="h3" component="div" style={{ fontWeight: 'bold' }}>{directory.name}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ ml: 2 }}>
                            <ChevronRightIcon style={{ transition: 'all 0.2s', transform: `rotate(${expanded ? 270 : 90}deg)` }} />
                        </Box>
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ position: 'relative', width: '100%', mr: 1, textAlign: 'right' }}>
                    <Typography variant="body2" component="div">{displayUrl(directory.website)}</Typography>
                    <Typography variant="body2" component="div">{displayAddress(directory)}</Typography>
                    <Typography variant="body2" component="div">{directory.phone}</Typography>
                    <Typography variant="body2" component="div">{directory.email}</Typography>
                    <HoursDisplay value={directory.hoursOfOperation} />
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default ResultsItem;
