import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions, Appearance, Stripe } from '@stripe/stripe-js';

import FormBody from './FormBody';

import { BRAND } from '../../../constants';
import { PaymentParamsBaseType, SavedCardType } from '../../../types';
import { createPaymentIntent, getCustomerSavedCardDetails } from '../../../request';
import { useIsMountedRef, useNotification, usePaymentConfigurationContext } from '../../../hooks';
import { schema } from './validation';

type InputsType = {
    // name: string;
    // saveCard: boolean;
    hasAgreedToTerms: boolean;
}

type Props = PaymentParamsBaseType;

const StripeForm = ({ itemId, itemType, paymentProvider, amount: predefinedAmount, pricingPackage, paymentType, promotion, onSuccess, onFailure, renderPaymentOptions }: Props): JSX.Element => {
    const notify = useNotification();
    const isMountedRef = useIsMountedRef();
    const { stripePublishableKey } = usePaymentConfigurationContext();
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(new Promise(() => null));

    const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
    const [paymentIntent, setPaymentIntent] = useState<string | undefined>(undefined);
    const [amount, setAmount] = useState<number>(0);
    const [formCurrency, setFormCurrency] = useState<string>('USD');

    const [savedCard, setSavedCard] = useState<SavedCardType | null | undefined>(null);
    const [savedCardLoading, setSavedCardLoading] = useState<boolean>(false);
    const [useSavedCard, setUseSavedCard] = useState<boolean>(false);

    const defaultValues = {
        // name: '',
        // saveCard: false
    };

    const form = useForm<InputsType>({
        resolver: yupResolver(schema),
        defaultValues
    });

    // const { control } = form;
    // const saveCard = useWatch<boolean>({ control, name: 'saveCard' }) || false;

    useEffect(() => {
        if (stripePublishableKey) {
            const promise = loadStripe(stripePublishableKey);
            setStripePromise(promise);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isMountedRef.current) {
            // Try to fetch any saved card details from the customer profile
            // This function will also create the customer if
            // there isn't any associated profile with the user
            setSavedCardLoading(true);
            getCustomerSavedCardDetails({ paymentProvider, brand: BRAND })
                .then((cardRes) => {
                    const { message } = cardRes;
                    if (message) {
                        setSavedCard({
                            cardBrand: (message as SavedCardType).cardBrand,
                            last4: (message as SavedCardType).last4
                        });
                        setUseSavedCard(true);
                    }
                }).catch(() => {
                    setSavedCard(null);
                }).finally(() => {
                    setSavedCardLoading(false);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            const paymentIntentProps = {
                itemId,
                itemType,
                amount: predefinedAmount,
                packageId: pricingPackage?.id || '',
                paymentType,
                paymentProvider,
                promotion,
                brand: BRAND,
                // We default it to false for Stripe, as stripe doesn't have a good way to switch saving payment
                // methods after payment intent has been created.
                saveCard: false,
                useSavedCard
            };

            createPaymentIntent(paymentIntentProps)
                .then(({ paymentIntent, clientSecret, amount, currency }) => {
                    if (clientSecret) {
                        setAmount(amount ? amount : 0);
                        setFormCurrency(currency ? currency : 'USD');
                        setClientSecret(clientSecret);
                        setPaymentIntent(paymentIntent);
                    } else {
                        const message = intl.get('common.form.elements.paymentIntent.clientSecret.error').d('Something went wrong. Please try again.');
                        notify({ message, severity: 'error' });
                    }
                })
                .catch((err) => {
                    // eslint-disable-next-line
                    console.log(err);
                    // const { message } = err;
                    // notify({ message, severity: 'error' });
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMountedRef]);

    const appearance: Appearance = {
        theme: 'stripe',
    };

    const options: StripeElementsOptions = {
        clientSecret,
        appearance
    };

    return clientSecret && paymentIntent ? (
        <Elements options={options} stripe={stripePromise}>
            <FormBody
                form={form}
                paymentIntent={paymentIntent}
                itemId={itemId}
                itemType={itemType}
                displayAmount={amount}
                amount={predefinedAmount}
                formCurrency={formCurrency}
                paymentType={paymentType}
                savedCardLoading={savedCardLoading}
                savedCard={savedCard}
                paymentProvider={paymentProvider}
                renderPaymentOptions={renderPaymentOptions}
                onFailure={onFailure}
                onSuccess={onSuccess}
                useSavedCard={useSavedCard}
                setUseSavedCard={setUseSavedCard}
                promotion={promotion}
                pricingPackage={pricingPackage}
            />
        </Elements>
    ) : (
        <></>
    );
};

export default StripeForm;
