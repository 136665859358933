import { WHITE_LABEL_BRANDS } from '../../constants';
import { CMSPermissionsType } from '../../types';

const defaultValues: CMSPermissionsType = {
    brand: WHITE_LABEL_BRANDS.RACING_EDGE,
    websiteTheme: WHITE_LABEL_BRANDS.RACING_EDGE,
    superAdmin: false,
    manageUsers: false,
    auctionEnabled: false,
    websiteEnabled: false,
    manageHomepage: false,
};

export default defaultValues;
