import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, InputLabel, InputLabelProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const useStyles = makeStyles(theme => ({
    root: {
        ...theme.typography.body2,
        '& .ck-editor__editable:not(.ck-editor__nested-editable)': {
            minHeight: 250
        }
    }
}));

type Props = InputLabelProps & {
    id: string;
    label: string;
    name: string;
    control: any;
    errorMessage?: string;
    disabled?: boolean;
}

const RichTextField = ({ label, name, control, id, error, errorMessage, disabled, ...rest }: Props): JSX.Element => {
    const classes = useStyles();

    return (
        <Controller
            render={({ onBlur, onChange, value }) => (
                <Box className={classes.root}>
                    <Box sx={{ mb: 1 }}>
                        <InputLabel
                            htmlFor={id}
                            error={error}
                            {...rest}
                        >
                            {label}
                        </InputLabel>
                    </Box>
                    <CKEditor
                        id={id}
                        editor={ClassicEditor}
                        disabled={disabled}
                        config={{
                            toolbar: [
                                'heading',
                                '|',
                                'bold',
                                'italic',
                                'link',
                                'bulletedList',
                                'numberedList',
                                '|',
                                'indent',
                                'outdent',
                                '|',
                                'blockQuote',
                                'insertTable',
                                'undo',
                                'redo'
                            ]
                        }}
                        onReady={(editor) => {
                            if (editor) {
                                editor.setData(value);
                            }
                        }}
                        onChange={(_, editor) => {
                            if (editor) {
                                const data = editor.getData();
                                onChange(data);
                            }
                        }}
                        onBlur={onBlur}
                    />
                    <Box>
                        {error && <Typography variant="body1" color="error" >{errorMessage}</Typography>}
                    </Box>
                </Box>
            )}
            name={name}
            control={control}
        />
    );
};

export default RichTextField;
