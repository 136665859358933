import { createMuiTheme, Theme, ThemeOptions } from '@material-ui/core/styles';
import { TEMPLATE_TYPE } from './modules/common/constants';
import { BrandThemeType } from './modules/common/types';
import makeNxgemTheme from './theming/nxgem/theme';

const theme = (configTheme: BrandThemeType): Theme => {
    const {
        darkMode,
        colours,
        primaryFont,
        secondaryFont,
    } = configTheme;
    const {
        primaryColor,
        secondaryColor,
        success,
        warning,
        info,
        error
    } = colours;

    let themeCustomization: ThemeOptions = {};

    if (TEMPLATE_TYPE === 'NX_GEM') {
        themeCustomization = makeNxgemTheme(configTheme);
    }

    const fontEnumMap = {
        MONTSERRAT: 'Montserrat',
        AVENIR: 'Avenir',
        ROBOTO: 'Roboto',
        ARIAL: 'Arial',
        SANS_SERIF: 'sans-serif'
    };

    // Default Font
    let font1 = 'Montserrat, Avenir, Roboto, "Helvetica Neue", Arial, sans-serif';
    // Body/Subtitle Font
    let font2 = 'Avenir, sans-serif';

    // Check if the Theme has configured a primary font
    if (primaryFont && fontEnumMap[primaryFont]) {
        // Attempt to use the configured font, falling back on the defaults
        font1 = `${fontEnumMap[primaryFont]}, ${font1}`;
    }
    // Check if the Theme has configured a secondary font
    if (secondaryFont && fontEnumMap[secondaryFont]) {
        // Attempt to use the configured font, falling back on the defaults
        font2 = `${fontEnumMap[secondaryFont]}, ${font2}`;
    }

    return createMuiTheme({
        typography: {
            fontFamily: font1,
            h1: {
                fontSize: '1.563rem',
            },
            h2: {
                fontSize: '1.25rem',
            },
            h3: {
                fontSize: '1.125rem',
            },
            h4: {
                fontSize: '0.938rem',
            },
            h5: {
                fontSize: '0.875rem',
            },
            h6: {
                fontSize: '0.75rem',
            },
            subtitle1: {
                fontFamily: font2,
                fontSize: '0.813rem',
            },
            subtitle2: {
                fontFamily: font2,
                fontSize: '0.75rem',
            },
            body1: {
                fontFamily: font2,
                fontSize: '0.875rem',
            },
            body2: {
                fontFamily: font2,
                fontSize: '0.813rem',
            },
            button: {
                fontFamily: font1,
                fontSize: '0.938rem',
                textTransform: 'unset'
            },
            caption: {
                fontFamily: font2,
                fontSize: '0.625rem',
            },
            overline: {
                fontFamily: font2,
                fontSize: '0.625rem',
            },
        },
        palette: {
            mode: darkMode ? 'dark' : 'light',
            primary: {
                main: primaryColor,
            },
            secondary: {
                main: secondaryColor,
            },
            success: {
                main: success
            },
            warning: {
                main: warning
            },
            info: {
                main: info
            },
            error: {
                main: error
            }
        },
        ...themeCustomization,
    });
};

export default theme;
