import { gql } from '@apollo/client';
import { ME_ATTRIBUTES, USER_ATTRIBUTES } from './fragments';

export const MUTATION_LOGIN_USER = gql`
    mutation loginUser($email: String!, $password: String!, $brand: String!) {
        loginUser(input: { email: $email, password: $password, brand: $brand }) {
            jwt
            user {
                ...UserParts
            }
        }
    },
    ${USER_ATTRIBUTES}
`;

export const MUTATION_UPLOAD_IMAGE = gql`
    mutation($refId: ID, $ref: String, $field: String, $source: String, $info: FileInfoInput, $file: Upload!) {
        upload(refId: $refId, ref: $ref, field: $field, source: $source, info: $info, file: $file) {
            id
        }
    }
`;

export const MUTATION_UPLOAD_MULTIPLE_IMAGE = gql`
    mutation($files: [Upload]!) {
        multipleUpload(files: $files) {
            id
        }
    }
`;

export const MUTATION_UPDATE_USER = gql`
    mutation updateUser($where: InputID, $data: editUserInput) {
        updateUser(input: { data: $data, where: $where }) {
            user {
                ...UserParts
            }
        }
    },
    ${USER_ATTRIBUTES}
`;

export const MUTATION_REGISTER_USER = gql`
    mutation registerUser($email: String!, $password: String!, $brand: String!, $username: String!, $firstName: String!, $lastName: String!) {
        registerUser(input: { email: $email, password: $password, brand: $brand, username: $username, firstName: $firstName, lastName: $lastName }) {
            jwt
            user {
                ...UserParts
            }
        }
    },
    ${USER_ATTRIBUTES}
`;

export const MUTATION_FORGOT_PASSWORD = gql`
    mutation forgotPassword($email: String!, $brand: String!) {
        forgotPasswordBrand(email: $email, brand: $brand) {
            ok
        }
    }
`;

export const MUTATION_RESET_PASSWORD = gql`
    mutation resetPassword($password: String!, $passwordConfirmation: String!, $code: String!, $brand: String!) {
        resetPasswordBrand(password: $password, passwordConfirmation: $passwordConfirmation, code: $code, brand: $brand) {
            jwt
            user {
                ...MeParts
            }
        }
    },
    ${ME_ATTRIBUTES}
`;

export const MUTATION_CREATE_TRANSACTION = gql`
    mutation createTransaction($input: createTransactionInput) {
        createListingGroup(input: $input) {
            transaction {
                id
            }
        }
    }
`;

export const MUTATION_UPDATE_PROMOTION = gql`
    mutation updatePromotion($input: updatePromotionInput) {
        updatePromotion(input: $input) {
            promotion {
                id
            }
        }
    }
`;

export const MUTATION_UPDATE_COUPON = gql`
    mutation updateCoupon($input: updateCouponInput) {
        updateCoupon(input: $input) {
            coupon {
                id
            }
        }
    }
`;
