import React from 'react';
import intl from 'react-intl-universal';
import { createStyles, Theme, withStyles, WithStyles, makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Button,
    Tooltip,
    IconButton,
    Dialog,
    DialogTitle as MuiDialogTitle,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogProps,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.common.black,
        },
        title: {
            width: '100%',
            fontWeight: 'bold',
            [theme.breakpoints.down('md')]: {
                maxWidth: 300
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: 280
            }
        }
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    const closeText = intl.get('common.icon.close.tooltip').d('Close');
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className={classes.title} variant="h2">{children}</Typography>
            {onClose ? (
                <Tooltip enterTouchDelay={50} title={closeText}>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

interface Props extends DialogProps {
    title: string;
    // children: string | React.ReactNode | React.ReactNode[];
    actionText?: string;
    actionDisabled?: boolean;
    onClose: () => void;
    onAction?: () => void;
}

const useStyles = makeStyles(theme => ({
    contentContainer: {
        fontFamily: theme.typography.body1.fontFamily
    },
}));

const CustomDialog = ({ onClose, onAction, title, children, actionText, actionDisabled = false, ...dialogProps }: Props): React.ReactElement => {
    const classes = useStyles();
    const handleClose = () => {
        onClose();
    };

    const handleAction = () => {
        onAction && onAction();
    };

    return (

        <Dialog {...dialogProps} onClose={handleClose}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {title}
            </DialogTitle>
            <DialogContent>
                {
                    typeof children === 'string' ? (
                        <Typography gutterBottom variant="body1">
                            {children}
                        </Typography>
                    ) : (
                        <div className={classes.contentContainer}>
                            {children}
                        </div>
                    )
                }
            </DialogContent>
            {
                onAction && actionText && (
                    <DialogActions>
                        <Button onClick={handleAction} variant="contained" color="secondary" disabled={actionDisabled}>
                            {actionText}
                        </Button>
                    </DialogActions>
                )
            }
        </Dialog>

    );
};

export default CustomDialog;
