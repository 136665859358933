import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Typography,
    Grid,
    Divider
} from '@material-ui/core';

import HoursAccordion from '../HoursAccordion';
import EditPopover from '../EditPopover';
import MoreDisplay from '../MoreDisplay';
import VideoDisplay from '../VideoDisplay';
import Bookmark from '../../containers/Bookmark';

import { displayUrl, displayAddress } from '../../helpers';
import { DirectoryType } from '../../types';
import { getImageUrl, hexToRGB } from '../../../common/helpers';
import { useBrandConfigurationContext } from '../../../common/hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: theme.shadows[1],
        background: theme.palette.background.default, // NOTE: same colour for titleText gradient
        color: theme.palette.text.primary,
        textAlign: 'left',
        height: '100%',
        position: 'relative',
    },
    verticalImage: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    clickable: {
        cursor: 'pointer',
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.dark,
        }
    },
    titleText: {
        marginTop: theme.spacing(2),
        position: 'relative',
        height: '3rem',
        lineHeight: '1.5rem',
        overflow: 'hidden',
        // Add a fade-out effect for the second line overflow cut-off
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '35%',
            height: '1.5rem',
            background: `linear-gradient(to right, rgba(${hexToRGB(theme.palette.background.default)}, 0), rgba(${hexToRGB(theme.palette.background.default)}, 1) 50%)`,
            pointerEvents: 'none',
        },
    },
    titleIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 1,
    },
    singleLineInfo: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    doubleLineInfo: {
        position: 'relative',
        height: '2.4rem',
        lineHeight: '1.2rem',
        overflow: 'hidden',
        // Add a fade-out effect for the second line overflow cut-off
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '35%',
            height: '1.2rem',
            background: `linear-gradient(to right, rgba(${hexToRGB(theme.palette.background.default)}, 0), rgba(${hexToRGB(theme.palette.background.default)}, 1) 50%)`,
            pointerEvents: 'none',
        },
    },
}));

interface Props {
    directory: DirectoryType;
    editDirectory?: (directory: DirectoryType, action?: string) => void;
    previewImage?: string;
}

const FeaturedCard = ({ directory, editDirectory, previewImage }: Props): JSX.Element => {
    const classes = useStyles();
    const brandConfig = useBrandConfigurationContext();
    const { theme: configTheme } = brandConfig;
    let defaultImageImgUrl = '';

    const { defaultImage } = configTheme.images;

    if (defaultImage) {
        defaultImageImgUrl = getImageUrl(defaultImage.image, 'small');
    }
    const featuredImage = getImageUrl(directory.image, 'small');

    const imageStyle = {
        backgroundImage: `url('${previewImage || (featuredImage) || defaultImageImgUrl}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        flexGrow: 1,
    };

    // More Options - available if there is at least one social link
    const moreOptions = !!directory.socialLinks && (
        directory.socialLinks.facebook ||
        directory.socialLinks.instagram ||
        directory.socialLinks.linkedin ||
        directory.socialLinks.twitter ||
        directory.socialLinks.youtube
    );
    const extraTitleStyle: any = {};
    // Hours of Operations
    let hooElement = <></>;
    if (directory.hoursOfOperation && Object.keys(directory.hoursOfOperation).length) {
        hooElement = (
            <>
                <Divider />
                <HoursAccordion directory={directory} />
            </>
        );
    }

    // Actions - edit / more options
    let actions: JSX.Element | null = null;
    if (editDirectory) {
        // Edit Directory
        actions = (
            <Box className={classes.titleIcon}>
                <EditPopover directory={directory} editDirectory={editDirectory} />
            </Box>
        );
    } else if (moreOptions) {
        // Display more options
        actions = (
            <Box className={classes.titleIcon}>
                <MoreDisplay directory={directory} />
            </Box>
        );
    }

    // If there are actions (displayed in top right) add extra padding
    if (actions) {
        extraTitleStyle.paddingRight = '30px';
    }

    return (
        <Grid container className={classes.root}>
            <Grid item container direction="column" xs={4} alignItems="stretch">
                <Grid item style={imageStyle}></Grid>
                {directory.videoURL && (
                    <Grid item>
                        <VideoDisplay value={directory.videoURL} />
                    </Grid>
                )}
            </Grid>
            <Grid item xs={8}>
                <Box sx={{ m: 1 }}>
                    {/* Title */}
                    <Typography component="h5" variant="h3" className={classes.titleText} style={extraTitleStyle}>
                        {directory.name}
                        {/* Actions */}
                        {actions}
                    </Typography>
                    {/* Website Link */}
                    {directory.website && (
                        <>
                            <Box sx={{ mt: 2, mb: 1 }} >
                                <a href={directory.website} target="_blank" rel="noopener noreferrer" className={classes.clickable}>
                                    <Typography component="div" variant="body2" className={`${classes.clickable} ${classes.singleLineInfo}`}>
                                        {displayUrl(directory.website)}
                                    </Typography>
                                </a>
                            </Box>
                        </>
                    )}
                    {/* Address */}
                    <Divider />
                    <Box sx={{ mt: 1, mb: 1 }}>
                        <Typography component="div" variant="body2" className={classes.singleLineInfo}>
                            {displayAddress(directory)}
                        </Typography>
                    </Box>
                    {/* Phone Number */}
                    {directory.phone && (
                        <>
                            <Divider />
                            <Box sx={{ mt: 1, mb: 1 }}>
                                <Typography component="div" variant="body2" className={classes.singleLineInfo}>
                                    {directory.phone}
                                </Typography>
                            </Box>
                        </>
                    )}
                    {/* Email */}
                    {directory.email && (
                        <>
                            <Divider />
                            <Box sx={{ mt: 1, mb: 2 }}>
                                <Typography component="div" variant="body2" className={classes.singleLineInfo}>
                                    {directory.email}
                                </Typography>
                            </Box>
                        </>
                    )}
                    {/* Hours of Operation */}
                    {hooElement}
                    {/* Bookmarks */}
                    <Box sx={{ position: 'absolute', top: '8px', left: '8px' }}>
                        <Bookmark directory={directory} background={true} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default FeaturedCard;
