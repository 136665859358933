import React from 'react';
import intl from 'react-intl-universal';

import PageHeader from '../../../common/components/PageHeader';
import Container from '../../../common/components/Container';
import AuctionWizard from '../../containers/AuctionWizard';

import { FORM_ACTION } from '../../../common/constants';
import { FormDataProvider } from '../../../common/hooks/useFormDataContext';

const EditAuctionPage = (): JSX.Element => {
    const headerText = intl.get('auction.page.editAuction.header.label').d('Edit Auction');
    const titleText = intl.get('auction.page.editAuction.title').d('Auction Edit');

    return (
        <>
            <PageHeader label={headerText} beta />
            <Container>
                <FormDataProvider>
                    <AuctionWizard title={titleText} action={FORM_ACTION.EDIT} />
                </FormDataProvider>
            </Container>
        </>
    );
};

export default EditAuctionPage;
