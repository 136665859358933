import * as yup from 'yup';
import intl from 'react-intl-universal';

export const schema = yup.object().shape({
    subject: yup.string()
        .required(
            intl.get('auction.form.emailSeller.subject.error.required').d('Please provide a subject')
        ),
    body: yup.string()
        .required(
            intl.get('auction.form.emailSeller.body.error.required').d('Please provide a message')
        ),
});
