import React, { ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import RoundedBox from '../../../components/RoundedBox';

import { BidType } from '../../../types';
import { localizeCurrency } from '../../../../common/helpers';

const useStyles = makeStyles(theme => ({
    bidContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center'
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    amount: {
        fontWeight: 'bold',
        fontFamily: 'Avenir',
        color: theme.palette.common.black,
        [theme.breakpoints.down('md')]: {
            fontSize: 16
        }
    },
    name: {
        fontWeight: 'bold',
        fontFamily: 'Avenir',
        color: theme.palette.grey[600],
        marginLeft: theme.spacing(1.5),
        [theme.breakpoints.down('md')]: {
            fontSize: 12
        }
    },
    timeAgo: {
        fontFamily: 'Avenir',
        color: theme.palette.grey[600]
    },
    currency: {
        fontSize: '0.5em',
        margin: '0.5em'
    }
}));

interface Props {
    bid: BidType;
    currency: string;
}

const Item = ({ bid, currency }: Props): ReactElement => {
    const classes = useStyles();

    const { amount, bidder, created_at } = bid;

    const formattedAmount = localizeCurrency(amount, currency);
    const timeAgo = moment(new Date(created_at)).fromNow();

    return (
        <RoundedBox>
            <Box className={classes.bidContainer}>
                <Typography variant="h1" component="h2" className={classes.amount}>
                    {formattedAmount}
                    <span className={classes.currency}>{currency}</span>
                </Typography>
                <Box className={classes.infoContainer}>
                    <Typography variant="subtitle1" className={classes.name}>{ bidder?.username ? bidder.username : 'Removed user' }</Typography>
                    <Typography variant="caption" className={classes.timeAgo}>{timeAgo}</Typography>
                </Box>
            </Box>
        </RoundedBox>
    );
};

export default Item;
