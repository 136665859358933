import { gql } from '@apollo/client';
import { CMS_AUCTION_ATTRIBUTES } from './fragments';
import { CONFIGURATION_AUCTION_ATTRIBUTES, UNIVERSAL_PAGE_ATTRIBUTES } from '../common/fragments';

export const QUERY_AUCTIONS_BY_BRAND_AND_STATUS = gql`
    query auctionsByBrandAndStatus($start: Int, $limit: Int, $brand: String!, $status: [ENUM_AUCTION_STATUS]!, $where: JSON) {
        auctionsByBrandAndStatus(start: $start, limit: $limit, brand: $brand, status: $status, where: $where) {
            auctions {
                ...CMSAuctionParts
            }
            count
        }
    },
    ${CMS_AUCTION_ATTRIBUTES}
`;

export const QUERY_AUCTIONS_BY_NETWORK = gql`
    query auctionsByNetwork($start: Int, $limit: Int) {
        auctionsByNetwork(start: $start, limit: $limit) {
            auctions {
                ...CMSAuctionParts
            }
            count
        }
    },
    ${CMS_AUCTION_ATTRIBUTES}
`;

export const QUERY_AUCTIONS_ACCEPTED_FROM_NETWORK = gql`
    query auctionsAcceptedFromNetwork($start: Int, $limit: Int) {
        auctionsAcceptedFromNetwork(start: $start, limit: $limit) {
            auctions {
                ...CMSAuctionParts
            }
            count
        }
    },
    ${CMS_AUCTION_ATTRIBUTES}
`;

export const QUERY_AUCTIONS_CONFIGURATIONS = gql`
    query configurationByBrand($brand: String!) {
        configurationByBrand(brand: $brand) {
            auctionConfiguration {
                ...ConfigurationAuctionParts
            }
        }
    }
    ${CONFIGURATION_AUCTION_ATTRIBUTES}
`;

export const QUERY_HOMEPAGE_CONFIG = gql`
    query getCMSHomepage {
        getCMSHomepage {
            ...UniversalPageParts
        }
    },
    ${UNIVERSAL_PAGE_ATTRIBUTES}
`;
