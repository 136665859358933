import React, { useCallback, useEffect, useMemo, useState, ReactElement } from 'react';
import intl from 'react-intl-universal';
// import uniqBy from 'lodash/uniqBy';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, IconButton, Grid, Tooltip, useMediaQuery } from '@material-ui/core';
import {
    Tune as TuneIcon,
} from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Configure, InstantSearch } from 'react-instantsearch-core';
import algoliasearch from 'algoliasearch/lite';
import { useQuery } from '@apollo/client';

import AuctionsResult from '../AuctionsResult';
// import CurrentRefinements from '../../../common/components/CurrentRefinements';
import SearchBox from '../../../common/components/SearchBox';
import NoResults from '../../components/NoResults';
import Pagination from '../../../common/components/Pagination';
import RefinementButtonList from '../../../common/components/RefinementButtonList';
import QueryResult from '../../../common/components/QueryResult';
import RefreshButton from '../../../common/components/RefreshButton';
import SortByList from '../../components/SortByList';
import FilterListDialog from '../../components/FilterListDialog';
import VirtualFilterList from '../../components/VirtualFilterList';

import { BRAND, INDEX_PREFIX } from '../../../common/constants';
import { AUCTION_STATUS, DEFAULT_DISPLAY_LIMIT } from '../../constants';
import { QUERY_AUCTIONS_CONFIGURATIONS } from '../../../cms/queries';
import { BrandAuctionConfigurationDataType } from '../../../cms/types';
import { BrandVariablesType } from '../../../common/types';
import { useIsMountedRef } from '../../../common/hooks';
import { createURL, searchStateToUrl, urlToSearchState } from '../../helpers';

const useStyles = makeStyles(theme => ({
    panelContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    searchContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    button: {
        ...theme.typography.button,
        // // textTransform: 'capitalize',
        borderColor: '#707070',
        borderRadius: '10px',
        padding: theme.spacing(1, 2),
        minHeight: '53px',
        maxHeight: '53px',
    },
    text: {
        color: theme.palette.common.black,
        fontWeight: 'bold',
        fontFamily: 'Avenir',
        textAlign: 'center',
        marginLeft: theme.spacing(1)
    },
}));

const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID ? process.env.REACT_APP_ALGOLIA_APP_ID : '',
    process.env.REACT_APP_ALGOLIA_API_KEY ? process.env.REACT_APP_ALGOLIA_API_KEY : '',
);

const indexName = `${INDEX_PREFIX}_auction`;
const SCROLL_TO_ID = 'auction_results_container';

// const deduplicate = (items: any) => uniqBy(items, (item: any) => item.attribute);

const useParamQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
};

const Auctions = (): ReactElement => {
    const classes = useStyles();
    const isMountedRef = useIsMountedRef();
    const location = useLocation();
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [searchState, setSearchState] = useState<Record<string, any>>({});
    const [refresh, setRefresh] = useState(false);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [initComplete, setInitComplete] = useState(false);
    const queryParams = useParamQuery();

    const { data, loading, error } = useQuery<BrandAuctionConfigurationDataType, BrandVariablesType>(QUERY_AUCTIONS_CONFIGURATIONS, {
        variables: {
            brand: BRAND
        }
    });

    const defStatus = queryParams.get('status');

    const defaultSearchParams = useMemo(() => ({
        sortBy: 'endDate-asc',
        status: defStatus
    }), [defStatus]);

    const filterText = intl.get('auction.page.home.search.filters').d('Filters');

    useEffect(() => {
        if (isMountedRef.current) {
            if (refresh) {
                setRefresh(false);
            }
        }
    }, [isMountedRef, refresh]);

    useEffect(() => {
        // when mounted
        if (isMountedRef.current) {
            // parse url params into search state
            const nextSearchState = urlToSearchState(location, defaultSearchParams);
            // compare search states
            if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
                // set search states
                setSearchState(nextSearchState);
            }
            setInitComplete(true);
            // refresh everytime location changes or component is remounted
            handleRefresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMountedRef, location]);

    const onSearchStateChange = useCallback((updatedSearchState) => {
        // Check if the page has been navigated to recently
        const _window: any = window;
        // True if this function has been called within 1 second of a navigation event
        if (_window.__dynamicNavItemClick && Date.now() - _window.__dynamicNavItemClick < 1000) {
            // Prevent overwriting the URL parameters on navigation with previous filters
            return;
        }
        // Push the user filters to the URL
        history.push(
            searchStateToUrl(updatedSearchState, location.pathname),
            updatedSearchState
        );
        // Update the search state
        setSearchState(updatedSearchState);
    }, [history, location]);

    const handleRefresh = () => {
        setTimeout(() => {
            setRefresh(true);
        }, 500);
    };

    const refreshProps = {
        refresh: handleRefresh
    };

    // Prevents providing an empty searchState to the InstantSearch component
    // This essentially ensures that nothing is rendered until the useEffect has run
    if (!initComplete) {
        return <></>;
    }

    return (
        <Box id={SCROLL_TO_ID} className={classes.panelContainer}>
            <QueryResult loading={loading} data={data} error={error}>
                {
                    ({ configurationByBrand }) => {
                        const { auctionConfiguration } = configurationByBrand;
                        const { host, displayLimit } = auctionConfiguration;
                        const algoliaRecordsfilter = `(brand:${BRAND} OR hosts:${host.id}) AND NOT status:${AUCTION_STATUS.PENDING} AND NOT status:${AUCTION_STATUS.APPROVED}`;
                        return (
                            <InstantSearch
                                indexName={indexName}
                                searchClient={searchClient}
                                searchState={searchState}
                                onSearchStateChange={onSearchStateChange}
                                createURL={createURL}
                                refresh={refresh}
                            >
                                <Configure
                                    hitsPerPage={displayLimit || DEFAULT_DISPLAY_LIMIT}
                                    filters={algoliaRecordsfilter}
                                />
                                <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                                    <Grid item xs={12} md={4}>
                                        <SearchBox />
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <RefinementButtonList attribute="status" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <Tooltip enterTouchDelay={50} title={filterText}>
                                                <IconButton onClick={() => setOpenFilter(true)}>
                                                    <TuneIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <VirtualFilterList />
                                            <FilterListDialog
                                                indexName={indexName}
                                                searchClient={searchClient}
                                                searchState={searchState}
                                                onSearchStateChange={onSearchStateChange}
                                                createURL={createURL}
                                                open={openFilter}
                                                setOpen={setOpenFilter}
                                                fullScreen={isMobile}
                                                hitsFilter={algoliaRecordsfilter}
                                                hitsPerPage={displayLimit || DEFAULT_DISPLAY_LIMIT}
                                            />
                                            <RefreshButton onRefresh={() => setRefresh(true)} />
                                            <SortByList />
                                        </Box>
                                    </Grid>
                                    {/* <Hidden mdDown>
                                        <Grid item xs={12} sm={8} md={8}>
                                            <CurrentRefinements transformItems={deduplicate} />
                                        </Grid>
                                    </Hidden> */}
                                </Grid>
                                <AuctionsResult {...refreshProps as any} />
                                <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }} >
                                    <NoResults />
                                    <Pagination scrollToId={SCROLL_TO_ID} />
                                </Box>
                            </InstantSearch>
                        );
                    }
                }
            </QueryResult>

        </Box>
    );
};

export default React.memo(Auctions);
