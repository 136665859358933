import React, { ReactNode } from 'react';
import { StyledEngineProvider } from '@material-ui/core';
import { MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';

import createTheme from '../../../../theme';
import { useBrandConfigurationContext } from '../../hooks';

interface Props {
    children?: ReactNode
}

const ThemeWrapper = ({ children }: Props): JSX.Element => {
    // Load brand configuration into cache
    const brandConfig = useBrandConfigurationContext();
    const { theme: configTheme } = brandConfig;

    let theme = createTheme(configTheme);
    theme = responsiveFontSizes(theme);

    return (
        <MuiThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
                {children}
            </StyledEngineProvider>
        </MuiThemeProvider>
    );
};

export default React.memo(ThemeWrapper);
