import React from 'react';
import intl from 'react-intl-universal';
import {
    useLocation,
    Link,
} from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { categories } from '../../constants';
import { getCategoryFromPath } from '../../helpers';

const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.text.primary,
        '&.active': {
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        },
    },
    box: {
        [theme.breakpoints.down('md')]: {
            overflowX: 'auto',
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
            /* Hide scrollbar for Chrome, Safari and Opera */
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            '-ms-overflow-style': 'none',  /* IE and Edge */
            'scrollbar-width': 'none',  /* Firefox */
            marginTop: theme.spacing(3)
        }
    },
    categoryContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
            flexWrap: 'nowrap'
        }
    }
}));

const Categories = (): JSX.Element => {
    const classes = useStyles();
    const location = useLocation();
    const { pathname } = location;

    // Determine the current category from the url path
    const currentCategory = getCategoryFromPath(pathname);
    const everythingClasses = currentCategory === 'EVERYTHING' ? `${classes.link} active` : classes.link;
    const everythingText = intl.get('directory.category.EVERYTHING').d('Everything');
    const everythingElement = (
        <Grid item style={{ position: 'relative' }}>
            <Link to="/directory" style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={everythingClasses}>{everythingText}</Typography>
            </Link>
        </Grid>
    );

    return (
        <Box className={classes.box}>
            <Grid container justifyContent="center" alignItems="center" spacing={3} className={classes.categoryContainer}>
                {everythingElement}
                {categories.map(category => {
                    const activeClass = currentCategory === category ? 'active' : '';
                    return (
                        <Grid item key={category} style={{ position: 'relative' }}>
                            <Link to={`/directory/category/${category.toLowerCase()}`} style={{ textDecoration: 'none' }}>
                                <Typography variant="h5" className={`${classes.link} ${activeClass}`}>{intl.get(`directory.category.${category}`).d(category)}</Typography>
                            </Link>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default Categories;
