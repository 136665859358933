import React from 'react';
import {
    // VirtualMenu,
    VirtualRefinementList,
    VirtualCurrentRefinements
} from '../../../common/components/virtual';

/**
 * This virtual list is used to refine the Aloglia search results based on a filter list in a dialog.
 * The reason why we need this virtual list is because refinement lists need to be mounted during a search state
 * for the refinements to be in effect. Due to the nature of dialogs, the components unmount, so any refinement lists won't work.
 */
const VirtualFilterList = (): JSX.Element => (
    <>
        <VirtualRefinementList attribute="status" />
        <VirtualRefinementList attribute="address.country" />
        <VirtualRefinementList attribute="dataFields.make.value" searchable />
        <VirtualRefinementList attribute="dataFields.year.value" searchable showMore />
        <VirtualRefinementList attribute="sellerType" />
        <VirtualCurrentRefinements />
    </>
);

export default VirtualFilterList;
