import React, { ReactElement } from 'react';
// import intl from 'react-intl-universal';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { IconType, PropertyValueType } from '../../types';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        marginY: theme.spacing(1)
    },
    bolded: {
        fontWeight: 'bold',
    },
    value: {
        flexGrow: 2,
        textAlign: 'right',
        textTransform: 'capitalize',
        wordBreak: 'break-word',
        alignSelf: 'center',
    },
    box: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 5,
        padding: theme.spacing(1, 2),
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'space-between',
        alignItems: 'flex-start',
        height: 55,
    },
    name: {
        color: theme.palette.grey[600]
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 1)
        },
        marginRight: theme.spacing(1),
    }
}));

interface Props {
    name: string;
    value: PropertyValueType;
    icon?: string;
    iconType?: IconType;
}

const Property = (
    { name, value }: Props): ReactElement => {
    const classes = useStyles();

    let displayName = '';
    let displayValue: any = '';

    if (name) {
        displayName = name;
        displayName = name.replace(/[-_]/g, ' ');
    }

    if (value) {
        displayValue = value;
        if (typeof value === 'string') {
            displayValue = value.replace(/[-_]/g, ' ');
        }
    }

    return (
        <Box className={classes.box}>
            <Box className={classes.iconContainer}>
                { /* iconJSX */ }
                <Typography variant="body1" className={`${classes.root} ${classes.name} `}>
                    {displayName}
                </Typography>
            </Box>
            <Typography variant="h5" className={`${classes.root} ${classes.bolded} ${classes.value}`} >
                { displayValue }
            </Typography>
        </Box>
    );
};

export default Property;
