import { ApolloClient } from '@apollo/client';
import axios, { AxiosRequestConfig } from 'axios';

import { checkLocalStorage, getCookieAndLocalStorage, runtimeAuth, setCookieAndLocalStorage } from './helpers';
import { isLoggedInVar } from '../../cache';
import { JWT_TOKEN, BRAND, USER_TOKEN } from './constants';

import {
    CreatePaymentIntentReturnType,
    CreateSetupIntentReturnType,
    ConfirmPaymentReturnType,
    EmailType,
    ErrorType,
    FetchCustomerReturnType,
    IntentOptionsType,
    MailchimpFieldsType,
    ResendConfirmationDataType,
    ResendConfirmationReturnType,
    ConfirmPaymentOptionsType,
    SubscriptionOptionsType,
    SubscriptionResultReturnType,
    TemplateEmailType,
    CreateCROPaymentReturnType,
    ChangePasswordDataType,
    GetCustomerSavedCardDetailsReturnType,
    ConfirmAndSaveOptionsType,
    CustomerSavedCardDetailsOptionsType,
    FetchCustomerOptionsType,
    BasicIntentOptionsType
} from './types';

// Instantiate the axios object used for all requests
const instance = axios.create({ timeout: 10000, baseURL: process.env.REACT_APP_BACKEND_URL });

/**
 * Used to execute every server call, provides a place to implement pre/post actions
 *
 * @param {Object} config
 */
const callServer = (config: AxiosRequestConfig) =>
    // Make the request
    instance.request(
        {
            ...config,
            data: {
                ...config.data,
                brand: BRAND
            }
        }
    ).then(
        // Return the server response
        response => response.data
    ).catch(error => {
        // Throw the servers response data
        // to be caught and handled further down
        throw error && error.response && error.response.data;
    });

// Retrieve the JWT from local or cookies. If there isn't any, return null.
const getJWT = () => {
    let jwt: string | null | undefined = null;

    if (checkLocalStorage()) {
        jwt = localStorage[JWT_TOKEN];
    } else {
        jwt = runtimeAuth.getJwt();
    }

    return jwt;
};

/**
 * Used to set cookie and localStorage with JWT.
 * Should only be used if the users credentials are verified and
 * a JWT is passed back.
 */
export const login = (jwt: string, userId: string, client: ApolloClient<any>): void => {
    setCookieAndLocalStorage(JWT_TOKEN, jwt);
    setCookieAndLocalStorage(USER_TOKEN, userId);
    isLoggedInVar(true);
    client.resetStore();
};

/**
 * Login user by provider access token
 */
export const loginByToken = (url: string): Promise<any> =>
    // Call the server
    callServer({
        method: 'get',
        url,
    });

// Logout a user.
export const logout = (client: ApolloClient<any>): void => {
    const userId = getCookieAndLocalStorage(USER_TOKEN);

    // Remove user details from localStorage
    setCookieAndLocalStorage(JWT_TOKEN, '');
    setCookieAndLocalStorage(USER_TOKEN, '');

    // Set the logged-in status to false
    isLoggedInVar(false);

    // Evict and garbage-collect the cached user object
    client.cache.evict({ id: `UsersPermissionsUser:${userId}` });
    client.cache.evict({ id: `UsersPermissionsMe:${userId}` });
    client.cache.gc();

    client.resetStore();
};

/**
 * Used to set cookie and localStorage with JWT.
 * Should only be used if the users credentials are verified and
 * a JWT is passed back.
 */
// export const register = (jwt?: string): void => {
//     if (jwt) {
//         setCookieAndLocalStorage(JWT_TOKEN, jwt);
//         isLoggedInVar(true);
//     }
// };

/**
 * Used to resend the confirmation email
 */
export const resendConfirmation = (data: ResendConfirmationDataType): Promise<ResendConfirmationReturnType> => callServer({
    method: 'post',
    url: '/auth/send-email-confirmation',
    data,
}).then(response =>
    // Handle success.
    response
).catch((error: ErrorType) => {
    // Throw the error id
    throw error.message;
});

/**
 * send out an email
 */
export const sendEmail = (options: EmailType | TemplateEmailType): Promise<any> =>
    // Call the server
    callServer({
        method: 'post',
        url: '/email',
        data: {
            ...options
        }
    }).then(res => res);

/**
 * subscribe to our mailchimp audience
 */
export const subscribeToMailchimp = (email: string, fields: MailchimpFieldsType): Promise<any> =>

    // Call the server
    callServer({
        method: 'post',
        url: '/subscribe',
        data: {
            email,
            fields
        }
    })
        .then(res => res.json())
        .then(data => data);

/**
 * Updates a users avatar
 */
export const changePassword = (data: ChangePasswordDataType): Promise<any> => {
    const jwt = getJWT();
    // No JWT, instantly return a rejected promise
    if (!jwt) {
        return Promise.reject('Unauthorized');
    }
    // Call the server providing the jwt
    return callServer({
        method: 'put',
        url: '/users/changePassword',
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
        data,
    }).then(user => user).catch(res => {
        // Throw the error, example: 'Unauthorized'
        throw (res && res.error) || res;
    });
};

/**
 * Cancels a directory subscription
 */
export const cancelDirectorySubscription = (directoryId: number): Promise<any> => {
    const jwt = getJWT();
    // No JWT, instantly return a rejected promise
    if (!jwt) {
        return Promise.reject('Unauthorized');
    }
    // Call the server providing the jwt
    return callServer({
        method: 'post',
        url: `/directories/cancel/${directoryId}`,
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
    });
};

/**
 * Cancels a directory subscription
 */
export const claimDirectory = (key: string): Promise<any> => {
    const jwt = getJWT();
    // No JWT, instantly return a rejected promise
    if (!jwt) {
        return Promise.reject('Unauthorized');
    }
    // Call the server providing the jwt
    return callServer({
        method: 'post',
        url: `/directories/claim/${key}`,
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
    });
};

/**
 * create a stripe payment intent
 */
export const createPaymentIntent = (options: IntentOptionsType): Promise<CreatePaymentIntentReturnType> => {
    const jwt = getJWT();
    // No JWT, instantly return a rejected promise
    if (!jwt) {
        return Promise.reject('Unauthorized');
    }
    // Call the server providing the jwt
    return callServer({
        method: 'post',
        url: '/payment/payment-intent',
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
        data: {
            ...options
        }
    }).then(res => {
        if (res.code === 200) {
            return res;
        } else {
            throw new Error('PaymentIntent API Error');
        }
    });
};

/**
 * create a stripe/square setup intent
 */
export const createSetupIntent = (options: IntentOptionsType | BasicIntentOptionsType): Promise<CreateSetupIntentReturnType> => {
    const jwt = getJWT();
    // No JWT, instantly return a rejected promise
    if (!jwt) {
        return Promise.reject('Unauthorized');
    }
    // Call the server providing the jwt
    return callServer({
        method: 'post',
        url: '/payment/setup-intent',
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
        data: {
            ...options
        }
    }).then(res => {
        if (res.code === 200) {
            return res;
        } else {
            throw new Error('SetupIntent API Error');
        }
    });
};

/**
 * create a stripe/square subscription
 */
export const confirmPayment = (options: ConfirmPaymentOptionsType): Promise<ConfirmPaymentReturnType> => {
    const jwt = getJWT();
    // No JWT, instantly return a rejected promise
    if (!jwt) {
        return Promise.reject('Unauthorized');
    }
    // Call the server providing the jwt
    return callServer({
        method: 'post',
        url: '/payment/confirm-payment',
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
        data: {
            ...options
        }
    }).then(res => {
        if (res.code === 200) {
            return res;
        } else {
            // Return user friendly strings for any error messages we can
            if (res.message === 'Authorization error: \'CVV_FAILURE\'') {
                throw new Error('Card CVV incorrect');
            } else if (res.message === 'Authorization error: \'ADDRESS_VERIFICATION_FAILURE\'') {
                throw new Error('Card postal code incorrect');
            } else if (res.message === 'Authorization error: \'INVALID_EXPIRATION\'') {
                throw new Error('Card expiration date incorrect');
            } else if (res.message === 'Authorization error: \'GENERIC_DECLINE\'') {
                throw new Error('Card declined');
            }
            // Generic Error
            throw new Error('Payment API Error');
        }
    });
};

/**
 * create a stripe/square subscription
 */
export const createSubscription = (options: SubscriptionOptionsType): Promise<SubscriptionResultReturnType> => {
    const jwt = getJWT();
    // No JWT, instantly return a rejected promise
    if (!jwt) {
        return Promise.reject('Unauthorized');
    }
    // Call the server providing the jwt
    return callServer({
        method: 'post',
        url: '/payment/create-subscription',
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
        data: {
            ...options
        }
    }).then(res => {
        if (res.code === 200) {
            return res;
        } else {
            throw new Error('Subscription API Error');
        }
    });
};

/**
 * cancel a stripe subscription
 */
// export const cancelSubscription = (options: SubscriptionOptionsProps) => {
//     // No JWT, instantly return a rejected promise
//     if (!jwt) {
//         return Promise.reject('Unauthorized');
//     }
//     // Call the server providing the jwt
//     return callServer({
//         method: 'post',
//         url: '/payment/cancel-subscription',
//         headers: {
//             Authorization: `Bearer ${jwt}`,
//         },
//         data: {
//             ...options
//         }
//     }).then(res => {
//         if (res.code === 200) {
//             return res;
//         } else {
//             throw new Error('Cancel Subscription Error');
//         }
//     });
// };

/**
 * Get a stripe customer
 */
export const fetchCustomer = (options: FetchCustomerOptionsType): Promise<FetchCustomerReturnType> => {
    const jwt = getJWT();
    // No JWT, instantly return a rejected promise
    if (!jwt) {
        return Promise.reject('Unauthorized');
    }
    // Call the server providing the jwt
    return callServer({
        method: 'get',
        url: '/payment/customer',
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
        data: {
            ...options
        }
    })
        .then(res => res)
        .then(data => data);
};

/**
 * create a CRO payment object
 */
export const createCROPayment = (options: IntentOptionsType): Promise<CreateCROPaymentReturnType> => {
    const jwt = getJWT();
    // // No JWT, instantly return a rejected promise
    if (!jwt) {
        return Promise.reject('Unauthorized');
    }
    // Call the server providing the jwt
    return callServer({
        method: 'post',
        url: '/cro/payments',
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
        data: {
            ...options
        }
    }).then(res => {
        if (res.code === 200) {
            return res;
        } else {
            throw new Error('Crypto Payment API Error');
        }
    });
};

/**
 * Get the customers saved card details.
 */
export const getCustomerSavedCardDetails = (options: CustomerSavedCardDetailsOptionsType): Promise<GetCustomerSavedCardDetailsReturnType> => {
    const jwt = getJWT();
    // No JWT, instantly return a rejected promise
    if (!jwt) {
        return Promise.reject('Unauthorized');
    }
    // Call the server providing the jwt
    return callServer({
        method: 'post',
        url: '/payment/getCustomerSavedCardDetails',
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
        data: {
            ...options
        }
    }).then(res => res);
};

/**
 * Confirm Square Credit Card and try to save it to the customer
 */
export const confirmAndSaveCard = (options: ConfirmAndSaveOptionsType): Promise<ConfirmPaymentReturnType> => {
    const jwt = getJWT();
    // No JWT, instantly return a rejected promise
    if (!jwt) {
        return Promise.reject('Unauthorized');
    }
    // Call the server providing the jwt
    return callServer({
        method: 'post',
        url: '/payment/confirm-card-and-save',
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
        data: {
            ...options
        }
    }).then(res => {
        if (res.code === 200) {
            return res;
        } else {
            throw new Error('Payment API Error');
        }
    });
};
