import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { QUERY_IS_LOGGED_IN } from '../../queries';
import { IsLoggedInDataType } from '../../types';
import useIsMountedRef from '../useIsMountedRef';

type ReturnType = [boolean, () => void];

const useLoggedIn = (): ReturnType => {
    const isMountedRef = useIsMountedRef();
    const [state, setState] = useState<boolean>(false);
    const [getLoggedIn, { data }] = useLazyQuery<IsLoggedInDataType>(QUERY_IS_LOGGED_IN);

    useEffect(() => {
        if (isMountedRef.current) {
            getLoggedIn();
        }
        if (data) {
            setState(data.isLoggedIn);
        }
    }, [data, getLoggedIn, isMountedRef]);


    return [state, getLoggedIn];
};

export default useLoggedIn;
