import React, { Suspense } from 'react';
import intl from 'react-intl-universal';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { Box } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

import AnimatedLoader from '../../common/components/AnimatedLoader';
import PageHeader from '../../common/components/PageHeader';

import { lazyWithRetry } from '../../common/helpers';
import ErrorMessage from '../../common/components/ErrorMessage';

const ArticlePage = lazyWithRetry(() => import('../pages/Article'));
const ArticlesPage = lazyWithRetry(() => import('../pages/Articles'));
const HomePage = lazyWithRetry(() => import('../pages/Home'));

const useStyle = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
    }
}));

const MediaRoutes = (): JSX.Element => {
    const classes = useStyle();
    const { url } = useRouteMatch();

    const titleText = intl.get('media.page.header').d('Media');

    return (
        <Box className={classes.root}>
            <Suspense fallback={<AnimatedLoader name="MediaRoutes" />}>
                <ErrorBoundary
                    fallback={<ErrorMessage />}
                    beforeCapture={(scope) => {
                        // Tag the Error boundary to know where it came from
                        scope.setTag('location', 'Media Routes');
                    }}
                >
                    <PageHeader label={titleText} />
                    <Switch>
                        {/* Article Page */}
                        <Route path={`${url}/articles/:slug`} render={(route) => (<ArticlePage slug={route.match.params.slug} />)} />

                        {/* Articles Page - filtered by category*/}
                        <Route path={`${url}/category/:category`} render={(route) => (<ArticlesPage category={route.match.params.category} />)} />

                        {/* Articles Page - filtered by tag*/}
                        <Route path={`${url}/tag/:tag`} render={(route) => (<ArticlesPage tag={route.match.params.tag} />)} />

                        {/* Articles Page - filtered by tag*/}
                        <Route path={`${url}/articles`} render={(route) => (<ArticlesPage />)} />

                        {/* Media Home Page */}
                        <Route path={`${url}`} >
                            <HomePage />
                        </Route>
                    </Switch>
                </ErrorBoundary>
            </Suspense>
        </Box>
    );
};

export default MediaRoutes;
