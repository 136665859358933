export const DEBOUNCE_TIME = 700;
export const LISTING_PRICE_FILTER_MIN = 0;
export const LISTING_PRICE_FILTER_MAX = 1000000000;

export const TOUR_COOKIE = 'paddockTourCookie';

export const ITEM_URL_PROPERTY_NAME = 'itemUrl';

export enum LISTING_STATUS {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    EXPIRED = 'EXPIRED',
    SOLD = 'SOLD',
    CANCELED = 'CANCELED',
}
