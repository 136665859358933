import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { useIsMountedRef } from '../../../common/hooks';
import { QUERY_GET_BOOKMARK_IDS } from '../../../common/queries';

type ReturnType = [Array<number>, () => void];

const useBookmarkIds = (): ReturnType => {
    const isMountedRef = useIsMountedRef();
    const [state, setState] = useState<Array<number>>([]);
    const [getBookmarks, { data }] = useLazyQuery(QUERY_GET_BOOKMARK_IDS);

    useEffect(() => {
        if (isMountedRef.current) {
            getBookmarks();
        }
        if (data) {
            setState(data.bookmarkIds);
        }
    }, [data, getBookmarks, isMountedRef]);

    return [state, getBookmarks];
};

export default useBookmarkIds;
