import React from 'react';
import intl from 'react-intl-universal';
import { FormGroup, Checkbox, TextField, ListItem, ListItemSecondaryAction, Chip, List, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { connectRefinementList } from 'react-instantsearch-dom';
import { RefinementListProvided } from 'react-instantsearch-core';

import { replaceAgencyToDealer, replaceUnderscoreAndLowercase, scrollToTop } from '../../helpers';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    label: {
        ...theme.typography.body2,
        color: theme.palette.text.primary,
        textTransform: 'capitalize'
    },
    textField: {
        ...theme.typography.body2,
        '& .MuiFormHelperText-root': {
            ...theme.typography.subtitle1
        }
    },
    checkbox: {
        // [theme.breakpoints.down('sm')]: {
        //     '&:hover': {
        //         backgroundColor: 'transparent'
        //     }
        // }
    }
}));

type Props = RefinementListProvided & {
    searchable?: boolean;
    scrollToId?: string;
}

const RefinementList = ({ items, refine, searchForItems, searchable = false, scrollToId }: Props): JSX.Element => {
    const classes = useStyles();

    const searchPlaceholderText = intl.get('generic.placeholder.searchForOther').d('Search for other...');
    const noResultsText = intl.get('generic.search.noResults').d('No Results');

    return (
        <FormGroup className={classes.root}>
            {
                searchable && (
                    <TextField
                        className={classes.textField}
                        type="search"
                        size="small"
                        fullWidth
                        variant="outlined"
                        placeholder={searchPlaceholderText}
                        onChange={(e) => searchForItems(e.currentTarget.value)}
                        color="secondary"
                    />
                )
            }
            <List>
                {
                    (items.length && items.length > 0) ? items.map(item => {
                        const correctedLabel = replaceUnderscoreAndLowercase(replaceAgencyToDealer(item.label));
                        const labelId = `filter-list-refinement-label-${item.value}`;
                        return (
                            <ListItem
                                key={item.label}
                                dense
                                button
                                disableRipple
                                onClick={(event) => {
                                    event.preventDefault();
                                    refine(item.value);
                                    if (scrollToId) {
                                        scrollToTop(scrollToId);
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        className={classes.checkbox}
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        checked={item.isRefined}
                                        color="secondary"
                                        inputProps={{ 'aria-labelledby': labelId }}

                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={labelId}
                                    className={classes.label}
                                    primary={correctedLabel}
                                    primaryTypographyProps={{ fontWeight: item.isRefined ? 'bold' : 'inherit' }}
                                />
                                <ListItemSecondaryAction>
                                    <Chip size="small" label={item.count} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    }) : (
                        <ListItem>
                            <ListItemText primary={noResultsText} />
                        </ListItem>
                    )
                }
            </List>
        </FormGroup>
    );
};

export default React.memo(connectRefinementList(RefinementList));
