import React, { ReactNode } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { QUERY_IS_LOGGED_IN } from '../../queries';
import { IsLoggedInDataType } from '../../types';
import AnimatedLoader from '../../components/AnimatedLoader';

interface Props extends RouteProps {
    children: ReactNode
}

// A wrapper for <Route> that redirects to the last link visited
// if you're not yet authenticated.
const PrivateRoute = ({ children, ...rest }: Props): JSX.Element => {
    const history = useHistory();
    const { loading, data } = useQuery<IsLoggedInDataType>(QUERY_IS_LOGGED_IN);

    if (loading) {
        return <AnimatedLoader name="PrivateRoute" />;
    }

    const isLoggedIn = data && data.isLoggedIn;

    if (!isLoggedIn) {
        history.goBack();
    }

    return (
        <Route {...rest}>
            {children}
        </Route>
    );
};

export default PrivateRoute;
