import React, { useEffect, useState, ReactElement } from 'react';
import intl from 'react-intl-universal';
import { Box, Grid, Container, Typography, Divider, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

import Dialog from '../../components/Dialog';
import SubmitButton from '../../../common/components/Button';
import SavedCard from '../../../common/components/SavedCard';

import { useIsMountedRef } from '../../../common/hooks';
import { SavedCardType } from '../../../common/types';
import { getCustomerSavedCardDetails } from '../../../common/request';
import { BRAND, PAYMENT_PROVIDER } from '../../../common/constants';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
    description: {
        [theme.breakpoints.down('md')]: {
            fontSize: 12
        }
    },
    cardContainer: {
        '& .sq-card-wrapper': {
            [theme.breakpoints.down('md')]: {
                minWidth: 'unset'
            }
        }
    },
    error: {
        color: theme.palette.error.main
    }
}));

interface Props {
    onSuccess?: () => void;
    onFailure?: () => void;
    onClose: () => void;
}

const ManageStripePaymentMethodsDialog = ({ onSuccess, onFailure, onClose }: Props): ReactElement => {
    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const isMountedRef = useIsMountedRef();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined | null>(null);
    const [savedCard, setSavedCard] = useState<SavedCardType | null | undefined>(null);

    // Try to fetch any saved card details from the customer profile
    // This function will also create the customer if
    // there isn't any associated profile with the user
    const fetchSavedCardDetails = () => {
        getCustomerSavedCardDetails({ paymentProvider: PAYMENT_PROVIDER.STRIPE, brand: BRAND })
            .then((cardRes) => {
                // Message is only the card details if code is 200
                const { code, message } = cardRes;
                if (code === 200 && message) {
                    setSavedCard({
                        cardBrand: (message as SavedCardType).cardBrand,
                        last4: (message as SavedCardType).last4
                    });
                }
            }).catch(() => {
                setSavedCard(null);
            });
    };

    useEffect(() => {
        if (isMountedRef.current) {
            // Try to fetch any saved card details from the customer profile
            fetchSavedCardDetails();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMountedRef]);

    const handleSave = async(event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setSubmitting(true);

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            setSubmitting(false);
            return;
        }

        const { error } = await stripe.confirmSetup({
            // `Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: window.location.href,
            },
            redirect: 'if_required'
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
            onFailure && onFailure();
        } else {
            // The customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            onSuccess && onSuccess();
        }

        setSubmitting(false);
    };

    const title = intl.get('dashboard.managePaymentMethods.dialog.title').d('Manage');
    const descriptionText = intl.get('dashboard.managePaymentMethods.dialog.description').d('Saving new card details will replace your current card on file.');
    const submitText = intl.get('dashboard.managePaymentMethods.button.dialog.save').d('Save Card');

    return (
        <Dialog onClose={onClose} title={title} fullWidth processing={submitting} fullScreen={isMobile}>
            <Box sx={{ p: 1, textAlign: 'center' }}>
                <Box sx={{ m: 1 }}>
                    <Container>
                        <Grid container spacing={1}>
                            {
                                savedCard && (
                                    <>
                                        <Grid item xs={12}>
                                            <SavedCard card={savedCard} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </>
                                )
                            }
                            <Grid item xs={12}>
                                <PaymentElement />
                                {/* Show error message to your customers */}
                                {errorMessage && <Box className={classes.error}>{errorMessage}</Box>}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.description} variant="body1">{descriptionText}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <SubmitButton
                                    id="form-managePaymentMethods-button-submit"
                                    onClick={handleSave}
                                    type="button"
                                    fullWidth
                                    text={submitText}
                                    disabled={submitting}
                                    color="secondary"
                                    className={classes.submit}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </Dialog>
    );
};

export default React.memo(ManageStripePaymentMethodsDialog);
