import { BRAND, PAYMENT_PROVIDER } from '../../constants';
import { PaymentConfigurationType } from '../../types';

const brand = BRAND;

const defaultValues: PaymentConfigurationType = {
    id: '0',
    brand,
    active: true,
    provider: PAYMENT_PROVIDER.SQUARE,
};

export default defaultValues;
