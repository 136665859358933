import React from 'react';
import { connectHits } from 'react-instantsearch-dom';

const Hits = ({ children, hits }) => (
    <>
        { children(hits) }
    </>
);

export default connectHits(Hits);
