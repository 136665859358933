import React from 'react';
// import intl from 'react-intl-universal';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    DialogTitle,
    DialogContent,
    Dialog as MuiDialog,
    LinearProgress,
    Typography,
    IconButton
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const StyledDialog = withStyles({
    paper: {
        borderRadius: 0,
    },
})(MuiDialog);

const useStyles = makeStyles(theme => ({
    title: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        textAlign: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: theme.palette.secondary.contrastText,
    },
}));

interface Props {
    title: string;
    children: React.ReactNode;
    onClose: () => void;
    processing?: boolean;
    fullWidth?: boolean;
    fullScreen?: boolean;
}

const Dialog = ({ processing, fullWidth, title, children, onClose, fullScreen }: Props): JSX.Element => {
    const classes = useStyles();

    return (
        <StyledDialog open={true} onClose={onClose} fullWidth={!!fullWidth} fullScreen={!!fullScreen}>
            <DialogTitle disableTypography className={classes.title}>
                <Typography variant="h3">{title}</Typography>
                {
                    onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null
                }
            </DialogTitle>
            {processing && <LinearProgress color="primary" />}
            <DialogContent>
                {children}
            </DialogContent>
        </StyledDialog>
    );
};

// Default export is the Dialog component
export default Dialog;
