import * as yup from 'yup';
import intl from 'react-intl-universal';

export const schema = yup.object().shape({
    name: yup.string()
        .required(
            intl.get('common.form.contactUsPartner.input.name.error.required').d('Please enter in your name')
        ),
    email: yup.string()
        .email(
            intl.get('common.form.contactUsPartner.input.email.error.invalid').d('Please enter in a valid email')
        )
        .required(
            intl.get('common.form.contactUsPartner.input.email.error.required').d('Please enter in your email')
        ),
    phoneNumber: yup.string()
        .required(
            intl.get('common.form.contactUsPartner.input.phoneNumber.error.required').d('Please enter in your phone number')
        ),
    businessName: yup.string()
        .required(
            intl.get('common.form.contactUsPartner.input.businessName.error.required').d('Please enter in your business name')
        )
});
