import React, { ReactNode } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
    children: ReactNode;
    isActive: boolean;
}

// A wrapper for <Route> that redirects to the last link visited
// if the current route isn't active.
const ActiveRoute = ({ isActive, children, ...rest }: Props): JSX.Element => (
    <Route
        {...rest}
        render={({ location }) =>
            (isActive ? (
                children
            ) : (
                <Redirect
                    to={{
                        pathname: location.pathname,
                    }}
                />
            ))
        }
    />
);

export default ActiveRoute;
