import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Box, Button, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import Breadcrumbs from '../../../common/components/Breadcrumbs';
import PageTitle from '../../../common/components/PageTitle';
import AuthenticationDialog from '../../../common/containers/AuthenticationDialog';
import { CheckpointIsAuthenticated } from '../../../common/components/checkpoints';

import { useLoggedIn } from '../../../common/hooks';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
    },
    button: {
        ...theme.typography.button,
        borderRadius: 0,
        [theme.breakpoints.down('md')]: {
            fontSize: ({ isLoggedIn }: { isLoggedIn: boolean }) => (isLoggedIn ? '0.60rem' : '0.75rem'),
        }
    },
    divider: {
        height: '2px',
        backgroundColor: theme.palette.divider,
        borderBottomWidth: 'inherit'
    }
}));

type VoidFunctionType = () => void;

const DirectoryHeader = (): JSX.Element => {
    const location = useLocation();
    const history = useHistory();
    const [isLoggedIn] = useLoggedIn();
    const { pathname } = location;
    const classes = useStyles({ isLoggedIn });

    // Wether or not the dialog is currently open
    const [loginDialogOpen, setLoginDialogOpen] = useState(false);
    // Login dialog callback function - executed when the user logs in
    const [loginCallback, setLoginCallback] = useState<VoidFunctionType | undefined>(undefined);

    // Callbacks which navigate to the desired page upon logging in
    const navigateToBookmarks = () => {
        history.push('/directory/bookmarks');
    };
    const navigateToAdd = () => {
        history.push('/directory/add');
    };

    // Events called when clicking options which require the user to be logged in first
    const handleBookmarkNav = (event: any) => {
        // If passing a function to a 'setState' method it is executed
        // and its returned value is what is set, so since we want to
        // set a callback, we need to use a function to return it.
        setLoginCallback(() => navigateToBookmarks);
        // Open the login dialog
        setLoginDialogOpen(true);
    };
    const handleAddNav = (event: any) => {
        // If passing a function to a 'setState' method it is executed
        // and its returned value is what is set, so since we want to
        // set a callback, we need to use a function to return it.
        setLoginCallback(() => navigateToAdd);
        // Open the login dialog
        setLoginDialogOpen(true);
    };

    // Header Button Elements
    const headerButtonElements = (
        <Grid container spacing={1} justifyContent="flex-end">
            {!isLoggedIn && (
                <>
                    <Grid item>
                        <Button color="secondary" variant="contained" className={classes.button} onClick={handleBookmarkNav}>Favourites</Button>
                    </Grid>
                    <Grid item>
                        <Button color="secondary" variant="contained" className={classes.button} onClick={handleAddNav}>List With Us</Button>
                    </Grid>
                </>
            )}
            {isLoggedIn && (
                <>
                    <Grid item>
                        <CheckpointIsAuthenticated>
                            <Link to="/directory/favourites" style={{ textDecoration: 'none' }}>
                                <Button color="secondary" variant="contained" className={classes.button}>Favourites</Button>
                            </Link>
                        </CheckpointIsAuthenticated>
                    </Grid>
                    <Grid item>
                        <Link to="/directory/add" style={{ textDecoration: 'none' }}>
                            <Button color="secondary" variant="contained" className={classes.button}>Add</Button>
                        </Link>
                    </Grid>
                    <Grid item>
                        <CheckpointIsAuthenticated>
                            <Link to="/directory/update" style={{ textDecoration: 'none' }}>
                                <Button color="secondary" variant="contained" className={classes.button}>Update</Button>
                            </Link>
                        </CheckpointIsAuthenticated>
                    </Grid>
                </>
            )}
        </Grid>
    );
    // Whether or not to show the header buttons
    let headerButtons = true;

    // The Page Title's label
    let label = 'Directory';
    if (pathname.startsWith('/directory/favourites')) {
        label = 'Directory Favourites';
        headerButtons = false;
    } else if (pathname.startsWith('/directory/add')) {
        label = 'List With us';
        headerButtons = false;
    } else if (pathname.startsWith('/directory/update')) {
        label = 'Update Directory';
        headerButtons = false;
    } else {
        const categoryMatch = pathname.match(/\/directory\/category\/(\w+)/);
        if (categoryMatch) {
            const category = categoryMatch[1].toUpperCase();
            const categoryLabel = intl.get(`directory.category.${category}`).d(category);
            label = `Directory - ${categoryLabel}`;
        }
    }

    return (
        <Box className={classes.root}>
            <Box sx={{ maxWidth: '95%', margin: 'auto', mb: 2 }}>
                {/* <Breadcrumbs /> */}
                <Box sx={{ mt: 2 }}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={4} sm={headerButtons ? 6 : 12}>
                            <PageTitle label={label} />
                        </Grid>
                        {headerButtons && (
                            <Grid item xs={8} sm={6}>
                                {headerButtonElements}
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>
            <Divider className={classes.divider} variant="fullWidth" />
            {loginDialogOpen && <AuthenticationDialog action="login" onClose={() => setLoginDialogOpen(false)} onLogin={loginCallback} />}
        </Box>
    );
};

export default DirectoryHeader;
