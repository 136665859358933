import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useMutation } from '@apollo/client';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, TextField, Select, Box, FormControl, InputLabel, MenuItem, Typography } from '@material-ui/core';

import CustomDialog from '../../../common/components/CustomDialog';

import { MUTATION_COMMENT_REPORT_ABUSE } from '../../mutations';
import { useNotification } from '../../../common/hooks';
import { CMS_CONTENT_TYPE_REF, COMMENT_REPORT_REASON } from '../../../common/constants';
import { ReportAbuseCommentDataType, ReportAbuseCommentVariableType } from '../../types';

const useStyles = makeStyles(theme => ({
    box: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic gap
        '& > * + *': {
            margin: theme.spacing(1, 0, 0, 0)
        },
    },
    formControl: {
        minWidth: 120,
    },
    item: {

    }
}));

interface Props {
    commentId: string;
    auctionId: string;
    onClose: () => void;
}


const ReportCommentDialog = ({ auctionId, commentId, onClose }: Props): JSX.Element => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const notify = useNotification();
    const [content, setContent] = useState<string>('');
    const [reason, setReason] = useState<COMMENT_REPORT_REASON>(COMMENT_REPORT_REASON.BAD_LANGAUGE);

    const [reportComment, { loading }] = useMutation<ReportAbuseCommentDataType, ReportAbuseCommentVariableType>(MUTATION_COMMENT_REPORT_ABUSE, {
        onCompleted({ commentReportAbuse }) {
            if (commentReportAbuse) {
                const message = intl.get('auction.comment.report.success').d('A report as been made. It will be looked into shortly.');
                notify({ message, severity: 'success' });
                onClose();
            } else {
                const message = intl.get('auction.comment.report.failure').d('Failed to report. Please try again.');
                notify({ message, severity: 'error' });
            }
        },
        onError(errors) {
            const { graphQLErrors } = errors;
            if (graphQLErrors && graphQLErrors.length > 0) {
                const [error] = graphQLErrors;
                const ext = error.extensions;

                if (ext) {
                    const { exception } = ext;
                    const { data } = exception;
                    const { id } = data;

                    const message = intl.get('auction.comment.report.error', { id }).d(`Error - '${id}' has occured.`);
                    notify({ message, severity: 'error' });
                }
            }
        }
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContent(event.target.value);
    };

    const handleReasonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setReason(event.target.value as COMMENT_REPORT_REASON);
    };

    const handleClose = () => {
        onClose();
    };

    const handleAction = () => {
        reportComment({
            variables: {
                id: commentId,
                relation: `${CMS_CONTENT_TYPE_REF.AUCTION}:${auctionId}`,
                reason,
                content
            }
        });
    };

    const titleText = intl.get('auction.reportCommentDialog.title').d('Report Comment');
    const placeholderText = intl.get('auction.reportCommentDialog.placeholder').d('Please explain...');
    const submitText = intl.get('auction.reportCommentDialog.button.action').d('Submit');
    const reasonText = intl.get('auction.reportCommentDialog.dropdown.reason').d('Reason');

    return (
        <>
            <CustomDialog
                open={true}
                title={titleText}
                children={
                    <Box className={classes.box}>
                        <FormControl className={classes.formControl} required>
                            <InputLabel id="reason-select-label">{reasonText}</InputLabel>
                            <Select
                                fullWidth
                                required
                                variant="standard"
                                labelId="reason-select-label"
                                id="reason-select"
                                value={reason}
                                onChange={handleReasonChange}
                            >
                                {
                                    Object.keys(COMMENT_REPORT_REASON)
                                        .map((key) => <MenuItem key={key} value={COMMENT_REPORT_REASON[key]}><Typography className={classes.item} variant="body1"> {COMMENT_REPORT_REASON[key].replaceAll('_', ' ') }</Typography></MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        <TextField
                            autoFocus
                            required
                            color="secondary"
                            fullWidth
                            placeholder={placeholderText}
                            multiline
                            rows={10}
                            value={content}
                            onChange={handleChange}
                        />
                    </Box>
                }
                actionText={submitText}
                onAction={handleAction}
                onClose={handleClose}
                fullScreen={isMobile}
                fullWidth={!isMobile}
                actionDisabled={loading}
                maxWidth="md"
            />
        </>
    );
};

export default ReportCommentDialog;
