import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PaymentButton from '../../../components/Button';
import CheckboxField from '../../../components/fields/Checkbox';
import SavedCardPaymentButton from '../../../containers/SavedCardPaymentButton';
import { FieldCheckbox } from '../../../components/fields';

import { schema } from './validation';
import { BRAND, PAYMENT_PROVIDER, PAYMENT_TYPE } from '../../../constants';
import { PaymentParamsBaseType, ConfirmPaymentReturnType, SavedCardType } from '../../../types';
import { confirmPayment, createPaymentIntent, getCustomerSavedCardDetails } from '../../../request';
import { displayCurrency } from '../../../helpers';
import { useNotification, usePaymentConfigurationContext } from '../../../hooks';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
    errorBox: {
        marginTop: theme.spacing(1),
        color: theme.palette.primary.main,
    },
    link: {
        fontWeight: 'bold'
    }
}));

type InputsType = {
    // name: string;
    saveCard: boolean;
    hasAgreedToTerms: boolean;
}

type Props = PaymentParamsBaseType;
const _window: any = window;

const SquareForm = ({ itemId, itemType, amount: predefinedAmount, paymentProvider, pricingPackage, paymentType, promotion, onSuccess, onFailure, renderPaymentOptions }: Props): JSX.Element => {
    const classes = useStyles();
    const notify = useNotification();
    const { locationId, appId } = usePaymentConfigurationContext();

    // const [initialized, setInitialized] = useState<boolean>(false);
    const [processing, setProcessing] = useState(false);

    const [amount, setAmount] = useState<number>(0);
    const [formCurrency, setFormCurrency] = useState<string>('USD');

    const [card, setCard] = useState<any>(null); // need to tokenize this for submission

    // saved card state
    const [savedCard, setSavedCard] = useState<SavedCardType | null | undefined>(null);
    const [savedCardLoading, setSavedCardLoading] = useState<boolean>(false);
    const [useSavedCard, setUseSavedCard] = useState<boolean>(false);


    useEffect(() => {
        // Try to fetch any saved card details from the customer profile
        // This function will also create the customer if
        // there isn't any associated profile with the user
        setSavedCardLoading(true);
        getCustomerSavedCardDetails({ paymentProvider, brand: BRAND })
            .then((cardRes) => {
                const { message } = cardRes;
                if (message) {
                    setSavedCard({
                        cardBrand: (message as SavedCardType).cardBrand,
                        last4: (message as SavedCardType).last4
                    });
                }
            }).catch(() => {
                setSavedCard(null);
            }).finally(() => {
                setSavedCardLoading(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Create PaymentIntent or SetupIntent based on pricing package over Square API
        // to make sure it can't be tampered with in the client.
        // And fetch pricing details such as amount and currency
        if (!useSavedCard) {
            if (appId && locationId) {
                const payments = _window.Square.payments(appId, locationId);
                try {
                    // Instantiate a card object
                    payments.card().then((card) => {
                        // Attach the instantiated card
                        card.attach('#card-container');
                        // Store the card instance to tokenize for submission
                        setCard(card);
                    }).catch((err) => {
                        // console.log('Initializing Card failed', err);
                        const message = intl.get('common.form.square.errorInitializing').d('Something went wrong. Please try again.');
                        notify({ message, severity: 'error' });
                        window.console.log(err);
                    });
                } catch (e) {
                    // NOTE: if there is no saved card, I don't think we want to fire the failure event (should only be called on payment failure)
                    // And if we are you add some notification should probably just show 'no saved card details' in the dialog,
                    // instead of displaying an error toast notification

                    // console.error('Initializing Card failed', e);
                    // const message = intl.get('common.form.square.errorInitializing').d('Something went wrong. Please try again.');
                    // notify({ message, severity: 'error' });
                    // onFailure && onFailure();
                }

                const paymentIntentProps = {
                    itemId,
                    itemType,
                    amount: predefinedAmount,
                    packageId: pricingPackage?.id || '',
                    paymentType,
                    paymentProvider,
                    promotion,
                    brand: BRAND
                };

                createPaymentIntent(paymentIntentProps)
                    .then(({ amount, currency }) => {
                        if (amount) {
                            setAmount(amount ? amount : 0);
                            setFormCurrency(currency ? currency : 'USD');
                        } else {
                            const message = intl.get('common.form.elements.paymentIntent.clientSecret.error').d('Something went wrong. Please try again.');
                            notify({ message, severity: 'error' });
                        }
                    })
                    .catch((err) => {
                        const { message } = err;
                        // console.log('Payment Intent Failure', err);
                        notify({ message, severity: 'error' });
                        onFailure && onFailure();
                    });
                // setInitialized(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemId, itemType, predefinedAmount, paymentType, pricingPackage?.id, promotion, useSavedCard]);

    const defaultValues = {
        // name: '',
        saveCard: false
    };

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<InputsType>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const onSubmit = async(data: InputsType) => {
        setProcessing(true);

        let sourceId;
        let canPay = useSavedCard;

        if (!useSavedCard) {
            await card.tokenize().then(data => {
                sourceId = data.token;
                canPay = true;
            });
        }
        if (locationId && canPay) {
            const params = {
                itemId,
                itemType,
                amount: predefinedAmount,
                packageId: pricingPackage?.id || '',
                paymentType,
                paymentProvider,
                promotion,
                brand: BRAND,
                locationId,
                sourceId,
                // If a user has press save card and then switches over to card on file, we should not save the card...
                // Because if we do, we save a blank card, which causes an error
                saveCard: useSavedCard ? false : data.saveCard,
                useSavedCard
            };

            confirmPayment(params)
                .then((res: ConfirmPaymentReturnType) => {
                    setProcessing(false);
                    onSuccess({ method: PAYMENT_PROVIDER.SQUARE, ...res });
                })
                .catch((err) => {
                    const { message } = err;
                    notify({ message, severity: 'error' });
                    setProcessing(false);
                });
        } else {
            setProcessing(false);
        }
    };

    let paymentTypeStr = '';

    if (paymentType === PAYMENT_TYPE.MONTHLY_PAYMENT || paymentType === PAYMENT_TYPE.YEARLY_PAYMENT) {
        paymentTypeStr = paymentType.replace('ly', '').replace(/^./, (firstLetter) => firstLetter.toUpperCase());
    }

    // Format the amount, example result "$9.99 USD"
    const price = displayCurrency(amount, formCurrency);
    // Adds Monthly or Yearly, example result: "$9.99 USD / Monthly"
    // TODO: Dup Code needs refactoring
    const priceLbl = paymentTypeStr ? `${price} / ${paymentTypeStr}` : price;

    const isHold = paymentType === PAYMENT_TYPE.HOLD_PAYMENT;

    // Get the label displayed to the user, example result: "Current Payment: $9.99 USD / Monthly"
    const prefix = `${isHold ? `Hold${useSavedCard ? ' with card' : ''}` : `Pay${useSavedCard ? ' with card' : ''}`}`;
    const paymentText = intl.get('common.form.elements.label.currentPayment', { prefix, amount: priceLbl }).d(`${isHold ? 'Hold' : 'Pay'} - ${priceLbl}`);

    // const nameText = intl.get('common.form.elements.input.name').d('Name on card');
    const saveCardText = intl.get('common.form.elements.input.saveCard').d('Save this card for future transactions');

    const agreementText = intl.getHTML('common.form.elements.checkbox.agreement').d(
        <span>
            I agree to the <a target="_blank" className={classes.link} href="/page/terms-and-conditions">Terms &amp; Conditions</a> and <a target="_blank" className={classes.link} href="/page/privacy-policy">Privacy Policy</a>
        </span>
    );


    return (
        <Box className="sr-payment-form">
            <Box className="sr-form-row" />
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} direction="column">
                    {
                        !!(renderPaymentOptions && renderPaymentOptions.length > 0) && (
                            <>
                                <Grid item xs={12}>
                                    {
                                        renderPaymentOptions.map(({ enabled, component, key }) => {
                                            if (enabled) {
                                                return (
                                                    <Box key={key} sx={{ mt: 1 }}>
                                                        { component }
                                                    </Box>
                                                );
                                            }
                                            return <></>;
                                        })
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </>
                        )
                    }
                    <Grid item xs={12}>
                        <SavedCardPaymentButton
                            card={savedCard}
                            loading={savedCardLoading}
                            setUseSavedCard={setUseSavedCard}
                        />
                    </Grid>
                    {
                        !useSavedCard && (
                            <>
                                {/* <Grid item xs={12}>
                                    <TextField
                                        id="form-elements-input-name"
                                        inputRef={register}
                                        variant="outlined"
                                        fullWidth
                                        label={nameText}
                                        name="name"
                                        autoComplete="cc-name"
                                        helperText={errors.name ? errors.name.message : ''}
                                        error={!!errors.name}
                                    />
                                </Grid> */}
                                <Grid item xs={12}>
                                    <div id="card-container"></div>
                                    <CheckboxField
                                        id="form-elements-checkbox-saveCard"
                                        name="saveCard"
                                        register={register}
                                        label={saveCardText}
                                    />
                                </Grid>
                            </>
                        )
                    }
                    {
                        isHold && (
                            <Grid item xs={12}>
                                <FieldCheckbox
                                    id="form-elements-checkbox-agreement"
                                    register={register}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    color="secondary"
                                    label={agreementText}
                                    name="hasAgreedToTerms"
                                    errorMessage={errors.hasAgreedToTerms?.message}
                                    error={!!(errors.hasAgreedToTerms)}
                                />
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <PaymentButton
                            id="form-elements-button-submit"
                            type="submit"
                            fullWidth
                            text={paymentText}
                            disabled={processing}
                            className={classes.submit}
                            color="secondary"
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default SquareForm;
