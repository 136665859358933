import React, { Suspense } from 'react';
import intl from 'react-intl-universal';
import { Switch, useHistory } from 'react-router-dom';
import { Box, Button, Grid, Hidden, Typography, Tooltip } from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useApolloClient } from '@apollo/client';

import PrivateRoute from '../../common/routes/PrivateRoute';
import Copyright from '../../common/components/Copyright';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import AnimatedLoader from '../../common/components/AnimatedLoader';
import Breadcrumbs from '../../common/components/Breadcrumbs';

import { useAuthenticatedUserContext, useBrandConfigurationContext } from '../../common/hooks';
import { getImageUrl, lazyWithRetry } from '../../common/helpers';
import { VARIATIONS } from '../../common/constants';
import { logout } from '../../common/request';

const HomePage = lazyWithRetry(() => import('../pages/Home'));
const AuctionPage = lazyWithRetry(() => import('../pages/Auction'));
const WebsitePage = lazyWithRetry(() => import('../pages/Website'));
const UserDataManagementPage = lazyWithRetry(() => import('../pages/UserDataManagement'));

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'stretch',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
    },
    top: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: '56px',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    left: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        width: '175px',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        display: 'flex',
        boxShadow: theme.shadows[4],
        zIndex: 1,
    },
    contentContainer: {
        position: 'relative',
        overflow: 'hidden',
        flexGrow: 1,
        marginLeft: '175px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginTop: '56px',
            marginBottom: '60px',
        }
    },
    content: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    bottom: {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        height: '60px',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        display: 'flex',
        justifyContent: 'center',
    },
    footer: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        textAlign: 'center',
        padding: 2
    },
    logoButton: {
        ...theme.typography.button,
        color: 'inherit'
    },
    navigationItem: {
        flexGrow: 1,
    },
}));

const CMSRoutes = (): JSX.Element => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const apolloClient = useApolloClient();
    const brandConfig = useBrandConfigurationContext();
    const { setUser } = useAuthenticatedUserContext();

    const { companyName, theme: configTheme } = brandConfig;
    const { dashboardLogo } = configTheme.images;
    const dashboardLogoUrl = getImageUrl(dashboardLogo.image, 'medium');

    const iconStyle = {
        color: theme.palette.text.primary,
    };

    if (configTheme.headerVariation === VARIATIONS.FILLED_SECONDARY) {
        iconStyle.color = theme.palette.secondary.contrastText;
    } else if (configTheme.headerVariation === VARIATIONS.FILLED_PRIMARY) {
        iconStyle.color = theme.palette.primary.contrastText;
    }

    const handleHome = () => {
        history.push('/');
    };

    const handleLogout = () => {
        logout(apolloClient);
        setUser && setUser(null);
        history.push('/');
    };

    const content = (
        <Suspense fallback={<AnimatedLoader />}>
            <Switch>
                <PrivateRoute exact path="/cms">
                    <HomePage />
                </PrivateRoute>
                <PrivateRoute path="/cms/users">
                    <UserDataManagementPage />
                </PrivateRoute>
                <PrivateRoute path="/cms/auction">
                    <AuctionPage />
                </PrivateRoute>
                <PrivateRoute path="/cms/website">
                    <WebsitePage />
                </PrivateRoute>
            </Switch>
        </Suspense>
    );

    // DESKTOP (Larger Screens)
    // Logo/Navigation/Actions - displayed on a left hand bar
    // Page contents - displayed on the right hand side

    // MOBILE (Smaller Screens)
    // Page Title/Logo - displayed at the top in a header
    // Page contents - displayed in the center of the screen
    // Navigation - displayed at the bottom in a footer

    // Render the top level routing component, which displays the
    // header/footer/navigation with the appropriate page content

    const logoutTooltipText = intl.get('dashboard.tooltip.logout').d('Logout');

    return (
        <Box className={classes.root}>
            {/* Top Header (only on Smaller Screens) */}
            <Hidden smUp>
                <Box className={classes.top}>
                    <Header />
                </Box>
            </Hidden>
            {/* Left Hand Side Navigation */}
            <Box className={classes.left}>
                <Grid container direction="column" justifyContent="space-between" wrap="nowrap" style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                    <Grid item>
                        <Box sx={{ m: 1, display: 'flex', justifyContent: 'center' }} >
                            <Button className={classes.logoButton} onClick={handleHome} fullWidth>
                                <img src={dashboardLogoUrl} alt="dashboard logo" style={{ maxHeight: '80px', maxWidth: '90%', pointerEvents: 'none' }} />
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item className={classes.navigationItem}>
                        <Navigation />
                    </Grid>
                    <Grid item>
                        <Box sx={{ m: 1, display: 'flex', justifyContent: 'center' }} >
                            {/* <ManageAccount anchorTopRight /> */}
                            <Tooltip enterTouchDelay={50} title={logoutTooltipText} placement="right">
                                <Button color="inherit" onClick={handleLogout} aria-label={logoutTooltipText}>
                                    <LogoutIcon />
                                    <Typography ml={2}>{logoutTooltipText}</Typography>
                                </Button>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/* Content (always visible) */}
            <Box className={classes.contentContainer}>
                <Box className={classes.content}>
                    {/* The Breadcrumbs */}
                    <Box pl={2} pr={2}>
                        <Breadcrumbs />
                    </Box>
                    {/* The page */}
                    <Box sx={{ flexGrow: 1 }}>
                        {content}
                    </Box>
                    {/* The footer */}
                    <Box className={classes.footer}>
                        <Copyright companyName={companyName} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CMSRoutes;
