import React, { Suspense } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Box, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import ProtectedRoute from './ProtectedRoute';
import AnimatedLoader from '../components/AnimatedLoader';
import ActiveRoute from './ActiveRoute';
import CMSRoutes from '../../cms/routes';
import DashboardRoutes from '../../dashboard/routes';
import PaddockRoutes from '../../paddock/routes';
import DirectoryRoutes from '../../directory/routes';
import MediaRoutes from '../../media/routes';
import AuctionRoutes from '../../auction/routes';
import SellMyVehicleFAB from '../components/SellMyVehicleFAB';
import BackToTopFAB from '../components/BackToTopFAB';
import ProviderRedirectPage from '../pages/ProviderRedirect';
import DealerServicesPage from '../pages/DealerServices';
import DealershipSolutionsPage from '../pages/DealershipSolutions';
import AuctionMarketplacePage from '../pages/AuctionMarketplace';

import { lazyWithRetry } from '../helpers';
import { useBrandConfigurationContext } from '../hooks';

const HeaderNav = lazyWithRetry(() => import('../containers/HeaderNav'));
const FooterNav = lazyWithRetry(() => import('../containers/FooterNav'));
const ConfirmationSuccessPage = lazyWithRetry(() => import('../pages/ConfirmationSuccess'));
const AboutUsPage = lazyWithRetry(() => import('../pages/AboutUs'));
const CustomHomePage = lazyWithRetry(() => import('../pages/CustomHome'));
const NotFoundPage = lazyWithRetry(() => import('../pages/NotFound'));
const ResetPasswordPage = lazyWithRetry(() => import('../pages/ResetPassword'));
const UniversalPage = lazyWithRetry(() => import('../pages/Universal'));
const PricingPage = lazyWithRetry(() => import('../pages/Pricing'));
const ContactUsPage = lazyWithRetry(() => import('../pages/ContactUs'));
const HowToSell = lazyWithRetry(() => import('../pages/HowToSell'));
const RacingEdgePartners = lazyWithRetry(() => import('../pages/RacingEdgePartners'));

const useStyles = makeStyles(() => ({
    content: {
        flex: '1 0 auto'
    }
}));

const useQueryParam = () => new URLSearchParams(useLocation().search);

const Routes = (): JSX.Element => {
    const classes = useStyles();
    const location = useLocation();
    const query = useQueryParam();
    const brandConfig = useBrandConfigurationContext();

    const isEmbedded = query.get('embedded');

    if (location.pathname.startsWith('/dashboard')) {
        return <DashboardRoutes />;
    }

    if (location.pathname.startsWith('/cms')) {
        return <CMSRoutes />;
    }

    const mappedRoutes = {
        paddock: PaddockRoutes,
        shop: PaddockRoutes,
        media: MediaRoutes,
        directory: DirectoryRoutes,
        auction: AuctionRoutes,
        // 'sim-racing': SimRacingRoutes,
    };

    const { headerNav, modules, homePage, theme } = brandConfig;
    let hasHome = true;
    let HomeComponent = AboutUsPage;
    let homeProps: any = {};
    const activeModuleRoutes: Array<React.ReactNode> = [];

    let FirstModuleRoute = null;
    hasHome = !!headerNav.find((header) => header.url === '/');

    // Check to see if we have any modules in our configuration
    if (modules && modules.length > 0) {
        // if we do, loop through them
        modules.forEach(module => {
            // Try to get the Routes Component from the map, if the module exists
            const ModuleRoutesComponent = mappedRoutes[module.name];
            // If the Routes doesn't exist, skip this iteration
            if (!ModuleRoutesComponent) {
                return;
            }
            // Check to see if we have the first Module Route component assigned
            if (!FirstModuleRoute) {
                // If we don't assing our current Module Route component to it.
                FirstModuleRoute = ModuleRoutesComponent;
            }

            // Build our Route JSX
            const routeJSX = (
                <ActiveRoute key={module.name} path={`/${module.name}`} isActive={module.active}>
                    <ModuleRoutesComponent />
                </ActiveRoute>
            );

            // Push it to our array
            activeModuleRoutes.push(routeJSX);
        });
    }

    let homePageModule: string | null = null;
    let homePageQueryParams: string | null = null;

    // Check if the configuration provided a home page
    if (homePage) {
        // assign the current homepage variable to another variable
        homePageModule = homePage;

        // Look for any query params. Basically anything after ?
        // eslint-disable-next-line no-useless-escape
        const queryParams = homePage.match(/[^\?]*$/);
        // if we find any params
        if (queryParams) {
            // assign the params
            // eslint-disable-next-line prefer-destructuring
            homePageQueryParams = queryParams[0];
        }
        // match first word in homepage string, we assume its the module
        const modFound = homePage.match(/\b[A-Za-z1-9-_]+\b/);
        if (queryParams && modFound) {
            // eslint-disable-next-line prefer-destructuring
            homePageModule = modFound[0];
        }

        // If the value matches a module, use that module
        const FoundModule = mappedRoutes[homePageModule];
        if (FoundModule) {
            HomeComponent = FoundModule;
        } else {
            // If the value does not match a module, assume it's a universal page slug
            HomeComponent = CustomHomePage;
            homeProps = { universalSlug: homePageModule };
        }

        if (queryParams) {
            homeProps.queryParams = homePageQueryParams;
        }
    } else if (!hasHome && FirstModuleRoute) {
        // If we don't have a home page setup
        // Use the first Module Route as our Home Component
        HomeComponent = FirstModuleRoute;
    }

    activeModuleRoutes.push(
        <Route key="home" path="/" exact>
            <HomeComponent {...homeProps } />
        </Route>
    );

    return (
        <Suspense fallback={<AnimatedLoader />}>
            {!isEmbedded && <HeaderNav />}
            <Box className={classes.content}>
                <Switch>
                    {/* All available active Routes for each module */}
                    {activeModuleRoutes}

                    <Route exact path="/pricing">
                        <PricingPage />
                    </Route>

                    <Route exact path="/how-to-sell">
                        <HowToSell />
                    </Route>
                    <Route exact path="/racingedge-partners">
                        <RacingEdgePartners />
                    </Route>

                    <Route exact path="/dealer-services">
                        <DealerServicesPage />
                    </Route>

                    <Route exact path="/dealer-services/dealership-solutions">
                        <DealershipSolutionsPage />
                    </Route>

                    <Route exact path="/dealer-services/auction-and-marketplace">
                        <AuctionMarketplacePage />
                    </Route>

                    <Route exact path="/about-us">
                        <AboutUsPage />
                    </Route>

                    {/* Catch provider login authentication redirect */}
                    <Route exact path="/auth/:provider/callback" render={route =>
                        <ProviderRedirectPage provider={route.match.params.provider} />
                    } />

                    <Route exact path="/confirmation/success">
                        <ConfirmationSuccessPage />
                    </Route>

                    <Route exact path="/reset-password">
                        <ResetPasswordPage />
                    </Route>

                    <Route exact path="/reset-password/:code" render={route =>
                        <ResetPasswordPage code={route.match.params.code} />
                    } />

                    {/* Contact us page */}
                    <Route exact path="/page/contact-us" render={route =>
                        <ContactUsPage />
                    } />

                    {/* Universal Pages */}
                    <Route exact path="/page/:slug" render={route =>
                        <UniversalPage slug={route.match.params.slug} />
                    } />

                    {/* Catch All: Page Not Found 404 */}
                    <Route path="*">
                        <NotFoundPage />
                    </Route>

                </Switch>
            </Box>
            {!isEmbedded && <FooterNav configTheme={theme} />}
            {!isEmbedded && (
                <Hidden mdUp>
                    <SellMyVehicleFAB />
                </Hidden>
            )
            }
            {!isEmbedded && <BackToTopFAB />}
        </Suspense>
    );
};

export default React.memo(Routes);
