import React, { createContext, useContext } from 'react';
import { useQuery } from '@apollo/client';

import QueryResult from '../../components/QueryResult';
import ErrorMessage from '../../components/ErrorMessage';
import AnimatedLoader from '../../components/AnimatedLoader';

import { PaymentConfigurationDataType, PaymentConfigurationType, PaymentVariablesType } from '../../types';
import { QUERY_PAYMENT_CONFIGURATION } from '../../queries';
import { BRAND } from '../../constants';
import defaultValues from './defaultValue';

interface Props {
    children: React.ReactNode;
}

const PaymentConfigurationContext = createContext<PaymentConfigurationType>(defaultValues);

export const PaymentConfigurationProvider = ({ children }: Props): JSX.Element => {
    // Fetch the brands Payment configuration
    const { data, loading, error } = useQuery<PaymentConfigurationDataType, PaymentVariablesType>(QUERY_PAYMENT_CONFIGURATION, {
        variables: {
            // if no brand is provided, use the Racing Edge Payment configuration instead
            brand: BRAND
        }
    });

    return (
        <QueryResult
            data={data}
            loading={loading}
            error={error}
            errorComponent={<ErrorMessage />}
            loadingComponent={<AnimatedLoader name="PaymentConfigurationProvider" />}
        >
            {
                ({ paymentConfigurationByBrand }) => (
                    <PaymentConfigurationContext.Provider value={paymentConfigurationByBrand}>
                        {children}
                    </PaymentConfigurationContext.Provider>
                )
            }
        </QueryResult>
    );
};

export const usePaymentConfigurationContext = (): PaymentConfigurationType => useContext(PaymentConfigurationContext);

export default React.memo(PaymentConfigurationProvider);
