import { createContext } from 'react';

import { SocketContextType } from '../../types';

const SocketContext = createContext<SocketContextType>({
    auction: undefined,
    comments: [],
    bids: [],
    topBid: 0.00,
    minIncrementAmount: 0.00,
    currency: 'USD'
});

export default SocketContext;
