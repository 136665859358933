import gql from 'graphql-tag';
import { DIRECTORY_PROPERTIES } from './constants';

const WHERE_CLAUSE = `
    where: {
        _or: [
            {addressCountry: $country},
            {addressState: $state},
            {addressCity: $city},
            {name_contains: $keywords},
            {keywords_contains: $keywords}
        ],
        category: $category
        featured: $featured
        status: "APPROVED"
    }
`;

export const DIRECTORIES_QUERY = gql`
    query findDirectories($start: Int!, $limit: Int!, $keywords: [String]!, $category: String!, $featured: Boolean!, $country: String!, $state: String!, $city: String!) {
        directories (start: $start, limit: $limit, ${WHERE_CLAUSE}) {
            ${DIRECTORY_PROPERTIES}
        }
        directoriesCount(${WHERE_CLAUSE})
    }
`;

export const MY_DIRECTORIES_QUERY = gql`
    query myDirectories($start: Int, $limit: Int) {
        myDirectories(start: $start, limit: $limit) {
            ${DIRECTORY_PROPERTIES}
        }
    }
`;

export const USER_DIRECTORIES_QUERY = gql`
    query getDirectories($id: ID!) {
        directories(where: {user: {id: $id}}) {
            ${DIRECTORY_PROPERTIES}
        }
    }
`;

export const BOOKMARKED_DIRECTORIES_QUERY = gql`
    query findDirectories($ids: [ID]!) {
        directories (where: { id_in: $ids }) {
            ${DIRECTORY_PROPERTIES}
        }
        directoriesCount(where: { id_in: $ids })
    }
`;

export const USER_DIRECTORY_BOOKMARKS_QUERY = gql`
    query getBookmarks($id: ID!) {
        directoryBookmarks(where: {user: {id: $id}}) {
            directory {
                id
            }
        }
    }
`;

export const DIRECTORY_PACKAGES_QUERY = gql`
    query PricingPackages($where: JSON) {
        pricingPackages(where: $where) {
            id
            title
            featured
            sellingPoints {
                text
            }
            oneTimePayment {
                price
                enabled
            }
            monthlyPayment {
                price
                enabled
            }
            yearlyPayment {
                price
                enabled
            }
        }
    }
`;

export const COUNTRY_STATE_QUERY = gql`
    query {
        countryState {
            data
        }
    }
`;

export const DIRECTORY_CONFIG_QUERY = gql`
    query config($brand: String!) {
        configurationByBrand(brand: $brand) {
            id
            directoryConfiguration {
                directoryInfo {
                    active
                    describes
                    content
                }
            }
        }
    }
`;
