import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';

import locales from '../../../../locales';
import { useIsMountedRef } from '../../hooks';

const LanguageLoader = (): JSX.Element => {
    const isMountedRef = useIsMountedRef();
    // Whether or not initialization has been completed (false by default)
    const [initLanguage, setInitLanguage] = useState(false);

    useEffect(() => {
        if (!initLanguage && isMountedRef.current) {
            // Get the current locale (provided by the store)
            const currentLocale = 'en-US';
            // Initialize INTL with the current language and map of locale data
            intl.init({
                currentLocale,
                locales,
            }).then(() => {
                // Language has been initialized.
                setInitLanguage(true);
            });
        }
    }, [initLanguage, isMountedRef]);

    return <></>;
};

export default React.memo(LanguageLoader);
