/* eslint-disable no-console */
// Apollo Client Setup

import { ApolloClient, gql } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { APOLLO_GRAPHQL_ERROR, JWT_TOKEN } from './modules/common/constants';
import { checkLocalStorage, runtimeAuth } from './modules/common/helpers';
import { cache } from './cache';
import { logout } from './modules/common/request';

export const typeDefs = gql`
    extend type Query {
        isLoggedIn: Boolean!
    }
`;

const httpLink = createUploadLink({
    uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
    credentials: 'same-origin'
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    let token: string | null = null;
    if (checkLocalStorage()) {
        token = localStorage.getItem(JWT_TOKEN);
    } else {
        token = runtimeAuth.getJwt();
    }

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        }
    };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            switch (message) {
                case APOLLO_GRAPHQL_ERROR.INVALID_TOKEN:
                case APOLLO_GRAPHQL_ERROR.USER_NOT_FOUND:
                    // Execute logout (clearing all stored user tokens)
                    logout(client);
                    // Reload the page (Now that there is no stored token, the issue will not occur)
                    window.location.reload();
                    break;
                case APOLLO_GRAPHQL_ERROR.FORBIDDEN:
                    break;
                default:
                    console.error(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                    );
            }
        });
    }

    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
    }
});

const client = new ApolloClient({
    link: authLink.concat(errorLink).concat(httpLink),
    cache,
    typeDefs
});

export default client;
