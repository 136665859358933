import React from 'react';
// import intl from 'react-intl-universal';

import LoginRequired from '../../components/LoginRequired';
import Bookmarks from '../../containers/Bookmarks';

import { useLoggedIn } from '../../../common/hooks';

const BookmarksPage = (): JSX.Element => {
    const [isLoggedIn] = useLoggedIn();

    return isLoggedIn ? <Bookmarks /> : <LoginRequired action="bookmarks" />;
};

export default BookmarksPage;
