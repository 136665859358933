import React, { ReactElement } from 'react';
import { FormControlLabel, Checkbox, FormControlProps, FormHelperText, FormControl } from '@material-ui/core';

interface Props extends FormControlProps {
    id: string;
    label: string | React.ReactNode;
    name: string;
    register: () => void;
    error?: boolean;
    errorMessage?: string;
}

const CheckboxField = ({ id, label, name, register, error, errorMessage, ...rest }: Props): ReactElement => (
    <FormControl error={error} {...rest} fullWidth>
        <FormControlLabel
            id={`${id}-label`}
            name={name}
            inputRef={register}
            label={label}
            control={<Checkbox id={`${id}-checkbox`} color="primary" />}
        />
        {error ? (<FormHelperText>{errorMessage}</FormHelperText>) : null}
    </FormControl>
);

export default CheckboxField;
