import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ScheduleIcon from '@material-ui/icons/Schedule';
import {
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
    Box,
    Grid,
    Typography,
} from '@material-ui/core';

import { DirectoryType } from '../../types';

const Accordion = withStyles({
    root: {
        background: 'transparent',
        boxShadow: 'none',
        '&$expanded': {
            margin: 0,
            padding: 0,
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
    root: {
        padding: 0,
        margin: 0,
        minHeight: 0,
        '&$expanded': {
            minHeight: 0,
        },
    },
    content: {
        margin: theme.spacing(1),
        '&$expanded': {
            margin: theme.spacing(1),
        },
    },
    expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles({
    root: {
        padding: 0,
    },
})(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    iconStyle: {
        color: theme.palette.text.primary,
    },
    weekDay: {
        fontWeight: 'bold',
    },
    weekend: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
}));

interface Props {
    directory: DirectoryType;
}

const HoursAccordion = ({ directory }: Props): JSX.Element => {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);

    const hoursOfOperation = directory.hoursOfOperation || {};

    const mon = intl.get('directory.card.operationHours.monday').d('Mon');
    const tue = intl.get('directory.card.operationHours.tuesday').d('Tue');
    const wed = intl.get('directory.card.operationHours.wednesday').d('Wed');
    const thu = intl.get('directory.card.operationHours.thursday').d('Thu');
    const fri = intl.get('directory.card.operationHours.friday').d('Fri');
    const sat = intl.get('directory.card.operationHours.saturday').d('Sat');
    const sun = intl.get('directory.card.operationHours.sunday').d('Sun');

    return (
        <Accordion square expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <ScheduleIcon className={classes.iconStyle} />
                    <ChevronRightIcon style={{ transition: 'all 0.2s', transform: `rotate(${expanded ? 270 : 90}deg)` }} />
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="body2"><span className={classes.weekDay}>{mon}</span> {hoursOfOperation.Mo || '-'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2"><span className={classes.weekDay}>{tue}</span> {hoursOfOperation.Tu || '-'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2"><span className={classes.weekDay}>{wed}</span> {hoursOfOperation.We || '-'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2"><span className={classes.weekDay}>{thu}</span> {hoursOfOperation.Th || '-'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2"><span className={classes.weekDay}>{fri}</span> {hoursOfOperation.Fr || '-'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2"><span className={classes.weekend}>{sat}</span> {hoursOfOperation.Sa || '-'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2"><span className={classes.weekend}>{sun}</span> {hoursOfOperation.Su || '-'}</Typography>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default HoursAccordion;
