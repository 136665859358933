import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useQuery } from '@apollo/client';

import QueryResult from '../../../common/components/QueryResult';
import CurrentBid from '../../components/CurrentBid';
import ReserveImage from './ReserveImage';
// import RoundedBox from '../../components/RoundedBox';
import TooltipIcon from '../../../paddock/components/TooltipIcon';

import { BrandAuctionConfigurationDataType } from '../../../cms/types';
import { QUERY_AUCTIONS_CONFIGURATIONS } from '../../../cms/queries';
import { BRAND } from '../../../common/constants';
import { BrandVariablesType } from '../../../common/types';
import { AuctionReservePercentageDataType, AuctionReservePercentageVariableType, AuctionType } from '../../types';
import { QUERY_AUCTION_RESERVE_PERCENTAGE } from '../../queries';

const useStyles = makeStyles(theme => ({
    border: {
        position: 'relative',
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '10px',
        borderTopLeftRadius: '40px',
        padding: theme.spacing(0, 1),
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1, 3)
        }
    },
    infoIcon: {
        width: 20,
        height: 20,
        '& svg': {
            width: 20,
            height: 20,
        }
    },
}));

interface Props {
    auction: AuctionType;
    topBid: number;
}

const ReserveMeter = ({ auction, topBid }: Props): JSX.Element => {
    const classes = useStyles();
    const {
        id,
        currency,
        startPrice,
        finalPrice,
        reserveMeterEnabled
    } = auction;

    const { data, loading, error } = useQuery<BrandAuctionConfigurationDataType, BrandVariablesType>(QUERY_AUCTIONS_CONFIGURATIONS, {
        variables: {
            brand: BRAND
        }
    });
    const { data: dataPercentage, loading: loadingPercentage, error: errorPercentage, refetch } = useQuery<AuctionReservePercentageDataType, AuctionReservePercentageVariableType>(QUERY_AUCTION_RESERVE_PERCENTAGE, {
        variables: {
            id
        }
    });

    useEffect(() => {
        refetch();
    }, [refetch, topBid]);

    const infoText = intl.get('auction.icon.reserveMeter.tooltip').d('The Reserve `O` Meter will fill up according to any bids placed. Once the meter is full, the reserve has been met!');

    return (
        <QueryResult loading={loading} data={data} error={error}>
            {
                ({ configurationByBrand }) => {
                    const { auctionConfiguration } = configurationByBrand;

                    if (auctionConfiguration) {
                        const { reserveMeter } = auctionConfiguration;
                        if (reserveMeter) {
                            const { enabled, fillColor } = reserveMeter;

                            // A flag to see if the Reserve O Meter can be shown for this auction
                            // global flag = true, auction flag = true === SHOW
                            // global flag = true, auction flag = false === SHOW
                            // global flag = false, auction flag = true === SHOW
                            // global flag = false, auction flag = false === HIDE
                            const showReserveMeter = enabled || reserveMeterEnabled;

                            if (showReserveMeter) {
                                return (
                                    // Fetch the percentage of the reserve meter to be filled up.
                                    <QueryResult data={dataPercentage} loading={loadingPercentage} error={errorPercentage}>
                                        {
                                            ({ findReservePercentage: percentage }) => (
                                                <>
                                                    <Box className={classes.border}>
                                                        <ReserveImage fillColor={fillColor} percentage={percentage}/>
                                                        <Box sx={{ position: 'absolute', top: '45%', right: '10%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <CurrentBid
                                                                border={false}
                                                                bid={topBid}
                                                                horizontal={true}
                                                                currency={currency}
                                                                startPrice={startPrice}
                                                                finalPrice={finalPrice}
                                                            />
                                                            <Box className={classes.infoIcon} sx={{ ml: 1 }}>
                                                                <TooltipIcon text={infoText} icon={InfoIcon} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </>
                                            )
                                        }
                                    </QueryResult>
                                );
                            }
                        }
                    }

                    return (
                        <CurrentBid
                            bid={topBid}
                            currency={currency}
                            startPrice={startPrice}
                            finalPrice={finalPrice}
                        />
                    );
                }
            }
        </QueryResult>
    );
};

export default ReserveMeter;
