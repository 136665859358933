/**
 * This is the highest-leveled component of the entire app,
 * this specific portion should be as small as possible to ensure
 * that the page is loaded as quickly as possible to begin showing
 * our preloader.
 */
import React, { ReactNode, Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';

import client from '../../../../apolloClient';

import AnimatedLoader from '../../components/AnimatedLoader';
import LanguageLoader from '../LanguageLoader';
import ExtraConfigurationWrapper from '../ExtraConfigurationWrapper';
import ThemeWrapper from '../ThemeWrapper';
import AuthenticatedUserProvider from '../../hooks/useAuthenticatedUserContext';
import BrandConfigurationProvider from '../../hooks/useBrandConfigurationContext';
import { PaymentConfigurationProvider } from '../../hooks/usePaymentConfigurationContext';

interface Props {
    children?: ReactNode;
}

const Initialization = ({ children }: Props): JSX.Element => (
    <Suspense fallback={<AnimatedLoader name="Initialization" />}>
        <ApolloProvider client={client}>
            <LanguageLoader />
            {/* Fetch the brand configuration object and store it in a context */}
            <BrandConfigurationProvider>
                {/* Fetch the correct payment configuration details to setup stripe or square for our app */}
                <PaymentConfigurationProvider>
                    {/* Instantiate our MUI theme with our brand theme data */}
                    <ThemeWrapper>
                        {/* Try to fetch and store user data into context */}
                        <AuthenticatedUserProvider>
                            <ExtraConfigurationWrapper>
                                { children }
                            </ExtraConfigurationWrapper>
                        </AuthenticatedUserProvider>
                    </ThemeWrapper>
                </PaymentConfigurationProvider>
            </BrandConfigurationProvider>
        </ApolloProvider>
    </Suspense>
);

export default React.memo(Initialization);
