import React from 'react';
import intl from 'react-intl-universal';
import { useForm } from 'react-hook-form';
import {
    Grid,
    TextField,
    Link,
    Box,
    Typography,
    Container,
    FormHelperText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useApolloClient, useMutation } from '@apollo/client';

// import Container from '../../../components/Container';
import RegisterButton from '../../../components/Button';
import CheckboxField from '../../../components/fields/Checkbox';
import LinkTerms from '../../../components/LinkTerms';

import { schema } from './validation';
import { AuthenticationActionFunctionType, BrandThemeType, BrandUserRegisterDataType, BrandUserRegisterVariablesType } from '../../../types';
import { MUTATION_REGISTER_USER } from '../../../mutations';
import { useNotification } from '../../../hooks';
import { BRAND, VARIATIONS } from '../../../constants';
import { login } from '../../../request';

const useStyle = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    link: {
        cursor: 'pointer',
        color: (configTheme: BrandThemeType) => {
            if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_SECONDARY) {
                return theme.palette.primary.main;
            } else if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_PRIMARY) {
                return theme.palette.secondary.main;
            }
            return theme.palette.primary.main;
        },
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        paddingBottom: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
    bar: {
        marginTop: theme.spacing(2),
        width: '70%',
        height: '4px',
        backgroundColor: theme.palette.divider,
    },
}));

type InputsType = {
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    username: string;
    acceptTerms: boolean;
    subscribe: boolean;
}

interface Props {
    configTheme: BrandThemeType;
    onRegister?: () => void;
    switchAction: AuthenticationActionFunctionType;
    title?: string;
    subtitle?: string;
}

const Register = ({ configTheme, onRegister, title, subtitle, switchAction }: Props): JSX.Element => {
    const classes = useStyle(configTheme);
    const apolloClient = useApolloClient();
    const notify = useNotification();

    const { loginVariation } = configTheme;

    const inheritStyle = {
        backgroundColor: 'inherit',
        color: 'inherit',
    };

    const defaultValues = {
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        username: '',
        acceptTerms: false,
        subscribe: false
    };

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<InputsType>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const [registerUser, { loading }] = useMutation<BrandUserRegisterDataType, BrandUserRegisterVariablesType>(MUTATION_REGISTER_USER, {
        onCompleted({ registerUser }) {
            if (registerUser) {
                const { jwt, user } = registerUser;
                // no confirm email needed, user is logged in
                if (jwt && jwt.length > 0 && user) {
                    login(jwt, user.id, apolloClient);
                } else {
                    // Password has bad formatting
                    const message = intl.get('common.form.register.info.confirmAfterRegistering').d('Registration successful, you must confirm your email before logging in.');
                    notify({ message, severity: 'success' });
                }
            }
            // Call the register callback if its defined
            onRegister && onRegister();
        },
        onError(errors) {
            const { graphQLErrors } = errors;
            if (graphQLErrors.length > 0) {
                const [error] = graphQLErrors;
                const ext = error.extensions;

                if (ext) {
                    const { exception } = ext;
                    const { data } = exception;
                    const { id } = data;

                    if (id === 'Auth.form.error.password.format') {
                        // Password has bad formatting
                        const message = intl.get('common.form.register.error.passwordFormat').d('Your password cannot contain more than three times the symbol `$`.');
                        notify({ message, severity: 'error' });
                    } else if (id === 'Auth.advanced.allow_register') {
                        // Registration is disabled
                        const message = intl.get('common.form.register.error.allowRegister').d('Register action is currently disabled.');
                        notify({ message, severity: 'warning' });
                    } else if (id === 'Auth.form.error.password.provide') {
                        // No Password provided
                        const message = intl.get('common.form.register.error.noPassword').d('Please provide your password.');
                        notify({ message, severity: 'error' });
                    } else if (id === 'Auth.form.error.email.provide') {
                        // No Email provided
                        const message = intl.get('common.form.register.error.noEmail').d('Please provide your email.');
                        notify({ message, severity: 'error' });
                    } else if (id === 'Auth.form.error.role.notFound') {
                        // No Default role found to assign to user
                        const message = intl.get('common.form.register.error.noDefaultRole').d('Impossible to find the default role.');
                        notify({ message, severity: 'error' });
                    } else if (id === 'Auth.form.error.email.format') {
                        // Invalid Email
                        const message = intl.get('common.form.register.error.invalidEmail').d('Please provide valid email address.');
                        notify({ message, severity: 'error' });
                    } else if (id === 'Auth.form.error.email.taken') {
                        // Email Taken already
                        const message = intl.get('common.form.register.error.emailTaken').d('Email is already taken.');
                        notify({ message, severity: 'error' });
                    }
                }
            }
        }
    });

    const onSubmit = (data: InputsType) => {
        registerUser({
            variables: {
                email: data.email,
                password: data.password,
                username: data.username,
                firstName: data.firstName,
                lastName: data.lastName,
                brand: BRAND
            }
        });
    };

    const submitText = intl.get('common.form.register.button.submit').d('Register');
    const signInText = intl.get('common.form.register.link.signIn').d('Already have an account?');
    const forgotPassText = intl.get('common.form.register.link.forgotPassword').d('Forgot your password?');

    const emailText = intl.get('common.form.register.input.email').d('Email Address');
    const passwordText = intl.get('common.form.register.input.password').d('Password');
    const confirmPasswordText = intl.get('common.form.register.input.confirmPassword').d('Confirm Password');
    const usernameText = intl.get('common.form.register.input.username').d('Display Name');
    const firstNameText = intl.get('common.form.register.input.firstName').d('First Name');
    const lastNameText = intl.get('common.form.register.input.lastName').d('Last Name');

    const subscribeText = intl.get('common.form.register.checkbox.subscribe').d('Subscribe to our newsletter');

    return (
        <>
            <Box sx={{ mb: 3, textAlign: 'center' }}>
                {
                    title && (
                        <Box sx={{ mb: 1, display: 'inline-block' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="h1">{title}</Typography>
                                <Box className={classes.bar}></Box>
                            </Box>
                        </Box>
                    )
                }
                {
                    subtitle && subtitle.length > 0 && (
                        <Typography variant="subtitle1">{subtitle}</Typography>
                    )
                }
            </Box>
            <Container maxWidth="xs">
                <div className={classes.paper}>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TextField
                                    id="form-register-input-firstName"
                                    inputRef={register}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={firstNameText}
                                    name="firstName"
                                    autoComplete="given-name"
                                    autoFocus
                                    helperText={errors.firstName ? errors.firstName.message : ''}
                                    error={!!errors.firstName}
                                    inputProps={{ style: inheritStyle }}
                                    InputProps={{ style: inheritStyle }}
                                    InputLabelProps={{ style: inheritStyle }}
                                    FormHelperTextProps={{ style: inheritStyle }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="form-register-input-lastName"
                                    inputRef={register}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={lastNameText}
                                    name="lastName"
                                    autoComplete="family-name"
                                    helperText={errors.lastName ? errors.lastName.message : ''}
                                    error={!!errors.lastName}
                                    inputProps={{ style: inheritStyle }}
                                    InputProps={{ style: inheritStyle }}
                                    InputLabelProps={{ style: inheritStyle }}
                                    FormHelperTextProps={{ style: inheritStyle }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="form-register-input-username"
                                    inputRef={register}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={usernameText}
                                    name="username"
                                    autoComplete="username"
                                    helperText={errors.username ? errors.username.message : ''}
                                    error={!!errors.username}
                                    inputProps={{ style: inheritStyle }}
                                    InputProps={{ style: inheritStyle }}
                                    InputLabelProps={{ style: inheritStyle }}
                                    FormHelperTextProps={{ style: inheritStyle }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="form-register-input-email"
                                    inputRef={register}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={emailText}
                                    name="email"
                                    autoComplete="email"
                                    helperText={errors.email ? errors.email.message : ''}
                                    error={!!errors.email}
                                    inputProps={{ style: inheritStyle }}
                                    InputProps={{ style: inheritStyle }}
                                    InputLabelProps={{ style: inheritStyle }}
                                    FormHelperTextProps={{ style: inheritStyle }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="form-register-input-password"
                                    inputRef={register}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={passwordText}
                                    type="password"
                                    autoComplete="new-password"
                                    helperText={errors.password ? errors.password.message : ''}
                                    error={!!errors.password}
                                    inputProps={{ style: inheritStyle }}
                                    InputProps={{ style: inheritStyle }}
                                    InputLabelProps={{ style: inheritStyle }}
                                    FormHelperTextProps={{ style: inheritStyle }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="form-register-input-password-confirm"
                                    inputRef={register}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label={confirmPasswordText}
                                    type="password"
                                    autoComplete="new-password"
                                    helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                                    error={!!errors.confirmPassword}
                                    inputProps={{ style: inheritStyle }}
                                    InputProps={{ style: inheritStyle }}
                                    InputLabelProps={{ style: inheritStyle }}
                                    FormHelperTextProps={{ style: inheritStyle }}
                                />
                            </Grid>
                            { BRAND !== 'DREAM_CARS' && (
                                <Grid item xs={12}>
                                    <CheckboxField
                                        id="form-register-checkbox-subscribe"
                                        name="subscribe"
                                        register={register}
                                        label={subscribeText}
                                    />
                                </Grid>
                            )
                            }
                            <Grid item xs={12}>
                                <CheckboxField
                                    id="form-register-checkbox-acceptTerms"
                                    name="acceptTerms"
                                    register={register}
                                    label={<LinkTerms/>}
                                />
                                <FormHelperText required error={!!errors.acceptTerms}>{errors.acceptTerms?.message}</FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                                <RegisterButton
                                    id="form-register-button-submit"
                                    type="submit"
                                    fullWidth
                                    text={submitText}
                                    disabled={loading}
                                    className={classes.submit}
                                    color={loginVariation === VARIATIONS.FILLED_SECONDARY ? 'primary' : 'secondary'}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <Link
                                    id="form-register-link-forgot"
                                    className={classes.link}
                                    onClick={() => switchAction('forgot')}
                                    variant="body2"
                                >
                                    {forgotPassText}
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    id="form-register-link-signIn"
                                    className={classes.link}
                                    onClick={() => switchAction('login')}
                                    variant="body2"
                                >
                                    {signInText}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </>
    );
};

export default Register;
