import React from 'react';
// import intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import parser from 'html-react-parser';

import { DIRECTORY_CONFIG_QUERY } from '../../queries';
import { DirectoryConfigQueryType, DirectoryInfoType } from '../../types';
import { getCategoryFromPath } from '../../helpers';
import { BRAND } from '../../../common/constants';

const CategoryInfo = (): JSX.Element => {
    const location = useLocation();
    const { pathname } = location;

    const variables = {
        brand: BRAND
    };

    const { data, loading, error } = useQuery<DirectoryConfigQueryType>(DIRECTORY_CONFIG_QUERY, {
        variables
    });

    // No data, or it is loading, or an error occured, render nothing.
    if (!data || loading || error) {
        return <></>;
    }

    // Determine the current category from the url path
    const currentCategory = getCategoryFromPath(pathname);

    // Get the info from the configuration
    const info = data.configurationByBrand.directoryConfiguration?.directoryInfo?.filter(info => info.describes === currentCategory);
    // Use the first info object if it exists
    const currentInfo: DirectoryInfoType | undefined = info?.length ? info[0] : undefined;
    // If no info, or it is not active, render nothing.
    if (!currentInfo || !currentInfo.active) {
        return <></>;
    }

    // Render the current categories information
    return (
        <Box>
            <Typography variant="body2" component="div">
                {
                    parser(currentInfo.content)
                }
            </Typography>
        </Box>
    );
};

export default CategoryInfo;
