import { useMemo } from 'react';
import { useLocation } from 'react-router';

function isNumberType(val: any): val is number {
    return !!Number(val);
}

const useQueryParams = <T extends Record<string, unknown>>(): Partial<T> => {
    const { search } = useLocation();

    const memoizedParams = useMemo(() => {
        const query = new URLSearchParams(search);
        const params = {};
        query.forEach((value, key) => {
            if (isNumberType(value)) {
                params[key] = Number(value);
            } else {
                params[key] = value;
            }
        });
        return params;
    }, [search]);

    return memoizedParams as T;
};

export default useQueryParams;
