import React from 'react';
// import intl from 'react-intl-universal';
import { Box, IconButton, Popover, Typography, Tooltip } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { makeStyles } from '@material-ui/core/styles';

import { days, dayLabelMap } from '../Hours';

import { HoursType } from '../../types';

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.palette.text.primary,
    },
    popover: {
        // pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

interface Props {
    value: HoursType;
}

const HoursDisplay = ({ value }: Props): JSX.Element => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (!value || !Object.keys(value).length) {
        return (<></>);
    }

    return (
        <Box>
            <Tooltip enterTouchDelay={50} title="Hours">
                <IconButton onClick={handlePopoverOpen} size="small" aria-label="hours">
                    <ScheduleIcon color="secondary" />
                </IconButton>
            </Tooltip>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{ paper: classes.paper }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {days.map(day => (
                    value[day] && <Typography variant="body2" key={day}>{dayLabelMap[day]}: {value[day]}</Typography>
                ))}
            </Popover>
        </Box>
    );
};

export default HoursDisplay;
