import { gql } from '@apollo/client';
import { MEDIA_ATTRIBUTES } from '../common/fragments';

export const CMS_AUCTION_ATTRIBUTES = gql`
    fragment CMSAuctionParts on Auction {
        id
        title
        slug
        reserve
        status
        paymentStatus
        currency
        finalEndDate
        scheduledStartDate
        scheduledEndDate
        finalPrice
        exterior {
            ...MediaParts
        }
        coverImage {
            ...MediaParts
        }
        hosts {
            id
            email
            username
            confirmed
            blocked
            created_at
            updated_at
        }
        hosts_declined {
            id
            email
            username
            confirmed
            blocked
            created_at
            updated_at
        }
        shared
        serviceSnappr
    },
    ${MEDIA_ATTRIBUTES}
`;
