import React, { useEffect, useState, ReactElement } from 'react';
import intl from 'react-intl-universal';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';

import BidBar from '../BidBar';
import AdvancedInfo from '../../../common/components/AdvancedInfo';
import Description from '../../components/Description';
import AuctionGallery from '../../components/AuctionGallery';
import SellerProfile from '../SellerProfile';
import BidHistory from '../BidHistory';
import Comments from '../Comments';
import CardChip from '../../../common/components/CardChip';
import AuctionHeroImage from '../../components/AuctionHeroImage';

import { scrollToTop, localizeCurrency } from '../../../common/helpers';
import { useIsMountedRef, useNotification } from '../../../common/hooks';
import { AUCTION_END_PADDING_IN_SECONDS, AUCTION_STATUS } from '../../constants';
import { useSocketContext } from '../../hooks/useSocketContext';
import { getComments, joinAuctionLot, leaveAuctionLot } from '../../sockets/emitters';
import Helmet from 'react-helmet';

const useStyles = makeStyles(theme => ({
    box: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    topSection: {
        margin: theme.spacing(3, 0),
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(2, 0, 0, 0),
        }
    },
    topSectionUpper: {
        marginBottom: theme.spacing(3)
    },
    topSectionLower: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic a gap
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 1)
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            '& > * + *': {
                margin: theme.spacing(1, 0, 0, 0)
            },
        }
    },
    leftSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flex: '0 70%',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(1),
        }
    },
    rightSection: {
        flex: '0 30%',
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(1),
        }
    },
    bottomSection: {
        borderTop: '1px solid #BCBCBC',
        paddingTop: theme.spacing(3),
        margin: theme.spacing(3, 0),
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 1)
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
            '& > * + *': {
                margin: theme.spacing(1, 0, 0, 0)
            },
        }
    },
    sticky: {
        position: 'fixed',
        backgroundColor: theme.palette.common.white,
        // opacity: 0.9,
        padding: theme.spacing(2),
        zIndex: 1000,
        top: 0,
        left: 0,
        right: 0,
        minHeight: '6vh'
    },
    notFoundContainer: {
        textAlign: 'center',
        marginTop: theme.spacing(10)
    },
    titleContainer: {
        margin: theme.spacing(3, 0)
    },
    title: {
        fontWeight: 'bold',
        // // textTransform: 'capitalize',
        fontFamily: 'Avenir',
        alignSelf: 'center'
    },
    image: {
        width: '50%',
        height: '100%',
    },

    chipContainer: {
        display: 'flex',
        flexDirection: 'row',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic a gap
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 1)
        },
    },
    galleryContainer: {
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'row',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic a gap
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 1)
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            '& > * + *': {
                margin: theme.spacing(1, 0, 0, 0)
            },
        }
    },
}));


const AuctionDetails = (): ReactElement => {
    const classes = useStyles();
    const isMountedRef = useIsMountedRef();
    const notify = useNotification();
    const { auction } = useSocketContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [oldStatus, setOldStatus] = useState<AUCTION_STATUS>(AUCTION_STATUS.PENDING);

    const scrollId = 'auction-details-top-section';
    const navId = 'auction-details-sticky-bar';

    const auctionLiveText = intl.get('auction.page.auction.live').d('This auction is live! Bidding is enabled.');
    const warningText = intl.get('auction.page.auction.warning').d('This auction is either not live or has ended. Bidding is disabled.');

    useEffect(() => {
        if (!isMobile) {
            const element = document.getElementById(navId);
            /*
                Gets the height amount
                of the element from the
                viewport and adds the
                pageYOffset to get the height
                relative to the page
            */
            const currStickyPos = element ? element.getBoundingClientRect().top + window.scrollY : null;
            const stickyScroll = (): any => {
                if (element && currStickyPos) {
                    /*
                        Check if the current Y offset
                        is greater than the position of
                        the element
                    */
                    if (window.scrollY > currStickyPos) {
                        element.classList.add(classes.sticky);
                    } else {
                        element.classList.remove(classes.sticky);
                    }
                }
            };

            scrollToTop(scrollId);
            window.addEventListener('scroll', stickyScroll);

            return () => window.removeEventListener('scroll', stickyScroll);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    useEffect(() => {
        if (isMountedRef.current) {
            // join the auction lot
            if (auction) {
                joinAuctionLot(auction.slug, notify);
                getComments(auction.id, auction.slug, notify);
            }
        }
        return () => {
            if (auction) {
                leaveAuctionLot(auction.slug, notify);
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMountedRef]);

    useEffect(() => {
        // Let our users know what is going on
        if (auction) {
            // Let's just make sure the old status does not equal the current auction status
            // If it does match, it means we can skip any message as the auction hasn't changed
            if (oldStatus !== auction.status) {
                // set the old status
                setOldStatus(auction.status);
                if (auction.status === AUCTION_STATUS.SOLD || auction.status === AUCTION_STATUS.RESERVE_NOT_MET) {
                    notify({ message: warningText, severity: 'warning', persist: false, vertical: 'bottom', horizontal: 'center' });
                    getComments(auction.id, auction.slug, notify);
                } else if (auction.status === AUCTION_STATUS.LIVE) {
                    notify({ message: auctionLiveText, severity: 'info', persist: false, vertical: 'bottom', horizontal: 'center' });
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auction?.status]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isMountedRef.current) {
                if (auction) {
                    // Get the end time of the auction
                    const { finalEndDate } = auction;

                    // Check to see if the auction has ended.
                    const hasEnded = finalEndDate ? moment(Date.now()).isAfter(finalEndDate) : false;
                    if (hasEnded) {
                        // if it has ended, we want to display a loading indicator or message
                        // for X amount of time while our auction finalizes any ongoing bids
                        const canDisplayMessage = finalEndDate ?
                            moment(Date.now()).isAfter(moment(finalEndDate)) &&
                            moment(Date.now()).isBefore((moment(finalEndDate).add(AUCTION_END_PADDING_IN_SECONDS, 's')))
                            : false;
                        if (canDisplayMessage) {
                            notify({ message: 'Please wait while bids are being finalized', severity: 'info', persist: false, vertical: 'top', horizontal: 'center', duration: 1500 });
                            clearInterval(interval);
                        }
                    }
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [isMountedRef, auction, notify]);

    if (!auction) {
        const notFoundText = intl.get('auction.page.auction.error.notFound').d('This auction is not available yet or doesn\'t exist. Please try again later.');
        return (
            <Box className={classes.notFoundContainer}>
                <Typography variant="h2">{notFoundText}</Typography>
            </Box>
        );
    }

    const {
        title,
        address,
        description,
        dataFields,
        user,
        status,
        reserve,
        exterior,
        coverImage,
        startPrice,
        finalPrice,
        currency
    } = auction;

    // make our current bid the starting price
    let currentPrice = startPrice;

    // if our auction has ended, and we have a final price, use the final auction price
    if (finalPrice) {
        currentPrice = finalPrice;
    }

    const formattedPrice = localizeCurrency(currentPrice, currency);

    const reserveNotMetText = intl.get('auction.page.auction.title.chip.reserveNotMet', { price: formattedPrice }).d(`Bid to ${formattedPrice}`);
    const soldText = intl.get('auction.page.auction.title.chip.sold').d('Sold');
    const noReserveText = intl.get('auction.page.auction.title.chip.noReserve').d('No Reserve');
    const liveText = intl.get('auction.page.auction.title.chip.live').d('Live');
    const comingSoonText = intl.get('auction.page.auction.title.chip.comingSoon').d('Coming Soon');

    const isCommentsDisabled = status === AUCTION_STATUS.PENDING || status === AUCTION_STATUS.APPROVED || status === AUCTION_STATUS.SCHEDULED;
    const image = auction.coverImage || (auction.exterior && auction.exterior.length > 0 ? auction.exterior[0] : undefined);

    return (
        <>
            <Helmet>
                <meta property="og:image" content={coverImage?.formats?.medium?.url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
            </Helmet>
            <Box className={classes.box}>
                <Box id={scrollId} className={classes.titleContainer}>
                    <Box className={classes.chipContainer}>
                        <Typography className={classes.title} variant="h1" component="h2">{title}</Typography>
                        {
                            (!reserve || reserve <= 0.00) && (
                                <CardChip backgroundColor={theme.palette.info.main} text={noReserveText} />
                            )
                        }
                        {
                            status === AUCTION_STATUS.RESERVE_NOT_MET && (
                                <CardChip backgroundColor={theme.palette.warning.main} text={reserveNotMetText} />
                            )
                        }
                        {
                            status === AUCTION_STATUS.SOLD && (
                                <CardChip backgroundColor={theme.palette.common.black} text={soldText} />
                            )
                        }
                        {
                            status === AUCTION_STATUS.LIVE && (
                                <CardChip backgroundColor={theme.palette.success.main} text={liveText} />
                            )
                        }
                        {
                            status === AUCTION_STATUS.SCHEDULED && (
                                <CardChip backgroundColor={theme.palette.info.light} text={comingSoonText} />
                            )
                        }
                    </Box>
                </Box>
                <Box id={navId}>
                    <BidBar />
                </Box>
                <Box className={classes.content}>
                    <Box className={classes.topSection}>
                        <Box className={classes.topSectionUpper}>
                            {/* Images */}
                            <Box className={classes.galleryContainer}>
                                <AuctionHeroImage image={coverImage} fallback={exterior && exterior[0]} />
                                <AuctionGallery auction={auction} />
                            </Box>
                        </Box>
                        <Box className={classes.topSectionLower}>
                            <Box className={classes.leftSection}>
                                {/* Properties */}
                                {
                                    dataFields && (
                                        <Box sx={{ width: '100%', mb: 2 }}>
                                            <AdvancedInfo properties={dataFields} address={address} />
                                        </Box>
                                    )
                                }
                                {/* Description */}
                                <Box sx={{ width: '100%', my: 2 }}>
                                    <Description
                                        title={title}
                                        description={description ? description : ''}
                                    />
                                </Box>
                            </Box>
                            <Box className={classes.rightSection}>
                                <SellerProfile image={image} seller={user} auctionTitle={title} />
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.bottomSection}>
                        <Box className={classes.leftSection}>
                            {/* Comments */}
                            <Box sx={{ width: '100%' }}>
                                <Comments disabled={isCommentsDisabled} />
                            </Box>
                        </Box>
                        <Box className={classes.rightSection}>
                            {/* Bid History */}
                            <BidHistory enableBid />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default React.memo(AuctionDetails);
