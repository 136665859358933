import React from 'react';
// import intl from 'react-intl-universal';
import Image from 'material-ui-image';

import { CoverType } from '../../types';
import { getImageUrl } from '../../helpers';

interface Props {
    image?: CoverType | Array<CoverType>;
}

const CardImage = ({ image }: Props): JSX.Element => {
    let imageUrl = '';
    if (image) {
        if (Array.isArray(image)) {
            imageUrl = getImageUrl(image[0], 'medium');
        } else {
            imageUrl = getImageUrl(image, 'medium');
        }
    }

    return (
        <Image
            cover
            src={imageUrl}
            aspectRatio={(16 / 9)}
        />
    );
};

export default CardImage;
