import React, { useState } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Tooltip, Typography } from '@material-ui/core';
import ReportIcon from '@material-ui/icons/Report';
import ReplyIcon from '@material-ui/icons/Reply';

import WriteCommentDialog from '../../containers/WriteCommentDialog';
import ReportCommentDialog from '../../containers/ReportCommentDialog';
import CommentsList from '../CommentsList';

import { CommentType } from '../../types';
import { useSocketContext } from '../../hooks/useSocketContext';
import { AUCTION_STATUS } from '../../constants';
import { useEffect } from 'react';
import { useIsMountedRef } from '../../../common/hooks';
import { CheckpointIsAuthenticated } from '../../../common/components/checkpoints';


const useStyles = makeStyles(theme => ({
    comment: {
        border: ({ isBid }: CSSPropsType) => (
            `${isBid ? '5px solid #008000' : '1px solid #707070'}`
        ),
        borderRadius: ({ isBid }: CSSPropsType) => `${isBid ? '50px' : '25px'}`,
        padding: ({ isBid }: CSSPropsType) => (isBid ? theme.spacing(0) : theme.spacing(2, 4)),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        }
    },
    sellerComment: {
        border: '2px solid orange !important',
    },
    author: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        flex: '1 0 auto',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }
    },
    seller: {
        fontWeight: 'bold',
        // // textTransform: 'uppercase',
        [theme.breakpoints.down('md')]: {
            width: '80%',
        }
    },
    name: {
        fontWeight: 'bold',
        // overflowWrap: 'anywhere',
        wordBreak: 'break-word',
        maxWidth: 200,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            maxWidth: 'unset',
        }
    },
    date: {
        color: theme.palette.grey[500]
    },
    contentContainer: {
        display: 'flex',
        justifyContent: ({ isBid }: CSSPropsType) => (isBid ? 'center' : 'flex-start'),
        alignItems: 'center',
        flex: ({ isBid }: CSSPropsType) => (isBid ? '1 1 100%' : '1 1 auto'),
        marginLeft: ({ isBid }: CSSPropsType) => (isBid ? theme.spacing(0) : theme.spacing(2)),
        marginRight: ({ isBid }: CSSPropsType) => (isBid ? theme.spacing(0) : theme.spacing(5)),
        [theme.breakpoints.down('md')]: {
            width: '100%',
            alignItems: 'flex-start',
            marginLeft: ({ isBid }: CSSPropsType) => (isBid ? theme.spacing(0) : theme.spacing(5)),
        }
    },
    content: {
        fontWeight: ({ isBid }: CSSPropsType) => (isBid ? 'bold' : 'inherit'),
        wordBreak: 'break-word',
        margin: ({ isBid }: CSSPropsType) => (isBid ? theme.spacing(1, 5) : theme.spacing(0)),
    },
    buttons: {
        [theme.breakpoints.down('md')]: {
            top: 35
        }
    }
}));

type Props = CommentType & {
    canComment?: boolean;
};

type CSSPropsType = {
    isBid: boolean;
}

const Comment = ({ id, content, created_at, isBid = false, canComment = true, authorUser, authorName, authorId, children }: Props): JSX.Element => {
    const classes = useStyles({ isBid });
    const [open, setOpen] = useState<boolean>(false);
    const [openAbuse, setOpenAbuse] = useState<boolean>(false);
    const isMountedRef = useIsMountedRef();
    const [isSellerComment, setIsSellerComment] = useState<boolean>(false);

    const { auction } = useSocketContext();

    const threadsCount = children ? children.length : 0;
    const hasThreads = threadsCount ? threadsCount > 0 : false;
    const isAuctionDisabled = auction ? (auction.status !== AUCTION_STATUS.LIVE) && (auction.status !== AUCTION_STATUS.SCHEDULED) : true;

    useEffect(() => {
        if (isMountedRef.current) {
            if (auction) {
                if (authorUser) {
                    setIsSellerComment(auction.user.id === authorUser.id);
                } else {
                    setIsSellerComment(auction.user.id === authorId);
                }
            }
        }
    }, [auction, authorId, authorUser, isMountedRef]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleReportOpen = () => {
        setOpenAbuse(true);
    };

    const handleReportClose = () => {
        setOpenAbuse(false);
    };

    const formattedDate = moment.utc(created_at).local().format('MMM Do, YYYY');

    const replyText = intl.get('auction.comment.button.reply').d('Reply');
    const reportText = intl.get('auction.comment.button.report').d('Report');
    const sellerText = intl.get('auction.comment.seller').d('Seller');
    const dialogTitleText = intl.get('auction.comment.replyDialog.title').d('Reply');

    const name = authorUser ? authorUser.username : authorName;

    return (
        <>
            <ListItem key={id} className={`${classes.comment} ${isSellerComment ? classes.sellerComment : ''}`} alignItems="center" >
                {
                    !isBid && (
                        <ListItemText
                            className={classes.author}
                            primary={
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography className={classes.name} component="span" variant="h5">
                                        { name ? name.replace(/_/g, ' ') : '' }
                                    </Typography>
                                    {
                                        isSellerComment ? (
                                            <Typography className={classes.seller} component="span" variant="h5">
                                                {sellerText}
                                            </Typography>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </Box>
                            }
                            secondary={
                                <Typography className={classes.date} component="span" variant="subtitle1">
                                    {formattedDate}
                                </Typography>
                            }
                        />
                    )
                }
                <ListItemText
                    className={classes.contentContainer}
                    primary={
                        <Typography className={classes.content} component="span" variant="body1">
                            {content}
                        </Typography>
                    }
                />
                {
                    !isBid && canComment && (
                        <ListItemSecondaryAction className={classes.buttons}>
                            {
                                !isAuctionDisabled && (
                                    <CheckpointIsAuthenticated>
                                        <Tooltip enterTouchDelay={50} title={replyText}>
                                            <IconButton edge="end" aria-label={replyText} onClick={handleOpen}>
                                                <ReplyIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </CheckpointIsAuthenticated>
                                )
                            }
                            <CheckpointIsAuthenticated>
                                <Tooltip enterTouchDelay={50} title={reportText}>
                                    <IconButton edge="end" aria-label={reportText} onClick={handleReportOpen}>
                                        <ReportIcon />
                                    </IconButton>
                                </Tooltip>
                            </CheckpointIsAuthenticated>
                        </ListItemSecondaryAction>
                    )
                }
            </ListItem>
            {
                open && auction && (
                    <WriteCommentDialog
                        title={dialogTitleText}
                        auctionLot={auction.slug}
                        referenceId={auction.id}
                        onClose={handleClose}
                        reply={{ commentId: id }}
                    />
                )
            }
            {
                openAbuse && auction && (
                    <ReportCommentDialog
                        commentId={id}
                        auctionId={auction.id}
                        onClose={handleReportClose}
                    />
                )
            }
            {
                hasThreads && (
                    <CommentsList thread={{ count: threadsCount }} comments={children} />
                )
            }
        </>
    );
};

export default React.memo(Comment);
