import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, InputLabel, Tooltip, Typography, InputLabelProps } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { PropertiesFieldType } from '../../../../types';
import { validateTextField } from '../../validation';

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.typography.body2,
        '& .ck-editor__editable:not(.ck-editor__nested-editable)': {
            minHeight: 250
        }
    },
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    infoIcon: {
        width: 20,
        height: 20,
        marginLeft: theme.spacing(1),
        '& svg': {
            width: 20,
            height: 20,
        }
    },
}));


type Props = PropertiesFieldType & InputLabelProps & {
    defaultValue?: any;
    disabled?: boolean;
};

const RichTextField = ({ id, name, label, required, regex, regexMessage, min, max, tooltip, defaultValue, placeholder, disabled = false, ...rest }: Props): JSX.Element => {
    const classes = useStyles();
    const { control, errors } = useFormContext();

    const validate = useMemo(() => validateTextField({ required, regex, regexMessage, min, max }), [max, min, regex, regexMessage, required]);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : ''}
            render={({ onBlur, onChange, value }) => (
                <Box className={`${tooltip ? classes.container : ''}`}>
                    <Box className={classes.root}>
                        <Box sx={{ mb: 1 }}>
                            <InputLabel
                                htmlFor={id}
                                disabled={disabled}
                                error={!!errors[name]}
                                {...rest}
                            >
                                {label}
                            </InputLabel>
                        </Box>
                        <CKEditor
                            id={id}
                            editor={ClassicEditor}
                            data={defaultValue ? defaultValue : ''}
                            disabled={disabled}
                            config={{
                                toolbar: [
                                    'heading',
                                    '|',
                                    'bold',
                                    'italic',
                                    'link',
                                    'bulletedList',
                                    'numberedList',
                                    '|',
                                    'indent',
                                    'outdent',
                                    '|',
                                    'blockQuote',
                                    'insertTable',
                                    'undo',
                                    'redo'
                                ]
                            }}
                            onReady={(editor) => {
                                if (editor) {
                                    editor.setData(value);
                                }
                            }}
                            onChange={(_, editor) => {
                                if (editor) {
                                    const data = editor.getData();
                                    onChange(data);
                                }
                            }}
                            onBlur={onBlur}
                        />
                        <Box>
                            {errors[name] && <Typography variant="body1" color="error" >{errors[name]?.message}</Typography>}
                        </Box>
                    </Box>
                    {tooltip && (
                        <Box className={classes.infoIcon}>
                            <Tooltip enterTouchDelay={50} title={tooltip} aria-label={tooltip}>
                                <InfoIcon />
                            </Tooltip>
                        </Box>
                    )}
                </Box>
            )}
            rules={{
                validate
            }}
        />
    );
};

export default RichTextField;
