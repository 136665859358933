import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Container, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FieldAutocompleteCountry, FieldAutocompleteState, FieldPrice, FieldRichText, FieldSelect } from '../../../common/components/fields';
import Button from '../../../common/components/Button';

import { useWizardContext } from '../../../common/hooks';
import { schema } from './validation';
import { CURRENCIES, SELLER_TYPES } from '../../../common/constants';
import { useFormDataContext } from '../../../common/hooks/useFormDataContext';
import { CountryStateType } from '../../../common/types';
import { useAuctionContext } from '../../hooks/useAuctionContext';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    buttons: {
        margin: theme.spacing(5, 0, 0),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}));

type InputsType = {
    title: string;
    sellerType: string;
    reserve: number;
    currency: string;
    country: string;
    state: string;
    city: string;
    userDescription: string;
}

interface Props {
    countries: Array<CountryStateType>;
}

const BasicDetails = ({ countries }: Props): JSX.Element => {
    const classes = useStyles();
    const { handleBack, handleNext, activePageIndex, steps } = useWizardContext();
    const { setValues, data } = useFormDataContext();
    const auction = useAuctionContext();

    let titleDef = data?.title || '';
    let sellerTypeDef = data?.sellerType || '';
    let reserveDef = data?.reserve || 0;
    let currencyDef = data?.currency || '';
    let countryDef = data?.country || '';
    let stateDef = data?.state || '';
    let cityDef = data?.city || '';
    let userDescriptionDef = data?.userDescription || '';

    if (auction) {
        titleDef = auction.title;
        sellerTypeDef = auction.sellerType;
        reserveDef = auction.reserve;
        currencyDef = auction.currency;
        if (auction.address) {
            if (auction.address.country) {
                countryDef = auction.address.country;
            }
            if (auction.address.state) {
                stateDef = auction.address.state;
            }
            if (auction.address.city) {
                cityDef = auction.address.city;
            }
        }
        userDescriptionDef = auction.userDescription;
    }

    const selectedCountry = countries.find((c) => c.name === countryDef);
    const states = useMemo(() => selectedCountry?.states || [], [selectedCountry]);
    const selectedState = states.find((s) => s.name === stateDef);

    const currencies = Object.keys(CURRENCIES).map((currency) => ({
        key: currency,
        label: currency,
        value: currency
    }));

    const defaultValues: InputsType = {
        title: titleDef,
        sellerType: sellerTypeDef,
        reserve: reserveDef,
        currency: currencies.length > 1 ? currencyDef : currencies[0].value,
        country: selectedCountry || countryDef,
        state: selectedState || stateDef,
        city: cityDef,
        userDescription: userDescriptionDef,
    };

    const methods = useForm<InputsType>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const { handleSubmit, register, control, formState: { errors } } = methods;

    const onSubmit = (data: InputsType) => {
        setValues && setValues(data);
        handleNext && handleNext();
    };

    const sellerTypes = Object.keys(SELLER_TYPES).map((sellerType) => ({
        key: sellerType,
        label: SELLER_TYPES[sellerType],
        value: sellerType
    }));


    const nextText = intl.get('auction.auctionWizard.button.next').d('Next');
    const submitText = intl.get('auction.auctionWizard.button.submit').d('Submit');
    const backText = intl.get('auction.auctionWizard.button.back').d('Back');
    const cancelText = intl.get('auction.auctionWizard.button.cancel').d('Cancel');

    const titleText = intl.get('auction.form.basicDetails.input.title').d('Title');
    const sellerTypeText = intl.get('auction.form.basicDetails.select.sellerType').d('Seller Type');
    const reserveText = intl.get('auction.form.basicDetails.input.reserve').d('Desired Reserve');
    const currencyText = intl.get('auction.form.basicDetails.select.currency').d('Desired Currency');
    const countryText = intl.get('auction.form.basicDetails.input.country').d('Country');
    const stateText = intl.get('auction.form.basicDetails.input.state').d('State');
    const cityText = intl.get('auction.form.basicDetails.input.city').d('City');
    const userDescriptionText = intl.get('auction.form.basicDetails.input.description').d('Tell us about your vehicle');

    return (
        <FormProvider {...methods}>
            <Container>
                <div className={classes.paper}>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container direction="row" spacing={4} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="form-basicDetails-input-title"
                                    inputRef={register}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    autoFocus
                                    color="secondary"
                                    label={titleText}
                                    name="title"
                                    helperText={errors.title?.message}
                                    error={!!errors.title}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FieldPrice
                                    id="form-basicDetails-input-reserve"
                                    variant="outlined"
                                    color="secondary"
                                    required
                                    fullWidth
                                    name="reserve"
                                    label={reserveText}
                                    control={control}
                                    helperText={errors.reserve?.message}
                                    error={!!errors.reserve}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                {currencies.length > 1 && (<FieldSelect
                                    id="form-basicDetails-select-currency"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    color="secondary"
                                    name="currency"
                                    label={currencyText}
                                    control={control}
                                    items={currencies}
                                    errorMessage={errors.currency?.message}
                                    error={!!errors.currency}
                                />)}
                                {currencies.length === 1 && (<FieldSelect
                                    id="form-basicDetails-select-currency"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    color="secondary"
                                    name="currency"
                                    label={currencyText}
                                    control={control}
                                    items={currencies}
                                    value={currencies[0].value}
                                    disabled={true}
                                    errorMessage={errors.currency?.message}
                                    error={!!errors.currency}
                                />)}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FieldSelect
                                    id="form-basicDetails-select-sellerType"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    color="secondary"
                                    name="sellerType"
                                    label={sellerTypeText}
                                    control={control}
                                    items={sellerTypes}
                                    errorMessage={errors.sellerType?.message}
                                    error={!!errors.sellerType}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FieldAutocompleteCountry
                                    id="form-basicDetails-autocomplete-country"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    color="secondary"
                                    name="country"
                                    label={countryText}
                                    control={control}
                                    helperText={errors.country?.message}
                                    error={!!errors.country}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FieldAutocompleteState
                                    id="form-basicDetails-input-state"
                                    variant="outlined"
                                    fullWidth
                                    color="secondary"
                                    label={stateText}
                                    name="state"
                                    register={register}
                                    control={control}
                                    helperText={errors.state?.message}
                                    error={!!errors.state}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    id="form-basicDetails-input-city"
                                    inputRef={register}
                                    variant="outlined"
                                    fullWidth
                                    color="secondary"
                                    label={cityText}
                                    name="city"
                                    helperText={errors.city?.message}
                                    error={!!(errors.city)}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FieldRichText
                                    id="form-basicDetails-input-userDescription"
                                    name="userDescription"
                                    color="secondary"
                                    required
                                    control={control}
                                    label={userDescriptionText}
                                    errorMessage={errors.userDescription?.message}
                                    error={!!errors.userDescription}
                                />
                            </Grid>
                        </Grid>
                        <Box className={classes.buttons}>
                            <Button
                                id="form-basicDetails-input-back-or-cancel"
                                type="button"
                                variant="contained"
                                color={activePageIndex !== 0 ? 'secondary' : 'primary'}
                                onClick={handleBack}
                                text={activePageIndex !== 0 ? backText : cancelText}
                            />
                            <Button
                                id="form-basicDetails-input-next-or-submit"
                                color="secondary"
                                variant="contained"
                                type="submit"
                                text={activePageIndex === (steps as number) - 1 ? submitText : nextText}
                            />
                        </Box>
                    </form>
                </div>
            </Container>
        </FormProvider>
    );
};

export default BasicDetails;
