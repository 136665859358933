import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Service from '../Service';

import { ServiceInfoType } from '../../types';

const useStyles = makeStyles(theme => ({
    box: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        '& > * + *': {
            margin: theme.spacing(3, 0, 0, 0)
        },
    }
}));

interface Props {
    services: Array<ServiceInfoType>;
}

const Services = ({ services }: Props): JSX.Element => {
    const classes = useStyles();

    return services.length > 0 ? (
        <Box className={classes.box}>
            {
                services.map((service) => <Service key={service.id} {...service} />)
            }
        </Box>
    ) : (
        <></>
    );
};

export default Services;
