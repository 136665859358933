import { InMemoryCache, makeVar } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';

import { JWT_TOKEN } from './modules/common/constants';

export const cache: InMemoryCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                isLoggedIn: {
                    read() {
                        return isLoggedInVar();
                    }
                },
                bookmarkIds: {
                    read() {
                        return bookmarkIdsVar();
                    }
                },
                UsersPermissionsUser: offsetLimitPagination()
            }
        },
        Configuration: {
            merge: true
        }
    }
});

export const isLoggedInVar = makeVar<boolean>(!!localStorage.getItem(JWT_TOKEN));
export const bookmarkIdsVar = makeVar<Array<number>>([]);
