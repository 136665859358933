import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { setCookie, getCookie } from '../../helpers';

const useStyle = makeStyles(theme => ({
    typography: {
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(1)
        }
    },
    button: {
        ...theme.typography.button,
    },
    link: {
        color: theme.palette.secondary.contrastText,
        fontStyle: 'italic',
        cursor: 'pointer',
    },
    container: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        left: 0,
        bottom: 0,
        position: 'fixed',
        width: '100%',
        alignItems: 'center',
        zIndex: theme.zIndex.modal
    },
    buttonWrapper: {
        padding: theme.spacing(1, 1.5),
        margin: theme.spacing(2),
    },
    contentWrapper: {
        margin: theme.spacing(2),
    }
}));

interface Props {
    buttonId?: string;
    privacyPolicyId?: string;
    cookiePolicyId?: string;
    buttonText?: string;
    cookie?: string;
    description?: string;
    expiry?: number;
}

const CookiePopup = ({ buttonId, privacyPolicyId, cookiePolicyId, buttonText, cookie = 'UserCookieConsent', description, expiry = 365 }: Props): JSX.Element => {
    const classes = useStyle();
    const [cookieValue, setCookieValue] = useState<string | undefined>(undefined);
    const [closed, setClosed] = useState<boolean>(false);

    const defaultButtonText = intl.get('generic.cookie.button.accept').d('Accept');

    const btnText = buttonText ? buttonText : defaultButtonText;

    const defaultDescriptionText = intl.get('generic.cookie.text.description', { btnText }).d(`We use cookies to improve your experience on our site. By clicking on '${btnText}', you agree to our terms and our use of cookies.`);

    const descriptionText = description ? description : defaultDescriptionText;

    const findMoreText1 = intl.get('generic.cookie.text.findMore.1').d('To find out more, read our');
    const findMoreText2 = intl.get('generic.cookie.text.findMore.2').d('and');

    const privacyPolicyText = intl.get('generic.cookie.link.privacyPolicy').d('privacy policy');
    const cookiePolicyText = intl.get('generic.cookie.link.cookiePolicy').d('cookie policy');

    const handleClick = () => {
        const options = {
            expires: expiry
        };

        setCookie(cookie, 'true', options);
        setClosed(true);
    };

    useEffect(() => {
        const value = getCookie(cookie);
        setCookieValue(value);
    }, [cookie]);

    const consentForm = (
        <Box className={classes.container}>
            <Box className={classes.contentWrapper}>
                <Typography variant="body1" className={classes.typography}>{descriptionText}</Typography>
                <Typography variant="body1" className={classes.typography}>{findMoreText1}{' '}
                    <Link id={privacyPolicyId ? privacyPolicyId : 'cookiePopup-link-privacyPolicy'} className={classes.link} to="/page/privacy-policy">{privacyPolicyText}</Link>
                    {' '}{findMoreText2}{' '}
                    <Link id={cookiePolicyId ? cookiePolicyId : 'cookiePopup-link-cookiePolicy'} className={classes.link} to="/page/cookie-policy">{cookiePolicyText}</Link>.
                </Typography>
            </Box>
            <Box className={classes.buttonWrapper}>
                <Button id={buttonId ? buttonId : 'cookiePopup-button-accept'} variant="contained" color="primary" className={classes.button} onClick={handleClick}>{btnText}</Button>
            </Box>
        </Box>
    );

    return cookieValue || closed ? (<></>) : consentForm;
};

export default React.memo(CookiePopup);
