import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PreviewCollapse from '../../PreviewCollapse';
import ReactPlayer from 'react-player';

const useStyles = makeStyles(theme => ({
    playerWrapper: {
        position: 'relative',
        paddingTop: '56.25%'
    },
    player: {
        position: 'absolute',
        top: 0,
        left: 0
    },
}));

interface Props {
    name: string;
    limit: number;
}

const VideoListField = ({ name, limit }: Props): JSX.Element => {
    const classes = useStyles();
    const { control } = useFormContext();

    const placeholders = useMemo(() => new Array(limit).fill('a'), [limit]);
    const videosTitleText = intl.get('common.videoList.title').d('Videos');
    const placeholderText = intl.get('common.videoList.placeholder').d('https://youtube.com/xxxxxxx');

    return (
        <Box>
            <Typography variant="h3">{videosTitleText}</Typography>
            {
                placeholders.map((_, index) => (
                    <Controller
                        key={`${name}[${index}]`}
                        name={`${name}[${index}]`}
                        control={control}
                        render={(props) => (
                            <Box key={index} sx={{ my: 3 }}>
                                <TextField
                                    {...props}
                                    fullWidth
                                    color="secondary"
                                    variant="outlined"
                                    type="text"
                                    placeholder={placeholderText}
                                />
                                {
                                    props.value && (
                                        <PreviewCollapse sx={{ my: 1 }}>
                                            <Box className={classes.playerWrapper}>
                                                <ReactPlayer className={classes.player} height="100%" width="100%" url={props.value}/>
                                            </Box>
                                        </PreviewCollapse>
                                    )
                                }
                            </Box>
                        )}
                    />
                ))
            }
        </Box>
    );
};

export default VideoListField;
