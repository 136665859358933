/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { PictureInfoType } from '../../types';
import { getImageUrl } from '../../helpers';

type MarginType = {
    hasImage: boolean;
}

const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'center',
        padding: theme.spacing(4, 2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        marginTop: ({ hasImage }: MarginType) => {
            if (hasImage) {
                return theme.spacing(6);
            }
            return theme.spacing(0);
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
            maxWidth: 300,
        },
        [theme.breakpoints.down('md')]: {
            marginTop: ({ hasImage }: MarginType) => {
                if (hasImage) {
                    return theme.spacing(4);
                }
                return theme.spacing(0);
            },
            marginBottom: theme.spacing(2)
        }
    },
    title: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 'bold',
        fontSize: 20

    },
    titleContainer: {
        margin: theme.spacing(2, 0, 4, 0)
    },
    image: {
        width: '55%',
        height: '100%',
        objectFit: 'contain'
    },
    imageContainer: {
        width: 100,
        height: 100,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        border: `2px ${theme.palette.primary.main} solid`,
        borderRadius: '50%',
        top: '-30%',
        [theme.breakpoints.down('md')]: {
            width: 75,
            height: 75,
            top: '-25%',
        }
    }
}));

interface Props extends PictureInfoType {
    index: number;
}

const Offering = ({ title, description, image, index }: Props): JSX.Element => {
    const imageSource = image && getImageUrl(image, 'small');
    const hasImage = !!imageSource;
    const classes = useStyles({ hasImage });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const style: any = {};
    if (index > 0) {
        if (isMobile) {
            style.marginTop = theme.spacing(6);
        } else {
            style.marginLeft = theme.spacing(2);
        }
    }

    return (
        <Paper className={classes.container} style={style} square>
            {
                imageSource && (
                    <Box className={classes.imageContainer}>
                        <img className={classes.image} src={imageSource} alt={image?.alternativeText} />
                    </Box>
                )
            }
            {
                title && (
                    <Box className={classes.titleContainer}>
                        <Typography className={classes.title}>{title}</Typography>
                    </Box>
                )
            }
            <Typography variant="body2">{description}</Typography>
        </Paper>
    );
};

export default Offering;
