import React from 'react';
import intl from 'react-intl-universal';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
    companyName?: string;
}

const useStyles = makeStyles(theme => ({
    poweredBy: {
        fontWeight: 100,
    },
    poweredByCompany: {
        ...theme.typography.subtitle2,
        fontWeight: 'bold',
        fontFamily: 'Usuzi, sans-serif'
    },
    clickable: {
        textDecoration: 'none',
        color: 'unset',
    },
}));

const Copyright = ({ companyName = 'Racing Edge' }: Props): JSX.Element => {
    const classes = useStyles();
    const year = new Date().getFullYear();
    const copyrightText = intl.get('generic.copyright', { year, companyName }).d(`Copyright © ${year} ${companyName} . All Rights Reserved.`);

    const isWhiteLabel = process.env.REACT_APP_BRAND !== process.env.REACT_APP_TEMPLATE_TYPE;
    const poweredByText = intl.get('common.page.header.whitelabel.poweredBy').d('Powered By');
    const companyNameText = intl.get('common.companyName').d('RACINGEDGE');

    return (
        <>
            <Typography variant="h6">
                {copyrightText}
            </Typography>
            {isWhiteLabel && (
                <Box sx={{ ml: 2 }}>
                    <a href="http://racingedgedigital.com/" target="_blank" rel="noopener noreferrer" className={classes.clickable}>
                        <Typography variant="subtitle2" className={classes.poweredBy}>
                            {poweredByText} <span className={classes.poweredByCompany}>{companyNameText}</span>
                        </Typography>
                    </a>
                </Box>
            )}
        </>
    );
};

export default Copyright;
