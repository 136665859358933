import React, { useMemo } from 'react';
// import intl from 'react-intl-universal';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { getImageUrl } from '../../../common/helpers';
import { CoverType } from '../../../common/types';

const useStyles = makeStyles(theme => ({
    imageContainer: {
        width: '100%',
        height: '100%',
        minHeight: 250
    },
}));

interface Props {
    image?: CoverType;
    fallback?: CoverType;
}

const AuctionHeroImage = ({ image, fallback }: Props): JSX.Element => {
    const classes = useStyles();

    // get our main image src
    const heroSrc = useMemo(() => getImageUrl(image || fallback, 'large'), [fallback, image]);

    return (
        // Hero image
        // Add padding top to match grid padding
        <Box sx={{ width: '100%', paddingTop: 1 }}>
            {
                !!heroSrc && (
                    <Box
                        className={classes.imageContainer}
                        sx={{
                            backgroundImage: `url(${heroSrc})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain'
                        }}
                    />
                )
            }
        </Box>
    );
};

export default React.memo(AuctionHeroImage);
