import React from 'react';
import { useQuery } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, TextField, TextFieldProps } from '@material-ui/core';
import { matchSorter } from 'match-sorter';

import { QUERY_COUNTRY_STATE } from '../../../queries';
import { CountryStateDataType, CountryStateType } from '../../../types';

type Props = TextFieldProps & {
    id: string;
    label: string;
    name: string;
    control: any;
    disabled?: boolean;
}

const AutocompleteCountryField = ({ label, name, control, id, disabled = false, ...rest }: Props): JSX.Element => {
    const { getValues, reset } = useFormContext();
    const { data } = useQuery<CountryStateDataType>(QUERY_COUNTRY_STATE);
    const countries = data?.countryState?.data || [];
    const filterCountries = (options: Array<CountryStateType>, { inputValue }) => matchSorter(options, inputValue, { keys: ['iso2', 'iso3', 'name'] });

    const currentVal = getValues('country');
    const defaultCountry = countries && countries.find((c) => c.name === currentVal);

    return (
        <Controller
            render={(props) => (
                <Autocomplete
                    {...props}
                    id={id}
                    disabled={disabled}
                    disableClearable={disabled}
                    autoHighlight
                    options={countries}
                    getOptionLabel={(option) => (option.name || '')}
                    getOptionSelected={(option, value) => option.name === value.name}
                    filterOptions={filterCountries}
                    filterSelectedOptions
                    onChange={(_, data) => {
                        props.onChange(data);
                        // everytime we select a new country we need to reset the value of state to empty
                        reset({ ...getValues(), state: '' });
                    }}
                    value={props.value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            {...rest}
                            label={label}
                            disabled={disabled}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'disabled'
                            }}
                        />
                    )}
                />
            )
            }
            name={name}
            control={control}
            defaultValue={defaultCountry}
        />
    );
};

export default AutocompleteCountryField;
