/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useContext, useState } from 'react';
import { AnyKeyType } from '../../types';

type ContextType = {
    setValues: (values: any) => void;
    data: AnyKeyType;
}

const FormDataContext = createContext<Partial<ContextType>>({});

export const FormDataProvider = ({ children }) => {
    const [data, setData] = useState({});

    const setValues = (values) => {
        setData(prevData => ({
            ...prevData,
            ...values
        }));
    };

    return (
        <FormDataContext.Provider value={{ data, setValues }}>
            {children}
        </FormDataContext.Provider>
    );
};

export const useFormDataContext = () => useContext(FormDataContext);
