import { Tooltip } from '@material-ui/core';
import React from 'react';

interface Props {
    text: string;
    icon: React.ComponentType;
}

const TooltipIcon = ({ text, icon: Icon }: Props): JSX.Element => (
    <Tooltip enterTouchDelay={50} title={text} aria-label={text}>
        <Icon />
    </Tooltip>
);

export default TooltipIcon;
