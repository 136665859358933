import React from 'react';
import intl from 'react-intl-universal';

import SortBy from '../../../common/components/SortBy';

import { INDEX_PREFIX } from '../../../common/constants';

const indexName = `${INDEX_PREFIX}_auction`;

export const encodedSortByOptions = {
    [`${indexName}_endDate_asc`]: 'endDate-asc',
    [`${indexName}_endDate_desc`]: 'endDate-desc',
    [`${indexName}_startDate_asc`]: 'startDate-asc',
    [`${indexName}_startDate_desc`]: 'startDate-desc',
    [`${indexName}_topBid_asc`]: 'bid-asc',
    [`${indexName}_topBid_desc`]: 'bid-desc',
};

const SortByList = (): JSX.Element => {
    const sortingEndDateAscText = intl.get('auction.page.home.search.sortBy.endDate.asc.label').d('Ending Soonest');
    const sortingEndDateDescText = intl.get('auction.page.home.search.sortBy.endDate.desc.label').d('Ending Latest');
    const sortingStartDateAscText = intl.get('auction.page.home.search.sortBy.startDate.asc.label').d('Starting Soonest');
    const sortingStartDateDescText = intl.get('auction.page.home.search.sortBy.startDate.desc.label').d('Starting Latest');
    const sortingTopBidAscText = intl.get('auction.page.home.search.sortBy.topBid.asc.label').d('Bid: Lowest to Highest');
    const sortingTopBidDescText = intl.get('auction.page.home.search.sortBy.topBid.desc.label').d('Bid: Highest to Lowest');

    const sortByItems = [
        { value: `${indexName}_endDate_asc`, label: sortingEndDateAscText },
        { value: `${indexName}_endDate_desc`, label: sortingEndDateDescText },
        { value: `${indexName}_startDate_asc`, label: sortingStartDateAscText },
        { value: `${indexName}_startDate_desc`, label: sortingStartDateDescText },
        { value: `${indexName}_topBid_asc`, label: sortingTopBidAscText },
        { value: `${indexName}_topBid_desc`, label: sortingTopBidDescText },
    ];

    return <SortBy defaultRefinement={`${indexName}_endDate_asc`} items={sortByItems} />;
};

export default React.memo(SortByList);
