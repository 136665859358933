import React from 'react';
import intl from 'react-intl-universal';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MailIcon from '@material-ui/icons/Mail';
import CallIcon from '@material-ui/icons/Call';
import RoomIcon from '@material-ui/icons/Room';
import { useQuery } from '@apollo/client';
import { BrandThemeDataType, BrandThemeType, BrandVariablesType } from '../../types';
import { QUERY_BRAND_THEME } from '../../queries';
import { BRAND, isRacingEdge } from '../../constants';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(5),
    },
    title: {
        margin: theme.spacing(2, 0),
        fontWeight: 'bold',
        // // textTransform: 'uppercase'
    },
    item: {
        display: 'inline-flex'
    },
    text: {
        marginLeft: theme.spacing(2),
    },
    bar: {
        borderLeft: `5px solid ${theme.palette.primary.main}`,
        paddingLeft: theme.spacing(1)
    },
    logoImage: {
        width: '200px',
        height: '100px',
        backgroundPosition: 'top',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    }
}));

interface Props {
    email?: string;
    phoneNumber?: string;
    address?: string;
    displayLogo?: boolean;
}

const ReachUs = ({ address, email, phoneNumber, displayLogo }: Props): JSX.Element => {
    const classes = useStyles();

    const reachUsText = intl.get('generic.contactUs.title').d('Contact Us');

    const hasPhoneNumber = phoneNumber && phoneNumber.trim().length !== 0;

    const { data: dataTheme } = useQuery<BrandThemeDataType, BrandVariablesType>(QUERY_BRAND_THEME, {
        variables: {
            // if no brand is provided, use the Racing Edge Brand configuration instead
            brand: BRAND
        },
        skip: !displayLogo
    });
    let configTheme: undefined | BrandThemeType = undefined;
    if (dataTheme && dataTheme.configurationByBrand) {
        configTheme = dataTheme.configurationByBrand.theme;
    }

    let logoNode;
    if (displayLogo && configTheme?.images.headerLogo.image) {
        const logoSrc = configTheme?.images.headerLogo.image.url;
        logoNode = <Box className={classes.logoImage} style={{ backgroundImage: `url('${logoSrc}')` }} />;
    }

    return (
        <Box className={classes.container}>
            {
                (email || address || hasPhoneNumber) && (
                    <Box className={classes.bar}>
                        <Typography variant="h2" className={classes.title}>{reachUsText}</Typography>
                    </Box>
                )
            }
            {
                email && (
                    <Box sx={{ mt: 4 }}>
                        <Box className={classes.item}>
                            <MailIcon />
                            <Typography variant="h4" className={classes.text}> {email} </Typography>
                        </Box>
                    </Box>
                )
            }
            {
                !isRacingEdge && hasPhoneNumber && (
                    <Box sx={{ mt: 4 }}>
                        <Box className={classes.item}>
                            <CallIcon />
                            <Typography variant="h4" className={classes.text}> {phoneNumber} </Typography>
                        </Box>
                    </Box>
                )
            }
            {
                !isRacingEdge && address && (
                    <Box sx={{ mt: 4, mb: 4 }}>
                        <Box className={classes.item}>
                            <RoomIcon />
                            <Typography variant="h4" className={classes.text}> {address} </Typography>
                        </Box>
                    </Box>
                )
            }
            {
                displayLogo && (
                    <Box sx={{ mt: 4 }}>
                        <Box className={classes.item}>
                            {logoNode}
                        </Box>
                    </Box>
                )
            }
        </Box>
    );
};

export default ReachUs;
