import enUS from './locales/en-US.json';
import { TEMPLATE_TYPE } from './modules/common/constants';
import nxgem_enUS from './theming/nxgem/locales/en-US.json';

const overrides = {
    enUS: {},
};

if (TEMPLATE_TYPE === 'NX_GEM') {
    overrides.enUS = nxgem_enUS;
}

// app locale data
const locales = {
    'en-US': { ...enUS, ...overrides.enUS },
};

export default locales;
