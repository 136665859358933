import { createContext, useContext } from 'react';
import intl from 'react-intl-universal';

type ContextType = {
    activePageIndex: number;
    steps: number;
    submitting?: boolean;
    setSteps: (newStep: number) => void;
    handleBack: () => void;
    handleNext: () => void;
}

// We initialise them without default values, to make that happen, we
// apply the Partial helper type.
export const WizardContext = createContext<Partial<ContextType>>({});

// Validate the context otherwise throw error
export const useWizardContext = (): Partial<ContextType> => {
    const context = useContext(WizardContext);

    if (!context) {
        const errorText = intl.get('common.wizard.context.error').d('The Compound Component cannot be rendered outside of the Wizard Component');
        throw new Error(errorText);
    }

    return context;
};

export default useWizardContext;
