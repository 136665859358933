import React from 'react';
import { Box, Grid, Pagination, Skeleton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FeaturedCard from '../../components/FeaturedCard';
import NonFeaturedCard from '../../components/NonFeaturedCard';

import { DirectoryType } from '../../types';
import { generateDummyDirectory } from '../../helpers';

const useStyles = makeStyles((theme) => ({
    pagination: {
        ...theme.typography.body1
    },
}));

type ItemsPerRow = 1 | 2 | 3 | 4;
type ItemSize = 12 | 6 | 4 | 3;

interface Props {
    // Component Properties
    directories: Array<DirectoryType>;
    currentPage: number;
    totalPages: number;
    component: typeof FeaturedCard | typeof NonFeaturedCard;
    // Optional Properties
    loading?: boolean;
    pageSize?: number;
    setPage?: (page: number) => void;
    editDirectory?: (directory: DirectoryType, action?: string) => void;
    itemsPerRow?: ItemsPerRow;
}

const ResultList = ({ directories, currentPage, totalPages, setPage, itemsPerRow, loading, pageSize, editDirectory, component: Component }: Props): JSX.Element => {
    const classes = useStyles();
    // Determine the Card component from the properties
    const Card = Component;
    // Determine if there are multiple pages
    const hasMorePages = totalPages > 1;
    // Create the pagination onChange function
    const handleChange = (event: any, value: number) => {
        setPage && setPage(value);
    };
    // Given items per row being 1/2/3/4, this can only result in values of 12/6/4/3
    const itemSize = (12 / (itemsPerRow || 2)) as ItemSize;

    // The content to display as the result list
    let content: JSX.Element | Array<JSX.Element> = <></>;

    if (loading) {
        // Loading
        // Create a list of dummy directories
        const dummyDirectories: Array<DirectoryType> = [];
        // Populate the dummy directories to match the desired page size
        for (let i = 0; i < (pageSize || 0); i++) {
            dummyDirectories.push(generateDummyDirectory());
        }

        // Display skeleton content
        content = dummyDirectories.map((directory: any) => (
            <Grid item key={directory.id} xs={12} md={itemSize}>
                <Skeleton width="100%">
                    <Card directory={directory} />
                </Skeleton>
            </Grid>
        ));
    } else {
        // Loaded - Display real content
        content = directories.map((directory: any) => (
            <Grid item key={directory.id} xs={12} md={itemSize}>
                <Card directory={directory} editDirectory={editDirectory} />
            </Grid>
        ));
    }

    // Render our Result List
    return (
        <Box sx={{ mb: 2 }}>
            {/* The Content */}
            <Grid container spacing={2}>
                {content}
            </Grid>
            {/* Pagination */}
            {hasMorePages && (
                <Box sx={{ m: 1, display: 'flex', justifyContent: 'center' }} >
                    <Pagination className={classes.pagination} count={totalPages} page={currentPage} onChange={handleChange} color="primary" shape="circular" />
                </Box>
            )}
        </Box>
    );
};

export default ResultList;
