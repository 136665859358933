import React from 'react';
import intl from 'react-intl-universal';
import { Divider, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    typography: {
        fontWeight: 'bold',
        // // textTransform: 'uppercase',
        marginLeft: theme.spacing(1),
    },
    text: {
        marginLeft: theme.spacing(1),
        letterSpacing: '0.9px',
        textTransform: 'capitalize',
        color: theme.palette.text.primary,
    },
    subtext: {
        color: theme.palette.secondary.light,
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        width: 4,
        [theme.breakpoints.down('md')]: {
            width: 4
        }
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(2)
    },
    sellingPoint: {
        color: theme.palette.secondary.light,
    },
    poweredBy: {
        fontWeight: 100,
    },
    poweredByCompany: {
        ...theme.typography.subtitle2,
        fontWeight: 'bold',
        fontFamily: 'Usuzi, sans-serif'
    },
    presentedBy: {
        fontWeight: 100,
    },
    presentedByCompany: {
        ...theme.typography.subtitle2,
        fontWeight: 'bold',
        fontStyle: 'italic',
        fontFamily: 'Arial, sans-serif'
    },
}));

interface Props {
    label: string;
    subtext?: string;
    sellingPoint?: string;
}

const PageTitle = ({ label, subtext, sellingPoint }: Props): JSX.Element => {
    const classes = useStyles();
    const isWhiteLabel = process.env.REACT_APP_BRAND !== process.env.REACT_APP_TEMPLATE_TYPE;

    let poweredByJsx = <></>;

    if (isWhiteLabel) {
        const poweredByText = intl.get('common.page.header.whitelabel.poweredBy').d('Powered By');
        const companyNameText = intl.get('common.companyName').d('RACINGEDGE');
        poweredByJsx = (
            <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" className={classes.poweredBy}>{poweredByText} <span className={classes.poweredByCompany}>{companyNameText}</span></Typography>
            </Box>
        );

        if (process.env.REACT_APP_BRAND === 'AREA_27') {
            const presentedByText = intl.get('common.page.header.whitelabel.presentedBy').d('The Racing Edge Paddock presented by');
            poweredByJsx = (
                <Box sx={{ ml: 2 }}>
                    <Typography variant="subtitle2" className={classes.presentedBy}>{presentedByText} <span className={classes.presentedByCompany}>AREA27</span></Typography>
                </Box>
            );
        }
    }

    return (
        <Box>
            <Box sx={{ display: 'flex' }}>
                <Divider className={classes.divider} orientation="vertical" flexItem />
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Typography variant="h1" className={classes.text}>
                        {label}
                    </Typography>
                    { poweredByJsx }
                </Box>
            </Box>
            <Box className={classes.subContainer}>
                { subtext && <Typography className={classes.subtext} variant="subtitle1">{subtext}</Typography>}
                { sellingPoint && <Typography className={classes.sellingPoint} variant="subtitle2">{sellingPoint}</Typography>}
            </Box>
        </Box>
    );
};

export default React.memo(PageTitle);
