import React, { createContext, useContext } from 'react';
import { useQuery } from '@apollo/client';

import QueryResult from '../../components/QueryResult';
import ErrorMessage from '../../components/ErrorMessage';
import AnimatedLoader from '../../components/AnimatedLoader';

import { BrandConfigurationDataType, BrandConfigurationType, BrandVariablesType } from '../../types';
import { QUERY_BRAND_CONFIGURATION } from '../../queries';
import { BRAND } from '../../constants';
import defaultValues from './defaultValue';

interface Props {
    children: React.ReactNode;
}

const BrandConfigurationContext = createContext<BrandConfigurationType>(defaultValues);

export const BrandConfigurationProvider = ({ children }: Props): JSX.Element => {
    // This query will load in user data if a JWT_TOKEN exists within localStorage
    const { data, loading, error } = useQuery<BrandConfigurationDataType, BrandVariablesType>(QUERY_BRAND_CONFIGURATION, {
        variables: {
            // if no brand is provided, use the Racing Edge Brand configuration instead
            brand: BRAND
        }
    });

    return (
        <QueryResult
            data={data}
            loading={loading}
            error={error}
            errorComponent={<ErrorMessage />}
            loadingComponent={<AnimatedLoader name="BrandConfigurationProvider" />}
            // loadingComponent={<AnimatedLoader name="BrandConfigurationWrapper" />}
        >
            {
                ({ configurationByBrand }) => (
                    <BrandConfigurationContext.Provider value={configurationByBrand}>
                        {children}
                    </BrandConfigurationContext.Provider>
                )
            }
        </QueryResult>
    );
};

export const useBrandConfigurationContext = (): BrandConfigurationType => useContext(BrandConfigurationContext);

export default React.memo(BrandConfigurationProvider);
