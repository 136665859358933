import React from 'react';
import intl from 'react-intl-universal';
import { Box, Button, Grid, IconButton, Popover, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';

import { DirectoryType } from '../../types';

const useStyles = makeStyles((theme) => ({
    iconStyle: {
        color: theme.palette.text.primary,
    },
    paper: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(1),
    },
    iconBtn: {
        minWidth: 'unset',
        borderRadius: '50%',
        padding: theme.spacing(1),
    },
}));

interface Props {
    directory: DirectoryType;
}

const MoreDisplay = ({ directory }: Props): JSX.Element => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (!directory) {
        return (<></>);
    }

    const more = intl.get('directory.featured.more').d('More');

    return (
        <Box>
            <Tooltip enterTouchDelay={50} title={more}>
                <IconButton onClick={handlePopoverOpen} size="small" aria-label="more">
                    <MoreIcon className={classes.iconStyle} />
                </IconButton>
            </Tooltip>
            <Popover
                id="mouse-over-popover"
                classes={{ paper: classes.paper }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Grid container direction="column" spacing={1}>
                    {directory && directory.socialLinks.facebook && (
                        <Grid item>
                            <a href={directory.socialLinks['facebook']} target="_blank" rel="noopener noreferrer">
                                <Button className={classes.iconBtn} color="secondary" variant="contained">
                                    <FacebookIcon />
                                </Button>
                            </a>
                        </Grid>
                    )}
                    {directory && directory.socialLinks.instagram && (
                        <Grid item>
                            <a href={directory.socialLinks['instagram']} target="_blank" rel="noopener noreferrer">
                                <Button className={classes.iconBtn} color="secondary" variant="contained">
                                    <InstagramIcon />
                                </Button>
                            </a>
                        </Grid>
                    )}
                    {directory && directory.socialLinks.linkedin && (
                        <Grid item>
                            <a href={directory.socialLinks['linkedin']} target="_blank" rel="noopener noreferrer">
                                <Button className={classes.iconBtn} color="secondary" variant="contained">
                                    <LinkedInIcon />
                                </Button>
                            </a>
                        </Grid>
                    )}
                    {directory && directory.socialLinks.twitter && (
                        <Grid item>
                            <a href={directory.socialLinks['twitter']} target="_blank" rel="noopener noreferrer">
                                <Button className={classes.iconBtn} color="secondary" variant="contained">
                                    <TwitterIcon />
                                </Button>
                            </a>
                        </Grid>
                    )}
                    {directory && directory.socialLinks.youtube && (
                        <Grid item>
                            <a href={directory.socialLinks['youtube']} target="_blank" rel="noopener noreferrer">
                                <Button className={classes.iconBtn} color="secondary" variant="contained">
                                    <YouTubeIcon />
                                </Button>
                            </a>
                        </Grid>
                    )}
                </Grid>
            </Popover>
        </Box>
    );
};

export default MoreDisplay;
