import React, { useRef } from 'react';
import intl from 'react-intl-universal';
import { useDrag, useDrop } from 'react-dnd';
import { Box, Typography, IconButton, Tooltip } from '@material-ui/core';
import {
    Delete as DeleteIcon,
    // Crop as CropIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { ImageStateType } from '../../../types';
// import CropDialog from '../../CropDialog';

const useStyles = makeStyles(theme => ({
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(2)
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    imageItem: {
        position: 'relative',
        width: 'calc(25% - 18px)',
        height: 160,
        border: `1px solid ${theme.palette.common.black}`,
        margin: theme.spacing(1),
        cursor: 'grab',
        transition: 'all 0.2s linear',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 150px)',
            height: 80
        },
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center'
    },
    actions: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 0,
        margin: theme.spacing(1, 0, 0, 0)
    },
    icon: {
        fontSize: '1rem'
    },
    button: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        marginRight: theme.spacing(1),
        padding: theme.spacing(0.5),
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        }
    },
}));

type DragNDropItemType = {
    type: string;
    id: string;
    index: number;
}

interface ImageProps {
    image: ImageStateType;
    index: number;
    moveImage: (dragIndex: number, hoverIndex: number) => void;
    updateImage: (itemIndex: number, newImage: string) => void;
    removeImage: (itemIndex: number) => void;
}

interface Props {
    images: Array<ImageStateType>;
    moveImage: (dragIndex: number, hoverIndex: number) => void;
    updateImage: (itemIndex: number, newImage: string) => void;
    removeImage: (itemIndex: number) => void;
}

const type = 'Image'; // Need to pass which type element can be draggable

const Image = ({ image, index, moveImage, updateImage, removeImage }: ImageProps): JSX.Element => {
    const classes = useStyles();
    const ref = useRef(null);
    // const [isCropOpen, setIsCropOpen] = useState<boolean>(false);

    // const handleOpen = () => {
    //     setIsCropOpen(true);
    // };

    // const handleClose = () => {
    //     setIsCropOpen(false);
    // };

    // const onCropSubmit = (newImage: string) => {
    //     updateImage(index, newImage);
    // };

    const [, drop] = useDrop({
        accept: type,
        hover(item: DragNDropItemType) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Move the content
            moveImage(dragIndex, hoverIndex);
            // Update the index for dragged item directly to avoid flickering when half dragged
            item.index = hoverIndex;
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type,
        item: {
            type,
            id: image.id,
            index
        },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    // initialize drag and drop into the element
    drag(drop(ref));


    const deleteText = intl.get('generic.action.delete').d('Delete');
    // const cropText = intl.get('generic.action.crop').d('Crop');

    return (
        <Box
            ref={ref}
            style={{ opacity: isDragging ? 0 : 1 }}
            className={classes.imageItem}
        >
            <img alt={`img - ${image.id}`} src={image.src} className={classes.image} />
            <Box className={classes.actions}>
                {/* TODO: Enabled cropping when completed */}
                {/* {
                    image.src && (
                        <Tooltip enterTouchDelay={50} title={cropText}>
                            <IconButton
                                className={classes.button}
                                size="small"
                                onClick={handleOpen}
                                aria-label="crop"
                            >
                                <CropIcon className={classes.icon} />
                            </IconButton>
                        </Tooltip>
                    )
                } */}
                <Tooltip enterTouchDelay={50} title={deleteText}>
                    <IconButton
                        className={classes.button}
                        size="small"
                        onClick={() => removeImage(index)}
                        aria-label="delete"
                    >
                        <DeleteIcon className={classes.icon} />
                    </IconButton>
                </Tooltip>
            </Box>
            {/* {
                image && isCropOpen && <CropDialog onClose={handleClose} onCropSubmit={onCropSubmit} image={image} />
            } */}
        </Box>
    );
};

const ImageListField = ({ images, moveImage, updateImage, removeImage }: Props): JSX.Element => {
    const classes = useStyles();

    const point1Text = intl.get('common.field.imageList.dragDropInst').d('Drag the images to re-order them');
    const point2Text = intl.get('common.field.imageList.dragDrop').d('The first image will be used as the cover image of the listing.');

    return images.length > 0 ? (
        <Box>
            <Box className={classes.textContainer}>
                <Typography variant="body1">{point1Text}</Typography>
                <Typography variant="body1">{point2Text}</Typography>
            </Box>
            <Box className={classes.imageContainer}>
                {
                    images.map((image, index) => (
                        <Image
                            image={image}
                            index={index}
                            key={`${image.id}-image`}
                            moveImage={moveImage}
                            updateImage={updateImage}
                            removeImage={removeImage}
                        />
                    ))
                }
            </Box>
        </Box>
    ) : (
        <></>
    );
};

export default ImageListField;
