import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

import { lazyWithRetry } from '../../common/helpers';

import PrivateRoute from '../../common/routes/PrivateRoute';
import AnimatedLoader from '../../common/components/AnimatedLoader';

const CheckoutPage = lazyWithRetry(() => import('../pages/Checkout'));
const CreateListingPage = lazyWithRetry(() => import('../pages/CreateListing'));
const EditListingPage = lazyWithRetry(() => import('../pages/EditListing'));
const HomePage = lazyWithRetry(() => import('../pages/Home'));
const ListingPage = lazyWithRetry(() => import('../pages/Listing'));
const ListingGroupPage = lazyWithRetry(() => import('../pages/ListingGroup'));
const OtherListingsByPage = lazyWithRetry(() => import('../pages/OtherListingsBy'));

interface Props {
    queryParams: string | null;
}

const PaddockRoutes = ({ queryParams }: Props): JSX.Element => {
    const { url } = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        if (queryParams) {
            history.push({
                search: queryParams
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Suspense fallback={<AnimatedLoader name="PaddockRoutes" />}>
            <Switch>
                <Route exact path={url}>
                    <HomePage />
                </Route>
                {/* Checkout Page - appropriate location state should be set */}
                <PrivateRoute exact path={`${url}/checkout`} >
                    <CheckoutPage />
                </PrivateRoute>
                <Route exact path={`${url}/listings/:userId`}>
                    <OtherListingsByPage />
                </Route>
                <PrivateRoute exact path={`${url}/listing/create`}>
                    <CreateListingPage />
                </PrivateRoute>
                <PrivateRoute exact path={`${url}/listing/:listingId/edit`}>
                    <EditListingPage />
                </PrivateRoute>
                <PrivateRoute exact path={`${url}/group`}>
                    <ListingGroupPage />
                </PrivateRoute>
                <Route exact path={`${url}/:category`}>
                    <HomePage />
                </Route>
                <Route exact path={`${url}/:category/:type`}>
                    <HomePage />
                </Route>
                <Route exact path={`${url}/:category/:type/:slug`}>
                    <ListingPage />
                </Route>
            </Switch>
        </Suspense>
    );
};

export default PaddockRoutes;
