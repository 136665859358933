import React, { useState, useEffect } from 'react';
// import intl from 'react-intl-universal';
import { useHistory, useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';

import LoginRequired from '../../components/LoginRequired';
import Plans from '../../containers/Plans';
import DirectoryForm from '../../components/DirectoryForm';

import { useLoggedIn } from '../../../common/hooks';
import { PricingPackageType } from '../../../common/types';

const AddPage = (): JSX.Element => {
    const history = useHistory();
    const location = useLocation();
    const [isLoggedIn] = useLoggedIn();
    // The user-selected directory package
    const [plan, setPlan] = useState<PricingPackageType | undefined>(undefined);

    // Ensure the pathing works as expected
    useEffect(() => {
        if (location.pathname.endsWith('add')) {
            // Upon going 'back' clear the plan so the user may re-select
            setPlan(undefined);
        } else if (!plan) {
            // The location is currently "...add/PLAN" but we do not have a plans info
            // replace the url as we will be requiring they select a plan
            history.replace('/directory/add');
        }
    }, [location, history, plan]);

    // Function for when the user selects a plan
    const selectPlan = (plan: PricingPackageType) => {
        // Set the chosen plans info
        setPlan(plan);
        // Update the url
        history.push(`/directory/add/${plan.title.toLowerCase()}`);
    };

    // Function for when the user chooses to change the plan
    const changePlan = () => {
        // Set the chosen plans info
        setPlan(undefined);
        // Replace the url
        history.replace('/directory/add');
    };

    // User must be signed in
    if (!isLoggedIn) {
        return (
            <Box>
                <LoginRequired action="add" />
            </Box>
        );
    }

    // Select a plan
    if (!plan) {
        // First need to select a plan
        return <Plans setPlan={selectPlan} />;
    }

    // Once a plan is selected, allow filling the form
    return (
        <Box sx={{ mt: 4 }}>
            <DirectoryForm action="add" plan={plan} goBack={changePlan} />
        </Box>
    );
};

export default AddPage;
