import { CategoryType } from './types';

/* Directory Categories */
export const categories: Array<CategoryType> = [
    'RACETRACK',
    'RESTORATION',
    'STORAGE',
    'TRANSPORTATION',
    'ENGINEERS',
    'PARTS',
    'TIRES',
    'RACINGCLUB',
    'INSPECTION',
    'SIMRACING',
];

/* Strapi Object Properties */
export const DIRECTORY_PROPERTIES = `
    id
    status
    featured
    name
    category
    addressCountry
    addressState
    addressCity
    addressPostal
    addressStreet1
    addressStreet2
    hoursOfOperation
    website
    phone
    email
    keywords
    socialLinks {
        facebook
        instagram
        youtube
        linkedin
        twitter
    }
    videoURL
    image {
        id
        url
    }
    activeSubscription
`;

/** Generic Constants */
export const NOT_FOUND = -1;
export const DEFAULT_FEATURED_PAGE_SIZE = 4;
export const DEFAULT_NON_FEATURED_PAGE_SIZE = 6;
export const MINIMUM_FEATURED = DEFAULT_FEATURED_PAGE_SIZE;
