import React, { useState } from 'react';
import intl from 'react-intl-universal';
import {
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
    Box,
    Container,
    Divider,
    Typography,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { BrandFAQDataType, BrandVariablesType } from '../../types';
import { BRAND, FAQ_PAGE } from '../../constants';
import { QUERY_FAQS } from '../../queries';

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 0,
        '&$expanded': {
            minHeight: 0,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        paddingTop: 0,
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
    },
    faqTitle: {
        fontWeight: 'bold',
        // // textTransform: 'uppercase',
    },
    faqBody: {
        color: theme.palette.text.secondary,
    },
    title: {
        // // textTransform: 'uppercase',
        fontWeight: 'bold'
    }
}));

interface Props {
    page: FAQ_PAGE;
}

const FAQ = ({ page }: Props): JSX.Element => {
    const classes = useStyles();

    // Load the page's FAQ
    const { data, loading, error } = useQuery<BrandFAQDataType, BrandVariablesType>(QUERY_FAQS, {
        variables: {
            brand: BRAND
        }
    });

    // Object tracking the expanded FAQs
    const [expanded, setExpanded] = useState({});
    // Function used to check if the provided FAQ is expanded
    const isExpanded = (faq) => !!expanded[faq.title];
    // Function used to expand/collapse the provided FAQ
    const toggleExpanded = (faq) => {
        setExpanded({
            ...expanded,
            [faq.title]: !expanded[faq.title]
        });
    };

    // Get the list of FAQs
    const FAQs = data?.faqsByBrand?.[page] || [];

    // If the FAQs are loading, there was an error, or are empty, display nothing
    if (loading || error || FAQs.length === 0) {
        return <></>;
    }

    const faqText = intl.get('generic.title.faq').d('FAQ');

    return (
        <Box className={classes.root}>
            <Box sx={{ mt: 6 }}>
                <Box sx={{ mb: 4 }}>
                    <Container>
                        <Typography align="center" variant="h2" className={classes.title}>{faqText}</Typography>
                    </Container>
                </Box>
                {FAQs.map(FAQ => (
                    <Box key={FAQ.title}>
                        <Container>
                            <Accordion square expanded={isExpanded(FAQ)} onChange={() => toggleExpanded(FAQ)}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="h4" className={classes.faqTitle}>{FAQ.title}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ ml: 2 }}>
                                                <ChevronRightIcon style={{ transition: 'all 0.2s', transform: `rotate(${isExpanded(FAQ) ? 270 : 90}deg)` }} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body1" className={classes.faqBody}>{FAQ.description}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Container>
                        <Box sx={{ mt: 4, mb: 6 }}>
                            <Divider />
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default FAQ;
