import React from 'react';
import moment from 'moment';
import intl from 'react-intl-universal';
// import { makeStyles } from '@material-ui/core/styles';

import RoundedBox from '../RoundedBox';
import CountdownTimer from '../../../common/components/CountdownTimer';

interface Props {
    startDate?: string;
    endDate?: string;
    border?: boolean;
    endingText?: string;
}

const Countdown = ({ startDate, endDate, border = true, endingText }: Props): JSX.Element => {
    const hasStarted = startDate ? moment(Date.now()).isAfter(startDate) : false;
    const startsInText = intl.get('common.countdown.startsIn').d('Starting in');
    const endsInText = intl.get('common.countdown.endsIn').d('Ending in');

    let countdownJsx = <></>;

    if (hasStarted && endDate) {
        if (!border) {
            countdownJsx = (
                <CountdownTimer date={endDate} align="center" variant="h2" textPrefix={endsInText} endingText={endingText} />
            );
        } else {
            countdownJsx = (
                <RoundedBox>
                    <CountdownTimer date={endDate} align="center" variant="h2" textPrefix={endsInText} endingText={endingText} />
                </RoundedBox>
            );
        }
    } else if (!hasStarted && startDate) {
        if (!border) {
            countdownJsx = (
                <CountdownTimer date={startDate} align="center" variant="h2" textPrefix={startsInText} endingText={endingText} />
            );
        } else {
            countdownJsx = (
                <RoundedBox>
                    <CountdownTimer date={startDate} align="center" variant="h2" textPrefix={startsInText} endingText={endingText} />
                </RoundedBox>
            );
        }
    }

    return countdownJsx;
};

export default Countdown;
