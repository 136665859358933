import { bookmarkIdsVar } from '../../cache';
import { DIRECTORY_STATUS } from '../common/constants';
import { categories } from './constants';
import { PotentialCategoryType, DirectoryType, CategoryType } from './types';

export const generateDummyDirectory = (): DirectoryType => {
    if ((window as any).nextGlobalId) {
        (window as any).nextGlobalId++;
    } else {
        (window as any).nextGlobalId = 1;
    }

    const id = (window as any).nextGlobalId;

    return {
        id,
        status: DIRECTORY_STATUS.PENDING,
        featured: false,
        name: 'Example',
        category: 'TRANSPORTATION',
        addressCountry: 'USA',
        addressState: 'NY',
        addressCity: 'New York',
        addressPostal: '12345',
        addressStreet1: '1234 Fake Street',
        addressStreet2: '',
        hoursOfOperation: null,
        website: 'www.example.com',
        phone: '123 456 7890',
        email: 'my@email.com',
        keywords: [],
        socialLinks: {
            facebook: 'facebook.com',
            instagram: 'instagram.com',
            youtube: 'youtube.com',
            linkedin: 'linkedin.com',
            twitter: 'twitter.com',
        },
        videoURL: '',
        image: {
            id: '0',
            url: '',
            name: '',
            mime: 'image/jpeg',
            alternativeText: '',
            caption: '',
            formats: {}
        },
        activeSubscription: false,
    };
};

export const displayUrl = (urlString: string | undefined): string => {
    if (!urlString) {
        return '';
    }
    // Strips the beginning of any http(s) or www
    let result = urlString.replace(/^(https?:\/\/)?(www\.)?/, '');
    // Strips anything after the first /
    result = result.replace(/\/.*$/, '');

    // Example  1: http://www.google.ca/?q=racingedge
    // Turns into: google.ca

    // Example  2: http://www.paddock.racingedge.com/directory
    // Turns into: paddock.racingedge.com
    return result;
};

export const displayAddress = (item: DirectoryType): string => {
    const country = (item.addressCountry || '').trim();
    const state = (item.addressState || '').trim();
    const city = (item.addressCity || '').trim();
    let result = country;
    if (state) {
        result += `, ${state}`;
    }
    if (city) {
        result += `, ${city}`;
    }
    return result;
};

export const getCategoryFromPath = (path: string): PotentialCategoryType => {
    // Default EVERYTHING
    let category: PotentialCategoryType = 'EVERYTHING';

    // Determine the selected category from the url
    const categoryMatch = path.match(/\/directory\/category\/(\w+)/);
    if (categoryMatch) {
        // Potentially not an actual CategoryType
        const _category: CategoryType = categoryMatch[1].toUpperCase() as CategoryType;
        // This enforces we only set the category if it is a valid type
        if (categories.indexOf(_category) > -1) {
            category = _category;
        }
    }

    return category;
};

export const setBookmarkIds = (bookmarkIds: Array<number>): void => {
    const newBookmarkIds = [...bookmarkIdsVar(), ...bookmarkIds];
    bookmarkIdsVar(newBookmarkIds);
};

export const addBookmarkId = (bookmarkId: number): void => {
    bookmarkIdsVar([...bookmarkIdsVar(), bookmarkId]);
};

export const removeBookmarkId = (bookmarkId: number): void => {
    const filteredBookmarkIds = [...bookmarkIdsVar()].filter(id => id !== bookmarkId);
    bookmarkIdsVar(filteredBookmarkIds);
};
