import React from 'react';
import intl from 'react-intl-universal';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { CurrentRefinementsProvided } from 'react-instantsearch-core';

const useStyles = makeStyles(theme => ({
    button: {
        ...theme.typography.button,
        padding: theme.spacing(0)
    },
}));

type Props = CurrentRefinementsProvided;

const ClearRefinements = ({ items, refine }: Props): JSX.Element => {
    const classes = useStyles();
    const clearRefinementsText = intl.get('generic.button.clearFilters').d('Clear Filters');
    return (<Button className={classes.button} color="primary" onClick={() => refine(items)} disabled={!items.length}>{clearRefinementsText}</Button>);
};

export default React.memo(connectCurrentRefinements(ClearRefinements));
