import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Box,
    IconButton,
    DialogTitle,
    Dialog as MuiDialog,
    Tooltip,
    DialogContent,
    useMediaQuery
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import LogoLink from '../../components/LogoLink';
import FormLoginUser from '../../forms/auth/LoginUser';
import FormRegisterUser from '../../forms/auth/RegisterUser';
import FormForgotPassword from '../../forms/auth/ForgotPassword';
import FormResendConfirmation from '../../forms/auth/ResendConfirmation';

import { VARIATIONS } from '../../constants';
import { AuthenticationDialogActionType, BrandThemeType } from '../../types';
import { useBrandConfigurationContext } from '../../hooks';

const Dialog = withStyles({
    paper: {
        borderRadius: 0,
        minWidth: '50%',
    },
})(MuiDialog);

const useStyle = makeStyles(theme => ({
    content: {
        marginBottom: '8vh',
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
            marginBottom: '2vh',
        },
        backgroundColor: 'inherit',
        color: 'inherit',
    },
    dialog: {
        '& .MuiDialog-paper': {
            backgroundColor: (configTheme: BrandThemeType) => {
                if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_SECONDARY) {
                    return theme.palette.secondary.main;
                } else if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_PRIMARY) {
                    return theme.palette.primary.main;
                }
                return theme.palette.background.paper;
            },
            color: (configTheme: BrandThemeType) => {
                if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_SECONDARY) {
                    return theme.palette.secondary.contrastText;
                } else if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_PRIMARY) {
                    return theme.palette.primary.contrastText;
                }
                return theme.palette.text.primary;
            },
        },
    },
    dialogTitle: {
        [theme.breakpoints.down('md')]: {
            paddingBottom: 0,
        }
    },
    logo: {
        maxWidth: '80%',
        maxHeight: (configTheme: BrandThemeType) => `${configTheme.dialogLogoHeight || 30}px`,
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(3),
    },
    iconStyle: {
        color: (configTheme: BrandThemeType) => {
            if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_SECONDARY) {
                return theme.palette.secondary.contrastText;
            } else if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_PRIMARY) {
                return theme.palette.primary.contrastText;
            }
            return theme.palette.secondary.main;
        },
    },
}));

interface Props {
    action?: AuthenticationDialogActionType;
    onClose: () => void;
    onLogin?: () => void;
    onRegister?: () => void;
    onForgotPassword?: () => void;
    onResendConfirmation?: () => void;
}

const AuthenticationDialog = ({ action = 'login', onClose, onLogin, onRegister, onForgotPassword, onResendConfirmation }: Props): JSX.Element => {
    const brandConfig = useBrandConfigurationContext();
    const { theme: configTheme } = brandConfig;
    const classes = useStyle(configTheme);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { loginLogo } = configTheme.images;

    // Action is used to determine which form to display: login, register, or forgot password
    const [selectedAction, setSelectedAction] = useState<AuthenticationDialogActionType>(action);

    // Function to change the action, provided to all the inner components so
    // they may communicate to this parent component to change to another form
    const switchAction = (newAction: AuthenticationDialogActionType) => setSelectedAction(newAction);

    const handleCloseDialog = () => {
        setSelectedAction('login');
        onClose();
    };

    const handleLogin = () => {
        onLogin && onLogin();
        handleCloseDialog();
    };

    const handleRegister = () => {
        onRegister && onRegister();
        handleCloseDialog();
    };

    const handleForgotPassword = () => {
        onForgotPassword && onForgotPassword();
        handleCloseDialog();
    };

    const handleResendConfirmation = () => {
        onResendConfirmation && onResendConfirmation();
        handleCloseDialog();
    };

    // Default contents, will always be populated from the next if-else block
    let contents = <></>;


    const closeText = intl.get('common.icon.close.tooltip').d('Close');

    const loginTitleText = intl.get('common.form.login.title').d('Login');
    const loginSubtitleText = intl.get('common.form.login.subtitle').d('');

    const registerTitleText = intl.get('common.form.register.title').d('Register');
    const registerSubtitleText = intl.get('common.form.register.subtitle').d('');

    const forgotPasswordTitleText = intl.get('common.form.forgotPassword.title').d('Forgot Password');
    const forgotPasswordSubtitleText = intl.get('common.form.forgotPassword.subtitle').d('');

    const resendConfirmationTitleText = intl.get('common.form.resendConfirmation.title').d('Resend Confirmation');
    const resendConfirmationSubtitleText = intl.get('common.form.resendConfirmation.subtitle').d('');


    // Determine which contents to display
    if (selectedAction === 'login') {
        // Display the login form
        contents = <FormLoginUser
            title={loginTitleText}
            subtitle={loginSubtitleText}
            configTheme={configTheme}
            switchAction={switchAction}
            onLogin={handleLogin}
        />;
    } else if (selectedAction === 'register') {
        // Display the register form
        contents = <FormRegisterUser
            title={registerTitleText}
            subtitle={registerSubtitleText}
            configTheme={configTheme}
            onRegister={handleRegister}
            switchAction={switchAction}
        />;
    } else if (selectedAction === 'forgot') {
        // Display the forgot password form
        contents = <FormForgotPassword
            title={forgotPasswordTitleText}
            subtitle={forgotPasswordSubtitleText}
            configTheme={configTheme}
            onForgetPassword={handleForgotPassword}
            switchAction={switchAction}
        />;
    } else if (selectedAction === 'resendConfirmation') {
        // Display the Resend Confirmation form
        contents = <FormResendConfirmation
            title={resendConfirmationTitleText}
            subtitle={resendConfirmationSubtitleText}
            configTheme={configTheme}
            onResendConfirmation={handleResendConfirmation}
            switchAction={switchAction}
        />;
    } else {
        const error = intl.get('common.form.auth.action.error', { action: selectedAction }).d(`The action ${selectedAction} is not-yet supported.`);
        // Throw an error. If a new action was introduced, this will alert
        // that the component is missing an action implementation
        throw new Error(error);
    }

    // Render a dialog with the contents
    return (
        <Dialog onClose={handleCloseDialog} open={true} className={classes.dialog} fullWidth fullScreen={isMobile}>
            <DialogTitle className={classes.dialogTitle}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                        <LogoLink ad={loginLogo} logoClass={classes.logo} />
                    </Box>
                    <Tooltip enterTouchDelay={50} title={closeText}>
                        <IconButton className={classes.iconStyle} onClick={handleCloseDialog} aria-label={closeText}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </DialogTitle>
            <DialogContent className={classes.content}>
                {contents}
            </DialogContent>
        </Dialog>
    );
};

export default AuthenticationDialog;
