import { gql } from '@apollo/client';

export const MUTATION_CREATE_AUCTION = gql`
    mutation createValidAuction($input: createAuctionInput) {
        createAuction(input: $input) {
            auction {
                id
            }
        }
    }
`;

export const MUTATION_UPDATE_AUCTION = gql`
    mutation updateAuction($input: updateAuctionInput) {
        updateAuction(input: $input) {
            auction {
                id
            }
        }
    }
`;

export const MUTATION_CREATE_BID = gql`
    mutation createBid($input: createBidInput) {
        createBid(input: $input) {
            bid {
                id
            }
        }
    }
`;

export const MUTATION_COMMENT_CREATE = gql`
    mutation commentAdd($comment: CommentNew) {
        commentAdd(comment: $comment) {
            id
            content
            authorId
            authorName
        }
    }
`;

export const MUTATION_COMMENT_LIKE = gql`
    mutation commentLike($id: ID!, $relation: Relation!) {
        commentLike(id: $id, relation: $relation) {
            id
            points
        }
    }
`;

export const MUTATION_COMMENT_REPORT_ABUSE = gql`
    mutation commentReportAbuse($id: ID!, $relation: Relation! $reason: CommentReportReason!, $content: String!) {
        commentReportAbuse(id: $id, relation: $relation, reason: $reason, content: $content) {
            id
            reason
            content
            resolved
        }
    }
`;

export const MUTATION_COMMENT_REMOVE = gql`
    mutation commentRemove($id: ID!, $relation: Relation!, $authorId: ID!) {
        commentRemove(id: $id, relation: $relation, authorId: $authorId) {
            id
        }
    }
`;

export const MUTATION_WATCH_AUCTION = gql`
    mutation watchAuction($id: ID!, $userId: ID!, $watch: Boolean!) {
        watchAuction(id: $id, userId: $userId, watch: $watch ) {
            success
        }
    }
`;
