/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Box, Grid, GridDirection, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import parse from 'html-react-parser';

import { PictureInfoType } from '../../types';
import { getImageUrl } from '../../helpers';

const useStyle = makeStyles(theme => ({
    title: {
        fontWeight: 'bold',
    },
    imageContainer: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    background: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(5, 0),
    },
    content: {
        ...theme.typography.body1
    },
    image: {
        width: '100%',
        height: '100%',
    }
}));

interface Props extends PictureInfoType {
    imagePosition?: 'right' | 'left';
}

const ContentWithImage = ({ title, description, image, imagePosition = 'right' }: Props): JSX.Element => {
    const classes = useStyle();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const mediaUrl = getImageUrl(image, 'medium');

    let position: GridDirection = 'row';

    if (imagePosition === 'right') {
        position = 'row';
        if (isMobile) {
            position = 'column';
        }
    } else if (imagePosition === 'left') {
        position = 'row-reverse';
        if (isMobile) {
            position = 'column-reverse';
        }
    }

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item container direction={position} spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                    {
                        title && <Typography variant="h2" className={classes.title}>{title}</Typography>
                    }
                    <Box className={classes.content}>
                        {
                            parse(description)
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box className={classes.imageContainer}>
                        <img className={classes.image} src={mediaUrl} alt={image?.alternativeText} />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ContentWithImage;
