import React, { useState } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connectSortBy } from 'react-instantsearch-dom';
import { MenuProvided } from 'react-instantsearch-core';

const useStyles = makeStyles(theme => ({
    textField: {
        ...theme.typography.body2,
        '& .MuiFormHelperText-root': {
            ...theme.typography.subtitle1
        },
        '& .MuiOutlinedInput-input': {
            [theme.breakpoints.down('md')]: {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1)
            }
        }
    },
    nativeInput: {
        width: 'initial'
    }
}));

type Props = MenuProvided & {
    onSort?: (indexName: string) => void;
}

const SortBy = ({ refine, items, currentRefinement, onSort }: Props) => {
    const classes = useStyles();
    const [refinement, setRefinement] = useState(currentRefinement);

    const handleSort = (newIndexName: string) => {
        setRefinement(newIndexName);
        onSort && onSort(newIndexName);
        refine(newIndexName);
    };

    return (
        <Select
            fullWidth
            variant="outlined"
            color="secondary"
            className={classes.textField}
            classes={{
                nativeInput: classes.nativeInput
            }}
            defaultValue={refinement}
            onChange={event => {
                event.preventDefault();
                const val = event.target.value;
                handleSort(val);
            }}
        >
            {
                items.map((item) => (
                    <MenuItem
                        key={item.value}
                        value={item.value}
                    >
                        {item.label}
                    </MenuItem>
                ))
            }
        </Select>
    );
};

export default React.memo(connectSortBy(SortBy));
