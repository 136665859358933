import { AUCTION_DEFAULT_CONSTANTS, BRAND } from '../../constants';
import { BrandConfigurationAuctionType, BrandConfigurationType, BrandThemeType, BusinessHoursType, ConfigHeaderType, ConfigModulesType, HeatmapConfigurationType, PaddockConfigurationType, SocialLinksType } from '../../types';

const brand = BRAND;
const rootUrl = 'https://racingedge.com';
const companyName = 'Racing Edge';
const isFeaturedCarouselDisabled = false;
const isFloatingSellButtonDisabled = false;

const modules: Array<ConfigModulesType> = [
    {
        name: 'paddock',
        active: true,
    },
    {
        name: 'partnership',
        active: true,
        url: '/page/partners'
    },
    {
        name: 'directory',
        active: true,
    },
    {
        name: 'media',
        active: true,
    }
];
const headerNav: Array<ConfigHeaderType> = [
    {
        active: true,
        title: 'Paddock',
        url: '/paddock',
    },
    {
        active: true,
        title: 'Media',
        url: '/media'
    },
    {
        active: true,
        title: 'Directory',
        url: '/directory'
    }
];

const businessHours: BusinessHoursType = {
    timezone: 'Pacific Daylight Time',
    weekdays: '9AM - 5PM',
    weekend: 'Closed',
};

const paddockConfiguration: PaddockConfigurationType = {
    numberOfHits: 32
};

const heatmap: HeatmapConfigurationType = {
    //  title: '',
    description: '',
    image: {
        alternativeText: '',
        caption: '',
        id: '0',
        url: '',
        name: '',
        mime: 'image/jpeg',
        formats: {}
    }
};

const emailNotificationConfiguration = {
    adminEmail: 'info@racingedge.com',
    daysBeforeExpiry: 7,
    emailExpiryOffset: 2,
    salesEmail: 'info@racingedge.com'
};

const contactUsConfiguration = {
    email: 'info@racingedge.com',
    phoneNumber: '+1 (855) 722-4643',
    address: '30-00 1 North Bridge Road, Singapore'
};

const socialConfiguration: SocialLinksType = {
    facebook: 'https://www.facebook.com/racingedgeofficial',
    instagram: 'https://www.instagram.com/racingedgeofficial/',
    linkedin: 'https://www.linkedin.com/company/40721820/',
    twitter: 'https://twitter.com/Racingedge1',
    youtube: 'https://www.youtube.com/channel/UC0-fFaaWSYA4pwxGkv-0idA'
};

const auctionConfiguration: BrandConfigurationAuctionType = {
    host: {
        blocked: false,
        confirmed: false,
        created_at: '',
        email: '',
        id: -1,
        updated_at: '',
        username: ''
    },
    sniperProtectionTime: AUCTION_DEFAULT_CONSTANTS.SNIPER_PROTECTION_TIME as number,
    displayLimit: AUCTION_DEFAULT_CONSTANTS.DISPLAY_LIMIT as number,
    dateRangeLimit: AUCTION_DEFAULT_CONSTANTS.DATE_RANGE_LIMIT as number,
    bidMinimumIncrement: AUCTION_DEFAULT_CONSTANTS.BID_MIN_INCREMENT as number,
    reserveMeter: {
        enabled: !!AUCTION_DEFAULT_CONSTANTS.RESERVE_METER_ENABLED,
        fillColor: AUCTION_DEFAULT_CONSTANTS.RESERVE_METER_FILL_COLOR as string
    }
};

const theme: BrandThemeType = {
    darkMode: false,
    headerVariation: 'ORIGINAL',
    footerVariation: 'ORIGINAL',
    loginVariation: 'ORIGINAL',
    soldSectionVariation: 'ORIGINAL',
    headerPosition: 'FIXED',
    // primaryFont: '',
    // secondaryFont: '',
    // headerLogoHeight: 0,
    // headerLogoHeightMd: 0,
    colours: {
        primaryColor: '#DC0000',
        secondaryColor: '#0A0A0A',
        success: '#06A77D',
        warning: '#F9C80E',
        info: '#3E92CC',
        error: '#FF0000',
    },
    images: {
        defaultImage: {
            image: {
                alternativeText: '',
                caption: '',
                id: '0',
                url: '',
                name: '',
                mime: 'image/jpeg',
                formats: {}
            },
            url: '/'
        },
        defaultProfile: {
            image: {
                alternativeText: '',
                caption: '',
                id: '0',
                url: '',
                name: '',
                mime: 'image/jpeg',
                formats: {}
            },
            url: '/'
        },
        dashboardLogo: {
            image: {
                alternativeText: '',
                caption: '',
                id: '0',
                url: '',
                name: '',
                mime: 'image/jpeg',
                formats: {}
            },
            url: '/'
        },
        socialLinksLogo: {
            image: {
                alternativeText: '',
                caption: '',
                id: '0',
                url: '',
                name: '',
                mime: 'image/jpeg',
                formats: {}
            },
            url: '/'
        },
        loginLogo: {
            image: {
                alternativeText: '',
                caption: '',
                id: '0',
                url: '',
                name: '',
                mime: 'image/jpeg',
                formats: {}
            },
            url: '/'
        },
        headerLogo: {
            image: {
                alternativeText: '',
                caption: '',
                id: '0',
                url: '',
                name: '',
                mime: 'image/jpeg',
                formats: {}
            },
            url: '/'
        },
    }
};

const defaultValues: BrandConfigurationType = {
    contactUsConfiguration,
    brand,
    businessHours,
    companyName,
    emailNotificationConfiguration,
    headerNav,
    heatmap,
    modules,
    paddockConfiguration,
    rootUrl,
    socialConfiguration,
    theme,
    isFeaturedCarouselDisabled,
    isFloatingSellButtonDisabled,
    auctionConfiguration,
    // announcement,
};

export default defaultValues;
