import { socket } from '../../../socket';
import { SOCKET_DISCONNECT_REASON, SOCKET_EVENT } from '../../common/constants';
import { findTopBidValue } from '../helpers';
import { AuctionType, CommentType, SocketSetValueType } from '../types';

type Props = SocketSetValueType;

export const socketEvents = ({ setValue }: Props): void => {
    socket.on(SOCKET_EVENT.AUCTION, (auction: AuctionType) => {
        const { currency, minIncrementAmount, bids } = auction;
        const topBid = findTopBidValue(bids);
        setValue(prevState => (
            {
                ...prevState,
                auction,
                bids: bids || [],
                topBid,
                currency,
                minIncrementAmount: minIncrementAmount || 0.00
            }
        ));
    });
    socket.on(SOCKET_EVENT.COMMENTS, (comments: Array<CommentType>) => {
        setValue(prevState => (
            {
                ...prevState,
                comments
            }
        ));
    });

    socket.on(SOCKET_EVENT.DISCONNECT, (reason) => {
        if (reason === SOCKET_DISCONNECT_REASON.IO_SERVER) {
            // the disconnection was initiated by the server, we need to reconnect manually
            socket.connect();
        }
        // else the socket will automatically try to reconnect
    });
};
