import React from 'react';
import intl from 'react-intl-universal';
import { Box } from '@material-ui/core';

import PageHeader from '../../../common/components/PageHeader';
import Container from '../../../common/components/Container';
import Auctions from '../../containers/Auctions';
import FAQ from '../../../common/containers/FAQ';

import { FAQ_PAGE } from '../../../common/constants';

const HomePage = (): JSX.Element => {
    const headerText = intl.get('auction.page.home.header.label').d('Auction');

    return (
        <>
            <PageHeader label={headerText} beta />
            <Container>
                <Box sx={{ mt: 3 }}>
                    {/* TODO: No Reserve Auctions Carousel */}
                </Box>
            </Container>
            <Container>
                <Auctions />
            </Container>
            <FAQ page={FAQ_PAGE.AUCTION} />
        </>
    );
};

export default HomePage;
