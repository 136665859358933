import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Tooltip, TextField, Autocomplete } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import { matchSorter } from 'match-sorter';

import { validateMakeField } from '../../validation';
import { QUERY_CAR_MAKE } from '../../../../queries';
import { CarMakeDataType, PropertiesFieldType } from '../../../../types';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    infoIcon: {
        width: 20,
        height: 20,
        marginLeft: theme.spacing(1),
        '& svg': {
            width: 20,
            height: 20,
        }
    },
}));


type Props = PropertiesFieldType & {
    defaultValue?: any;
    disabled?: boolean;
};

const MakeField = ({ id, name, label, required, tooltip, defaultValue, disabled = false }: Props): JSX.Element => {
    const classes = useStyles();
    const { control, errors } = useFormContext();
    const { data } = useQuery<CarMakeDataType>(QUERY_CAR_MAKE);
    const carMakes = data?.carMake?.data || [];
    const filterMakeOptions = (options, { inputValue }) => {
        // Filter the list
        const result = matchSorter(options, inputValue, { keys: ['name', 'synonym', 'slug'] });
        // Get the 'other' option if one exists
        const otherOption = options.find((entry: any) => (entry?.slug === 'other'));
        // If an 'other' option does exist, and the filtered results do not contain it
        if (otherOption && !result.find((entry: any) => (entry?.slug === 'other'))) {
            // Add the 'other' option into the filtered results
            result.push(otherOption);
        }
        // Return the filtered result set
        return result;
    };
    const validate = useMemo(() => validateMakeField({ required }), [required]);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : ''}
            render={(props) => (
                <Box className={`${tooltip ? classes.container : ''}`}>
                    <Autocomplete
                        {...props}
                        id={id}
                        disabled={disabled}
                        disableClearable={disabled}
                        autoHighlight
                        options={carMakes}
                        getOptionLabel={(option) => (option.name || '')}
                        getOptionSelected={(option, value) => option.name === value.name}
                        filterOptions={filterMakeOptions}
                        filterSelectedOptions
                        onChange={(_, data) => {
                            props.onChange(data);
                        }}
                        value={props.value}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                disabled={disabled}
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                required={required}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'disabled'
                                }}
                                helperText={errors[name]?.message}
                                error={!!errors[name]}
                            />
                        )}
                    />
                    {tooltip && (
                        <Box className={classes.infoIcon}>
                            <Tooltip enterTouchDelay={50} title={tooltip} aria-label={tooltip}>
                                <InfoIcon />
                            </Tooltip>
                        </Box>
                    )}
                </Box>
            )}
            rules={{
                validate
            }}
        />
    );
};

export default MakeField;
