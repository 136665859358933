import React, { useState, ReactElement } from 'react';
import { Skeleton, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SavedCard from '../../components/SavedCard';

import { SavedCardType } from '../../types';

const useStyles = makeStyles(theme => ({
    groupRoot: {
        '& > * + *': {
            marginTop: theme.spacing(0.3)
        },
    },
    formRoot: {
        marginRight: theme.spacing(0)
    },
    label: {
        width: '100%'
    }
}));

interface Props {
    card?: SavedCardType | null;
    setUseSavedCard: React.Dispatch<React.SetStateAction<boolean>>;
    loading?: boolean;
}

type RadioSelectType = 'saved' | 'new';

const SavedCardPaymentButon = ({ card, setUseSavedCard, loading = false }: Props): ReactElement => {
    const classes = useStyles();
    const [value, setValue] = useState<RadioSelectType>(card ? 'saved' : 'new');

    if (!card || !card.last4 || !card.cardBrand) {
        return <></>;
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = (event.target as HTMLInputElement).value as RadioSelectType;
        setValue(val);

        if (val === 'saved') {
            setUseSavedCard(true);
        } else {
            setUseSavedCard(false);
        }
    };

    return loading ? (
        <Skeleton width="100%" height={68} />
    ) : (
        <RadioGroup
            classes={{
                root: classes.groupRoot
            }}
            aria-label="payment-method"
            name="controlled-pay-method-radio-buttons-group"
            value={value}
            onChange={handleChange}
        >
            <FormControlLabel
                classes={{
                    root: classes.formRoot,
                    label: classes.label
                }}
                value="new"
                control={<Radio />}
                label={<SavedCard card={card} newCard />}
            />
            <FormControlLabel
                classes={{
                    root: classes.formRoot,
                    label: classes.label
                }}
                value="saved"
                control={<Radio />}
                label={<SavedCard card={card} />}
            />
        </RadioGroup>
    );
};

export default SavedCardPaymentButon;
