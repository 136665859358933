import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import Countdown from '../../components/Countdown';

import RoundedBox from '../../components/RoundedBox';
import BidsDisplay from '../../components/BidsDisplay';
import BidModal from '../BidModal';
import ReserveMeter from '../ReserveMeter';
import BidButton from '../../../common/components/Button';

import { AUCTION_END_PADDING_IN_SECONDS, AUCTION_STATUS } from '../../constants';
import { useSocketContext } from '../../hooks/useSocketContext';
import { CheckpointIsAuthenticated } from '../../../common/components/checkpoints';
import { useIsMountedRef } from '../../../common/hooks';
import AuctionRedirectToBrand from '../AuctionRedirectToBrand';

const useStyles = makeStyles(theme => ({
    button: {
        padding: theme.spacing(2, 5),
        backgroundColor: 'green',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1, 3)
        }
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

const BidBar = (): JSX.Element => {
    const classes = useStyles();
    const { auction, topBid, bids } = useSocketContext();
    const isMountedRef = useIsMountedRef();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [finalizing, setFinalizing] = useState<boolean>(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isMountedRef.current) {
                if (auction) {
                    // Get the end time of the auction
                    const { finalEndDate } = auction;

                    // Check to see if the auction has ended.
                    const hasEnded = finalEndDate ? moment(Date.now()).isAfter(finalEndDate) : false;
                    if (hasEnded) {
                        // if it has ended, we want to display a loading indictor or message
                        // for X amount of time while our auction finalizes any ongoing bids
                        const canDisplayMessage = finalEndDate ?
                            moment(Date.now()).isAfter(moment(finalEndDate)) &&
                            moment(Date.now()).isBefore((moment(finalEndDate).add(AUCTION_END_PADDING_IN_SECONDS, 's')))
                            : false;
                        if (canDisplayMessage) {
                            setFinalizing(true);
                        } else {
                            setFinalizing(false);
                        }
                    }
                }
            }
        }, 1000);

        return () => {
            setFinalizing(false);
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMountedRef]);

    if (!auction) {
        return <></>;
    }

    const {
        id,
        status,
        finalEndDate,
        scheduledStartDate,
        brand,
        title,
        slug
    } = auction;

    const bidNowText = intl.get('auction.bidNow').d('Bid Now');
    const finalizeText = intl.get('auction.bidBar.finalize.bids').d('Finalizing bids...');

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const onBid = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12} md={3}>
                    {/* <RoundedBox> */}
                    <ReserveMeter auction={auction} topBid={topBid}/>
                    {/* </RoundedBox> */}
                </Grid>
                <Grid item xs={6} md={3}>
                    <Countdown
                        startDate={scheduledStartDate}
                        endDate={finalEndDate}
                        endingText={finalizing ? finalizeText : undefined}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <RoundedBox>
                        <BidsDisplay numberOfBids={bids ? bids.length : 0}/>
                    </RoundedBox>
                </Grid>
                {/* TODO: add watch list and sharing */}
                {/* <Grid item xs={6} md={2}> */}
                {/* <AuctionLikeAndShare /> */}
                {/* </Grid> */}
                <Grid item xs={6} md={2}>
                    <CheckpointIsAuthenticated>
                        <AuctionRedirectToBrand slug={slug} title={title} brand={brand}>
                            <BidButton
                                id={id}
                                className={classes.button}
                                fullWidth
                                disabled={status !== AUCTION_STATUS.LIVE}
                                color="secondary"
                                text={bidNowText}
                                onClick={onBid}
                            />
                        </AuctionRedirectToBrand>
                    </CheckpointIsAuthenticated>
                </Grid>
            </Grid>
            {
                isModalOpen && <BidModal onClose={handleClose}/>
            }
        </>
    );
};

export default React.memo(BidBar);
