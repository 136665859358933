import { EXCHANGE_RATE_CURRENCIES } from '../common/constants';

export const DEBOUNCE_TIME = 700;
export const DEFAULT_DISPLAY_LIMIT = 9;
export const AUCTION_PRICE_FILTER_MIN = 0;
export const AUCTION_PRICE_FILTER_MAX = 1000000000;
export const AUCTION_END_PADDING_IN_SECONDS = 1;

export const TOUR_COOKIE = 'auctionTourCookie';

// 5 minutes
export const AUCTION_BIDS_INTERVAL = 300000;

export enum AUCTION_STATUS {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    LIVE = 'LIVE',
    SCHEDULED = 'SCHEDULED',
    SOLD = 'SOLD',
    RESERVE_NOT_MET = 'RESERVE_NOT_MET',
}

export enum BID_ERROR {
    INVALID_BID = 'bid.invalid',
    OWN_AUCTION = 'bid.invalid.own.auction',
    NO_AUCTION = 'bid.invalid.no.auction',
    AUCTION_STATUS_SCHEDULED = 'bid.invalid.auction.status.scheduled',
    AUCTION_STATUS_PENDING = 'bid.invalid.auction.status.pending',
    AUCTION_STATUS_SOLD = 'bid.invalid.auction.status.sold',
    AUCTION_STATUS_RESERVE_NOT_MET = 'bid.invalid.auction.status.reserve_not_met',
    DATE_BEFORE = 'bid.invalid.date.start.before',
    DATE_AFTER = 'bid.invalid.date.end.after',
    FIRST_BID_TOO_LOW = 'bid.invalid.firstBid.tooLow',
    INCREMENT_NOT_MET = 'bid.invalid.increment.notMet',
    AMOUNT_TOO_LOW = 'bid.invalid.amount.tooLow',
    AMOUNT_TOO_HIGH = 'bid.invalid.maxValue.exceeded',
}

export enum AUCTION_NETWORK_ERROR {
    AUCTION_LIVE = 'auction.network.share.error.auctionLive',
    AUCTION_ENDED = 'auction.network.share.error.auctionEnded',
    AUCTION_INVALID_STATUS = 'auction.network.schedule.error.invalid.status',
    HAS_HOSTS = 'auction.network.share.error.hasHosts',
    SCHEDULE_RANGE = 'auction.network.schedule.error.rangeOutOfBounds',
    SCHEDULE_MISSING_DATES = 'auction.network.schedule.error.dates.missing',
    SCHEDULE_DATES_SAME = 'auction.network.schedule.error.dates.same',
    SCHEDULE_START_DATE_PAST = 'auction.network.schedule.error.startDate.inPast',
    SCHEDULE_END_DATE_PAST = 'auction.network.schedule.error.endDate.inPast',
    SCHEDULE_END_DATE_BEFORE_START_DATE = 'auction.network.schedule.error.dates.end.before.start',
}

export const MENU_ITEMS_MAP = [
    {
        key: 'Bitcoin',
        value: EXCHANGE_RATE_CURRENCIES.BTC
    },
    {
        key: 'Ethereum',
        value: EXCHANGE_RATE_CURRENCIES.ETH
    },
    {
        key: 'Litecoin',
        value: EXCHANGE_RATE_CURRENCIES.LTC
    },
    // {
    //     key: 'Dodgecoin',
    //     value: EXCHANGE_RATE_CURRENCIES.DODGE
    // },
    {
        key: 'Cardano',
        value: EXCHANGE_RATE_CURRENCIES.ADA
    },
    {
        key: 'United States Dollar',
        value: EXCHANGE_RATE_CURRENCIES.USD
    },
    // {
    //     key: 'Canadian Dollar',
    //     value: EXCHANGE_RATE_CURRENCIES.CAD
    // },
    // {
    //     key: 'Pound Sterling',
    //     value: EXCHANGE_RATE_CURRENCIES.GBP
    // },
    {
        key: 'Euro',
        value: EXCHANGE_RATE_CURRENCIES.EUR
    },
    // {
    //     key: 'Australian Dollar',
    //     value: EXCHANGE_RATE_CURRENCIES.AUD
    // },
    // {
    //     key: 'Hong Kong Dollar',
    //     value: EXCHANGE_RATE_CURRENCIES.HKD
    // },
    {
        key: 'Singapore Dollar',
        value: EXCHANGE_RATE_CURRENCIES.SGD
    },
];
