import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        ...theme.typography.body2,
        paddingTop: theme.spacing(0)
    },
    boxContainer: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.between('sm', 'md')]: {
            width: '40%'
        },
        [theme.breakpoints.up('md')]: {
            width: '100%'
        }
    }
}));

interface Props {
    label: string;
    children: React.ReactNode;
}

const RefinementListAccordion = ({ label, children }: Props): JSX.Element => {
    const classes = useStyles();
    return (
        <Box className={classes.boxContainer}>
            <Accordion>
                <AccordionSummary
                    id={`panel-header-${label}`}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-content"
                >
                    <Typography variant="h4">{label}</Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.root }}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default React.memo(RefinementListAccordion);
