import React from 'react';
import intl from 'react-intl-universal';
import { useLocation } from 'react-router';

import PageHeader from '../../../common/components/PageHeader';
import Container from '../../../common/components/Container';
import AuctionWizard from '../../containers/AuctionWizard';

import { FORM_ACTION } from '../../../common/constants';
import { FormDataProvider } from '../../../common/hooks/useFormDataContext';
import { CheckoutStateType } from '../../../common/types';

const CreateAuctionPage = (): JSX.Element => {
    const location = useLocation<CheckoutStateType>();
    const headerText = intl.get('auction.page.createAuction.header.label').d('Create Auction');
    const titleText = intl.get('auction.page.createAuction.title').d('Auction Creation');

    return (
        <>
            <PageHeader label={headerText} beta />
            <Container>
                <FormDataProvider>
                    <AuctionWizard
                        title={titleText}
                        action={FORM_ACTION.CREATE}
                        pricingPackage={location.state?.pricingPackage}
                        paymentType={location.state?.paymentType}
                    />
                </FormDataProvider>
            </Container>
        </>
    );
};

export default CreateAuctionPage;
