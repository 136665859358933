import React from 'react';
import { useLocation } from 'react-router-dom';
// import intl from 'react-intl-universal';
import { Box, Divider } from '@material-ui/core';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Index, Configure } from 'react-instantsearch-dom';

import SearchBox from '../../../common/components/SearchBox';
import Categories from '../Categories';
import Hits from '../../../common/components/Hits';
import AlgoliaResultList from '../AlgoliaResultsList';
import FeaturedCard from '../../components/FeaturedCard';
import Pagination from '../../../common/components/Pagination';
import NonFeaturedCard from '../../components/NonFeaturedCard';

import { DirectoryType } from '../../types';
import { getCategoryFromPath } from '../../helpers';
import { INDEX_PREFIX } from '../../../common/constants';

// Polyfill
import 'url-search-params-polyfill';

const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID as string,
    process.env.REACT_APP_ALGOLIA_API_KEY as string,
);

const useQueryParam = () => new URLSearchParams(useLocation().search);

const AlgoliaResults = (): JSX.Element => {
    const location = useLocation();
    const queryParam = useQueryParam();
    // Determine the current category from the url path
    const currentCategory = getCategoryFromPath(location.pathname);
    const extraFilter = currentCategory !== 'EVERYTHING' ? ` AND category:${currentCategory}` : '';

    return (
        <Box>
            <InstantSearch indexName={`${INDEX_PREFIX}_directory`} searchClient={searchClient} >
                <Box>
                    <SearchBox defaultRefinement={queryParam.get('q') || ''} />
                    <Box sx={{ mt: 1, mb: 2 }}>
                        <Categories />
                    </Box>
                    <Divider />
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Index indexName={`${INDEX_PREFIX}_directory_featured`} indexId={'directory-featured'} >
                        <Configure hitsPerPage={4} filters={`featured:true${extraFilter}`} />
                        <Hits>
                            {
                                (hits: DirectoryType[]) => (
                                    hits.length === 0 ?
                                        (
                                            <></>
                                        ) :
                                        (
                                            <Box>
                                                <AlgoliaResultList
                                                    directories={hits}
                                                    component={FeaturedCard}
                                                />
                                            </Box>
                                        )
                                )
                            }
                        </Hits>
                        <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }} >
                            <Pagination />
                        </Box>
                    </Index>
                    <Index indexName={`${INDEX_PREFIX}_directory`} indexId={'directory-nonfeatured'} >
                        <Configure hitsPerPage={8} filters={`featured:false${extraFilter}`} />
                        <Hits>
                            {
                                (hits: DirectoryType[]) => (
                                    hits.length === 0 ?
                                        (
                                            <></>
                                        ) :
                                        (
                                            <Box>
                                                <AlgoliaResultList
                                                    directories={hits}
                                                    component={NonFeaturedCard}
                                                />
                                            </Box>
                                        )
                                )
                            }
                        </Hits>
                        <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }} >
                            <Pagination />
                        </Box>
                    </Index>
                </Box>
            </InstantSearch>
        </Box>
    );
};

export default AlgoliaResults;
