import React, { useState } from 'react';
// import intl from 'react-intl-universal';
import {
    Box,
    Grid,
    Switch,
    TextField,
    Typography,
    ToggleButton,
    ToggleButtonGroup
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DayType, HoursType } from '../../types';

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.palette.text.primary,
    },
    businessDay: {
        borderBottomWidth: '2px',
        borderBottomColor: theme.palette.primary.main,
    },
    textField: {
        ...theme.typography.body2,
        '& .MuiFormHelperText-root': {
            ...theme.typography.subtitle1
        }
    },
}));

// Private Constants
const __DEFAULT_TIME = '9:00 AM - 5:00 PM';
// Public Constants
export const DEFAULT_VALUE = { Mo: __DEFAULT_TIME, Tu: __DEFAULT_TIME, We: __DEFAULT_TIME, Th: __DEFAULT_TIME, Fr: __DEFAULT_TIME };

export const days: Array<DayType> = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
export const dayLabelMap = {
    Su: 'Sunday',
    Mo: 'Monday',
    Tu: 'Tuesday',
    We: 'Wednesday',
    Th: 'Thursday',
    Fr: 'Friday',
    Sa: 'Saturday',
};

type OnChangeFunctionType = (hours: HoursType) => void;

interface Props {
    label: string;
    value: HoursType;
    onChange: OnChangeFunctionType;
}

const Hours = ({ label, onChange, value: incomingValue }: Props): JSX.Element => {
    const classes = useStyles();

    const [day, setDay] = useState<DayType>('Mo');

    const hourValue = incomingValue || {};

    return (
        <Box>
            <Typography variant="h4">{label}</Typography>
            <Box sx={{ maxWidth: 'calc(100vw - 48px)', overflow: 'auto' }}>
                <ToggleButtonGroup
                    value={day}
                    exclusive
                    onChange={(event, value) => value && setDay(value)}
                    size="small"
                >
                    {days.map(d => (
                        <ToggleButton key={d} value={d} className={hourValue[d] !== undefined ? classes.businessDay : ''}>
                            <Typography variant="body1">{d}</Typography>
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <Typography variant="body1">{dayLabelMap[day]}</Typography>
                </Grid>
                <Grid item>
                    <Switch
                        checked={hourValue[day] !== undefined}
                        onChange={(event, open) => {
                            if (open) {
                                hourValue[day] = __DEFAULT_TIME;
                            } else {
                                delete hourValue[day];
                            }
                            onChange(hourValue);
                        }}
                        name="open"
                        color="primary"
                        inputProps={{ 'aria-label': 'open' }}
                    />
                </Grid>
            </Grid>
            <Box sx={{ mb: 1 }}>
                <TextField
                    className={classes.textField}
                    value={hourValue[day] !== undefined ? hourValue[day] : 'Closed'}
                    disabled={hourValue[day] === undefined}
                    onChange={(event) => {
                        hourValue[day] = event.target.value;
                        onChange(hourValue);
                    }}
                    fullWidth
                />
            </Box>
        </Box>
    );
};

export default Hours;
