import React from 'react';
import intl from 'react-intl-universal';

import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import maintenanceLogo from '../../assets/images/maintenance-icon.png';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        width: '100px',
        height: '100px',
        display: 'block'
    },
}));

const MaintenanceMessage = (): JSX.Element => {
    const classes = useStyles();

    const msgTitle = intl.get('common.server.error.maintenance.title').defaultMessage('Sorry! Our site is we\'re currently down for maintenance.');
    const msgSubtitle = intl.get('common.server.error.maintenance.subtitle').defaultMessage('We\'ll be back shortly!');
    const logoText = intl.get('generic.alt.logo').d('Logo');

    return (
        <Box className={classes.root}>
            <Box>
                <img src={maintenanceLogo} alt={logoText} className={classes.logo} />
            </Box>
            <Box sx={{ marginTop: 1, textAlign: 'center' }}>
                <Typography variant="h5">{msgTitle}</Typography>
                <Typography variant="subtitle1">{msgSubtitle}</Typography>
            </Box>
        </Box>
    );
};

export default MaintenanceMessage;
