import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Box, Grid, Container, Typography, Divider, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Dialog from '../../components/Dialog';
import SubmitButton from '../../../common/components/Button';
import SavedCard from '../../../common/components/SavedCard';

import { useIsMountedRef, useNotification, usePaymentConfigurationContext } from '../../../common/hooks';
import { SavedCardType } from '../../../common/types';
import { confirmAndSaveCard, getCustomerSavedCardDetails } from '../../../common/request';
import { BRAND, PAYMENT_PROVIDER } from '../../../common/constants';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
    description: {
        [theme.breakpoints.down('md')]: {
            fontSize: 12
        }
    },
    cardContainer: {
        '& .sq-card-wrapper': {
            [theme.breakpoints.down('md')]: {
                minWidth: 'unset'
            }
        }
    },
    error: {
        color: theme.palette.error.main
    }
}));

const _window: any = window;

interface Props {
    onSuccess?: () => void;
    onFailure?: () => void;
    onClose: () => void;
}

const ManageSquarePaymentMethodsDialog = ({ onSuccess, onFailure, onClose }: Props): JSX.Element => {
    const classes = useStyles();
    const isMountedRef = useIsMountedRef();
    const notify = useNotification();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const paymentConfiguration = usePaymentConfigurationContext();

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [card, setCard] = useState<any>(null); // need to tokenize this for submission
    const [savedCard, setSavedCard] = useState<SavedCardType | null | undefined>(null);

    // Try to fetch any saved card details from the customer profile
    // This function will also create the customer if
    // there isn't any associated profile with the user
    const fetchSavedCardDetails = () => {
        getCustomerSavedCardDetails({ paymentProvider: PAYMENT_PROVIDER.SQUARE, brand: BRAND })
            .then((cardRes) => {
                // Message is only the card details if code is 200
                const { code, message } = cardRes;
                if (code === 200 && message) {
                    setSavedCard({
                        cardBrand: (message as SavedCardType).cardBrand,
                        last4: (message as SavedCardType).last4
                    });
                }
            }).catch(() => {
                setSavedCard(null);
            });
    };

    useEffect(() => {
        if (isMountedRef.current) {
            const { appId, locationId } = paymentConfiguration;
            // Tokenize credit card info if Square.
            if (appId && locationId) {
                if (appId.trim().length > 0 && locationId.trim().length > 0) {
                    const payments = _window.Square.payments(appId, locationId);
                    try {
                        // Instantiate a card object
                        payments.card().then((card) => {
                            // Attach the instantiated card
                            card.attach('#manage-card-container');
                            // Store the card instance to tokenize for submission
                            setCard(card);
                        }).catch((err) => {
                            // console.log('Initializing Card failed', err);
                            const message = intl.get('common.form.square.errorInitializing').d('Something went wrong. Please try again.');
                            notify({ message, severity: 'error' });
                            window.console.log(err);
                        });
                    } catch (e) {
                        // console.error('Initializing Card failed', e);
                        const message = intl.get('common.form.square.errorInitializing').d('Something went wrong. Please try again.');
                        notify({ message, severity: 'error' });
                    }
                }
            }
            // Try to fetch any saved card details from the customer profile
            fetchSavedCardDetails();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMountedRef]);

    const handleSave = async(event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        setSubmitting(true);
        let sourceId;

        await card.tokenize().then(data => {
            sourceId = data.token;
        });

        if (sourceId) {
            const params = {
                paymentProvider: PAYMENT_PROVIDER.SQUARE,
                sourceId,
                brand: BRAND
            };

            confirmAndSaveCard(params)
                .then((res) => {
                    const { code, message } = res;
                    if (code === 200) {
                        setSavedCard({
                            cardBrand: (message as SavedCardType).cardBrand,
                            last4: (message as SavedCardType).last4
                        });
                    }
                    onSuccess && onSuccess();
                })
                .catch((err) => {
                    const { message } = err;
                    notify({ message, severity: 'error' });
                    onFailure && onFailure();
                })
                .finally(() => {
                    setSubmitting(false);
                });
        } else {
            setSubmitting(false);
        }
    };

    const title = intl.get('dashboard.managePaymentMethods.dialog.title').d('Manage');
    const descriptionText = intl.get('dashboard.managePaymentMethods.dialog.description').d('Saving new card details will replace your current card on file.');
    const submitText = intl.get('dashboard.managePaymentMethods.button.dialog.save').d('Save Card');

    return (
        <Dialog onClose={onClose} title={title} fullWidth processing={submitting} fullScreen={isMobile}>
            <Box sx={{ p: 1, textAlign: 'center' }}>
                <Box sx={{ m: 1 }}>
                    <Container>
                        <Grid container spacing={1}>
                            {
                                savedCard && (
                                    <>
                                        <Grid item xs={12}>
                                            <SavedCard card={savedCard} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </>
                                )
                            }
                            <Grid item xs={12}>
                                <div className={classes.cardContainer} id="manage-card-container"></div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.description} variant="body1">{descriptionText}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <SubmitButton
                                    id="form-managePaymentMethods-button-submit"
                                    onClick={handleSave}
                                    type="button"
                                    fullWidth
                                    text={submitText}
                                    disabled={submitting}
                                    color="secondary"
                                    className={classes.submit}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </Dialog>
    );
};

export default React.memo(ManageSquarePaymentMethodsDialog);
