import React from 'react';
import intl from 'react-intl-universal';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button, Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(5, 0)
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center'
    },
    title: {
        fontWeight: 'bold'
    },
    descriptionContainer: {
        margin: theme.spacing(1, 0)
    },
    linkContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center'
    }
}));

interface Props {
    title?: string;
    subtitle?: string;
    description?: string;
    buttonText?: string;
    link?: string;
}

const ErrorMessage = ({ title, subtitle, description, buttonText, link }: Props): JSX.Element => {
    const classes = useStyles();

    const errorTitleText = intl.get('generic.error.message.title').d('Error');
    const errorSubtitleText = intl.get('generic.error.message.subtitle').d('Something went wrong!');
    const errorDescriptionText = intl.get('generic.error.message.description').d('An error has occured on this page. If this issue presists, please contact support.');
    const goToHomeText = intl.get('generic.error.message.goToHome').d('Go to Home');

    const goTo = () => {
        if (link) {
            window.location.href = link;
        } else {
            window.location.href = '/';
        }
    };

    return (
        <Container>
            <Box className={classes.container}>
                <Box>
                    <Box className={classes.titleContainer}>
                        <Typography variant="h1" className={classes.title} >
                            {title ? title : errorTitleText}
                        </Typography>
                        <Typography variant="subtitle1" >
                            {subtitle ? subtitle : errorSubtitleText}
                        </Typography>
                    </Box>
                    <Box className={classes.descriptionContainer}>
                        <Typography variant="body1" >
                            {description ? description : errorDescriptionText}
                        </Typography>
                    </Box>
                    <Box className={classes.linkContainer}>
                        <Button onClick={goTo}>
                            <Typography variant="body1">
                                {buttonText ? buttonText : goToHomeText}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default ErrorMessage;
