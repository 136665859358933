import * as yup from 'yup';
import intl from 'react-intl-universal';

export const schema = yup.object().shape({
    title: yup.string()
        .required(
            intl.get('auction.form.basicDetails.title.error.required').d('Please enter in a title')
        ),
    sellerType: yup.string()
        .required(
            intl.get('auction.form.basicDetails.sellerType.error.required').d('Please select a type')
        ),
    reserve: yup.number()
        .required(
            intl.get('auction.form.basicDetails.reserve.error.required').d('Please enter in a reserve')
        ),
    currency: yup.string()
        .required(
            intl.get('paddock.form.basicDetails.currency.error.required').d('Please select a currency')
        ),
    country: yup.object()
        .required(
            intl.get('auction.form.basicDetails.country.error.required').d('Please select a country')
        ),
    userDescription: yup.string()
        .required(
            intl.get('auction.form.basicDetails.userDescription.error.required').d('Please tell us about your vehicle')
        ),
});
