import React, { ReactElement } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Typography, useMediaQuery } from '@material-ui/core';

import { SavedCardType } from '../../types';
import { CREDIT_CARD_BRAND } from '../../constants';
import { IconFaCcAmex,
    IconFaCcDinersClub,
    IconFaCcDiscover,
    IconFaCcJcb,
    IconFaCcMastercard,
    IconFaCcVisa,
    IconFaCreditCard
} from '../../../../Icons';

const useStyles = makeStyles(theme => ({
    card: {
        maxHeight: 90,
        border: '2px solid black',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 2),
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }

    },
    descriptor: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        '& > * + *': {
            margin: theme.spacing(0.3, 0, 0, 0)
        },
        [theme.breakpoints.down('md')]: {
            '& > * + *': {
                margin: theme.spacing(1, 0, 0, 0)
            },
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    last4: {
        fontWeight: 'bold',
        color: theme.palette.grey[700],
        [theme.breakpoints.down('md')]: {
            fontSize: 12
        }
    },
    brand: {
        fontWeight: 'bold',
        color: theme.palette.grey[500],
        textTransform: 'capitalize',
        [theme.breakpoints.down('md')]: {
            fontSize: 12
        }
    },
}));

interface Props {
    card: SavedCardType;
    newCard?: boolean;
}

const SavedCard = ({ card, newCard }: Props): ReactElement => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { last4, cardBrand } = card;

    let icon: ReactElement;

    if (!newCard) {
        switch (cardBrand) {
            case CREDIT_CARD_BRAND.VISA:
                icon = <IconFaCcVisa/>;
                break;
            case CREDIT_CARD_BRAND.DISCOVER:
                icon = <IconFaCcDiscover/>;
                break;
            case CREDIT_CARD_BRAND.JCB:
                icon = <IconFaCcJcb/>;
                break;
            case CREDIT_CARD_BRAND.MASTERCARD:
                icon = <IconFaCcMastercard/>;
                break;
            case CREDIT_CARD_BRAND.AMERICAN_EXPRESS:
                icon = <IconFaCcAmex/>;
                break;
            case CREDIT_CARD_BRAND.DISCOVER_DINERS:
                icon = <IconFaCcDinersClub/>;
                break;
            default:
                icon = <IconFaCreditCard/>;
        }
    } else {
        icon = <IconFaCreditCard/>;
    }

    const last4DigitsLabel = newCard ? '**** **** **** ****' : `Card on file ending in ${last4}`;
    const brand = newCard ? 'New' : cardBrand;

    return (
        <Box className={classes.card}>
            <Box
                sx={{
                    display: 'flex',
                    fontSize: isMobile ? 36 : 54,
                    lineHeight: 0,
                    my: isMobile ? '-2px' : '-3px'
                }}>
                { icon }
            </Box>
            <Box>
                <Box className={classes.descriptor}>
                    <Typography className={classes.last4} variant="h5" component="span">{last4DigitsLabel}</Typography>
                    <Typography className={classes.brand} variant="h5" component="span">{brand ? brand.replaceAll('_', ' ') : ''}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default SavedCard;
