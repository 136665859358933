/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { HeroType } from '../../types';
import { Box, Typography } from '@material-ui/core';
import { getImageUrl } from '../../helpers';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        height: '100%',
        position: 'relative',
        textAlign: 'center',
        minHeight: 150
    },
    image: {
        width: '100%',
        height: '100%',
        maxHeight: 446,
        minHeight: 150,
        objectFit: 'cover',
        [theme.breakpoints.down('md')]: {
            objectFit: 'fill'
        }
    },
    text: {
        width: '59%',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 45,
        fontWeight: 'bold',
        color: theme.palette.common.white,
        // // textTransform: 'uppercase',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('md')]: {
            width: '80%',
            fontSize: 15,
        }
    }
}));

type Props = HeroType;

const Hero = ({ image, text }: Props): JSX.Element => {
    const classes = useStyles();

    const imageSource = getImageUrl(image, 'original');

    return (
        <Box className={classes.container}>
            <img className={classes.image} src={imageSource} alt={image?.alternativeText} />
            {
                text && <Typography className={classes.text}>{text}</Typography>
            }
        </Box>
    );
};

export default Hero;
