import React, { ReactElement } from 'react';
import intl from 'react-intl-universal';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Close as CloseIcon
} from '@material-ui/icons';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery } from '@material-ui/core';

import CurrentBid from '../../components/CurrentBid';
import Countdown from '../../components/Countdown';
import BidsDisplay from '../../components/BidsDisplay';
// import AuctionLikeAndShare from '../../components/AuctionLikeAndShare';
import BidNowField from '../../components/BidNowField';
import BidHistory from '../BidHistory';

import { getImageUrl } from '../../../common/helpers';
import { useSocketContext } from '../../hooks/useSocketContext';

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: 'Avenir',
        textAlign: 'left',
        fontWeight: 'bolder',
        // textTransform: 'capitalize',
        marginLeft: theme.spacing(1)
    },
    detailContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contentContainer: {
        overflow: 'hidden'
    },
    topSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    imageContainer: {
        maxWidth: 400
    },
    image: {
        width: '100%',
        height: '100%',
    },
    infoContainer: {
        margin: theme.spacing(0, 4),
        width: '50%'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        // justifyContent: 'space-between'
    },
    actionIconsContainer: {
        marginTop: theme.spacing(2)
    },
    midSection: {

    },
    bidField: {
        margin: theme.spacing(1, 0)
    },
    bottomSection: {
        margin: theme.spacing(2, 0)
    },
    historyContainer: {

    },
    accent: {
        backgroundColor: theme.palette.primary.main,
        width: 8,
        height: 28,
    },
    clickHere: {
        fontWeight: 'bold',
        textDecoration: 'underline',
        color: theme.palette.common.black
    }
}));

interface Props {
    onClose: () => void;
}

const BidModal = ({ onClose }: Props): ReactElement => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { auction, bids, topBid } = useSocketContext();

    if (!auction) {
        return <></>;
    }

    const {
        title,
        startPrice,
        finalPrice,
        currency,
        finalEndDate,
        scheduledStartDate,
        coverImage,
        exterior
    } = auction;

    const imageUrl = coverImage ? getImageUrl(coverImage, 'large') : exterior && getImageUrl(exterior[0], 'large');

    const handleClose = () => {
        onClose();
    };

    const placeBidText = intl.get('auction.bid.modal.liveBid').d('Place Live Bid');
    // const placeMaxBidText = intl.get('auction.bid.modal.maxBid').d('Place Max Bid');
    // const placeMaxBidSubText = intl.getHTML('auction.bid.modal.maxBid.sub').d(
    //     <span>
    //         To learn more about max bid, please <a className={classes.clickHere} href="/">click here</a>.
    //     </span>
    // );

    return (
        <Dialog fullScreen={fullScreen} onClose={handleClose} open={true} fullWidth maxWidth="md">
            <DialogTitle disableTypography>
                <Box className={classes.detailContainer}>
                    <CurrentBid
                        bid={topBid}
                        currency={currency}
                        startPrice={startPrice}
                        finalPrice={finalPrice}
                        border={false}
                    />
                    <Countdown
                        startDate={scheduledStartDate}
                        endDate={finalEndDate}
                        border={false}
                    />
                    <BidsDisplay numberOfBids={bids ? bids.length : 0}/>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon color="secondary" />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box className={classes.contentContainer}>
                    <Box className={classes.topSection}>
                        {
                            imageUrl && (
                                <Box className={classes.imageContainer}>
                                    <img alt="main" src={imageUrl} className={classes.image} />
                                </Box>
                            )
                        }
                        <Box className={classes.infoContainer}>
                            <Box className={classes.titleContainer}>
                                <Box className={classes.accent} />
                                <Typography variant="h1" component="h2" className={classes.title}>{title}</Typography>
                            </Box>
                            <Box className={classes.actionIconsContainer}>
                                {/* TODO: Implement watch list */}
                                {/* <AuctionLikeAndShare /> */}
                            </Box>
                            <Box className={classes.bidField}>
                                <BidNowField
                                    key={'bidNow-field1'}
                                    autoFocus={true}
                                    label={placeBidText}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.midSection}>
                        {/* TODO: Revisit this functionality at a later date */}
                        {/* <Box className={classes.bidField}>
                            <BidNowField
                                key={'bidNow-field2'}
                                isMaxBid={true}
                                label={placeMaxBidText}
                                subtext={placeMaxBidSubText}
                            />
                        </Box> */}
                    </Box>
                    <Box className={classes.bottomSection}>
                        <Box className={classes.historyContainer}>
                            <BidHistory limit={3} />
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default React.memo(BidModal);
