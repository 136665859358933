import React, { useContext, useEffect, useState } from 'react';

import SocketContext from './context';

import { initSockets } from '../../sockets';
import { AuctionType, CommentType, SocketContextType } from '../../types';
import { findTopBidValue } from '../../helpers';
import { useIsMountedRef } from '../../../common/hooks';

interface Props {
    auction?: AuctionType;
    comments?: Array<CommentType>;
    children: React.ReactNode | React.ReactNodeArray;
}

const SocketProvider = ({ auction, comments, children }: Props): JSX.Element => {
    const isMountedRef = useIsMountedRef();
    const topBid = findTopBidValue(auction?.bids);

    const [value, setValue] = useState<SocketContextType>({
        auction,
        comments: comments ? comments : [],
        bids: auction?.bids || [],
        topBid,
        currency: auction?.currency || 'USD',
        minIncrementAmount: auction?.minIncrementAmount || 0.00
    });

    useEffect(() => {
        if (isMountedRef) {
            initSockets({ setValue });
        }
    }, [isMountedRef]);

    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocketContext = (): SocketContextType => useContext(SocketContext);

export default SocketProvider;
