/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Box, Button, useMediaQuery } from '@material-ui/core';
import {
    ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';
import parser from 'html-react-parser';
import { stripHtml } from 'string-strip-html';

import CustomDialog from '../../../common/components/CustomDialog';
import ClampLines from 'react-clamp-lines';

const useStyles = makeStyles(theme => ({
    description: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.text.primary,
        display: '-webkit-box',
        WebkitLineClamp: 5,
        boxOrient: 'vertical',
    },
    button: {
        border: '1px solid #C8C8C8',
        color: theme.palette.common.black,
        padding: theme.spacing(1, 6),
        // // textTransform: 'capitalize',
        '&:hover': {
            border: '1px solid #C8C8C8',
            backgroundColor: 'rgba(184, 184, 184, 0.04)'
        }
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 'bold'
    },
    fullDescription: {
        color: theme.palette.text.primary,
    },
}));

interface Props {
    title: string;
    description: string;
}

const clampedId = 'clamped-auction-description-box';

const Description = ({ title, description }: Props): JSX.Element => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const viewDescriptionText = intl.get('auction.detail.description.view').d('View Full Description');

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Typography variant="body1" component="span">
                <ClampLines
                    text={stripHtml(description).result}
                    id={clampedId}
                    lines={5}
                    buttons={false}
                    innerElement="p"
                />
            </Typography>
            <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Button
                    className={classes.button}
                    variant="outlined"
                    onClick={() => setOpen(true)}
                    endIcon={<ExpandMoreIcon />}
                >
                    {viewDescriptionText}
                </Button>
            </Box>

            {/* Dialog Description START */}
            <CustomDialog
                open={open}
                children={parser(description)}
                title={title}
                onClose={handleClose}
                onBackdropClick={handleClose}
                fullScreen={isMobile}
                fullWidth={!isMobile}
                maxWidth="lg"
            />
            {/* Dialog Description END */}
        </>
    );
};

export default Description;
