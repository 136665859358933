import * as yup from 'yup';
import intl from 'react-intl-universal';

export const schema = yup.object().shape({
    email: yup.string()
        .email(
            intl.get('common.form.forgotPassword.input.email.error.invalid').d('Please enter in a valid email')
        )
        .required(
            intl.get('common.form.forgotPassword.input.email.error.required').d('Please enter in your email')
        )
});
