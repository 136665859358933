import React from 'react';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import PageHeader from '../../../common/components/PageHeader';
import Container from '../../../common/components/Container';
import AuctionDetails from '../../containers/AuctionDetails';
import AnimatedLoader from '../../../common/components/AnimatedLoader';
import ErrorMessage from '../../../common/components/ErrorMessage';

import { AUCTION_STATUS } from '../../constants';
import { QUERY_AUCTION_BY_SLUG, QUERY_AUCTION_COMMENTS_HIERARCHY_BY_ID } from '../../queries';
import { AuctionBySlugDataType, AuctionBySlugVariableType, AuctionCommentsAllHierarchyByIDDataType, AuctionCommentsByRelationVariableType } from '../../types';
import SocketProvider from '../../hooks/useSocketContext';
import { CMS_CONTENT_TYPE_REF } from '../../../common/constants';


interface Params {
    slug: string
}

const AuctionPage = (): JSX.Element => {
    const { slug } = useParams<Params>();

    const auctionText = intl.get('auction.page.auction.header.label').d('Auction');

    const { data, loading, error } = useQuery<AuctionBySlugDataType, AuctionBySlugVariableType>(QUERY_AUCTION_BY_SLUG, {
        variables: {
            slug,
            where: {
                status_nin: [AUCTION_STATUS.PENDING],
            }
        }
    });

    const auction = data && data.auctionBySlug;

    const { data: dataComments, loading: loadingComments } = useQuery<AuctionCommentsAllHierarchyByIDDataType, AuctionCommentsByRelationVariableType>(QUERY_AUCTION_COMMENTS_HIERARCHY_BY_ID, {
        variables: {
            relation: auction ? `${CMS_CONTENT_TYPE_REF.AUCTION}:${auction.id}` : ''
        },
        skip: !auction?.id
    });

    if (loading || loadingComments) {
        return <AnimatedLoader />;
    }

    if (error) {
        return <ErrorMessage />;
    }

    const comments = dataComments && dataComments.findAllInHierarchy;

    return (
        <>
            <PageHeader label={auctionText} beta />
            <Container>
                <SocketProvider auction={auction} comments={comments}>
                    <AuctionDetails />
                </SocketProvider>
            </Container>
        </>
    );
};

export default AuctionPage;
