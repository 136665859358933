import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Box, Button, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import AuthenticationDialog from '../../../common/containers/AuthenticationDialog';

const useStyles = makeStyles((theme) => ({
    button: {
        ...theme.typography.button,
    }
}));


interface LoginRequiredProps {
    action: 'add' | 'update' | 'bookmarks' | 'claim';
}

const LoginRequired = (props: LoginRequiredProps): JSX.Element => {
    const classes = useStyles();
    // Wether or not the dialog is currently open
    const [loginDialogOpen, setLoginDialogOpen] = useState(false);
    // Login subtitle - a short reason why the login is being prompted
    // const [loginSubtitle, setLoginSubtitle] = useState('');
    // Login dialog callback function - executed when the user logs in
    // const [loginCallback, setLoginCallback] = useState<Function | undefined>(undefined);
    // unused <LoginDialog> properties: subtitle={loginSubtitle} loginCallback={loginCallback}

    // I18N's
    let loginRequired = intl.get('directory.createDirectory.label.loginRequired').d('You must be logged in to add a business listing');
    if (props.action === 'update') {
        loginRequired = intl.get('directory.updateDirectory.label.loginRequired').d('You must be logged in to manage your business listings');
    } else if (props.action === 'bookmarks') {
        loginRequired = intl.get('directory.bookmarks.label.loginRequired').d('You must be logged in to view your favourites');
    } else if (props.action === 'claim') {
        loginRequired = intl.get('directory.claim.label.loginRequired').d('You must be logged in to claim a directory');
    }
    const loginAction = intl.get('generic.action.login').d('Login');
    const backAction = intl.get('generic.action.back').d('Back');
    const history = useHistory();

    return (
        <Box sx={{ mt: 10 }}>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                    <Typography>{loginRequired}</Typography>
                </Grid>
                <Grid item container justifyContent="center" spacing={2}>
                    <Grid item>
                        <Button className={classes.button} variant="contained" onClick={() => history.push('/directory')}>
                            {backAction}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button className={classes.button} color="primary" variant="contained" onClick={() => setLoginDialogOpen(true)}>
                            {loginAction}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {loginDialogOpen && <AuthenticationDialog action="login" onClose={() => setLoginDialogOpen(false)} />}
        </Box>
    );
};

export default LoginRequired;
