import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useForm } from 'react-hook-form';
import {
    Grid,
    TextField,
    Container,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers/yup';

import SubmitButton from '../../../components/Button';
import CheckboxField from '../../../components/fields/Checkbox';

import { schema } from './validation';
import { useBrandConfigurationContext, useNotification } from '../../../hooks';
import { sendEmail, subscribeToMailchimp } from '../../../request';

const useStyle = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    }
}));

type InputsType = {
    name: string;
    email: string;
    businessName: string;
    businessWebsite: string;
    phoneNumber?: string;
    comments: string;
    instagram: string;
    subscribe: boolean;
}

interface Props {
    contactUsEmail?: string;
}

const ContactUsPartner = ({ contactUsEmail }: Props): JSX.Element => {
    const classes = useStyle();
    const notify = useNotification();
    const config = useBrandConfigurationContext();

    const [submitting, setSubmitting] = useState<boolean>(false);

    const defaultValues = {
        name: '',
        email: '',
        phoneNumber: '',
        businessName: '',
        businessWebsite: '',
        comments: '',
        instagram: '',
        subscribe: false
    };

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<InputsType>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const onSubmit = (data: InputsType) => {
        setSubmitting(true);

        // Send an email to contact us email.
        const templateVariables = {
            'v:name': data.name,
            'v:phone': data.phoneNumber,
            'v:email': data.email,
            'v:businessName': data.businessName,
            'v:businessWebsite': data.businessWebsite,
            'v:comments': data.comments,
            'v:instagram': data.instagram
        };

        const emailObj = {
            to: contactUsEmail ? contactUsEmail : 'info@racingedge.com',
            from: data.email,
            subject: `Partners Contact Inquiry - ${data.businessName}`,
            template: 'partners.contact',
            'o:tag': ['contact', data.email],
            ...templateVariables
        };

        sendEmail(emailObj)
            .then((res) => {
                if (data.subscribe) {
                    const fields = {
                        name: data.name
                    };

                    subscribeToMailchimp(data.email, fields)
                        .then((res) => {
                            if (res.error) {
                                const message = intl.get('common.form.contactUsPartner.subscribe.error').d('An error occured when trying to subscribe. The provided email may be subscribed already or looks fake.');
                                notify({ message, severity: 'error' });
                            }
                        });
                }

                const message = intl.get('common.form.contactUsPartner.sendEmail.success', { companyName: config.companyName }).d(`Thank you for reaching out to ${config.companyName}. We will look into your inquiry shortly.`);
                notify({ message, severity: 'success' });

                setSubmitting(false);
            })
            .catch((err) => {
                const { message } = err;
                notify({ message, severity: 'error' });
                setSubmitting(false);
            });
    };

    const submitText = intl.get('common.form.contactUsPartner.button.submit').d('Submit');
    const emailText = intl.get('common.form.contactUsPartner.input.email').d('Email Address');
    const nameText = intl.get('common.form.contactUsPartner.input.name').d('Name');
    const instagramText = intl.get('common.form.contactUsPartner.select.instagram').d('Instagram Account');
    const phoneNumberText = intl.get('common.form.contactUsPartner.input.phoneNumber').d('Phone Number');
    const businessNameText = intl.get('common.form.contactUsPartner.input.businessName').d('Business / Community Name');
    const businessWebsiteText = intl.get('common.form.contactUsPartner.input.businessWebsite').d('Business / Community Website');
    const commentsText = intl.get('common.form.contactUsPartner.input.comments').d('Comments');
    const commentsPlaceholderText = intl.get('common.form.contactUsPartner.input.comments.placeholder').d('Leave us a comment...');
    const subscribeText = intl.get('common.form.contactUsPartner.checkbox.subscribe').d('Subscribe to our monthly newsletter to get exciting news and special deals!');

    return (
        <Container maxWidth="sm">
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            id="form-contactUsPartner-input-name"
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={nameText}
                            name="name"
                            autoComplete="name"
                            helperText={errors.name ? errors.name.message : ''}
                            error={!!errors.name}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="form-contactUsPartner-input-email"
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={emailText}
                            name="email"
                            autoComplete="email"
                            helperText={errors.email ? errors.email.message : ''}
                            error={!!errors.email}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="form-contactUsPartner-input-phoneNumber"
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            label={phoneNumberText}
                            name="phoneNumber"
                            autoComplete="tel"
                            helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
                            error={!!errors.phoneNumber}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="form-contactUsPartner-input-instagram"
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={instagramText}
                            name="instagram"
                            helperText={errors.instagram ? errors.instagram.message : ''}
                            error={!!errors.instagram}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="form-contactUsPartner-input-businessName"
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            label={businessNameText}
                            name="businessName"
                            helperText={errors.businessName ? errors.businessName.message : ''}
                            error={!!errors.businessName}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="form-contactUsPartner-input-businessWebsite"
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={businessWebsiteText}
                            name="businessWebsite"
                            helperText={errors.businessWebsite ? errors.businessWebsite.message : ''}
                            error={!!errors.businessWebsite}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="form-contactUsPartner-input-comments"
                            inputRef={register}
                            multiline
                            rows={3}
                            variant="outlined"
                            margin="normal"
                            placeholder={commentsPlaceholderText}
                            fullWidth
                            label={commentsText}
                            name="comments"
                            helperText={errors.comments ? errors.comments.message : ''}
                            error={!!errors.comments}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxField
                            id="form-contactUsPartner-checkbox-subscribe"
                            name="subscribe"
                            register={register}
                            label={subscribeText}
                        />
                    </Grid>
                    <Grid item xs={12} textAlign="right">
                        <SubmitButton
                            id="form-contactUsPartner-button-submit"
                            type="submit"
                            text={submitText}
                            disabled={submitting}
                            className={classes.submit}
                            color="secondary"
                        />
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

export default ContactUsPartner;
