import qs from 'qs';
import { Location } from 'history';

import { BidType } from './types';
import { AUCTION_LINK } from '../common/constants';
import { encodedSortByOptions } from './components/SortByList';

export const findTopBidValue = (bids?: Array<BidType>): number => {
    let val = 0.00;

    if (bids && bids.length > 0) {
        val = bids.reduce((acc, bid) => acc = acc > bid.amount ? acc : bid.amount, 0);
    }

    return val;
};

export const findTopBidValueByMe = (bids?: Array<BidType>, id?: number | string): number => {
    let val = 0.00;

    if (bids && bids.length > 0 && id) {
        // find the bids by the user's id
        const usersBids = bids.filter((bid) => Number(bid.bidder.id) === Number(id));

        // find topBid value from users bids
        val = usersBids.reduce((acc, bid) => acc = acc > bid.amount ? acc : bid.amount, 0);
    }

    return val;
};

export const checkFirstBidByUser = (userId: string, bids?: Array<BidType>): boolean => {
    let isFirst = true;

    if (bids && bids.length > 0) {
        isFirst = bids.filter((b) => b.bidder?.id === userId).length > 0;
    }

    return isFirst;
};

type QueryParametersType = {
    query?: string;
    page?: number;
    sellerTypes?: string[];
    status?: string[];
    // status?: string;
    sortBy?: string;

    // Extra Details
    countries?: string[];
    makes?: string[];
    years?: number[];
}

export const decodeOptions = (encodedOptions: Record<string, string>): Record<string, string> => Object.keys(encodedOptions).reduce((acc, key) => {
    const newKey = encodedOptions[key];
    const newValue = key;

    return {
        ...acc,
        [newKey]: newValue
    };
}, {});

export const createURL = (state: Record<string, any>): string => {
    const isDefaultRoute = !state.query && state.page === 1 && !state.sortBy &&
    (state.refinementList && state.refinementList.sellerType && state.refinementList.sellerType.length === 0) &&
    (state.refinementList && state.refinementList['address.country'] && state.refinementList['address.country'].length === 0) &&
    (state.refinementList && state.refinementList['dataFields.year.value'] && state.refinementList['dataFields.year.value'].length === 0) &&
    (state.refinementList && state.refinementList['dataFields.make.value'] && state.refinementList['dataFields.make.value'].length === 0) &&
    (state.refinementList && state.refinementList.status && state.refinementList.status.length === 0);
    // (state.menu && !state.menu.status);

    if (isDefaultRoute) {
        return '';
    }

    // We need this to make sure the query params are still part of the dynamic routing
    const pathname = window.location ? `${window.location.pathname}` : `/${AUCTION_LINK}`;

    const queryParameters: QueryParametersType = {};

    if (state.query) {
        queryParameters.query = encodeURIComponent(state.query);
    }
    if (state.page && state.page !== 1) {
        queryParameters.page = state.page;
    }
    if (state.refinementList && state.refinementList.sellerType) {
        queryParameters.sellerTypes = state.refinementList.sellerType.map(encodeURIComponent);
    }
    if (state.refinementList && state.refinementList.status) {
        queryParameters.status = state.refinementList.status.map(encodeURIComponent);
    }
    // if (state.menu && state.menu.status) {
    //     queryParameters.status = encodeURIComponent(state.menu.status);
    // }
    if (state.sortBy) {
        const encodedSortByOption = encodedSortByOptions[state.sortBy];
        queryParameters.sortBy = encodeURIComponent(encodedSortByOption);
    }

    // Extra Details
    if (state.refinementList && state.refinementList['address.country']) {
        queryParameters.countries = state.refinementList['address.country'].map(encodeURIComponent);
    }
    if (state.refinementList && state.refinementList['dataFields.make.value']) {
        queryParameters.makes = state.refinementList['dataFields.make.value'].map(encodeURIComponent);
    }
    if (state.refinementList && state.refinementList['dataFields.year.value']) {
        queryParameters.years = state.refinementList['dataFields.year.value'].map(encodeURIComponent);
    }

    const queryString = qs.stringify(queryParameters, {
        addQueryPrefix: true,
        arrayFormat: 'repeat',
    });

    return `${pathname}${queryString}`;
};

export const searchStateToUrl = (searchState: Record<string, string>, defaultLocation: string): string => (searchState ? createURL(searchState) : defaultLocation);

export const urlToSearchState = (location: Location, defaultParams?: Record<string, any>): Record<string, any> => {
    const {
        query = defaultParams?.query || '',
        page = defaultParams?.page || 1,
        sellerTypes = defaultParams?.sellerTypes || [],
        status = defaultParams?.status || [],
        sortBy = defaultParams?.sortBy || '',
        countries = defaultParams?.countries || [],
        makes = defaultParams?.makes || [],
        years = defaultParams?.years || [],
    } = qs.parse(
        location.search.slice(1)
    );
    // `qs` does not return an array when there's a single value.
    const allSellerTypes = Array.isArray(sellerTypes) ? sellerTypes : [sellerTypes].filter(Boolean);
    const allStatusTypes = Array.isArray(status) ? status : [status].filter(Boolean);
    const allCountries = Array.isArray(countries) ? countries : [countries].filter(Boolean);
    const allMakes = Array.isArray(makes) ? makes : [makes].filter(Boolean);
    const allYears = Array.isArray(years) ? years : [years].filter(Boolean);

    const decodedSortByOptions = decodeOptions(encodedSortByOptions);
    const decodedSortByOption = decodedSortByOptions[sortBy];

    const searchState: any = {
        query: decodeURIComponent(query),
        page,
        // menu: {
        //     status: decodeURIComponent(status)
        // },
        refinementList: {
            status: allStatusTypes.map(decodeURIComponent),
            sellerType: allSellerTypes.map(decodeURIComponent),
            'address.country': allCountries.map(decodeURIComponent),
            'dataFields.make.value': allMakes.map(decodeURIComponent),
            'dataFields.year.value': allYears.map(decodeURIComponent),
        },
        sortBy: decodeURIComponent(decodedSortByOption),
    };

    return searchState;
};
