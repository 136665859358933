import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useMutation } from '@apollo/client';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, TextField } from '@material-ui/core';

import CustomDialog from '../../../common/components/CustomDialog';

import { MUTATION_COMMENT_CREATE } from '../../mutations';
import { CreateCommentDataType, CreateCommentVariableType } from '../../types';
import { useAuthenticatedUserContext, useNotification } from '../../../common/hooks';
import { CMS_CONTENT_TYPE_REF, CMS_FIELD_TYPE } from '../../../common/constants';
import { userCommentOnLot } from '../../sockets/emitters';

const useStyles = makeStyles(theme => ({
    text: {
        ...theme.typography.body1
    }
}));

interface Props {
    title?: string;
    auctionLot: string;
    referenceId: string;
    onClose: () => void;
    reply?: {
        commentId: string;
    };
}


const WriteCommentDialog = ({ title, auctionLot, referenceId, onClose, reply }: Props): JSX.Element => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const notify = useNotification();
    const { user } = useAuthenticatedUserContext();
    const [comment, setComment] = useState<string>('');

    const [addComment, { loading }] = useMutation<CreateCommentDataType, CreateCommentVariableType>(MUTATION_COMMENT_CREATE, {
        onCompleted({ commentAdd }) {
            if (commentAdd) {
                userCommentOnLot(referenceId, auctionLot, notify);
                const message = intl.get('auction.comment.add.success').d('Commented posted');
                notify({ message, severity: 'success' });
                onClose();
            } else {
                const message = intl.get('auction.comment.add.failure').d('Something went wrong. Please try again.');
                notify({ message, severity: 'error' });
            }
        },
        onError(errors) {
            const { graphQLErrors } = errors;
            if (graphQLErrors && graphQLErrors.length > 0) {
                const [error] = graphQLErrors;
                const ext = error.extensions;

                if (ext) {
                    const { exception } = ext;
                    const { data } = exception;
                    const { id } = data;

                    const message = intl.get('auction.comment.add.error', { id }).d(`Error - '${id}' has occured.`);
                    notify({ message, severity: 'error' });
                }
            }
        }
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
    };

    const handleClose = () => {
        onClose();
    };

    const handleAction = () => {
        if (user) {
            addComment({
                variables: {
                    comment: {
                        content: comment,
                        authorId: user.id,
                        authorEmail: user.email,
                        authorUsername: user.username,
                        authorName: user.username,
                        threadOf: reply ? `${reply.commentId}` : undefined,
                        related: {
                            field: CMS_FIELD_TYPE.COMMENT,
                            ref: CMS_CONTENT_TYPE_REF.AUCTION,
                            refId: `${referenceId}`
                        }

                    }
                }
            });
        } else {
            const message = intl.get('auction.comment.add.noUser').d('Please login to make a comment.');
            notify({ message, severity: 'warning' });
        }
    };

    const titleText = intl.get('auction.writeCommentDialog.title').d('Add Comment');
    const placeholderText = intl.get('auction.writeCommentDialog.placeholder').d('Write a comment...');
    const submitText = intl.get('auction.writeCommentDialog.button.action').d('Post');

    return (
        <>
            <CustomDialog
                open={true}
                title={title ? title : titleText}
                children={
                    <TextField
                        className={classes.text}
                        autoFocus
                        color="secondary"
                        fullWidth
                        required={true}
                        placeholder={placeholderText}
                        multiline
                        rows={10}
                        value={comment}
                        onChange={handleChange}
                    />
                }
                actionText={submitText}
                onAction={handleAction}
                onClose={handleClose}
                fullScreen={isMobile}
                fullWidth={!isMobile}
                actionDisabled={loading}
                maxWidth="md"
            />
        </>
    );
};

export default WriteCommentDialog;
