import React from 'react';
import intl from 'react-intl-universal';
import { useLocation, useHistory } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, MenuItem } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import useCMSPermissions from '../../../common/hooks/useCMSPermissions';

const useStyles = makeStyles(theme => ({
    root: {
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'stretch',
    },
    navItem: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    heading: {
        color: 'inherit',
        '&.active': {
            fontWeight: 'bold',
        },
    },
    label: {
        width: '100%',
        color: 'inherit',
        '&.active': {
            fontWeight: 'bold',
        },
    },
}));

type PageProps = {
    title: string;
    location: string;
    exact: boolean;
};

type GroupProps = {
    groupName: string;
    location: string;
    groupPages: PageProps[];
};

type NavProps = PageProps | GroupProps;

const Navigation = (): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [cmsPermissions] = useCMSPermissions();

    // Get the main Navigation Items
    const navigation: NavProps[] = [
        {
            title: intl.get('cms.navigation.page.home.title').d('Home'),
            location: '/cms',
            exact: true,
        }
    ];

    // Check if the user has permissions to manage users
    if (cmsPermissions.superAdmin) {
        navigation.push({
            title: intl.get('cms.navigation.page.tools.title').d('Tools'),
            location: '/cms/tools',
            exact: false,
        });
    }

    // Check if the user has permissions to manage users
    if (cmsPermissions.manageUsers) {
        navigation.push({
            title: intl.get('cms.navigation.page.users.title').d('Users'),
            location: '/cms/users',
            exact: false,
        });
    }

    // Check if the user has Auction permissions enabled
    if (cmsPermissions.auctionEnabled) {
        navigation.push({
            groupName: intl.get('cms.navigation.page.auction.title').d('Auction'),
            location: '/cms/auction',
            groupPages: [
                {
                    title: intl.get('cms.navigation.page.auction.manage').d('Manage'),
                    location: '/cms/auction/manage',
                    exact: false,
                },
                {
                    title: intl.get('cms.navigation.page.auction.configure').d('Configure'),
                    location: '/cms/auction/configure',
                    exact: false,
                }
            ]
        });
    }

    // Check if the user has Website permissions enabled
    if (cmsPermissions.websiteEnabled) {
        const groupPages = [{
            title: intl.get('cms.navigation.page.website.configure').d('Configure'),
            location: '/cms/website/configure',
            exact: false,
        }];
        if (cmsPermissions.manageHomepage) {
            groupPages.push({
                title: intl.get('cms.navigation.page.website.homePage').d('Home Page'),
                location: '/cms/website/homepage',
                exact: false,
            });
        }
        navigation.push({
            groupName: intl.get('cms.navigation.page.website.title').d('Website'),
            location: '/cms/website',
            groupPages
        });
    }

    const handleClick = (path: string) => {
        history.push(path);
    };

    const buildPageNav = (page: PageProps) => {
        // Determine if this page is active
        const active = page.exact
            ? location.pathname === page.location
            : location.pathname.startsWith(page.location);
        // Active class
        const actClass = active ? ' active' : '';
        // Render the page navigation item
        return (
            <Box key={page.location} className={classes.navItem}>
                <MenuItem
                    className={`${classes.label} ${actClass}`}
                    onClick={() => handleClick(page.location)}
                >
                    {page.title}
                </MenuItem>
            </Box>
        );
    };

    const buildGroupNav = (group: GroupProps) => {
        // Determine if this page is active
        const active = location.pathname.startsWith(group.location);
        // Active class
        const actClass = active ? ' active' : '';
        // Render the navigation group
        return (
            <Box key={group.groupName}>
                <Accordion elevation={0}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${group.groupName}-content`}
                        id={`panel-${group.groupName}-header`}
                    >
                        <Typography className={`${classes.heading} ${actClass}`}>{group.groupName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {group.groupPages.map(page => buildPageNav(page))}
                    </AccordionDetails>
                </Accordion>
            </Box>
        );
    };

    return (
        <Box className={classes.root}>
            {navigation.map(nav => {
                // Is this a group of pages, or a single page?
                if ((nav as GroupProps).groupName) {
                    // Build the Group of Pages
                    return buildGroupNav(nav as GroupProps);
                }
                // Build the Singular Page
                return buildPageNav(nav as PageProps);
            })}
        </Box>
    );
};

export default Navigation;
