import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box } from '@material-ui/core';

import AuthenticationDialog from '../../../containers/AuthenticationDialog';

import { useAuthentication } from '../../../hooks';

const useStyles = makeStyles(theme => ({
    overlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 1000,
    },
    fullWidth: {
        width: '100%'
    },
    relative: {
        position: 'relative'
    }
}));

interface Props {
    children: React.ReactNode;
    fullWidth?: boolean;
}

const CheckpointIsAuthenticated = ({ children, fullWidth = true }: Props): JSX.Element => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [authentication] = useAuthentication();

    const { isLoggedIn, isConfirmed } = authentication;

    let content = <></>;

    // user is logged in and has a confirmed account
    if (isLoggedIn && isConfirmed) {
        content = (
            <>
                { children }
            </>
        );
    }

    // user is not confirmed yet or logged in yet
    if ((isLoggedIn && !isConfirmed) || !isLoggedIn) {
        content = (
            <>
                <Box className={`${classes.relative}${fullWidth ? ` ${classes.fullWidth}` : ''}`}>
                    { children }
                    <Box className={classes.overlay} onClick={() => setIsOpen(true)}/>
                </Box>
                {
                    isOpen && <AuthenticationDialog action={isLoggedIn ? 'resendConfirmation' : 'login'} onClose={() => setIsOpen(false)} />
                }
            </>
        );
    }

    return content;
};

export default CheckpointIsAuthenticated;
