import React from 'react';
import { Box, Container, Grid, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';

import FormContactUs from '../../../../forms/contact/ContactUs';
import FormContactUsDealer from '../../../../forms/contact/ContactUsDealer';
import FormContactUsPartner from '../../../../forms/contact/ContactUsPartner';

import { BrandConfigurationCompanyContactDataType, BrandVariablesType, UniversalFormSegmentProps } from '../../../../types';
import { BRAND } from '../../../../constants';
import { QUERY_BRAND_COMPANY_CONTACT_CONFIGURATION } from '../../../../queries';
import ReachUs from '../../../../components/ReachUs';

const useStyle = makeStyles(theme => ({
    title: {
        fontWeight: 'bold',
        textAlign: 'center'
    },
    background: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    }
}));

type Props = UniversalFormSegmentProps;

const FormComponent = ({ id, title, type, reachUs, contactOverrides }: Props): JSX.Element => {
    const classes = useStyle();

    const { data } = useQuery<BrandConfigurationCompanyContactDataType, BrandVariablesType>(QUERY_BRAND_COMPANY_CONTACT_CONFIGURATION, {
        variables: {
            // if no brand is provided, use the Racing Edge Brand configuration instead
            brand: BRAND
        }
    });

    let reachUsEmail: string | undefined = undefined;
    let reachUsPhoneNumber: string | undefined = undefined;
    let reachUsAddress: string | undefined = undefined;

    let hideNewsletter = false;
    let displayLogo = false;

    if (data) {
        const { contactUsConfiguration } = data.configurationByBrand;

        reachUsEmail = contactUsConfiguration.email;
        reachUsPhoneNumber = contactUsConfiguration.phoneNumber;
        reachUsAddress = contactUsConfiguration.address;
    }

    // Check for Overrides
    if (contactOverrides) {
        // Email
        if (contactOverrides.displayEmail === false) {
            reachUsEmail = undefined;
        } else if (contactOverrides.email) {
            reachUsEmail = contactOverrides.email;
        }
        // Phone Number
        if (contactOverrides.displayPhone === false) {
            reachUsPhoneNumber = undefined;
        } else if (contactOverrides.phone) {
            reachUsPhoneNumber = contactOverrides.phone;
        }
        // Address
        if (contactOverrides.displayAddress === false) {
            reachUsAddress = undefined;
        } else if (contactOverrides.address) {
            reachUsAddress = contactOverrides.address;
        }
        // Newsletter Subscription
        if (contactOverrides.displayNewsletter === false) {
            hideNewsletter = true;
        }
        // Logo Display
        if (contactOverrides.displayLogo === true) {
            displayLogo = true;
        }
    }

    let form = <></>;

    switch (type) {
        case 'CONTACT_US':
            form = <FormContactUs contactUsEmail={reachUsEmail} hideNewsletter={hideNewsletter} />;
            break;
        case 'CONTACT_US_DEALER':
            form = <FormContactUsDealer />;
            break;
        case 'CONTACT_US_PARTNER':
            form = <FormContactUsPartner />;
            break;
        default:
            form = <></>;
    }

    return (
        <Grid key={`${id}-${title}`} item>
            <Container>
                <Box pb={4}>
                    <Grid container direction="column" spacing={5}>
                        {
                            title && (
                                <Grid item>
                                    <Typography variant="h2" className={classes.title}>{title}</Typography>
                                </Grid>
                            )
                        }
                        <Grid container item direction="row">
                            {
                                reachUs && (
                                    <Hidden mdDown>
                                        <Grid className={classes.background} item xs={12} md={6}>
                                            <ReachUs
                                                email={reachUsEmail}
                                                address={reachUsAddress}
                                                phoneNumber={reachUsPhoneNumber}
                                                displayLogo={displayLogo}
                                            />
                                        </Grid>
                                    </Hidden>
                                )
                            }
                            <Grid item xs={12} md={6}>
                                <Box sx={{ textAlign: 'center', border: '1px solid black' }}>
                                    {form}
                                </Box>
                            </Grid>
                            {
                                reachUs && (
                                    <Hidden mdUp>
                                        <Grid className={classes.background} item xs={12} md={6}>
                                            <ReachUs
                                                email={reachUsEmail}
                                                address={reachUsAddress}
                                                phoneNumber={reachUsPhoneNumber}
                                                displayLogo={displayLogo}
                                            />
                                        </Grid>
                                    </Hidden>
                                )
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Grid>
    );
};

export default FormComponent;
