import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import ExchangeRatesContext from './context';

import { useIsMountedRef } from '../../../common/hooks';
import { ExchangeRatesContextType, ExchangeRatesDataType } from '../../types';
import { QUERY_EXCHANGE_RATES } from '../../queries';

interface Props {
    children: React.ReactNode;
}

const ExchangeRatesProvider = ({ children }: Props): JSX.Element => {
    const isMountedRef = useIsMountedRef();
    const [value, setValue] = useState<ExchangeRatesContextType>([]);

    // Fetch interval in minutes, if we don't have it set, use default of 15 minutes
    const exchangeRateInterval = process.env.REACT_APP_EXCHANGE_UPDATE_INTERVAL_MIN ? parseInt(process.env.REACT_APP_EXCHANGE_UPDATE_INTERVAL_MIN) : 5;
    // Convert minutes into milliseconds
    const EXCHANGE_RATE_UPDATE_INTERVAL = exchangeRateInterval * 60000;

    // Poll for crypto exchange data
    const [getExchangeRates, { data }] = useLazyQuery<ExchangeRatesDataType>(QUERY_EXCHANGE_RATES, {
        pollInterval: EXCHANGE_RATE_UPDATE_INTERVAL
    });

    useEffect(() => {
        if (isMountedRef) {
            // Initialize Exchange Rates
            getExchangeRates();
        }
        if (data && data.exchangeRate && data.exchangeRate.rates && data.exchangeRate.rates.length > 0) {
            setValue((prevState) => (
                [
                    ...prevState,
                    ...data.exchangeRate.rates
                ]
            ));
        }
    }, [data, getExchangeRates, isMountedRef]);

    return (
        <ExchangeRatesContext.Provider value={value}>
            {children}
        </ExchangeRatesContext.Provider>
    );
};

export const useExchangeRatesContext = (): ExchangeRatesContextType => useContext(ExchangeRatesContext);

export default ExchangeRatesProvider;
