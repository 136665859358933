import React from 'react';
import intl from 'react-intl-universal';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';

import Plan from '../../components/Plan';
import QueryResult from '../../../common/components/QueryResult';

import { PricingPackagesDataType, PricingPackageType } from '../../../common/types';
import { BRAND } from '../../../common/constants';
import { QUERY_PRICING_PACKAGES } from '../../../common/queries';
import { useBrandConfigurationContext } from '../../../common/hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
    },
}));

interface Props {
    // Component Properties
    setPlan: (plan: PricingPackageType) => void;
}
const Plans = ({ setPlan }: Props): JSX.Element => {
    const classes = useStyles();
    const brandConfig = useBrandConfigurationContext();

    const { data, loading, error } = useQuery<PricingPackagesDataType>(QUERY_PRICING_PACKAGES, {
        variables: {
            where: {
                enabled: true,
                type: 'DIRECTORY',
                brand: BRAND
            }
        }
    });

    const errorText = intl.get('directory.plans.error').d('An error has occured. Failed to retrieve payment plans.');

    const { companyName } = brandConfig;
    const currentCompanyName = companyName ? companyName : 'Racing Edge';

    const featureText = intl.get('directory.plans.featured', { companyName: currentCompanyName }).d(`Feature your business on the ${currentCompanyName} Directory now!`);
    const noPlansText = intl.get('directory.plans.noPlans').d('There are no available plans to choose from.');

    return (
        <QueryResult data={data} loading={loading} error={error} errorMessage={errorText}>
            {
                ({ pricingPackages }) => {
                    if (pricingPackages && pricingPackages.length > 0) {
                        // There are packages to display
                        const items = pricingPackages.map((packageInfo: PricingPackageType) => (
                            <Grid item xs={12} md={6} key={packageInfo.id}>
                                <Plan packageInfo={packageInfo} setPlan={setPlan} />
                            </Grid>
                        ));
                        return (
                            <Box className={classes.root}>
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body1">{featureText}</Typography>
                                </Box>
                                <Grid container spacing={2}>
                                    {items}
                                </Grid>
                            </Box>
                        );
                    } else {
                        // There are no packages to display
                        return (
                            <Box sx={{ mt: 2 }} className={classes.root}>
                                <Typography variant="body1">{noPlansText}</Typography>
                            </Box>
                        );
                    }
                }
            }
        </QueryResult>
    );
};

export default Plans;
