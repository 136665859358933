import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

import Routes from '../modules/common/routes';
import Announcement from '../modules/common/containers/Announcement';
import CookiePopup from '../modules/common/components/CookiePopup';
import withClearCache from '../modules/common/components/withClearCache';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.paper
    }
}));

const useQueryParam = () => new URLSearchParams(window.location.search);

const App = (): JSX.Element => {
    const classes = useStyles();
    const query = useQueryParam();

    const isEmbedded = query.get('embedded');

    return (
        <Box className={classes.root}>
            <BrowserRouter>
                {
                    process.env.NODE_ENV !== 'production' && (
                        <Helmet>
                            <meta name="robots" content="noindex"></meta>
                        </Helmet>
                    )
                }
                <Routes />
                {!isEmbedded && <Announcement />}
                {!isEmbedded && <CookiePopup />}
            </BrowserRouter>
        </Box>
    );
};


const ClearCacheComponent = withClearCache(App);

const AppWithClearCache = (): JSX.Element => <ClearCacheComponent />;

export default AppWithClearCache;
