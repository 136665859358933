import React, { useState, useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import intl from 'react-intl-universal';
import { Box, Grid, Typography, IconButton, Tooltip } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import update from 'immutability-helper';
import cuid from 'cuid';
import { FileRejection } from 'react-dropzone';

import { FieldDropzone } from '../../../../common/components/fields';

// import { ImageStateType } from '../../../../common/types';
import { AuctionType } from '../../../types';

const useStyles = makeStyles(theme => ({
    remainedBox: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center',
    },
    nbUsedPackage: {
        marginRight: theme.spacing(1),
        fontWeight: 'bold'
    },
    icon: {
        fontSize: '1rem'
    },
    imageItem: {
        position: 'relative',
        width: 'calc(25% - 18px)',
        height: 160,
        border: `1px solid ${theme.palette.common.black}`,
        margin: theme.spacing(1, 0),
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 150px)',
            height: 80
        },
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center'
    },
    cover: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0,0,0,0.5)',
        color: 'white',
        padding: theme.spacing(1),
    },
    actions: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 0,
        margin: theme.spacing(1, 0, 0, 0)
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    button: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        marginRight: theme.spacing(1),
        padding: theme.spacing(0.5),
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        }
    },
    title: {
        fontWeight: 'bold',
        // textTransform: 'capitalize'
    }
}));

interface Props {
    name: string;
    label?: string;
    auction?: AuctionType;
    imageLimit: number;
    images: Array<any>;
    setImages: Dispatch<SetStateAction<Array<any>>>;
    setValue: any;
    errors: any;
    coverIndex?: number;
    setCoverIndex?: any;
}

const ImageDropzone = ({ name, label, auction, imageLimit, images, setImages, errors, setValue, coverIndex, setCoverIndex }: Props): JSX.Element => {
    const classes = useStyles();

    const [fileErrors, setFileErrors] = useState<string>('');
    const [isImagesDeleted, setIsImagesDeleted] = useState<boolean>(false);
    const hasCover = coverIndex !== undefined;

    const hasReachedFileUploadLimit = images.length >= imageLimit;

    useEffect(() => {
        /**
         * Check to see if we have images already (an edit). Also keep the following in mind:
         *
         * - make sure that our images state handler is empty. If not, it will keep duplicating if you save the form data by going to the next step
         * - make sure that if we have already images in our form provider, don't use.
         * - make sure that if we have deleted images, we do not reload the images again
         */
        if ((images && images.length === 0)) {
            if (auction && auction[name] && images.length === 0 && !isImagesDeleted) {
                auction[name].map(async(image) => {
                    const { url, name, mime } = image;
                    const res = await fetch(url, {
                        method: 'GET',
                        cache: 'no-cache',
                        headers: {
                            Origin: window.location.origin
                        }
                    });

                    const blob = await res.blob();
                    const file = new File([blob], name, { type: mime });

                    setImages(prevState => {
                        // If we reach the image limit, stop adding
                        if (prevState.length < imageLimit) {
                            return [
                                ...prevState,
                                {
                                    id: cuid(),
                                    file,
                                    src: url,
                                    // remove extension
                                    name: name.replace(/\.[^/.]+$/, '')
                                }
                            ];
                        }

                        return [
                            ...prevState
                        ];
                    });
                });
            }
        }
    }, [auction, imageLimit, images, images.length, isImagesDeleted, name, setImages]);

    useEffect(() => {
        setValue(name, images);
    }, [images, auction, setValue, name]);

    const removeImage = (itemIndex: number) => {
        if (hasCover) {
            const newCoverIndex = itemIndex < coverIndex ? coverIndex - 1 : coverIndex;
            setCoverIndex(Math.max(Math.min(newCoverIndex, images.length - 2), 0));
        }
        // remove item at index
        setImages((prevState) => {
            if (prevState.length === 1) {
                setIsImagesDeleted(true);
            }

            return update(images, {
                $splice: [[itemIndex, 1]]
            });
        });
    };

    const onDrop = useCallback((acceptedFiles: Array<File>, fileRejections: Array<FileRejection>) => {
        setFileErrors('');
        // Loop through rejected files
        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === 'file-too-large') {
                    const errorText = intl.get('auction.form.media.onDrop.rejectedFile.tooLarge').d('An image was too large to upload. Image size cannot exceed 5 Mb.');
                    setFileErrors(errorText);
                } else if (err.code === 'file-invalid-type') {
                    const errorText = intl.get('auction.form.media.onDrop.rejectedFile.invalidType').d('The image uploaded is invalid. Please only upload .jpg, .jpeg or .png');
                    setFileErrors(errorText);
                } else if (err.code === 'too-many-files') {
                    const errorText = intl.get('auction.form.media.onDrop.rejectedFile.tooManyFiles', { imageLimit }).d(`You can only upload a maximum of ${imageLimit} images`);
                    setFileErrors(errorText);
                }
            });
        });

        // Loop through accepted files
        acceptedFiles.map((file) => {
            // Initialize FileReader browser API
            const reader = new FileReader();
            // onload callback gets called after the reader reads the file data
            reader.onload = (e) => {
                // add the image into the state. Since FileReader reading process is asynchronous, its better to get the latest snapshot state (i.e., prevState) and update it.
                setImages(prevState => {
                    // If we reach the image limit, stop adding
                    if (prevState.length < imageLimit) {
                        return [
                            ...prevState,
                            {
                                id: cuid(),
                                file,
                                src: e?.target?.result as string,
                                name: file.name
                            }
                        ];
                    }

                    return [
                        ...prevState
                    ];
                });
            };

            // Read the file as Data URL (since we accept only images)
            reader.readAsDataURL(file);
            return file;
        });
    }, [imageLimit, setImages]);

    const deleteText = intl.get('generic.action.delete').d('Delete');
    const coverImage = intl.get('generic.label.coverImage').d('Cover Image');

    return (
        <Box>
            <Box sx={{ my: 2 }}>
                <Typography className={classes.title} variant="h2">{label || name}</Typography>
            </Box>
            <Box className={classes.remainedBox}>
                <Typography variant="h4" className={classes.nbUsedPackage}>{images.length}</Typography>
                <Typography variant="body2"> / {imageLimit}</Typography>
            </Box>
            <Grid container direction="column" spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} width="100%">
                    <FieldDropzone
                        id={`form-media-dropzone-${name}`}
                        name={name}
                        accept="image/jpg, image/jpeg, image/png"
                        hasReachedFileUploadLimit={hasReachedFileUploadLimit}
                        onDrop={onDrop}
                        fileLimit={imageLimit}
                    />
                    {!!errors[name] && <Typography variant="body1" color="error">{(errors[name] as any)?.message}</Typography>}
                    {fileErrors.length > 0 && <Typography variant="body1" color="error">{fileErrors}</Typography>}
                </Grid>
                <Grid item xs={12} width="100%">
                    <Box className={classes.imageContainer}>
                        {
                            images.length > 0 && images.map((image, index) => (
                                <Box key={`${name}-image-${index}`} className={classes.imageItem} onClick={() => setCoverIndex && setCoverIndex(index)}>
                                    <img alt={`img - ${image.id}`} src={image.src} className={classes.image} />
                                    {!!(hasCover && coverIndex === index) && (
                                        <Box className={classes.cover}>
                                            <Typography>{coverImage}</Typography>
                                        </Box>
                                    )}
                                    <Box className={classes.actions}>
                                        <Tooltip enterTouchDelay={50} title={deleteText}>
                                            <IconButton
                                                className={classes.button}
                                                size="small"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    removeImage(index);
                                                }}
                                                aria-label="delete"
                                            >
                                                <DeleteIcon className={classes.icon} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ImageDropzone;
