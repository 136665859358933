import React, { useState, useEffect } from 'react';
// import intl from 'react-intl-universal';
import {
    Switch,
    Route,
    useLocation,
} from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import HomePage from '../pages/Home';
import AddPage from '../pages/Add';
import UpdatePage from '../pages/Update';
import BookmarksPage from '../pages/Bookmarks';
import ClaimDirectoryPage from '../pages/ClaimDirectory';
import { CheckpointIsAuthenticated } from '../../common/components/checkpoints';
import BookmarksLoader from '../containers/BookmarksLoader';
import DirectoryHeader from '../containers/Header';

const useStyle = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
    }
}));

const DirectoryRoutes = (): JSX.Element => {
    const classes = useStyle();
    // Location
    const location = useLocation();
    // Last Location Path
    const [lastPath, setLastPath] = useState('');

    // When location changes
    useEffect(() => {
        // Check if the current location is equal to the last location
        if (location.pathname !== lastPath) {
            // Location changed, scroll to top
            window.scrollTo(0, 0);
            // Store new location
            setLastPath(location.pathname);
        }
    }, [location, lastPath]);

    const myRoutes = (
        <Switch>
            {/* Add Results Page */}
            <Route path="/directory/add">
                <AddPage />
            </Route>

            {/* Update Results Page */}
            <Route path="/directory/update">
                <CheckpointIsAuthenticated>
                    <UpdatePage />
                </CheckpointIsAuthenticated>
            </Route>

            {/* Bookmarks Page */}
            <Route path="/directory/favourites">
                <CheckpointIsAuthenticated>
                    <BookmarksPage />
                </CheckpointIsAuthenticated>
            </Route>

            {/* Claim it */}
            <Route path="/directory/claim/:key" render={(route) => (
                <ClaimDirectoryPage directoryKey={route.match.params.key} />
            )} />

            {/* Catch All: Home Page */}
            <Route path="/">
                <HomePage />
            </Route>
        </Switch>
    );

    return (
        <Box className={classes.root}>
            <BookmarksLoader />
            <DirectoryHeader />
            <Box sx={{ maxWidth: '95%', margin: 'auto' }}>
                {myRoutes}
            </Box>
        </Box>
    );
};

export default DirectoryRoutes;
