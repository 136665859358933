import { createContext, useContext } from 'react';

import intl from 'react-intl-universal';
type ContextType = {
    activePageIndex: number;
    steps: number;
    setSteps: (newStep: number) => void;
    handleBack: () => void;
    handleNext: () => void;
}

// We initialise them without default values, to make that happen, we
// apply the Partial helper type.
export const PaymentWizardContext = createContext<Partial<ContextType>>({});

// Validate the context otherwise throw error
export const usePaymentWizardContext = (): Partial<ContextType> => {
    const context = useContext(PaymentWizardContext);

    if (!context) {
        const errorText = intl.get('common.paymentWizard.context.error').d('The Compound Component cannot be rendered outside of the Payment Wizard Component');
        throw new Error(errorText);
    }

    return context;
};

export default usePaymentWizardContext;
